import type {
  publishNodeInput,
  PublishNodeMutation,
  PublishNodeMutationVariables,
} from "../../../API";

import { type FetchResult, useMutation } from "@apollo/client";

import { PUBLISH_NODE } from "../../../common/operations/mutations";

interface PublishNodeInterface {
  publishNode: (
    input: publishNodeInput
  ) => Promise<
    FetchResult<PublishNodeMutation, Record<string, any>, Record<string, any>>
  >;
  data?: PublishNodeMutation | null;
  loading: boolean;
}

export const usePublishNode = (): PublishNodeInterface => {
  const [sendNode, { data, loading }] = useMutation<
    PublishNodeMutation,
    PublishNodeMutationVariables
  >(PUBLISH_NODE);

  const publishNode = async (input: publishNodeInput) => {
    return await sendNode({
      variables: {
        input,
      },
    });
  };

  return { publishNode, data, loading };
};
