import { useMemo, useState } from "react";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import { useGetNodesByCustomer } from "./useGetNodesByCustomer";
import { useGetLocations } from "../../../hooks/useGetLocations";

interface INodeSelectOption {
  title: string | null | undefined;
  value: string | null | undefined;
  location: string | null | undefined;
  locationId: string | null | undefined;
}

interface INodeSelectProps {
  nodeId: string | null | undefined;
  locationId?: string;
  hasError?: boolean;
  disableClearable?: boolean;
  onNodeChange: (value: string) => void;
}

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: INodeSelectOption): string =>
    (option.location ?? "") + (option.title ?? ""),
});

const NodeSelect = ({
  nodeId,
  locationId,
  hasError,
  disableClearable,
  onNodeChange,
}: INodeSelectProps): JSX.Element => {
  const { data, loading } = useGetNodesByCustomer();
  const { locations } = useGetLocations();

  const [inputValue, setInputValue] = useState("");

  const memoizedOptions = useMemo((): Array<INodeSelectOption> => {
    let options = (
      data?.getNodesByCustomer?.items.map((node): INodeSelectOption => {
        const locationId = node?.locationId
          ?.replace("C_", "C#")
          .replace("_L_", "#L#");
        const location = locations?.items?.find(
          (item): boolean => item?.id === locationId
        );

        const nodeId = node?.nodeId
          ?.replace("C_", "C#")
          .replace("_L_", "#L#")
          .replace("_N_", "#N#");

        return {
          title: node?.nodeName,
          value: nodeId,
          location: location?.name ?? "",
          locationId,
        };
      }) ?? []
    )?.sort((a, b): number =>
      b?.location ? -b?.location?.localeCompare(a?.location ?? "") : 1
    );

    if (locationId) {
      options = options.filter((option): boolean => {
        return option.locationId === locationId;
      });
    }

    return options;
  }, [data?.getNodesByCustomer?.items, locationId]);

  const memoizedValue = useMemo((): INodeSelectOption | null => {
    if (!nodeId) {
      return null;
    }

    return (
      memoizedOptions.find((option): boolean => option?.value === nodeId) ??
      null
    );
  }, [nodeId, memoizedOptions]);

  return (
    <Autocomplete
      disableClearable={disableClearable}
      loading={loading}
      value={memoizedValue}
      options={memoizedOptions}
      filterOptions={filterOptions}
      groupBy={(option): string => option?.location ?? ""}
      getOptionLabel={(option): string => option?.title ?? ""}
      onChange={(_event: any, newValue: INodeSelectOption | null): void => {
        if (newValue) {
          onNodeChange(newValue?.value ?? "");
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue): void => {
        setInputValue(newInputValue);
      }}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          label="Node"
          error={hasError && !memoizedValue?.value}
          helperText={
            hasError && !memoizedValue?.value ? "Please select a node" : ""
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default NodeSelect;
