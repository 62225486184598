import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { GetDevicesQuery, GetDevicesQueryVariables } from "../../../../API";
import client from "../../../../configs/apolloClient";
import { useCustomerIdGuard } from "../../../hooks/useCustomerIdGuard";
import { GET_DEVICES } from "../../../operations/queries";
import { errorNotification } from "../../../variables/notification";

const DEVICES_SELECT_ITEMS = 50;

export const useGetDevicesByLocation = (selectedLocationId?: string | null) => {
  const selectedCustomerId = useCustomerIdGuard();

  useEffect(() => {
    if (selectedCustomerId && selectedLocationId) {
      fetchDevices(selectedCustomerId, selectedLocationId);
    }
  }, [selectedCustomerId, selectedLocationId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();
    errorNotification();
  };

  const [getDevices, { data, loading }] = useLazyQuery<
    GetDevicesQuery,
    GetDevicesQueryVariables
  >(GET_DEVICES, {
    fetchPolicy: "network-only",
  });

  const fetchDevices = (
    customerId: string,
    locationId?: string | null,
    nextToken?: string
  ): void => {
    getDevices({
      variables: {
        limit: DEVICES_SELECT_ITEMS,
        customerId,
        locationId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getDevices.nextToken) {
          fetchDevices(
            customerId,
            locationId,
            response.data?.getDevices.nextToken
          );
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading };
};
