import { useEffect } from "react";

import { Box, CardMedia } from "@mui/material";

import { allowedFileTypes, extractFileNameExt } from "./fileHelper";

interface ThumbnailInterface {
  src: string;
  controls?: boolean;

  handleOnCLick?: () => void;
}

const Thumbnail = ({
  src,
  controls,
  handleOnCLick,
}: ThumbnailInterface): JSX.Element => {
  const isImage = allowedFileTypes.images.some(
    (ext): boolean => ext === extractFileNameExt(src)?.toLowerCase()
  );
  const isVideo = !allowedFileTypes.images.some(
    (ext): boolean => ext === extractFileNameExt(src)?.toLowerCase()
  );

  useEffect((): void => {
    const video = document.getElementById("video") as HTMLVideoElement;

    if (video) {
      video.playbackRate = 2;
    }
  }, [src]);

  return (
    <Box sx={{ cursor: "pointer" }} onClick={handleOnCLick}>
      {isImage && src && (
        <CardMedia
          sx={{ maxHeight: "380px" }}
          component="img"
          height="auto"
          src={src}
        />
      )}
      {isVideo && src && (
        <CardMedia
          sx={{ maxHeight: "calc(100vh - 104px)" }}
          id="video"
          component="video"
          height="auto"
          controls={controls}
          src={src}
        />
      )}
    </Box>
  );
};

export default Thumbnail;
