import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import TableNoDataOverlay from "../../../../common/components/table/TableNoDataOverlay";
import { useAnnotationsTableColumns } from "../hooks/useAnnotationsTableColumns";

import { ANNOTATIONS_TABLE_LIMIT } from "../hooks/useGetAnnotations";

const AnnotationsTable = ({
  rows,
  loading,
}: {
  serviceId: string;
  zoneId: string;
  rows: any;
  loading: any;
}): JSX.Element => {
  const { columns } = useAnnotationsTableColumns();

  return (
    <DataGrid
      sx={{
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 600,
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
      }}
      rowsPerPageOptions={[ANNOTATIONS_TABLE_LIMIT]}
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: TableNoDataOverlay,
      }}
      autoHeight
      disableSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: any }): string => row.rowId as string}
      loading={loading}
      pageSize={ANNOTATIONS_TABLE_LIMIT}
    />
  );
};

export default AnnotationsTable;
