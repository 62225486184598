import { useReactiveVar } from "@apollo/client";
import { Alert, Slide, Snackbar, Typography } from "@mui/material";

import {
  defaultNotificationVariable,
  notificationVariable,
} from "../../variables/notification";

const DEFAULT_AUTO_HIDE_DURATION = 3000; // 3s

const Notification = (): JSX.Element | null => {
  const { isOpen, message, severity } = useReactiveVar(notificationVariable);

  const clearNotification = (): void => {
    notificationVariable(defaultNotificationVariable);
  };

  if (!severity && !isOpen) {
    return null;
  }

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={DEFAULT_AUTO_HIDE_DURATION}
      onClose={clearNotification}
      TransitionComponent={Slide}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <Alert
        elevation={2}
        variant="filled"
        onClose={clearNotification}
        severity={severity}
      >
        <Typography
          variant="body2"
          sx={{
            width: 320,
          }}
        >
          {message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default Notification;
