import { useMemo } from "react";

import { useGetLazyPaginatedAlerts } from "./useGetLazyPaginatedAlerts";

export interface IAlertsTableRow {
  rowId: string;
  alertName: string;
  location: string;
  model: string;
  alertType: string;
  status: boolean;
  email: string;
  additionalEmails?: (string | null)[];
  node?: string;
  customService?: string;
  jsonObject?: string | null;
}

export interface IUseAlertsTableRow {
  rows: IAlertsTableRow[];
  loading: boolean;
}

export const useAlertsTableRow = (): IUseAlertsTableRow => {
  const { data, loading } = useGetLazyPaginatedAlerts();

  const rows = useMemo((): IAlertsTableRow[] => {
    return (
      data?.getAllAlerts.items?.map(item => ({
        rowId: item?.id ?? "",
        alertName: item?.alertName ?? "",
        location: item?.location ?? "",
        model: item?.model ?? "",
        alertType: item?.alertType ?? "",
        status: item?.status ?? false,
        email: item?.email ?? "",
        additionalEmails: item?.additionalEmails ?? [],
        node: item?.node ?? "",
        customService: item?.customService ?? "",
        jsonObject: item?.jsonObject,
      })) ?? []
    );
  }, [data?.getAllAlerts.items]);

  return { rows, loading };
};
