import { ChangeEvent, useState } from "react";

import { Autocomplete, Chip, Grid, TextField } from "@mui/material";

import {
  locationFormRules,
  locationFormValidationStateVariable,
  locationFormVariables,
  useLocationFormValidationState,
  useLocationFormVariables,
} from "../variables/locationData";

const LocationForm = (): JSX.Element => {
  const form = useLocationFormVariables();
  const validation = useLocationFormValidationState();

  const [, setTags] = useState<string[]>([]);
  const [tagsList] = useState(["NORTH", "SOUTH", "DJ"]);

  const setLocationName = (event: ChangeEvent<HTMLInputElement>): void => {
    const locationName = event.target.value;

    locationFormVariables({
      ...form,
      locationName,
    });

    let hasError = !locationName.match(locationFormRules.locationName.pattern);
    let errorMessage = "";

    if (hasError) {
      errorMessage = "Valid characters: A-Z, a-z, 0-9";
    }

    // NOTE: This rule is handled on submit
    if (hasError && !locationName) {
      hasError = false;
      errorMessage = "";
    }

    locationFormValidationStateVariable({
      ...validation,
      locationName: {
        hasError,
        errorMessage,
      },
    });
  };

  const updateLocationFormVariable = (key: string, value: any): void => {
    locationFormVariables({
      ...form,
      [key]: value,
    });

    locationFormValidationStateVariable({
      ...validation,
      [key]: {
        hasError: false,
        errorMessage: "",
      },
    });
  };

  return (
    <Grid container justifyContent="center" direction="column" spacing={3}>
      <Grid item>
        <TextField
          fullWidth
          size="medium"
          id="locationName"
          label="Name"
          error={validation?.locationName.hasError}
          helperText={
            validation?.locationName.hasError &&
            validation?.locationName.errorMessage
          }
          value={form.locationName}
          onChange={setLocationName}
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          size="medium"
          id="latitude"
          label="Latitude"
          error={validation?.latitude.hasError && !form?.latitude}
          helperText={
            validation?.latitude.hasError && validation?.latitude.errorMessage
          }
          value={form.latitude}
          onChange={(e): void =>
            updateLocationFormVariable(e.target.id, e.target.value)
          }
        />
      </Grid>
      <Grid item>
        <TextField
          fullWidth
          size="medium"
          id="longitude"
          label="Longitude"
          error={validation?.longitude.hasError && !form?.longitude}
          helperText={
            validation?.longitude.hasError && validation?.longitude.errorMessage
          }
          value={form.longitude}
          onChange={(e): void =>
            updateLocationFormVariable(e.target.id, e.target.value)
          }
        />
      </Grid>
      <Grid item>
        <Autocomplete
          value={form.tags}
          onChange={(event, newValue): void => {
            setTags(newValue);
            updateLocationFormVariable("tags", newValue);
          }}
          multiple
          size="medium"
          id="tags-filled"
          options={tagsList}
          renderTags={(value, getTagProps): JSX.Element[] =>
            value.map(
              (option, index): JSX.Element => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  key={option}
                />
              )
            )
          }
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              variant="outlined"
              label="Tags (optional)"
              placeholder="Tag"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default LocationForm;
