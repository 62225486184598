import { forwardRef } from "react";

import { Slide, SlideProps } from "@mui/material";

const TransitionUp = forwardRef<unknown, SlideProps>(
  (props, ref): JSX.Element => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

TransitionUp.displayName = "TransitionUp";

export default TransitionUp;
