import { Box, FormLabel } from "@mui/material";

const OutlinedBox = ({
  paddingSpacing,
  gapSpacing,
  borderStyle,
  borderRadius,
  backgroundColor,
  label,
  children,
}) => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      marginTop: "10px",
    }}
  >
    {label && (
      <FormLabel
        sx={{
          marginTop: "-0.71em",
          paddingLeft: "0.44em",
          paddingRight: "0.44em",
          zIndex: 2,
          backgroundColor: backgroundColor || "#ffffff",
          position: "absolute",
          width: "auto",
        }}
      >
        {label}
      </FormLabel>
    )}
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        border: borderStyle || "1px solid #ccc",
        borderRadius: borderRadius || "4px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          padding: paddingSpacing || "0px",
          gap: gapSpacing || "0px",
          display: "flex",
          flexWrap: "wrap",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  </Box>
);

export default OutlinedBox;
