import {
  type FetchResult,
  type OperationVariables,
  useMutation,
} from "@apollo/client";

import type {
  AddCustomerMutation,
  AddCustomerMutationVariables,
} from "../../API";
import { ADD_CUSTOMER } from "../operations/mutations";

export const useCreateCustomer = (): [
  createCutomer: ({
    customerName,
    additionalAdminGroupId,
    domains,
  }: AddCustomerMutationVariables) => Promise<FetchResult<AddCustomerMutation>>,
  variables: OperationVariables
] => {
  const [addCustomer, { data, loading, error }] = useMutation(ADD_CUSTOMER);

  const createCutomer = async ({
    customerName,
    additionalAdminGroupId,
    domains,
    userPoolId,
    bucketName,
  }: AddCustomerMutationVariables) => {
    return await addCustomer({
      variables: {
        customerName,
        additionalAdminGroupId,
        domains,
        bucketName,
        userPoolId,
      },
      refetchQueries: ["GetCustomers"],
    });
  };

  return [createCutomer, { data, loading, error }];
};
