import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import LocationIcon from "../../icons/Location";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import NodeIcon from "../../icons/Node";
import DeviceIcon from "../../icons/Device";
import AIManagerIcon from "../../icons/AIManager";
import GasLeakEventsIcon from "../../icons/GasLeakEvents";
import SystemSettingsIcon from "../../icons/SystemSettings";

const listItemButtonCss = {
  borderRadius: "8px",
  "&.MuiListItemButton-root": {
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: "4px",
    marginBottom: "4px",
  },
};

const listItemButtonCssNoAddIcon = {
  borderRadius: "8px",
  "&.MuiListItemButton-root": {
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: "4px",
    marginBottom: "4px",
    height: "40px",
  },
};

const listItemTextCss = {
  fontWeight: 500,
};

const CcAdminMenuView = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  return (
    <div>
      <Link to={"/locations"}>
        <Box
          component="img"
          sx={{ width: "100%" }}
          alt="logo-image"
          src={
            theme.palette.mode === "dark"
              ? "/Autonomous365 White.png"
              : "/Autonomous365 Dark.png"
          }
        />
      </Link>
      <List sx={{ paddingTop: "52px" }}>
        <ListItem disablePadding onClick={(): void => navigate("/locations")}>
          <ListItemButton
            selected={pathname.includes("/locations")}
            sx={{ ...listItemButtonCss }}
          >
            <ListItemIcon>
              <LocationIcon />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
              primary="Locations"
            />
            <IconButton
              onClick={(e): void => {
                e.stopPropagation();
                navigate("/locations/create");
              }}
            >
              <AddCircleOutlineOutlinedIcon
                sx={{ color: theme.palette.text.primary }}
              />
            </IconButton>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={(): void => navigate("/nodes")}>
          <ListItemButton
            selected={pathname.includes("/nodes")}
            sx={{ ...listItemButtonCss }}
          >
            <ListItemIcon>
              <NodeIcon />
            </ListItemIcon>
            <ListItemText
              primary="Nodes"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
            <IconButton
              onClick={(e): void => {
                e.stopPropagation();
                navigate("/nodes/create");
              }}
            >
              <AddCircleOutlineOutlinedIcon
                sx={{ color: theme.palette.text.primary }}
              />
            </IconButton>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={(): void => navigate("/devices")}>
          <ListItemButton
            selected={pathname.includes("/devices")}
            sx={{ ...listItemButtonCss }}
          >
            <ListItemIcon>
              <DeviceIcon />
            </ListItemIcon>
            <ListItemText
              primary="Devices"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
            <IconButton
              onClick={(e): void => {
                e.stopPropagation();
                navigate("/devices/create");
              }}
            >
              <AddCircleOutlineOutlinedIcon
                sx={{ color: theme.palette.text.primary }}
              />
            </IconButton>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={(): void => navigate("/model")}>
          <ListItemButton
            selected={pathname.includes("/model")}
            sx={{ ...listItemButtonCss }}
          >
            <ListItemIcon>
              <AIManagerIcon />
            </ListItemIcon>
            <ListItemText
              primary="AI Manager"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
            <IconButton
              onClick={(e): void => {
                e.stopPropagation();
                navigate("/model/create");
              }}
            >
              <AddCircleOutlineOutlinedIcon
                sx={{ color: theme.palette.text.primary }}
              />
            </IconButton>
          </ListItemButton>
        </ListItem>

        {/* <ListItem disablePadding>
          <ListItemButton
            selected={pathname === "/human-validator"}
            sx={{ ...listItemButtonCss }}
            onClick={(): void => navigate("/human-validator")}
          >
            <ListItemIcon>
              <HumanValidatorIcon />
            </ListItemIcon>
            <ListItemText
              primary="Human Validator"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={pathname === "/hv-review"}
            sx={{ ...listItemButtonCss }}
            onClick={(): void => navigate("/hv-review")}
          >
            <ListItemIcon>
              <HumanValidatorReviewIcon />
            </ListItemIcon>
            <ListItemText
              primary="HV Review"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding>
          <ListItemButton
            selected={pathname === "/gasLeakTest"}
            sx={{ ...listItemButtonCssNoAddIcon }}
            onClick={(): void => navigate("/gasLeakTest")}
          >
            <ListItemIcon>
              <GasLeakEventsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Gas Leak Events"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={pathname === "/system-settings"}
            sx={{ ...listItemButtonCssNoAddIcon }}
            onClick={(): void => navigate("/system-settings")}
          >
            <ListItemIcon>
              <SystemSettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary="System Settings"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton
            selected={pathname === "/webgl-dashboard"}
            sx={{ ...listItemButtonCssNoAddIcon }}
            onClick={(): void => navigate("/webgl-dashboard")}
          >
            <ListItemIcon>
              <ViewInArIcon />
            </ListItemIcon>
            <ListItemText
              primary="Webgl Dashboard"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            selected={pathname === "/email-notifications"}
            onClick={(): void => navigate("/email-notifications")}
          >
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Email Notifications" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export default CcAdminMenuView;
