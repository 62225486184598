import { makeVar, useReactiveVar } from "@apollo/client";

import { IFormValidationState } from "../../../../../common/models/formValidation";

interface ISystemAlertForm {
  alertName: string;
  node: string;
  customService: string;
  jsonObject: string;
}

export const defaultSystemAlertFormVariables: ISystemAlertForm = {
  alertName: "",
  node: "",
  customService: "",
  jsonObject: "",
};

interface ISystemAlertFormValidationState {
  alertName: IFormValidationState;
}

export const defaultSystemAlertFormValidationState: ISystemAlertFormValidationState =
  {
    alertName: {
      hasError: false,
      errorMessage: "",
    },
  };

export const ISystemAlertFormRules = {
  alertName: {
    required: true,
  },
};

export const systemAlertFormVariables = makeVar<ISystemAlertForm>(
  defaultSystemAlertFormVariables
);

export const useSystemAlertFormVariables = () =>
  useReactiveVar(systemAlertFormVariables);

export const systemAlertFormValidationState =
  makeVar<ISystemAlertFormValidationState>(
    defaultSystemAlertFormValidationState
  );

export const useSystemAlertFormValidationState = () =>
  useReactiveVar(systemAlertFormValidationState);
