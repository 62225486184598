const GasLeakEventsIcon = (): JSX.Element => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4717 9.2L22.685 5.55071C22.9112 5.39235 23.223 5.44737 23.3813 5.6736C23.4401 5.75764 23.4717 5.85774 23.4717 5.96033V18.0397C23.4717 18.3158 23.2478 18.5397 22.9717 18.5397C22.8691 18.5397 22.769 18.5081 22.685 18.4493L17.4717 14.8V19C17.4717 19.5523 17.024 20 16.4717 20H2.47168C1.9194 20 1.47168 19.5523 1.47168 19V5C1.47168 4.44772 1.9194 4 2.47168 4H16.4717C17.024 4 17.4717 4.44772 17.4717 5V9.2ZM17.4717 12.3587L21.4717 15.1587V8.84131L17.4717 11.6413V12.3587ZM3.47168 6V18H15.4717V6H3.47168ZM5.47168 8H7.47168V10H5.47168V8Z"
        fill="black"
      />
      <path
        d="M11.9123 11.4015C11.9123 10.9594 11.5539 10.601 11.1118 10.601C10.6697 10.601 10.3113 10.9594 10.3113 11.4015C10.3113 11.8436 10.6697 12.202 11.1118 12.202C11.5539 12.202 11.9123 11.8436 11.9123 11.4015ZM13.5133 11.4015C13.5133 12.7279 12.4381 13.803 11.1118 13.803C9.78546 13.803 8.71027 12.7279 8.71027 11.4015C8.71027 10.0752 9.78546 9 11.1118 9C12.4381 9 13.5133 10.0752 13.5133 11.4015ZM11.1132 16.2045C10.3393 16.2045 9.63997 16.5173 9.13208 17.0251L8 15.893C8.79587 15.0972 9.89765 14.6035 11.1132 14.6035C12.3288 14.6035 13.4306 15.0972 14.2264 15.893L13.0944 17.0251C12.5865 16.5173 11.8871 16.2045 11.1132 16.2045Z"
        fill="black"
      />
    </svg>
  );
};

export default GasLeakEventsIcon;
