import type {
  NetworkStatus,
  ApolloQueryResult,
  FetchMoreQueryOptions,
  OperationVariables,
} from "@apollo/client";
import { useEffect, useMemo } from "react";

import {
  ValidationState,
  type GetEventsPendingHumanValidationQuery,
  type GetEventsPendingHumanValidationQueryVariables,
  type HumanValidationEvent,
} from "../../../API";
import { notificationVariable } from "../../../common/variables/notification";
import { useGetHumanValidatorTable } from "./useGetHumanValidatorTable";
import { useListenToHumanValidationEvents } from "./useListenToHumanValidationEvents";

export interface PendingEventRow {
  rowId: string;
  customerId: string;
  locationId: string;
  nodeId: string;
  serviceId: string;
  validationState: ValidationState | null | undefined;
  data: string | null;

  date: string;
  eventId: string;
  eventTime: string;
  model: string;
  cameraName: string;
  mediaOutput: string;
  location: string;
}

interface GetHumanValidatorTableDataInterface {
  rows: PendingEventRow[];
  nextToken?: string | null;
  loading: boolean;
  networkStatus: NetworkStatus;
  fetchMore: (
    fetchMoreOptions: FetchMoreQueryOptions<
      GetEventsPendingHumanValidationQueryVariables,
      GetEventsPendingHumanValidationQuery
    > & {
      updateQuery?: (
        previousQueryResult: GetEventsPendingHumanValidationQuery,
        options: {
          fetchMoreResult: GetEventsPendingHumanValidationQuery;
          variables: OperationVariables;
        }
      ) => GetEventsPendingHumanValidationQuery;
    }
  ) => Promise<ApolloQueryResult<GetEventsPendingHumanValidationQuery>>;
}

export const useGetHumanValidatorTableData =
  (): GetHumanValidatorTableDataInterface => {
    const { data, error } = useListenToHumanValidationEvents();

    console.log("useListenToHumanValidationEvents", data, error);

    const { pendingEvents, nextToken, loading, networkStatus, fetchMore } =
      useGetHumanValidatorTable();

    useEffect((): void => {
      if (
        data &&
        data.listenToHumanValidationEvents?.validationState ===
          ValidationState.IN_VALIDATION
      ) {
        notificationVariable({
          isOpen: true,
          message: `Event ${data?.listenToHumanValidationEvents?.id} was taken for validation`,
          severity: "info",
        });
      }
    }, [data]);

    const rows = useMemo((): PendingEventRow[] => {
      return (
        pendingEvents
          ?.map((item: HumanValidationEvent): PendingEventRow => {
            let eventData = null;

            if (item.data) {
              eventData = JSON.parse(item.data);
            }

            const validationState =
              data && data?.listenToHumanValidationEvents?.id === item.id
                ? data?.listenToHumanValidationEvents?.validationState
                : item.validationState;

            return {
              rowId: item.id,
              customerId: item.customerId,
              locationId: item.locationId,
              nodeId: item.nodeId,
              serviceId: item.serviceId,
              validationState,
              data: eventData,

              date: eventData?.Timestamp,
              eventId: item.id,
              eventTime: item?.eventTime,
              model: item.serviceId,
              cameraName: eventData.camera_id,
              mediaOutput: eventData.mediaOutput,
              location: eventData.location,
            };
          })
          .filter((item): boolean =>
            data || item.validationState === ValidationState.IN_VALIDATION
              ? item.rowId !== data?.listenToHumanValidationEvents?.id
              : !!item
          ) ?? []
      );
    }, [pendingEvents, data]);

    return { rows, nextToken, loading, networkStatus, fetchMore };
  };
