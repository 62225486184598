import { type ApolloError, useSubscription } from "@apollo/client";

import type { ListenToHumanValidationEventsSubscription } from "../../../API";
import { LISTEN_TO_HUMAN_VALIDATION_EVENTS } from "../../../common/operations/subscriptions";

interface ListenToHumanValidationEventsInterface {
  data?: ListenToHumanValidationEventsSubscription;
  loading: boolean;
  error?: ApolloError;
}

export const useListenToHumanValidationEvents =
  (): ListenToHumanValidationEventsInterface => {
    const { data, loading, error } =
      useSubscription<ListenToHumanValidationEventsSubscription>(
        LISTEN_TO_HUMAN_VALIDATION_EVENTS,
        {
          fetchPolicy: "no-cache",
        }
      );

    return { data, loading, error } as const;
  };
