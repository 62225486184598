import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Auth } from "aws-amplify";

import env from "../../../../../environment.json";
import appSyncConfig from "../../aws-exports.json";

interface IUseCurrentUserSession {
  authenticated: boolean;
  isLoading: boolean;
  customerId: string;
  role: string | null;
  userGroups: Array<string> | null;
}

export const useCurrentUserSession = (): IUseCurrentUserSession => {
  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [userGroups, setUserGroups] = useState(null);
  const [role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const resetState = (): void => {
    setAuthenticated(false);
    setUserGroups(null);
    setRole(null);
    setCustomerId("");
  };

  useEffect(() => {
    setIsLoading(true);

    if (window.location.hostname.includes("localhost")) {
      if (env?.stacks.webapp.domain.subdomains.length) {
        const targetDomain = `${env.name}.${env.stacks.webapp.domain.apex}`;

        Auth.configure({
          ...appSyncConfig,
          endpoint: `https://${targetDomain}/auth`,
        });
      }
    } else {
      Auth.configure({
        ...appSyncConfig,
        endpoint: `https://${window.location.hostname}/auth`,
      });
    }

    Auth.currentSession()
      .then(user => {
        if (user) {
          const userClaims = user.getIdToken().payload;
          const userGroupsAsRawString = userClaims.user_groups;
          const role = userClaims.role;
          const customer = userClaims.customer_id ?? "";

          if (userGroupsAsRawString) {
            setAuthenticated(true);
            setUserGroups(JSON.parse(userGroupsAsRawString));
            setRole(role);
            setCustomerId(customer);
          } else {
            resetState();

            navigate("/unauthorized", { replace: true });
          }
        }
      })
      .catch(err => {
        resetState();
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return { authenticated, userGroups, role, customerId, isLoading } as const;
};
