import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { GetServicesQuery, GetServicesQueryVariables } from "../../../API";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { GET_SERVICES } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";

export const SERVICES_TABLE_LIMIT = 100;

interface IUseGetServices {
  data?: GetServicesQuery;
  loading: boolean;
}

export const useGetServices = (): IUseGetServices => {
  const selectedCustomerId = useCustomerIdGuard();

  useEffect((): void => {
    if (selectedCustomerId) {
      fetchServices(selectedCustomerId, SERVICES_TABLE_LIMIT);
    }
  }, [selectedCustomerId]);

  const [getServices, { data, loading }] = useLazyQuery<
    GetServicesQuery,
    GetServicesQueryVariables
  >(GET_SERVICES, {
    fetchPolicy: "network-only",
    refetchWritePolicy: "merge",
  });

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();
    errorNotification();
  };

  const fetchServices = (
    customerId: string,
    limit: number,
    nextToken?: string
  ): void => {
    getServices({
      variables: {
        customerId,
        limit,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getServices.nextToken) {
          fetchServices(
            customerId,
            SERVICES_TABLE_LIMIT,
            response.data?.getServices.nextToken
          );
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading };
};
