import React from "react";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import {
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
}

const StyledDialogTitle = (props: DialogTitleProps): JSX.Element => {
  const { children, onClose, ...other } = props;

  const theme = useTheme();

  return (
    <DialogTitle
      sx={{
        p: 0,
      }}
      {...other}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">{children}</Typography>

        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 14,
            top: 18,
            color: theme.palette.text.primary,
          }}
        >
          <HighlightOffRoundedIcon />
        </IconButton>
      </Stack>
    </DialogTitle>
  );
};

export default StyledDialogTitle;
