import { useGetZones } from "./useGetZones";

export const useZoneTableRows = (serviceId: string) => {
  const { data, loading } = useGetZones(serviceId);

  const rows =
    data?.getZones?.items
      ?.map((item, index) => {
        return {
          id: index + 1,
          rowId: item?.name,
          name: item?.name,
          pan: item?.pan,
          tilt: item?.tilt,
          orderNumber: item?.orderNumber,
          mediaUrl: item?.mediaUrl,
          deviceId: item?.deviceId,
          status: item?.status ? "Active" : "Inactive",
          serviceId: item?.serviceId,
        };
      })
      .filter(item => {
        return item;
      }) ?? [];

  return { rows, loading };
};
