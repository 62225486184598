import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { GetServicesQuery, GetServicesQueryVariables } from "../../../../API";
import client from "../../../../configs/apolloClient";
import { useCustomerIdGuard } from "../../../hooks/useCustomerIdGuard";
import { GET_SERVICES } from "../../../operations/queries";
import { errorNotification } from "../../../variables/notification";

const SERVICES_SELECT_ITEMS = 100;

export const useGetServicesByLocation = (
  selectedLocationId?: string | null
) => {
  const selectedCustomerId = useCustomerIdGuard();

  useEffect(() => {
    if (selectedCustomerId && selectedLocationId) {
      fetchServices(selectedCustomerId, selectedLocationId);
    }
  }, [selectedCustomerId, selectedLocationId]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();
    errorNotification();
  };

  const [getServices, { data, loading }] = useLazyQuery<
    GetServicesQuery,
    GetServicesQueryVariables
  >(GET_SERVICES, {
    fetchPolicy: "network-only",
  });

  const fetchServices = (
    customerId: string,
    locationId?: string | null,
    nextToken?: string
  ): void => {
    getServices({
      variables: {
        limit: SERVICES_SELECT_ITEMS,
        customerId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getServices.nextToken) {
          fetchServices(
            customerId,
            locationId,
            response.data?.getServices.nextToken
          );
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading };
};
