import { Box } from "@mui/material";

import DashboardPageHeader from "./components/DashboardPageHeader";
import DashboardGrid from "./components/DashboardGrid";
import CreateProjectModal from "./components/CreateProjectModal";
import DeleteProjectModal from "./components/DeleteProjectModal";

const WebglConfiguratorDashboardPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        paddingBottom: "1.25em",
      }}
    >
      <DashboardPageHeader />
      <DashboardGrid />
      <CreateProjectModal />
      <DeleteProjectModal />
    </Box>
  );
};

export default WebglConfiguratorDashboardPage;
