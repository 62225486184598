import { useEffect, useRef, useState } from "react";

import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";

import { isEqual } from "lodash";
import { useGetDevicesByLocation } from "../../../../common/components/select/DeviceSelect/useGetDevicesByLocation";
import ServiceSelect from "../../../../common/components/select/ServiceSelect/ServiceSelect";
import ZoneSelect from "../../../../common/components/select/ZoneSelect/ZoneSelect";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { useUpdateShadow } from "../../../../common/hooks/useUpdateShadow";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";
import { useGetServices } from "../../../model-manager/hooks/useGetServices";
import { usePublishNode } from "../../hooks/usePublishNode";
import { useUpdateDevice } from "../../hooks/useUpdateDevice";
import PTConfigPage from "../PTConfigPage";
import AnnotationsTableContainer from "../hooks/AnnotationsTableContainer";
import { useAnnotationsTableRows } from "../hooks/useAnnotationsTableRows";
import { useDeleteAllAnnotations } from "../hooks/useDeleteAllAnnotations";
import ImageAnnotation from "./ImageAnnotation";

const GasLeakTabContent = ({
  streamImage,
  nodeId,
  deviceName,
  rtspSource,
}: {
  streamImage: string;
  nodeId: string;
  deviceName: string;
  rtspSource: string;
}): JSX.Element => {
  const [selectedServiceId, setSelectedServiceId] = useState("");
  const [selectedZoneId, setSelectedZoneId] = useState("");
  const [open, setOpen] = useState(false);

  const selectedCustomerId = useCustomerIdGuard();

  // hook to send message to node via MQTT
  const { publishNode } = usePublishNode();

  // hook to delete all annotations from DDB
  const { deleteAllAnnotations: deleteAllAnnotationsHookFunction } =
    useDeleteAllAnnotations();

  // hook to update device shadow
  const { updateDeviceShadow } = useUpdateShadow();

  // hook to update device in DDB
  const { updateDevice } = useUpdateDevice();

  // hook that gets annotation table rows from DDB
  const { rows, loading } = useAnnotationsTableRows({
    zoneId: selectedZoneId,
    serviceId: selectedServiceId,
  });

  // REACT RENDER LOGIC THAT HANDLES NODE CONFIG
  // const previousRows = useRef(rows);
  const thingName = nodeId.replace(/#/g, "_");
  // update device shadow if queried data is different than before
  // useEffect((): void => {
  //   if (!isEqual(previousRows.current, rows) && rows.length !== 0) {
  //     updateShadowFromDevice(thingName, {
  //       nodeId: nodeId,
  //       locationId: nodeId.split("#N#")[0],
  //       annotations: rows,
  //     });
  //     previousRows.current = rows;
  //   }
  // }, [rows]);

  const { data } = useGetDevicesByLocation(nodeId.split("#N#")[0]);
  const { data: services } = useGetServices();

  const servicesArray = services?.getServices.items;

  let savedServiceType: string | null = null;
  if (servicesArray) {
    for (const item of servicesArray) {
      if (item && item.id === selectedServiceId && item.serviceType) {
        savedServiceType = item.serviceType;
        break;
      }
    }
  }

  const handleDelete = (): void => {
    const newArray = rows.map((row: { name: any }): any => ({
      pk1: `A#${row.name}`,
      sk: selectedServiceId,
    }));
    deleteAllAnnotationsHookFunction(newArray);
  };

  const setPTAPIIP = (): void => {
    updateDevice({
      customerId: selectedCustomerId,
      deviceId: selectedCustomerId + "#DE#" + deviceName,
      deviceData: JSON.stringify({
        pt_api_ip: prompt(
          "What is the PT API IP for this device's pan tilt motor?"
        ),
      }),
    });
  };
  const previousRows = useRef(rows);

  useEffect((): void => {
    if (!isEqual(previousRows.current, rows)) {
      updateDeviceShadow(thingName ?? "", {
        nodeId: nodeId,
        locationId: nodeId.split("#N#")[0],
        exclusions: rows,
        cameras: data?.getDevices.items,
      });
      previousRows.current = rows;
    }
  }, [rows]);

  const onConfigureZonesClick = (): void => {
    if (!selectedServiceId) {
      return;
    }

    setOpen(!open);
    publishNode({
      message: JSON.stringify({
        TARGET: "PANTILT",
        ACTION: "PT_START",
        instance_name: selectedServiceId,
        device_name: deviceName,
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });

    // alert node agent to start "PT stream"
    publishNode({
      message: JSON.stringify({
        TARGET: "CAMERA_MANAGER",
        ACTION: "STREAM_START",
        instance_name: selectedServiceId,
        model_name: savedServiceType,
        device_name: deviceName,
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });
  };

  // turns off "PT stream"
  const endStream = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "CAMERA_MANAGER",
        ACTION: "STREAM_END",
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });
  };

  const handleDialogClose = (): void => {
    endStream();
    setOpen(false);
  };

  // const handleSendAllAnnotationsToNodeAgent = (): void => {
  //   updateShadowFromDevice("C_chevron_L_newcustomertest1_N_testnode9", {
  //     nodeId: nodeId,
  //     locationId: nodeId.split("#N#")[0],
  //     annotations: rows,
  //   });
  // };

  return (
    <Grid container spacing={2}>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="lg"
        sx={{ boxShadow: 3 }}
      >
        <DialogContent>
          <PTConfigPage
            handleDialogClose={handleDialogClose}
            streamImage={streamImage}
            nodeId={nodeId}
            deviceName={deviceName}
            rtspSource={rtspSource}
            serviceId={selectedServiceId}
          />
        </DialogContent>
      </Dialog>

      <Grid item xs={6}>
        <Grid item>
          <ImageAnnotation
            zoneId={selectedZoneId}
            serviceId={selectedServiceId}
            rows={rows}
          />
          <Grid container flexDirection="row">
            <Grid>
              <Typography marginRight={1}>Selected zone:</Typography>
              <ZoneSelect
                onZoneChange={setSelectedZoneId}
                zoneId={selectedZoneId}
                serviceId={selectedServiceId}
              />
            </Grid>
            <Grid>
              <Typography marginRight={1}>Selected Service:</Typography>
              <ServiceSelect
                onServiceChange={setSelectedServiceId}
                locationId={nodeId.split("#N#")[0]}
                serviceId={selectedServiceId}
              />
            </Grid>
          </Grid>
        </Grid>
        <br />

        {rows.length > 0 && (
          <Grid item>
            <Button
              variant="contained"
              color="error"
              onClick={(): void => handleDelete()}
            >
              DELETE ALL ANNOTATIONS
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item xs={6} alignItems="flex-start">
        <Grid container direction="column">
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="flex-end"
            padding={1}
          >
            <AnnotationsTableContainer rows={rows} loading={loading} />
          </Grid>
          <Grid item xs={6} container>
            <Grid
              container
              justifyContent="space-evenly"
              alignItems="center"
              direction="row"
              padding={1}
            >
              <Typography
                variant="body1"
                padding={2}
                style={{ fontFamily: "Arial", fontSize: "14px" }}
              >
                On this page you can set up annotations for a selected service
                for a specific zone. You can open up a dialog to configure your
                zones as well as set up a pan tilt API with the provided
                buttons.
              </Typography>
              <Button
                variant="contained"
                onClick={(): void => onConfigureZonesClick()}
              >
                Configure Zones
              </Button>
              <Button variant="contained" onClick={(): void => setPTAPIIP()}>
                Set Pantilt API ip
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GasLeakTabContent;
