import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SystemSettingsIcon from "../../icons/SystemSettings";

const CustomerSecurityMenuView = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const listItemButtonCssNoAddIcon = {
    borderRadius: "8px",
    "&.MuiListItemButton-root": {
      paddingBottom: 0,
      paddingTop: 0,
      marginTop: "4px",
      marginBottom: "4px",
      height: "40px",
    },
  };

  const listItemTextCss = {
    fontWeight: 500,
  };

  return (
    <div>
      <Link to={"/system-settings"}>
        <Box
          component="img"
          sx={{ width: "100%" }}
          alt="logo-image"
          src={
            theme.palette.mode === "dark"
              ? "/Autonomous365 White.png"
              : "/Autonomous365 Dark.png"
          }
        />
      </Link>
      <List sx={{ paddingTop: "52px" }}>
        <ListItem disablePadding>
          <ListItemButton
            selected={pathname === "/system-settings"}
            sx={{ ...listItemButtonCssNoAddIcon }}
            onClick={(): void => navigate("/system-settings")}
          >
            <ListItemIcon>
              <SystemSettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary="System Settings"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export default CustomerSecurityMenuView;
