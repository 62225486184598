const AIManagerIcon = (): JSX.Element => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.4717 5.2L21.685 1.55071C21.9112 1.39235 22.223 1.44737 22.3813 1.6736C22.4401 1.75764 22.4717 1.85774 22.4717 1.96033V14.0397C22.4717 14.3158 22.2478 14.5397 21.9717 14.5397C21.8691 14.5397 21.769 14.5081 21.685 14.4493L16.4717 10.8V15C16.4717 15.5523 16.024 16 15.4717 16H1.47168C0.9194 16 0.47168 15.5523 0.47168 15V1C0.47168 0.44772 0.9194 0 1.47168 0H15.4717C16.024 0 16.4717 0.44772 16.4717 1V5.2ZM16.4717 8.3587L20.4717 11.1587V4.84131L16.4717 7.6413V8.3587ZM2.47168 2V14H14.4717V2H2.47168ZM4.47168 4H6.47168V6H4.47168V4Z"
        fill="black"
      />
    </svg>
  );
};

export default AIManagerIcon;
