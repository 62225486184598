import { RenderPass } from "three/addons/postprocessing/RenderPass";
import { EffectComposer } from "three/addons/postprocessing/EffectComposer";
import { ShaderPass } from "three/addons/postprocessing/ShaderPass";
import { ColorifyShader } from "three/addons/shaders/ColorifyShader";
import { GammaCorrectionShader } from "three/addons/shaders/GammaCorrectionShader";
import { FilmPass } from "three/addons/postprocessing/FilmPass";
import { VignetteShader } from "three/addons/shaders/VignetteShader";
import { BleachBypassShader } from "three/addons/shaders/BleachBypassShader";
import * as THREE from "three";

export default class PostEffects {
  constructor(renderer, scene) {
    this.renderer = renderer;
    this.scene = scene;
  }

  initComposer(camera) {
    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, camera));
    this.addGasCameraEffect();
  }

  addGasCameraEffect() {
    const effectColorify1 = new ShaderPass(ColorifyShader);
    const effectColorify2 = new ShaderPass(ColorifyShader);
    const gammaCorrection = new ShaderPass(GammaCorrectionShader);
    const effectFilmBW = new FilmPass(0.1, 0.3, 2048, true);
    const effectVignette = new ShaderPass(VignetteShader);
    const bleachEffect = new ShaderPass(BleachBypassShader);
    effectColorify1.uniforms["color"] = new THREE.Uniform(
      new THREE.Color(1.0, 1.0, 1.0)
    );
    effectColorify2.uniforms["color"] = new THREE.Uniform(
      new THREE.Color(0.5, 0.555, 0.515)
    );
    effectVignette.uniforms["offset"].value = 0.97;
    effectVignette.uniforms["darkness"].value = 1.2;
    gammaCorrection.uniforms["tDiffuse"].value = 1.1;
    bleachEffect.uniforms["opacity"].value = 1.0;
    this.composer.addPass(gammaCorrection);
    this.composer.addPass(effectFilmBW);
    this.composer.addPass(effectColorify1);
    this.composer.addPass(effectColorify2);
    this.composer.addPass(effectVignette);
    this.composer.addPass(bleachEffect);
  }

  resize(w, h) {
    this.composer.setSize(w, h);
  }

  render(camera) {
    const renderPass = this.composer.passes.find(
      pass => pass instanceof RenderPass
    );
    if (renderPass && camera) {
      renderPass.camera = camera;
      this.composer.render();
    }
  }
}
