import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import type {
  GetLocationsQuery,
  GetLocationsQueryVariables,
  PaginatedLocations,
} from "../../API";
import { GET_LOCATIONS } from "../operations/queries";
import { useCustomerIdGuard } from "./useCustomerIdGuard";

export const useGetLocations = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const { data, loading } = useQuery<
    GetLocationsQuery,
    GetLocationsQueryVariables
  >(GET_LOCATIONS, { variables: { customerId: selectedCustomerId } });

  const locations = useMemo(
    () => data?.getLocations as PaginatedLocations,
    [data?.getLocations]
  );

  return { locations, loading } as const;
};
