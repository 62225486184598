import { useContext } from "react";
import { Box, Typography } from "@mui/material";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";

const ObjectCard = ({ obj }) => {
  const { sceneStarted, isLoading, addObject } = useContext(ProjectContext);

  const onAddObjectButton = () => {
    if (sceneStarted && !isLoading) {
      addObject(obj);
    }
  };

  return (
    <Box
      onClick={onAddObjectButton}
      sx={{
        minWidth: "100px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #98A7BC",
        overflow: "hidden",
        borderRadius: "4px",
        cursor: sceneStarted && !isLoading && "pointer",
        "&:hover": {
          borderColor: sceneStarted && !isLoading && "#0C64D9",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "66px",

          background: "#F0F4FD",
          borderBottom: "1px solid #98A7BC",
          "@media (max-width:1500px)": {
            height: "60px",
          },

          "& img": {
            width: "100%",
            height: "100%",
          },
        }}
      >
        <img src={obj.previewPath} />
      </Box>
      <Box
        sx={{
          height: "48px",
          background: " #F9F9F9",
          color: "#0D254A",
          padding: "1px 4px",
          "@media (max-width:1500px)": {
            height: "43px",
          },
        }}
      >
        <Typography
          variant="body10Medium"
          component="p"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {obj.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default ObjectCard;
