import { useEffect } from "react";

import { useReactiveVar } from "@apollo/client";
import type { GraphQLSubscription } from "@aws-amplify/api";
import { ConnectionState, CONNECTION_STATE_CHANGE } from "@aws-amplify/pubsub";
import WifiOffRoundedIcon from "@mui/icons-material/WifiOffRounded";
import { Stack, Tooltip, Typography } from "@mui/material";
import { API, graphqlOperation, Hub } from "aws-amplify";

import type { ListenToHumanValidationEventsSubscription } from "../../../API";
import { listenToHumanValidationEvents } from "../../../graphql/subscriptions";
import { hvConnection } from "../variables/humanValidatorTable";

const badConnectionStates = [
  ConnectionState.ConnectedPendingDisconnect,
  ConnectionState.Disconnected,
];

const HumanValidatorPageHeader = (): JSX.Element => {
  const connected = useReactiveVar(hvConnection);

  useEffect((): (() => void) => {
    Hub.listen("api", (data): void => {
      const { payload } = data;

      if (payload.event === CONNECTION_STATE_CHANGE) {
        if (badConnectionStates.includes(payload.data.connectionState)) {
          hvConnection(false);
          console.log("Hub.listen CONNECTION_STATE_CHANGE", payload);
        } else {
          hvConnection(true);
        }
      }
    });

    const sub = API.graphql<
      GraphQLSubscription<ListenToHumanValidationEventsSubscription>
    >(graphqlOperation(listenToHumanValidationEvents)).subscribe({
      next: (data): void =>
        console.log(
          "PubSub.subscribe listenToHumanValidationEvents next",
          data
        ),
      error: (error): void =>
        console.log(
          "PubSub.subscribe listenToHumanValidationEvents error",
          error
        ),
      complete: (): void =>
        console.log(
          "PubSub.subscribe listenToHumanValidationEvents completed",
          "Completed!"
        ),
    });

    return (): void => {
      if (!connected) {
        sub.unsubscribe();
      }
    };
  }, []);

  return (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h5">Alert Operations Center</Typography>
      {!connected && (
        <Tooltip arrow title="Not Connected" placement="top">
          <WifiOffRoundedIcon
            fontSize="large"
            color="error"
            sx={{ cursor: "pointer" }}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

export default HumanValidatorPageHeader;
