import { useState } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button } from "@mui/material";

import { isEmpty } from "lodash";
import StyledDialog from "../../../common/components/dialog/StyledDialog";
import { useDialog } from "../../../common/components/dialog/useDialog";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { useUpdateShadow } from "../../../common/hooks/useUpdateShadow";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useLocationsTableData } from "../../locations/hooks/useLocationsTableData";
import { useCreateDevice } from "../hooks/useCreateDevice";
import { usePublishNode } from "../hooks/usePublishNode";
import {
  defaultDeviceFormValues,
  deviceFormVariables,
  useDeviceFormVariables,
} from "../variables/devices";
import CreateDeviceForm from "./CreateDeviceForm";

const CreateDeviceDialogContainer = (): JSX.Element => {
  const form = useDeviceFormVariables();
  const selectedCustomerId = useCustomerIdGuard();

  const { open, handleDialogOpen, handleDialogClose } = useDialog();

  const { createDevice, loading: createDeviceLoading } = useCreateDevice();
  const { publishNode, loading: publishNodeLoading } = usePublishNode();
  const { updateDeviceShadow } = useUpdateShadow();
  const { rows: locationRows } = useLocationsTableData();

  const [hasError, setHasError] = useState(false);
  const [deviceNameError, setDeviceNameError] = useState(false);

  const openDialog = (): void => {
    deviceFormVariables(defaultDeviceFormValues);
    setHasError(false);
    setDeviceNameError(false);
    handleDialogOpen();
  };

  const closeDialog = (): void => {
    deviceFormVariables(defaultDeviceFormValues);
    setHasError(false);
    setDeviceNameError(false);
    handleDialogClose();
  };

  const saveDevice = async (): Promise<void> => {
    const requiredFields = [
      form.deviceName,
      form.deviceType,
      form.nodeId,
      form.location?.value,
      isEmpty(form.deviceConfig) ? null : form.deviceConfig,
    ];

    if (requiredFields.some((field): boolean => isEmpty(field))) {
      setHasError(true);
      return;
    }

    try {
      const createDeviceResponse = await createDevice({
        name: form.deviceName,
        customerId: selectedCustomerId,
        nodeId: form.nodeId,
        locationId: form.location?.value ?? "",
        makeModelId: form.deviceType,
        deviceData: form.deviceConfig
          ? JSON.stringify(form.deviceConfig)
          : null,
      });

      // TODO: Will reconsider using batch and device shadow acknowledge to improve bug proneness
      // update edge node agent shadow
      updateNodeAgentShadow(createDeviceResponse?.data?.addDevice);
      startRTSPOnNodeAgent();
    } catch (error) {
      errorNotification("Something went wrong when creating device");
      setDeviceNameError(true);
      console.error(error);
    }
  };

  const updateNodeAgentShadow = (deviceDetails: any): void => {
    const locationRow = locationRows.find(
      (row): any => row.rowId === deviceDetails?.locationId
    );
    const locationCoordinates = locationRow ? locationRow.coordinates : null;

    const payload = {
      Locations: {
        [deviceDetails?.locationId]: {
          id: deviceDetails?.locationId,
          coordinates: locationCoordinates,
        },
      },
      Devices: {
        Cameras: {
          [form.deviceName]: {
            rtspHost: form.deviceConfig["Source Video"],
            makeModel: form.deviceType,
          },
        },
      },
    };

    // Contact with RJ to update the shadow with the real data
    updateDeviceShadow(form.nodeId.replace(/#/g, "_"), payload);
  };

  const startRTSPOnNodeAgent = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "RTSP",
        ACTION: "RTSP_START",
      }),
      nodeId: form.nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
          closeDialog();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });
  };

  const loading = createDeviceLoading || publishNodeLoading;

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddOutlinedIcon />}
        onClick={openDialog}
      >
        Create
      </Button>
      <StyledDialog
        open={open}
        title="Create a new device"
        onClose={closeDialog}
        SubmitButtonProps={{
          loading,
          onSubmit: saveDevice,
        }}
      >
        <CreateDeviceForm
          hasError={hasError}
          deviceNameError={deviceNameError}
        />
      </StyledDialog>
    </>
  );
};

export default CreateDeviceDialogContainer;
