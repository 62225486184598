import * as THREE from "three";

export const getUniqueVectorsFromArray = arrayOfVectors => {
  const arrayOfUniqueVectors = [];

  arrayOfVectors.forEach(vector => {
    if (arrayOfUniqueVectors.length === 0) {
      arrayOfUniqueVectors.push(vector);
    } else if (!arrayOfUniqueVectors.some(uVec => uVec.equals(vector))) {
      arrayOfUniqueVectors.push(vector);
    }
  });

  return arrayOfUniqueVectors;
};

export const getVectorsArrayWithOffset = (arrayOfVectors, sceneOffset) => {
  return arrayOfVectors.map(vec =>
    new THREE.Vector3().subVectors(vec, sceneOffset)
  );
};
