import { Button, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import { UpdateAlertStatusInput } from "../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import { successNotification } from "../../../../../../common/variables/notification";
import { IAlertsTableRow } from "../../hooks/useAlertsTableRow";
import { useUpdateAlertStatus } from "../../hooks/useUpdateAlertStatus";

interface ITurnOnButtonProps {
  row: IAlertsTableRow;
}

const TurnOnButton = ({ row }: ITurnOnButtonProps): JSX.Element => {
  const confirm = useConfirm();

  const selectedCustomerId = useCustomerIdGuard();

  const { updateAlert, loading } = useUpdateAlertStatus();

  const handleTurnOffClick = (): void => {
    confirm({
      title: null,
      content: (
        <Typography variant="subtitle1">
          Are you sure you want to turn on <strong>{row?.alertName}</strong>?
        </Typography>
      ),
      confirmationText: "Turn On",
      cancellationText: "Cancel",
      contentProps: {
        sx: {
          color: "text.primary",
        },
      },
      confirmationButtonProps: {
        variant: "text",
        color: "error",
        disabled: loading,
      },
    }).then((): void => {
      const input: UpdateAlertStatusInput = {
        customerId: selectedCustomerId,
        id: row?.rowId,
        status: true,
      };

      updateAlert(input).then((response): void => {
        if (response.data?.updateAlertStatus) {
          successNotification("Alert has been turned on");
        }
      });
    });
  };

  return (
    <Button onClick={handleTurnOffClick} color="primary">
      TURN ON
    </Button>
  );
};

export default TurnOnButton;
