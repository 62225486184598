import { DialogActions, Grid, Typography } from "@mui/material";

import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";

const DeleteServiceForm = ({
  onCancel,
  onConfirm,
  loading,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
}): JSX.Element => {
  return (
    <>
      <Grid container paddingBottom="1em">
        <Typography>
          Are you sure you want to delete this modal instance?
        </Typography>
        <Grid container>
          <DialogActions>
            <StyledLoadingButton
              fullWidth
              variant="contained"
              onClick={onCancel}
            >
              {"Cancel"}
            </StyledLoadingButton>
          </DialogActions>
          <DialogActions>
            <StyledLoadingButton
              fullWidth
              loadingPosition="start"
              variant="contained"
              loading={loading}
              onClick={onConfirm}
            >
              {"Confirm Delete"}
            </StyledLoadingButton>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
};

export default DeleteServiceForm;
