import { useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import { Paper, Typography } from "@mui/material";
import { isEmpty } from "lodash";

import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { useUpdateShadow } from "../../../common/hooks/useUpdateShadow";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import CreateDeviceForm from "../components/CreateDeviceForm";
import { useCreateDevice } from "../hooks/useCreateDevice";
import { usePublishNode } from "../hooks/usePublishNode";
import {
  defaultDeviceFormValues,
  deviceFormVariables,
  useDeviceFormVariables,
} from "../variables/devices";

const CreateDevicePage = (): JSX.Element => {
  const form = useDeviceFormVariables();
  const selectedCustomerId = useCustomerIdGuard();

  const { createDevice, loading: createDeviceLoading } = useCreateDevice();
  const { publishNode, loading: publishNodeLoading } = usePublishNode();
  const { updateDeviceShadow } = useUpdateShadow();

  const [hasError, setHasError] = useState(false);
  const [deviceNameError, setDeviceNameError] = useState(false);

  const resetState = (): void => {
    deviceFormVariables({ ...defaultDeviceFormValues });
    setHasError(false);
    setDeviceNameError(false);
  };

  const saveDevice = async (): Promise<void> => {
    const requiredFields = [
      form.deviceName,
      form.deviceType,
      form.nodeId,
      form.location?.value,
      isEmpty(form.deviceConfig) ? null : form.deviceConfig,
    ];

    if (requiredFields.some((field): boolean => isEmpty(field))) {
      setHasError(true);
      return;
    }

    try {
      const createDeviceResponse = await createDevice({
        name: form.deviceName,
        customerId: selectedCustomerId,
        nodeId: form.nodeId,
        locationId: form.location?.value ?? "",
        makeModelId: form.deviceType,
        deviceData: form.deviceConfig
          ? JSON.stringify(form.deviceConfig)
          : null,
      });

      resetState();
      // TODO: Will reconsider using batch and device shadow acknowledge to improve bug proneness
      // update edge node agent shadow
      updateNodeAgentShadow(createDeviceResponse?.data?.addDevice);
      startRTSPOnNodeAgent();
    } catch (error) {
      errorNotification("Something went wrong when creating device");
      setDeviceNameError(true);
      console.error(error);
    }
  };

  const updateNodeAgentShadow = (deviceDetails: any): void => {
    const payload = {
      Locations: {
        [deviceDetails?.locationId]: {
          id: deviceDetails?.locationId,
        },
      },
      Devices: {
        Cameras: {
          [form.deviceName]: {
            rtspHost: form.deviceConfig["Source Video"],
          },
        },
      },
    };

    updateDeviceShadow(form.nodeId.replace(/#/g, "_"), payload);
  };

  const startRTSPOnNodeAgent = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "RTSP",
        ACTION: "RTSP_START",
        rtspDevices: [
          {
            deviceName: form.deviceName,
            rtspSource: form.deviceConfig["Source Video"],
          },
        ],
      }),
      nodeId: form.nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });
  };

  const loading = createDeviceLoading || publishNodeLoading;

  return (
    <Paper sx={{ padding: "1.5em" }}>
      <Typography variant="h5" sx={{ paddingBottom: "1em" }}>
        Create a new device
      </Typography>
      <CreateDeviceForm hasError={hasError} deviceNameError={deviceNameError} />
      <StyledLoadingButton
        fullWidth
        loading={loading}
        loadingPosition="start"
        startIcon={<CheckIcon />}
        variant="contained"
        color="success"
        onClick={saveDevice}
      >
        Create
      </StyledLoadingButton>
    </Paper>
  );
};

export default CreateDevicePage;
