import { Box, Stack, Typography } from "@mui/material";

import LocationIdSelect from "../../../common/components/layout/components/LocationIdSelect";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  selectedLocationAIManagerVariable,
  useSelectedLocationAIManager,
} from "../variables/modelManager";
import CreateServiceDialog from "./CreateServiceDialog";

const ModelManagerPageHeader = (): JSX.Element => {
  const locationVariable = useSelectedLocationAIManager();

  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    selectedLocationAIManagerVariable(locationValue);
  };

  return (
    <Box padding="26px 24px 0 24px">
      <Stack spacing={4} direction="row" alignItems="center">
        <Typography variant="h5" padding={0} sx={{ flexGrow: 1 }}>
          AI Manager
        </Typography>
        <Box sx={{ maxWidth: 400, width: 280 }}>
          <LocationIdSelect
            variant="standard"
            location={locationVariable}
            setLocation={handleLocationChange}
          />
        </Box>
        <CreateServiceDialog />
      </Stack>
    </Box>
  );
};

export default ModelManagerPageHeader;
