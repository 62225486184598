import { type FetchResult, gql, useMutation } from "@apollo/client";

import { UPDATE_SERVICE } from "../../../common/operations/mutations";
import type {
  UpdateServiceMutation,
  UpdateServiceMutationVariables,
  UpdateServiceInput,
} from "../../../API";

type Data<T> = T | null | undefined;

interface UpdateServiceInterface {
  updateService: (
    input: UpdateServiceInput
  ) => Promise<FetchResult<UpdateServiceMutation>>;
  data?: UpdateServiceMutation | null;
  loading: boolean;
}

export const useUpdateService = (): UpdateServiceInterface => {
  const [putService, { data, loading }] = useMutation<
    UpdateServiceMutation,
    UpdateServiceMutationVariables
  >(UPDATE_SERVICE);

  const updateService = async (
    input: UpdateServiceInput
  ): Promise<FetchResult<UpdateServiceMutation>> => {
    return await putService({
      variables: {
        input,
      },
      update(cache, response): void {
        cache.modify({
          fields: {
            getServices(existing): Data<UpdateServiceMutation> {
              const newServiceRef = cache.writeFragment({
                data: response.data?.updateService,
                fragment: gql`
                  fragment NewService on Service {
                    configuration
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newServiceRef],
              };
            },
          },
        });
      },
    });
  };

  return { updateService, data, loading };
};
