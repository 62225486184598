import { makeVar, useReactiveVar } from "@apollo/client";

import { AutocompleteOptionType } from "../../../../../common/models/autocomplete";

interface INonAdminAlertForm {
  alertName: string;
  location: AutocompleteOptionType;
  model: string;
  // locationTag: string;
}

export const defaultNonAdminAlertFormValues: INonAdminAlertForm = {
  alertName: "",
  location: null,
  model: "",
  // locationTag: "",
};

export const nonAdminAlertFormVariables = makeVar<INonAdminAlertForm>(
  defaultNonAdminAlertFormValues
);

export const useNonAdminAlertFormVariables = () =>
  useReactiveVar(nonAdminAlertFormVariables);

export const selectedLocationAlertsVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedLocationAlerts = () =>
  useReactiveVar(selectedLocationAlertsVariable);
