import { useEffect, useMemo, useState } from "react";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {
  Button,
  FormControl,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { Role } from "../../../../../API";
import StyledDialog from "../../../../../common/components/dialog/StyledDialog";
import { useDialog } from "../../../../../common/components/dialog/useDialog";
import RoleSelect from "../../../../../common/components/select/RoleSelect/RoleSelect";
import { useGetRoles } from "../../../../../common/components/select/RoleSelect/useGetRoles";
import { errorNotification } from "../../../../../common/variables/notification";
import { useFirstUpdateRole } from "../hooks/useFirstUpdateRole";
import { useUpdateRole } from "../hooks/useUpdateRole";
import RoleIcon from "./RoleIcon";
import aws_exports from "../../../../../../../web/src/aws-exports.json";

interface IAssignRoleContainerProps {
  userId: string;
  email: string;
  role: string;
}

const AssignRoleContainer = ({
  userId,
  email,
  role,
}: IAssignRoleContainerProps): JSX.Element => {
  const { open, handleDialogOpen, handleDialogClose } = useDialog();

  const { data } = useGetRoles();
  const { loading: updateUserRoleLoading, updateUserRole } = useUpdateRole();
  const { firstUpdateUserRole } = useFirstUpdateRole();

  const memoizedRole = useMemo(
    (): Role | null =>
      data?.getRoles?.items.find((r): boolean => r?.id === role) ?? null,
    [data?.getRoles?.items, role]
  );

  const [newRole, setNewRole] = useState((): Role | null => {
    return memoizedRole;
  });
  const [hasError, setHasError] = useState(false);

  useEffect((): void => {
    if (memoizedRole?.id !== newRole?.id) {
      setNewRole(memoizedRole);
    }
  }, [memoizedRole?.id]);

  const handleUserUpdate = (): void => {
    if (!newRole) {
      setHasError(true);
      return;
    }

    // TODO: clarify with Brian how to set newGroup properly
    const rootGroup = newRole?.userGroup ? newRole?.userGroup[0] : null;
    const partnerGroup = newRole?.userGroup ? newRole?.userGroup[1] : null;

    const newGroup = rootGroup
      ? rootGroup + "#PARTNER"
      : partnerGroup + "#PARTNER";

    if (role.includes("PENDING")) {
      // first time update role i.e. recreate DDB item due to modified primary key
      firstUpdateUserRole(
        userId,
        newRole?.id as string,
        newGroup,
        aws_exports.aws_user_pools_id
      ).then((response): void => {
        if (response.errors) {
          errorNotification(response.errors[0].message);
        } else {
          handleDialogClose();
          setNewRole(null);
        }
      });
    } else {
      // follow-up update role i.e. no need to recreate item because of a changed primary key
      updateUserRole(userId, newRole?.id as string, newGroup).then(
        (response): void => {
          if (response.errors) {
            errorNotification(response.errors[0].message);
          } else {
            handleDialogClose();
            setNewRole(null);
          }
        }
      );
    }
  };

  const handleRoleChange = (value: Role | null): void => {
    setNewRole(value);
  };

  return (
    <>
      <Button onClick={handleDialogOpen}>ASSIGN ROLE</Button>

      <StyledDialog
        open={open}
        title="Assign Role"
        onClose={handleDialogClose}
        SubmitButtonProps={{
          onSubmit: handleUserUpdate,
          loading: updateUserRoleLoading,
          title: "Assign",
        }}
      >
        <>
          <Table
            sx={{
              borderCollapse: "separate",
              borderSpacing: "0 12px",
              border: 0,
              paddingBottom: "1em",
            }}
          >
            <TableRow
              sx={{
                borderRadius: "6px",
                backgroundColor: (theme): string =>
                  theme.palette.info.shades?.["190p"] ?? "",
              }}
            >
              <TableCell
                variant="head"
                sx={{
                  width: "24px",
                  paddingRight: "0px",
                  borderBottom: 0,
                  borderRadius: "6px 0 0 6px",
                }}
              >
                <EmailOutlinedIcon />
              </TableCell>
              <TableCell
                variant="head"
                sx={{
                  paddingLeft: "14px",
                  borderBottom: 0,
                }}
              >
                <Typography variant="subtitle1">Email</Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: 0,
                  borderRadius: "0 6px 6px 0",
                }}
              >
                <Typography variant="body1">{email}</Typography>
              </TableCell>
            </TableRow>
            <TableRow
              sx={{
                backgroundColor: (theme): string =>
                  theme.palette.info.shades?.["190p"] ?? "",
              }}
            >
              <TableCell
                variant="head"
                sx={{
                  width: "24px",
                  paddingRight: "0px",
                  borderBottom: 0,
                  borderRadius: "6px 0 0 6px",
                }}
              >
                <RoleIcon />
              </TableCell>
              <TableCell
                variant="head"
                sx={{
                  paddingLeft: "14px",
                  borderBottom: 0,
                }}
              >
                Previous role
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: 0,
                  borderRadius: "0 6px 6px 0",
                }}
              >
                <Typography variant="body1">
                  <strong>{memoizedRole?.name}</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </Table>

          <FormControl fullWidth>
            <RoleSelect
              role={newRole}
              hasError={hasError}
              onRoleChange={handleRoleChange}
            />
          </FormControl>
        </>
      </StyledDialog>
    </>
  );
};

export default AssignRoleContainer;
