import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import {
  GetUsersByCompanyQuery,
  GetUsersByCompanyQueryVariables,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { GET_USERS_BY_COMPANY } from "../../../../../common/operations/queries";
import { errorNotification } from "../../../../../common/variables/notification";
import client from "../../../../../configs/apolloClient";

export const USERS_TABLE_LIMIT = 10;

export const useGetLazyPaginatedUsers = () => {
  const selectedCustomerId = useCustomerIdGuard();

  useEffect(() => {
    if (selectedCustomerId) {
      fetchUsers(selectedCustomerId);
    }
  }, [selectedCustomerId]);

  const [getAllUsers, { data, loading }] = useLazyQuery<
    GetUsersByCompanyQuery,
    GetUsersByCompanyQueryVariables
  >(GET_USERS_BY_COMPANY, {
    fetchPolicy: "network-only",
  });

  const handleRequestError = async () => {
    await client.cache.reset();
    errorNotification();
  };

  const fetchUsers = (
    customerId: string,
    limit?: number,
    nextToken?: string
  ) => {
    getAllUsers({
      variables: {
        customerId,
        limit: USERS_TABLE_LIMIT,
        nextToken,
      },
    })
      .then(async response => {
        if (response.data?.getUsersByCompany.nextToken) {
          fetchUsers(
            customerId,
            limit,
            response.data?.getUsersByCompany.nextToken
          );
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async () => {
        await handleRequestError();
      });
  };

  return { data, loading };
};
