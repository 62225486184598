import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";

import WebglConfiguratorHeaderTopBar from "../../../../common/components/layout/WebglConfiguratorHeaderTopBar";
import Notification from "../Notification";

import WebglConfigurtorMuiThemeProvider from "../../context/ThemeProvider/WebglConfigurtorMuiThemeProvider";
import ModalAlertContextProvider from "../../context/ModalAlertContext/ModalAlertContextProvider";
import ProjectContextProvider from "../../context/ProjectContext/ProjectContextProvider";
import ConfiguratorPermissionContextProvider from "../../context/ConfiguratorPermissionContext/ConfiguratorPermissionContextProvider";

const WebglConfiguratorLayout = () => {
  return (
    <Box component="main">
      <WebglConfiguratorHeaderTopBar />

      <Box
        sx={{
          height: "calc(100vh - 98px)",
          padding: "0 1.25em 0.5em",
          backgroundColor: "#ffffff",
        }}
      >
        <WebglConfigurtorMuiThemeProvider>
          <ModalAlertContextProvider>
            <ConfiguratorPermissionContextProvider>
              <ProjectContextProvider>
                <Outlet />
                <Notification />
              </ProjectContextProvider>
            </ConfiguratorPermissionContextProvider>
          </ModalAlertContextProvider>
        </WebglConfigurtorMuiThemeProvider>
      </Box>
    </Box>
  );
};

export default WebglConfiguratorLayout;
