import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import type {
  GetCustomersQuery,
  GetCustomersQueryVariables,
  PaginatedCustomers,
} from "../../API";
import { GET_CUSTOMERS } from "../operations/queries";

export const useGetCustomers = () => {
  const { data, loading } = useQuery<
    GetCustomersQuery,
    GetCustomersQueryVariables
  >(GET_CUSTOMERS, {
    onError: error => {
      console.error("GetCustomers", error);
    },
  });

  const customers = useMemo(
    () => data?.getCustomers as PaginatedCustomers,
    [data?.getCustomers]
  );

  return { customers, loading } as const;
};
