const DeviceIcon = (): JSX.Element => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.47168 8H16.4717V2H2.47168V8ZM18.4717 1V17C18.4717 17.5523 18.024 18 17.4717 18H1.47168C0.9194 18 0.47168 17.5523 0.47168 17V1C0.47168 0.44772 0.9194 0 1.47168 0H17.4717C18.024 0 18.4717 0.44772 18.4717 1ZM16.4717 10H2.47168V16H16.4717V10ZM4.47168 12H7.47168V14H4.47168V12ZM4.47168 4H7.47168V6H4.47168V4Z"
        fill="black"
      />
    </svg>
  );
};

export default DeviceIcon;
