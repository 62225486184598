import { useNavigate } from "react-router-dom";

import CheckIcon from "@mui/icons-material/Check";
import { LoadingButton } from "@mui/lab";
import { Paper, Typography } from "@mui/material";

import { useCustomerIdGuard } from "../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../common/variables/notification";
import client from "../../configs/apolloClient";
import LocationForm from "./components/LocationForm";
import { useSaveLocation } from "./hooks/useSaveLocation";
import {
  defaultLocationFormValidationState,
  defaultLocationFormVariables,
  locationFormRules,
  locationFormValidationStateVariable,
  locationFormVariables,
  useLocationFormValidationState,
  useLocationFormVariables,
} from "./variables/locationData";

const CreateLocationPage = (): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();

  const form = useLocationFormVariables();
  const validation = useLocationFormValidationState();

  const navigate = useNavigate();

  const [saveLocationItem, { loading }] = useSaveLocation();

  const saveLocation = (): void => {
    let validationState = {
      ...(validation ?? defaultLocationFormValidationState),
    };

    const key = form.locationName.toLowerCase().replace(/\s+/g, "_");
    const locationId = `${selectedCustomerId}#L#${key}`;

    const normalizedId =
      client.cache.identify({
        id: locationId,
        __typename: "Location",
      }) ?? "";

    const extract = client.cache.extract();

    if (normalizedId && extract[normalizedId]?.name === form.locationName) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: "Location name already exists",
        },
      };
    }

    if (!form.locationName) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: "Please type location name",
        },
      };
    }

    if (form.locationName.length > locationFormRules.locationName.maxLength) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: `Location name cannot be longer than ${locationFormRules.locationName.maxLength} characters`,
        },
      };
    }

    if (!form.latitude) {
      validationState = {
        ...validationState,
        latitude: {
          hasError: true,
          errorMessage: "Please type latitude",
        },
      };
    }

    if (!form.longitude) {
      validationState = {
        ...validationState,
        longitude: {
          hasError: true,
          errorMessage: "Please type longitude",
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      locationFormValidationStateVariable(validationState);
      return;
    }

    saveLocationItem({
      input: {
        customerId: selectedCustomerId,
        id: form.locationName,
        name: form.locationName,
        locationData: JSON.stringify({
          lat: form.latitude,
          lon: form.longitude,
        }),
        tags: form.tags,
      },
    })
      .then((): void => {
        successNotification();
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      })
      .finally((): void => {
        locationFormVariables(defaultLocationFormVariables);
        locationFormValidationStateVariable(defaultLocationFormValidationState);
        navigate({ pathname: "/locations" });
      });
  };

  return (
    <Paper sx={{ padding: "1.5em" }}>
      <Typography variant="h5" sx={{ paddingBottom: "1em" }}>
        Create a new location
      </Typography>
      <LocationForm />
      <LoadingButton
        fullWidth
        sx={{
          marginTop: "1.5em",
        }}
        loading={loading}
        loadingPosition="start"
        startIcon={<CheckIcon />}
        variant="contained"
        color="primary"
        onClick={saveLocation}
      >
        Create
      </LoadingButton>
    </Paper>
  );
};

export default CreateLocationPage;
