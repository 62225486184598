import { Button } from "@mui/material";

import { useState } from "react";
import StyledDialog from "../../../common/components/dialog/StyledDialog";
import { useDialog } from "../../../common/components/dialog/useDialog";
import { useUpdateService } from "../hooks/useUpdateService";
import UpdateServiceForm from "./UpdateServiceForm";

const UpdateServiceDialogContainer = ({
  serviceId,
  nodeId,
}: {
  serviceId: string;
  nodeId: string;
}): JSX.Element => {
  console.log({ serviceId, nodeId });
  const [configJSON, setConfigJSON] = useState("");

  const { open, handleDialogOpen, handleDialogClose } = useDialog();
  const { updateService } = useUpdateService();
  const closeDialog = (): void => {
    handleDialogClose();
  };
  const openDialog = (): void => {
    handleDialogOpen();
  };

  const updateServiceFromDDB = (): void => {
    updateService({
      nodeId,
      serviceId,
      configuration: JSON.stringify(configJSON),
    });
  };

  return (
    <>
      <Button color="primary" onClick={openDialog}>
        Configure
      </Button>
      <StyledDialog
        open={open}
        title="Update Model Service Instance"
        maxWidth="md"
        onClose={closeDialog}
        SubmitButtonProps={{}}
        showSubmitButton={false}
      >
        <UpdateServiceForm
          onCancel={closeDialog}
          onConfirm={updateServiceFromDDB}
          textValue={configJSON}
          setTextValue={setConfigJSON}
        />
      </StyledDialog>
    </>
  );
};

export default UpdateServiceDialogContainer;
