/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listenToHumanValidationEvents = /* GraphQL */ `
  subscription ListenToHumanValidationEvents {
    listenToHumanValidationEvents {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const listenToNodeFiles = /* GraphQL */ `
  subscription ListenToNodeFiles($nodeId: String!) {
    listenToNodeFiles(nodeId: $nodeId) {
      downloadURL
      file
      internalNodeId
    }
  }
`;
export const listenToNodeMessages = /* GraphQL */ `
  subscription ListenToNodeMessages($nodeId: String!) {
    listenToNodeMessages(nodeId: $nodeId) {
      internalNodeId
      message
    }
  }
`;
