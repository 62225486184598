import { useMemo, useState } from "react";

import { Autocomplete, CircularProgress, TextField } from "@mui/material";

import { Role } from "../../../../API";
import { itemsToExclude } from "../../../../routes/AppRoutes";
import { IAutocompleteOption } from "../../../models/autocomplete";
import { RoleEnum } from "../../../models/enums";
import { useGetRoles } from "./useGetRoles";

interface IRoleSelectOption extends IAutocompleteOption {
  role: Role | null;
}

interface IRoleSelect {
  role: Role | null;
  hasError?: boolean;
  onRoleChange: (role: Role | null) => void;
}

const RoleSelect = ({
  role,
  hasError,
  onRoleChange,
}: IRoleSelect): JSX.Element => {
  const { data, loading } = useGetRoles();

  const [inputValue, setInputValue] = useState("");

  const options = useMemo((): IRoleSelectOption[] => {
    return (
      data?.getRoles.items
        .map((item): IRoleSelectOption => {
          return {
            title: item?.name ?? "",
            value: item?.id ?? "",
            role: item,
          };
        })
        .filter(
          (item): boolean =>
            !itemsToExclude.includes((item?.value ?? "") as RoleEnum)
        ) ?? []
    );
  }, [data?.getRoles.items]);

  const memoizedValue = useMemo((): IRoleSelectOption | null => {
    if (!role) {
      return null;
    }

    return options.find((option): boolean => option?.value === role.id) ?? null;
  }, [role, options]);

  return (
    <Autocomplete
      fullWidth
      loading={loading}
      options={options}
      value={memoizedValue as IRoleSelectOption | null}
      onChange={(_event: any, newValue: IRoleSelectOption | null): void => {
        onRoleChange(newValue ? (newValue?.role as Role | null) : null);
      }}
      getOptionLabel={(option: IRoleSelectOption): string =>
        option?.title ?? ""
      }
      isOptionEqualToValue={(option, optionValue): boolean =>
        option && option?.value === ""
          ? true
          : option?.value === optionValue?.value
      }
      inputValue={inputValue}
      onInputChange={(_event, newInputValue): void => {
        setInputValue(newInputValue);
      }}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          error={hasError && !memoizedValue}
          helperText={hasError && !memoizedValue ? "Please select a role" : ""}
          label="Role"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default RoleSelect;
