import { Box, OutlinedInput, Typography } from "@mui/material";
import CustomInputLabel from "./CustomInputLabel";

const TextInput = ({ labelText, error, onChange, required }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <CustomInputLabel
        title={labelText}
        required={required}
        id={`${labelText}-lable`}
      />
      <OutlinedInput
        id={`${labelText}-input`}
        onChange={onChange}
        error={error}
        sx={{
          width: "336px",
          height: "28px",
        }}
      />
      <Typography
        variant="body12Regular"
        sx={{
          color: error ? "#E17575" : "#787878",
        }}
      >
        From 3 to 30 characters
      </Typography>
    </Box>
  );
};

export default TextInput;
