import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  PaginatedAlerts,
  UpdateUserAlertInput,
  UpdateUserAlertMutation,
  UpdateUserAlertMutationVariables,
} from "../../../../../API";
import { UPDATE_USER_ALERT } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useUpdateUserAlert = () => {
  const [updateUserAlert, { data, loading }] = useMutation<
    UpdateUserAlertMutation,
    UpdateUserAlertMutationVariables
  >(UPDATE_USER_ALERT);

  const updateAlert = (input: UpdateUserAlertInput) => {
    return updateUserAlert({
      variables: {
        input,
      },
      onCompleted: response => {
        if (response.updateUserAlert) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        console.error(error);
        errorNotification(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getAllAlerts(existing: PaginatedAlerts) {
              const newAlertRef = cache.writeFragment({
                data: response.data?.updateUserAlert,
                fragment: gql`
                  fragment NewAlert on Alert {
                    additionalEmails
                    alertName
                    alertType
                    customService
                    email
                    id
                    jsonObject
                    location
                    locationTag
                    model
                    node
                    status
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: existing.items.map(item => {
                  if (item?.id !== response.data?.updateUserAlert?.id) {
                    return item;
                  }

                  return newAlertRef;
                }),
              };
            },
          },
        });
      },
    });
  };

  return { updateAlert, data, loading };
};
