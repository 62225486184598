import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";

import AvatarPopover from "./components/AvatarPopover";
import CustomerSelect from "./components/CustomerSelect";
import UserSettingsPopover from "./components/UserSettingsPopover";
import { type LayoutProps } from "./GeneralLayout";

import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { RoleEnum } from "../../models/enums";

const HeaderTopBar = ({ toogleDrawer }: LayoutProps): JSX.Element => {
  const { role } = useAuthenticatedUser();

  return (
    <AppBar enableColorOnDark color="primary" position="relative">
      <Toolbar
        sx={{
          "&.MuiToolbar-root": {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <IconButton
              edge="start"
              onClick={toogleDrawer}
              sx={{ marginRight: "1em", display: { md: "none" } }}
            >
              <MenuRoundedIcon />
            </IconButton>
            {[
              RoleEnum.ROOT,
              RoleEnum.CC_OPS_ADMIN,
              RoleEnum.CC_OPS,
              RoleEnum.CC_SALES,
              RoleEnum.PARTNER_ADMIN,
            ].includes(role) && <CustomerSelect size="small" />}
            {/* TODO: Create Customer Select component for Partner Admin role that will just display customers
                within their scope */}
          </Box>

          <Box sx={{ marginLeft: "auto" }}>
            <Stack spacing={2} direction="row">
              <UserSettingsPopover />
              <AvatarPopover />
            </Stack>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderTopBar;
