import { useContext } from "react";
import { Alert, Snackbar, Stack, Typography } from "@mui/material";

import ModalAlertContext from "../context/ModalAlertContext/ModalAlertContext";

import { CheckCircleOutlineOutlined } from "../assets/icons/svgAssets";

const Notification = () => {
  const { alertState, closeAlert, alertType } = useContext(ModalAlertContext);

  return (
    <Snackbar
      open={alertState}
      autoHideDuration={6000}
      onClose={closeAlert}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={closeAlert}
        sx={{
          width: "472px",
          border: "1px solid #CEEED1",
          padding: "12px 25px",
        }}
        severity="success"
        iconMapping={{
          success: <CheckCircleOutlineOutlined />,
        }}
      >
        <Stack direction="column" spacing={2}>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontFamily: "Inter",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "22px",
              "@media (max-width:1500px)": {
                fontSize: "15px",
                lineHeight: "18px",
              },
            }}
          >
            {alertType}
          </Typography>
          <Typography variant="body14Regular">
            Your project was {alertType} successfully
          </Typography>
        </Stack>
      </Alert>
    </Snackbar>
  );
};

export default Notification;
