import {
  type ApolloError,
  type FetchResult,
  type MutationFunctionOptions,
  useMutation,
} from "@apollo/client";

import type {
  SetPendingStateMutation,
  SetPendingStateMutationVariables,
} from "./../../../API";

import { SET_PENDING_STATE } from "../../../common/operations/mutations";

interface SetPendingStateInterface {
  data?: SetPendingStateMutation | null;
  loading: boolean;
  error?: ApolloError;
  setToPending: (
    options?: MutationFunctionOptions<
      SetPendingStateMutation,
      SetPendingStateMutationVariables
    >
  ) => Promise<FetchResult<SetPendingStateMutation>>;
}

export const useSetPendingState = (): SetPendingStateInterface => {
  const [setToPending, { data, loading, error }] = useMutation<
    SetPendingStateMutation,
    SetPendingStateMutationVariables
  >(SET_PENDING_STATE);

  return { data, loading, error, setToPending } as const;
};
