import * as THREE from "three";
import SceneObjectBuilder from "./SceneObjectBuilder";
import { setMaterial } from "../MaterilsUtils";

class ObjectBuilder extends SceneObjectBuilder {
  createObjectInstance(
    objectClone,
    materialClone,
    objectData,
    faceNormal,
    defaultObjectPosition
  ) {
    const object = objectClone.clone(true);
    setMaterial(object, materialClone.clone());

    object.ID = objectData.id;
    object.EntityType = objectData.EntityType;

    this.setAxisDependOnGround(object, faceNormal);

    this.setRotation(object, objectData.rotation);
    this.setPosition(object, objectData.position, defaultObjectPosition);

    object.initialQuaternion = objectData.initialQuaternion
      ? new THREE.Quaternion().fromArray(objectData.initialQuaternion)
      : object.quaternion.clone();

    return object;
  }
}

export default ObjectBuilder;
