import { useMemo } from "react";

import type { GridRowsProp } from "@mui/x-data-grid";
import { isArray } from "lodash";

import { useGetNodes } from "./useGetNodes";

export interface NodesTableRowsDataInterface {
  rowId: any;
  nodeName?: string | null;
  serviceData?: string | null;
}

interface NodesTableRowsInterface {
  rows: GridRowsProp<NodesTableRowsDataInterface>;
  loading: boolean;
}

export const useNodeTableRows = (): NodesTableRowsInterface => {
  const { data, loading } = useGetNodes();

  const rows = useMemo((): NodesTableRowsDataInterface[] => {
    return isArray(data?.getNodes?.items)
      ? data?.getNodes?.items
          ?.map((item): NodesTableRowsDataInterface => {
            return {
              rowId: item?.id,
              nodeName: item?.nodeName,
              serviceData: item?.serviceData,
            };
          })
          .filter((item): NodesTableRowsDataInterface => item) ?? []
      : [];
  }, [data?.getNodes?.items]);

  return { rows, loading };
};
