import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  PaginatedAlerts,
  UpdateAdminEmailAlertInput,
  UpdateAdminEmailAlertMutation,
  UpdateAdminEmailAlertMutationVariables,
} from "../../../../../API";
import { UPDATE_ADMIN_EMAIL_ALERT } from "../../../../../common/operations/mutations";
import { errorNotification } from "../../../../../common/variables/notification";

export const useUpdateAdminEmailAlert = () => {
  const [updateAdminEmailAlert, { data, loading }] = useMutation<
    UpdateAdminEmailAlertMutation,
    UpdateAdminEmailAlertMutationVariables
  >(UPDATE_ADMIN_EMAIL_ALERT);

  const updateAlert = (input: UpdateAdminEmailAlertInput) => {
    return updateAdminEmailAlert({
      variables: {
        input,
      },
      onError: (error: ApolloError): void => {
        console.error(error);
        errorNotification(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getAllAlerts(existing: PaginatedAlerts) {
              const newAlertRef = cache.writeFragment({
                data: response.data?.updateAdminEmailAlert,
                fragment: gql`
                  fragment NewAlert on Alert {
                    additionalEmails
                    alertName
                    alertType
                    customService
                    email
                    id
                    jsonObject
                    location
                    locationTag
                    model
                    node
                    status
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: existing.items.map(item => {
                  if (item?.id !== response.data?.updateAdminEmailAlert?.id) {
                    return item;
                  }

                  return newAlertRef;
                }),
              };
            },
          },
        });
      },
    });
  };

  return { updateAlert, data, loading };
};
