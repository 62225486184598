import { ChangeEvent, Fragment } from "react";

import { Button, Grid, TextField } from "@mui/material";

import LocationIdSelect from "../../../../../../../common/components/layout/components/LocationIdSelect";
import ServiceSelect from "../../../../../../../common/components/select/ServiceSelect/ServiceSelect";
import { AutocompleteOptionType } from "../../../../../../../common/models/autocomplete";
import {
  emailAlertFormVariables,
  useEmailAlertFormValidationState,
  useEmailAlertFormVariables,
} from "../../../variables/emailAlerts";

const EmailAlertDialogForm = (): JSX.Element => {
  const alertForm = useEmailAlertFormVariables();
  const validation = useEmailAlertFormValidationState();

  const setAlertName = (event: ChangeEvent<HTMLInputElement>): void => {
    emailAlertFormVariables({
      ...alertForm,
      alertName: event.target.value,
    });
  };

  const setLocation = (value: AutocompleteOptionType): void => {
    emailAlertFormVariables({
      ...alertForm,
      location: value,
    });
  };

  const setService = (value: string): void => {
    emailAlertFormVariables({
      ...alertForm,
      model: value,
    });
  };

  const setAdditionalEmail = (
    event: ChangeEvent<HTMLInputElement>,
    position: number
  ): void => {
    emailAlertFormVariables({
      ...alertForm,
      additionalEmails: alertForm.additionalEmails.map(
        (oldEmail, index): string => {
          if (index !== position) {
            return oldEmail;
          }

          return event.target.value;
        }
      ),
    });
  };

  const addAdditionalEmail = (): void => {
    emailAlertFormVariables({
      ...alertForm,
      additionalEmails: [...alertForm.additionalEmails, ""],
    });
  };

  return (
    <Grid container spacing={2} paddingBottom="1em">
      <Grid item xs={12} sm={12}>
        <TextField
          margin="dense"
          fullWidth
          label="Alert Name"
          error={validation?.alertName.hasError}
          helperText={
            validation?.alertName.hasError && validation?.alertName.errorMessage
          }
          value={alertForm.alertName}
          onChange={setAlertName}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
          <LocationTagSelect />
        </Grid> */}
      <Grid item xs={12} sm={12}>
        <LocationIdSelect
          disableClearable
          setDefault
          location={alertForm.location}
          setLocation={setLocation}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <ServiceSelect
          onServiceChange={setService}
          locationId={alertForm.location?.value}
          serviceId={alertForm.model}
        />
      </Grid>

      {alertForm.additionalEmails.map((email, index): JSX.Element => {
        return (
          <Fragment key={index}>
            <Grid item xs={8} sm={8}>
              <TextField
                margin="dense"
                fullWidth
                label="Additional Email"
                value={email}
                onChange={(event): void =>
                  setAdditionalEmail(
                    event as ChangeEvent<HTMLInputElement>,
                    index
                  )
                }
              />
            </Grid>
            {index === alertForm.additionalEmails.length - 1 &&
              alertForm.additionalEmails.length < 5 && (
                <Grid
                  item
                  xs={4}
                  sm={4}
                  sx={{
                    position: "relative",
                  }}
                >
                  <Button
                    sx={{
                      position: "absolute",
                      top: "34px",
                    }}
                    variant="text"
                    color="primary"
                    onClick={addAdditionalEmail}
                  >
                    ADD EMAIL
                  </Button>
                </Grid>
              )}
          </Fragment>
        );
      })}
    </Grid>
  );
};

export default EmailAlertDialogForm;
