import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AdminNavigation = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box>
      <Stack
        padding="0.75em 1em"
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1">HV</Typography>
        <Button
          variant="contained"
          onClick={(): void => navigate("/human-validator")}
        >
          Open
        </Button>
      </Stack>
      <Divider />
      <Stack
        padding="0.75em 1em"
        direction="row"
        spacing={2}
        justifyContent="space-between"
      >
        <Typography variant="body1">HV Review</Typography>
        <Button
          variant="contained"
          onClick={(): void => navigate("/hv-review")}
        >
          Open
        </Button>
      </Stack>
    </Box>
  );
};

export default AdminNavigation;
