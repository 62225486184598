import { Fragment, useEffect, useMemo, type SyntheticEvent } from "react";

import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { isArray } from "lodash";

import { useGetLocations } from "../../../hooks/useGetLocations";

import type {
  AutocompleteOptionsType,
  AutocompleteOptionType,
  IDisableClearableOption,
} from "../../../models/autocomplete";

interface LocationIdSelectProps {
  setLocation: (locationValue: AutocompleteOptionType) => void;
  location: AutocompleteOptionType;
  defaultValue?: string;
  variant?: "outlined" | "standard";
  setDefault?: boolean;
  disableClearable?: boolean;
  hasError?: boolean;
}

const LocationIdSelect = ({
  setLocation,
  location,
  defaultValue,
  setDefault,
  disableClearable,
  hasError,
  variant = "outlined",
}: LocationIdSelectProps): JSX.Element => {
  const { locations, loading } = useGetLocations();

  useEffect((): void => {
    if (isArray(locations?.items) && setDefault && defaultValue) {
      const locationItem =
        locations?.items?.find((l): boolean => l?.id === defaultValue) ?? null;
      const defaultOption = {
        title: locationItem?.name ?? "",
        value: locationItem?.id ?? "",
      };

      setLocation(defaultOption);
    } else if (isArray(locations?.items) && setDefault) {
      setLocation({
        title: locations.items[0]?.name ?? "",
        value: locations.items[0]?.id ?? "",
      });
    } else if (isArray(locations?.items)) {
      const locationItem =
        locations?.items?.find((l): boolean => l?.id === location?.value) ??
        null;

      if (!locationItem) {
        setLocation({
          title: locations.items[0]?.name ?? "",
          value: locations.items[0]?.id ?? "",
        });
      } else {
        const defaultOption = {
          title: locationItem?.name ?? "",
          value: locationItem?.id ?? "",
        };

        setLocation(defaultOption);
      }
    }
  }, [locations, setDefault, defaultValue]);

  const handleOnchange = (
    _event: SyntheticEvent<Element, Event>,
    optionValue: AutocompleteOptionType
  ): void => {
    if (!optionValue) {
      setLocation({
        title: "",
        value: "",
      });

      return;
    }

    setLocation(optionValue);
  };

  const options =
    useMemo<AutocompleteOptionsType>((): IDisableClearableOption[] => {
      const defaultOption = [
        {
          title: "",
          value: "",
        },
      ];

      if (!Array.isArray(locations?.items)) {
        return defaultOption;
      }

      return locations?.items?.map((location): IDisableClearableOption => {
        return {
          title: location?.name ?? "",
          value: location?.id ?? "",
        };
      });
    }, [locations]);

  return (
    <Autocomplete
      fullWidth
      disableClearable={disableClearable}
      loading={loading}
      options={options}
      getOptionLabel={(option: AutocompleteOptionType): string =>
        option?.title ?? ""
      }
      isOptionEqualToValue={(option, optionValue): boolean =>
        option?.value === "" ? true : option?.value === optionValue?.value
      }
      value={location}
      onChange={handleOnchange}
      popupIcon={<ExpandMoreOutlinedIcon />}
      renderOption={(props, option): JSX.Element => {
        return (
          <Fragment key={option?.value}>
            {option?.value && (
              <Box {...props} component="li">
                {option?.title}
              </Box>
            )}
          </Fragment>
        );
      }}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          error={hasError && !location?.value}
          helperText={
            hasError && !location?.value ? "Please select a location" : ""
          }
          variant={variant}
          label="Select Location"
          fullWidth
        />
      )}
    />
  );
};

export default LocationIdSelect;
