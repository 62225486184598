import { makeVar, useReactiveVar } from "@apollo/client";

import { AutocompleteOptionType } from "../../../common/models/autocomplete";

interface IDeviceForm {
  deviceName: string;
  location: AutocompleteOptionType;
  nodeId: string;
  deviceType: string;
  deviceConfig: any;
}

export const defaultDeviceFormValues: IDeviceForm = {
  deviceName: "",
  location: null,
  nodeId: "",
  deviceType: "",
  deviceConfig: {},
};

export const deviceFormVariables = makeVar<IDeviceForm>(
  defaultDeviceFormValues
);

export const useDeviceFormVariables = () => useReactiveVar(deviceFormVariables);

export const selectedLocationDevicesVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedLocationDevices = () =>
  useReactiveVar(selectedLocationDevicesVariable);
