import { type FetchResult, gql, useMutation } from "@apollo/client";

import { ADD_SERVICE } from "./../../../common/operations/mutations";
import type {
  AddServiceMutation,
  AddServiceMutationVariables,
  AddServiceInput,
} from "./../../../API";

type Data<T> = T | null | undefined;

interface CreateServiceInterface {
  createService: (
    input: AddServiceInput
  ) => Promise<FetchResult<AddServiceMutation>>;
  data?: AddServiceMutation | null;
  loading: boolean;
}

export const useCreateService = (): CreateServiceInterface => {
  const [saveService, { data, loading }] = useMutation<
    AddServiceMutation,
    AddServiceMutationVariables
  >(ADD_SERVICE);

  const createService = async (
    input: AddServiceInput
  ): Promise<FetchResult<AddServiceMutation>> => {
    return await saveService({
      variables: {
        input,
      },
      update(cache, response): void {
        cache.modify({
          fields: {
            getServices(existing): Data<AddServiceMutation> {
              const newServiceRef = cache.writeFragment({
                data: response.data?.addService,
                fragment: gql`
                  fragment NewService on Service {
                    id
                    locationId
                    nodeId
                    deviceId
                    serviceType
                    configuration
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newServiceRef],
              };
            },
          },
        });
      },
    });
  };

  return { createService, data, loading };
};
