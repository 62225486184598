import { DialogActions, Grid, TextField, Typography } from "@mui/material";

import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import { Dispatch, SetStateAction } from "react";

const UpdateServiceForm = ({
  onCancel,
  onConfirm,
  textValue,
  setTextValue,
}: {
  onCancel: () => void;
  onConfirm: () => void;
  textValue: string;
  setTextValue: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
  return (
    <>
      <Grid container paddingBottom="1em">
        <Typography>
          Provide JSON to update model instance&apos;s config
        </Typography>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            fullWidth
            label="JSON configuration"
            multiline
            variant="outlined"
            value={textValue}
            onChange={(e): void => setTextValue(e.target.value)}
          />
        </Grid>
        <Grid container>
          <DialogActions>
            <StyledLoadingButton
              fullWidth
              loadingPosition="start"
              variant="contained"
              onClick={onCancel}
            >
              {"Cancel"}
            </StyledLoadingButton>
          </DialogActions>
          <DialogActions>
            <StyledLoadingButton
              fullWidth
              loadingPosition="start"
              variant="contained"
              onClick={onConfirm}
            >
              {"Confirm Update"}
            </StyledLoadingButton>
          </DialogActions>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateServiceForm;
