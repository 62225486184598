/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ReactNode, useEffect, useRef, useState } from "react";
import { Layer, Stage } from "react-konva";

import { Grid, Button, TextField } from "@mui/material";
import Konva from "konva";

import { AddAnnotationInput } from "../../../../API";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";
import { useCreateAnnotation } from "../hooks/useCreateAnnotation";

import img from "./cameraImage.png";

const ImageAnnotation = ({
  rows,
  zoneId,
  serviceId,
}: {
  zoneId: string;
  rows: any;
  serviceId: string;
}): any => {
  const lineRef = useRef<Konva.Line | null>(null);
  const stageRef = useRef<Konva.Stage>(null);
  const layerRef = useRef<Konva.Layer>(null);

  const [isDrawing, setIsDrawing] = useState(false);
  const [lines, setLines] = useState<Konva.Line[]>([]);
  const [coordinates, setCoordinates] = useState<any>([]);
  const [imageDimensions, setImageDimensions] = useState([500, 500]);
  const [points, setPoints] = useState<number[]>([]);
  const [annotationName, setAnnotationName] = useState<string[]>([""]);
  const [isToggledAsExlcusionArea, setIsToggledAsExclusionArea] = useState([
    false,
  ]);
  const { createAnnotation } = useCreateAnnotation();

  const saveAnnotation = (index: number): void => {
    const input: AddAnnotationInput = {
      name: annotationName[index],
      polygon: JSON.stringify({
        annotations: calculatePolygon(index),
      }),
      zoneId,
      serviceId,
      annotationType: isToggledAsExlcusionArea[index]
        ? "EXCLUSION_AREA"
        : "EQUIPMENT",
    };

    createAnnotation(input)
      .then((response): void => {
        if (response?.data) {
          successNotification();
          console.log(response.data);
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      });
  };

  // render existing annotations
  useEffect((): void => {
    const layer = layerRef.current!;
    if (!layer) return;

    rows.map((row: any): void => {
      const polygon = JSON.parse(row.polygon);
      const type = row.annotationType;
      if (!type) return;

      const { annotations } = polygon;
      if (!annotations) return;
      annotations.pop();

      const stage = stageRef.current;
      if (!stage) return;

      // go through annotations and second element needs to be inverted
      const invertedYAnnotations = annotations.map((pair: any): any => {
        const [x, y] = pair;
        return [x, stage.height() - y];
      });

      // Create a new Konva line object with the points
      const line = new Konva.Line({
        points: invertedYAnnotations.flat(),
        closed: true, // Make the polygon closed
        stroke: "black",
        fill:
          type === "EQUIPMENT"
            ? "rgba(0, 0, 255, 0.2)"
            : "rgba(255, 0, 0, 0.2)",
        strokeWidth: 2,
      });

      layer.add(line);
    });
    layer.draw();
  }, [rows, imageDimensions, stageRef]);

  // save annotation to DB and send to NA for storing in config
  const calculatePolygon = (index: number): number[][] => {
    if (index === -1) return [];

    const selectedCoordinate = coordinates[index];
    const convertedStruct: number[][] = [];

    const stage = stageRef.current!;
    selectedCoordinate.map((pairs: [number, number]): void => {
      const [x, y] = pairs;
      // Convert the position relative to image
      const absX = (x - stage.x()) / stage.scaleX();
      const absY = stage.height() - (y - stage.y()) / stage.scaleY();
      // duplicate first element and add to end
      convertedStruct.push([Math.floor(absX), Math.floor(absY)]);
    });

    return convertedStruct;
  };

  function getFlattenedIndexRange(
    arr: any[][],
    index: number
  ): [number, number] {
    let start_index = 0;
    for (let i = 0; i < index; i++) {
      start_index += arr[i].length;
    }
    const end_index = start_index + arr[index].length;
    return [start_index, end_index];
  }

  // handle delete of a konva rectangle
  const handleDeleteClick = (selectedShapeIndex: number): void => {
    // get the index range to remove from lines as that is flattened out; coordinates is nested
    const [start, end] = getFlattenedIndexRange(
      coordinates,
      selectedShapeIndex
    );

    // delete from lines
    if (selectedShapeIndex !== null) {
      for (let i = start; i < end; i++) {
        lines[i].destroy();
      }
      lines.splice(start, end - start);

      // delete from coordinates
      const newCoordinates = [...coordinates];
      newCoordinates.splice(selectedShapeIndex, 1);
      setCoordinates(newCoordinates);
    }
  };

  // Load the image onto the stage and adjust the stage size to match the image size.
  useEffect((): void => {
    const streamImage = new Image();
    streamImage.src = img;
    const layer = layerRef.current;

    streamImage.onload = (): void => {
      const img_width = streamImage.width;
      const img_height = streamImage.height;
      setImageDimensions([img_width, img_height]);

      // now load the Konva image
      const theImg = new Konva.Image({
        image: streamImage,
        x: 0,
        y: 0,
        width: img_width,
        height: img_height,
      });

      if (layer) {
        layer.add(theImg);
      }
    };
  }, []);

  function convertToPairs(numbers: number[]): [number, number][] {
    const pairs: [number, number][] = [];
    for (let i = 0; i < numbers.length; i += 2) {
      pairs.push([numbers[i], numbers[i + 1]]);
    }
    pairs.push([numbers[0], numbers[1]]);
    return pairs;
  }

  // Add event listeners for the mouse down, mouse move, and mouse up events on the stage.
  useEffect((): (() => void) => {
    const stage = stageRef.current!;
    const layer = layerRef.current!;
    // Create a line with a click event listener
    lineRef.current = new Konva.Line({
      stroke: "red",
      fill: "rgba(0, 255, 0, 0.2)",
      strokeWidth: 2,
      points: points,
      closed: false,
    });
    layer.add(lineRef.current);

    const handleMouseDown = (): void => {
      setIsDrawing(true);
      // const pos = stage.getRelativePointerPosition()!;
      const pos = stage.getPointerPosition()!;
      setPoints([...points, Math.floor(pos.x), Math.floor(pos.y)]);
      if (lineRef.current) {
        lineRef.current.points([...points, points[0]]);
      }
      layer.batchDraw();
    };

    const handleMouseUp = (): void => {
      setLines([...lines, lineRef.current!]);

      if (isDrawing && points.length > 2) {
        // End drawing the polygon
        const pos = stage.getRelativePointerPosition()!;
        const firstPointX = points[0];
        const firstPointY = points[1];
        const distance = Math.sqrt(
          Math.pow(pos.x - firstPointX, 2) + Math.pow(pos.y - firstPointY, 2)
        );
        if (distance <= 25) {
          setIsDrawing(false);
          if (lineRef.current) {
            lineRef.current.points([...points, points[0], points[1]]);
            lineRef.current.closed(true);

            // add new coordinates locally
            const newCoordinates = convertToPairs(points);
            // save to coordinates
            setCoordinates([...coordinates, newCoordinates]);

            // new points
            setPoints([]);
            // new line
            lineRef.current = new Konva.Line({
              stroke: "red",
              fill: "rgba(0, 255, 0, 0.2)",
              strokeWidth: 2,
              points: points,
              closed: false,
            });
          }
        }
      }
    };

    stage.on("mousedown touchstart", handleMouseDown);
    stage.on("mouseup touchend", handleMouseUp);

    return (): void => {
      stage.off("mousedown touchstart", handleMouseDown);
      stage.off("mouseup touchend", handleMouseUp);
    };
  }, [isDrawing, lines, coordinates]);

  return (
    <Grid>
      <Stage
        ref={stageRef}
        width={imageDimensions[0]}
        height={imageDimensions[1]}
      >
        <Layer ref={layerRef} />
      </Stage>

      {coordinates.length > 0 && <h3>Shapes</h3>}

      <ul>
        {coordinates.map(
          (coords: any[], index: number): ReactNode => (
            <li key={index}>
              <Grid
                item
                xs
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                <TextField
                  margin="dense"
                  required
                  style={{ minWidth: 200 }}
                  id="Annotation Name"
                  label="Annotation Name"
                  type="text"
                  value={annotationName[index]}
                  onChange={(e): void =>
                    setAnnotationName((prevArray: any): string[] => {
                      const newArray = [...prevArray];
                      newArray[index] = e.target.value;
                      return newArray;
                    })
                  }
                />
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={(): void => handleDeleteClick(index)}
                >
                  Delete draft annotation
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={(): void => saveAnnotation(index)}
                >
                  Save Annotation
                </Button>
                <Button
                  variant="contained"
                  color="info"
                  size="small"
                  onClick={(): void =>
                    // setIsToggledAsExclusionArea(!isToggledAsExlcusionArea)
                    setIsToggledAsExclusionArea(
                      (prevArray: boolean[]): boolean[] => {
                        const newArray = [...prevArray];
                        newArray[index] = !prevArray[index];
                        return newArray;
                      }
                    )
                  }
                >
                  {isToggledAsExlcusionArea[index]
                    ? "TOGGLE: EXCLUSION_AREA"
                    : "TOGGLE: EQUIPMENT"}
                  {isToggledAsExlcusionArea[index]}
                </Button>
              </Grid>
            </li>
          )
        )}
      </ul>
    </Grid>
  );
};

export default ImageAnnotation;
