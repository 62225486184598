import { ChangeEvent, useMemo } from "react";

import { Grid, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";

import LocationIdSelect from "../../../common/components/layout/components/LocationIdSelect";
import MakeSelect from "../../../common/components/layout/components/MakeSelect";
import NodeSelect from "../../../common/components/select/NodeSelect/NodeSelect";
import type { AutocompleteOptionType } from "../../../common/models/autocomplete";
import DeviceDynamicFields from "../devices-create/DeviceDynamicFields";
import {
  deviceFormVariables,
  useDeviceFormVariables,
  useSelectedLocationDevices,
} from "../variables/devices";

interface ICreateDeviceFormProps {
  hasError?: boolean;
  deviceNameError?: boolean;
}

const CreateDeviceForm = ({
  hasError,
  deviceNameError,
}: ICreateDeviceFormProps): JSX.Element => {
  const selectedLocation = useSelectedLocationDevices();
  const deviceForm = useDeviceFormVariables();

  const setDeviceName = (event: ChangeEvent<HTMLInputElement>): void => {
    deviceFormVariables({
      ...deviceForm,
      deviceName: event.target.value,
    });
  };

  const setDeviceType = (value: string): void => {
    deviceFormVariables({
      ...deviceForm,
      deviceType: value,
    });
  };

  const setLocation = (value: AutocompleteOptionType): void => {
    deviceFormVariables({
      ...deviceForm,
      location: value,
    });
  };

  const setNodeId = (value: string): void => {
    deviceFormVariables({
      ...deviceForm,
      nodeId: value,
    });
  };

  const setDeviceConfig = (name: string, value: any): void => {
    deviceFormVariables({
      ...deviceForm,
      deviceConfig: {
        ...deviceForm.deviceConfig,
        [name]: value,
      },
    });
  };

  const helperText = useMemo((): string => {
    if (hasError && deviceForm.deviceName === "") {
      return "Please type a device name";
    }

    if (deviceNameError) {
      return "Name is already taken";
    }

    return "";
  }, [hasError, deviceNameError, deviceForm.deviceName]);

  return (
    <Grid container justifyContent="center" direction="column" spacing={2}>
      <Grid item>
        <TextField
          margin="dense"
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          error={(deviceNameError || hasError) && !deviceForm.deviceName}
          helperText={helperText}
          fullWidth
          id="Device Name"
          label="Device Name"
          value={deviceForm.deviceName}
          onChange={setDeviceName}
        />
      </Grid>
      <Grid item>
        <MakeSelect
          hasError={hasError}
          makeId={deviceForm.deviceType}
          onMakeChange={setDeviceType}
        />
      </Grid>
      <Grid item>
        <LocationIdSelect
          disableClearable
          setDefault
          defaultValue={selectedLocation?.value}
          hasError={hasError}
          location={deviceForm.location}
          setLocation={setLocation}
        />
      </Grid>
      <Grid item>
        <NodeSelect
          disableClearable
          hasError={hasError}
          nodeId={deviceForm.nodeId}
          onNodeChange={setNodeId}
        />
      </Grid>
      <Grid item>
        {deviceForm.deviceType && (
          <>
            <br />
            <Divider />
            <br />
            <DeviceDynamicFields
              hasError={hasError}
              deviceConfig={deviceForm.deviceConfig}
              setDeviceConfig={setDeviceConfig}
              deviceType={deviceForm.deviceType}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateDeviceForm;
