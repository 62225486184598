import type { ComponentsOverrides, Theme } from "@mui/material";

import { dark } from "../../pallete/colors";

export const MuiListItemButtonDarkStyleOverrides: ComponentsOverrides<Theme>["MuiListItemButton"] =
  {
    root: ({ theme }) => {
      return {
        color: theme.palette.text.primary,
        "&.MuiListItemButton-root:hover": {
          backgroundColor: dark.primaryLight02,
        },
        "&.Mui-selected": {
          backgroundColor: dark.primaryLight02,
        },
        ".MuiListItemButton-root.Mui-selected:hover": {
          backgroundColor: dark.primaryLight02,
        },
      };
    },
  };
