import { Divider, Paper } from "@mui/material";

import SystemSettingsHeader from "./tabs/SystemSettingsHeader";
import SystemSettingsTabs from "./tabs/SystemSettingsTabs";

const SystemSettingsPage = (): JSX.Element => {
  return (
    <Paper>
      <SystemSettingsHeader />
      <Divider />
      <SystemSettingsTabs />
    </Paper>
  );
};

export default SystemSettingsPage;
