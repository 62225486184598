import * as THREE from "three";
import Delaunator from "delaunator";

export const createLineSegments = (points, material) => {
  const geometry = new THREE.BufferGeometry();

  geometry.setFromPoints(points);
  geometry.computeVertexNormals();

  return new THREE.LineSegments(geometry, material);
};

export const createMeshByPoints = (points, material) => {
  const geometry = new THREE.BufferGeometry().setFromPoints(points);

  const indices = Delaunator.from(points.map(v => [v.x, v.z])).triangles.map(
    triangle => triangle
  );
  const uvs = new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]);

  geometry.setIndex(new THREE.BufferAttribute(indices, 1));
  geometry.setAttribute("uv", new THREE.Float32BufferAttribute(uvs, 2));

  geometry.computeVertexNormals();

  return new THREE.Mesh(geometry, material);
};

export const createGroup = (name, parent) => {
  const group = new THREE.Group();
  group.name = name;
  parent.add(group);

  return group;
};
