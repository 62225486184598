import AWS, { AWSError } from "aws-sdk";

import aws from "../../aws-exports.json";
import { getJwtToken } from "./getJwtToken";

export class S3Helper {
  public static getObject = async (objectKey: string) => {
    const jwt = await getJwtToken();

    AWS.config.region = aws.aws_user_files_s3_bucket_region;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: aws.aws_cognito_identity_pool_id,
      Logins: {
        ["cognito-idp.us-east-1.amazonaws.com/" + aws.aws_user_pools_id]: jwt,
      },
    });

    const s3 = new AWS.S3();
    const key =
      objectKey.charAt(0) === "/" ? objectKey.substring(1) : objectKey;

    try {
      const params = {
        Bucket: aws.aws_node_files_s3_bucket,
        Key: key,
      };

      const data = await s3.getObject(params).promise();

      const blob = new Blob([data.Body as Blob], { type: data.ContentType });
      const url = URL.createObjectURL(blob);

      return url;
    } catch (error) {
      throw new Error(
        `Could not retrieve file from S3: ${(error as AWSError).message}`
      );
    }
  };
}
