import { useContext } from "react";

import { Box, Button, Typography, Stack } from "@mui/material";
import { WarningIcon } from "../../../common/assets/icons/svgAssets";
import ModalWindow from "../../../common/components/ModalWindow";

import ModalAlertContext from "../../../common/context/ModalAlertContext/ModalAlertContext";
import ProjectContext from "../../../common/context/ProjectContext/ProjectContext";

const DiscardChangesModal = () => {
  const { isDisplayDiscardChangesModal, setIsDisplayDiscardChangesModal } =
    useContext(ModalAlertContext);
  const { exitFromProjectConfigurator } = useContext(ProjectContext);

  const closeModal = () => {
    setIsDisplayDiscardChangesModal(false);
  };

  const onDiscardButton = () => {
    exitFromProjectConfigurator();
    closeModal();
  };

  return (
    <ModalWindow isOpen={isDisplayDiscardChangesModal} onClose={closeModal}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "40px",
        }}
      >
        <Box
          sx={{
            width: "24px",
            height: "100%",
            position: "absolute",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WarningIcon fillColor="#FFD747" />
        </Box>

        <Typography id="modal-modal-title" variant="h3" component="h2">
          Discard changes
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "40px",
          margin: "16px 0 24px",
        }}
      >
        <Typography id="modal-modal-description" variant="body14Regular">
          Are you sure you want to discard all changes?
        </Typography>
      </Box>

      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Button
          onClick={onDiscardButton}
          borders="rectangle"
          variant="primary"
          color="blue"
        >
          Discard
        </Button>
        <Button
          onClick={closeModal}
          borders="rectangle"
          variant="secondary"
          color="blue"
        >
          Cancel
        </Button>
      </Stack>
    </ModalWindow>
  );
};

export default DiscardChangesModal;
