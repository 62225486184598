import { Box, Stack, Typography } from "@mui/material";

import CreateDeviceDialogContainer from "./CreateDeviceDialogContainer";
import LocationSelectContainer from "./LocationSelectContainer";

const DevicePageHeader = (): JSX.Element => {
  return (
    <Box padding="20px 24px 19px 24px">
      <Stack spacing={4} direction="row" alignItems="center">
        <Typography variant="h5" padding={0} sx={{ flexGrow: 1 }}>
          Devices
        </Typography>
        <LocationSelectContainer />
        <CreateDeviceDialogContainer />
      </Stack>
    </Box>
  );
};

export default DevicePageHeader;
