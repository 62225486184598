import { useContext } from "react";
import { ListItem } from "@mui/material";

import TowerPanelListItemHeader from "./TowerPanelListItemHeader";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";

import { CamerasType } from "../../../data/CamerasData";

const TowerCameraListItem = ({ data }) => {
  const {
    sceneStarted,
    activeCameraId,
    handleSetActiveCameraId,
    handleSetHoveredCameraId,
    setSecondaryCameraId,
    setSecondaryViewType,
  } = useContext(ProjectContext);

  const handleOnItemClick = () => {
    if (sceneStarted) {
      handleSetActiveCameraId(data.id);

      setSecondaryCameraId(null);
      setSecondaryViewType(null);
    }
  };

  const handleOnItemOver = () => {
    sceneStarted && handleSetHoveredCameraId(data.id);
  };

  const handleItemLeaved = () => {
    sceneStarted && handleSetHoveredCameraId(null);
  };

  return (
    <ListItem
      sx={{
        width: "100%",
        display: "flex",
        background: "transparent",
        padding: "0px",
      }}
      onMouseOver={handleOnItemOver}
      onMouseLeave={handleItemLeaved}
    >
      <TowerPanelListItemHeader
        text={data.name}
        selected={data.id === activeCameraId}
        onItemClick={handleOnItemClick}
      />
    </ListItem>
  );
};

export default TowerCameraListItem;
