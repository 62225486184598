import * as THREE from "three";

import { loadFBXAsset, loadTexture } from "../AssetLoaders";
import { setMaterial, setTexturesToMaterial } from "../MaterilsUtils";

import { OBJECT_DEFAULT_COLOR } from "../Constants";

class SceneObjectBuilder {
  constructor() {
    this.objectTypes = {};
  }

  async getObjectInstance(objectData) {
    if (!this.objectTypes[objectData.name]) {
      const object = await this.loadModel(objectData.path);

      object.name = objectData.name;
      object.castShadow = true;
      object.receiveShadow = true;

      await this.updateModelMaterial(object, objectData);

      this.objectTypes[objectData.name] = {
        objectToClone: object,
        materialToClone: object.children[0].material,
      };
    }

    return this.objectTypes[objectData.name];
  }

  async loadModel(path) {
    const object = await loadFBXAsset(path);

    return object;
  }

  async updateModelMaterial(object, objectData) {
    if (objectData.textures && objectData.textures.length > 0) {
      const textures = await Promise.all(
        objectData.textures.map(async data => {
          const texture = await loadTexture(data.path);

          return {
            mapName: data.mapName,
            texture,
          };
        })
      );

      const material = new THREE.MeshStandardMaterial();
      material.copy(object.children[0].material);

      material.metalness = 0.9;
      material.color = OBJECT_DEFAULT_COLOR;

      if (objectData.envMap) {
        material.envMap = objectData.envMap;
        material.envMapIntensity = 2.0;
      }

      setMaterial(object, material);
      setTexturesToMaterial(object, textures);
    }
  }

  setAxisDependOnGround(object, faceNormal, rotateXDeg = 90) {
    object.lookAt(faceNormal);
    object.rotateX(THREE.MathUtils.degToRad(rotateXDeg));
  }

  setRotation(object, rotation) {
    rotation && object.rotation.set(rotation.x, rotation.y, rotation.z);
  }

  setPosition(object, position, defaultPosition) {
    position
      ? object.position.copy(position)
      : object.position.copy(defaultPosition);
  }
}

export default SceneObjectBuilder;
