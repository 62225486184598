import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  AddDeviceMakeInput,
  AddDeviceMakeMutation,
  AddDeviceMakeMutationVariables,
} from "../../../../../API";
import { ADD_DEVICE_MAKE } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useCreateDeviceMake = () => {
  const [saveDeviceMake, { data, loading }] = useMutation<
    AddDeviceMakeMutation,
    AddDeviceMakeMutationVariables
  >(ADD_DEVICE_MAKE);

  const createDeviceMake = (input: AddDeviceMakeInput) => {
    return saveDeviceMake({
      variables: {
        input: {
          name: input.name.trim(),
          model: input.model.trim(),
          rtspTemplate: input.rtspTemplate.trim(),
          type: input.type.trim(),
        },
      },
      onCompleted: response => {
        if (response.addDeviceMake) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);
        console.error(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getDevicesMakes(existing) {
              const newDeviceMakeRef = cache.writeFragment({
                data: response.data?.addDeviceMake,
                fragment: gql`
                  fragment NewDeviceMake on DeviceMake {
                    id
                    model
                    name
                    rtspTemplate
                    type
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newDeviceMakeRef],
              };
            },
          },
        });
      },
    });
  };

  return { createDeviceMake, data, loading };
};
