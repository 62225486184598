import { Box, Tab, Tabs } from "@mui/material";

import { useEffect } from "react";
import TabPanel from "../../../common/components/tabs/TabPanel";
import { useAuthenticatedUser } from "../../../common/hooks/useAuthenticatedUser";
import {
  SystemSettingsTabsEnum,
  SystemSettingsTabsList,
  systemSettingsTabsVariable,
  useSystemSettingsTabsVariable,
} from "../variables/systemSettings";
import AlertsTableContainer from "./alert-management/AlertsTableContainer";
import DevicesMakes from "./devices/DevicesMakes";
import AdminNavigation from "./navigation/AdminNavigation";
import RolesTable from "./roles/RolesTable";

const SystemSettingsTabs = (): JSX.Element => {
  const { role } = useAuthenticatedUser();

  const { selectedTab } = useSystemSettingsTabsVariable();

  useEffect((): void => {
    if (role !== "") {
      const allowedTabs = SystemSettingsTabsList.filter((tab): boolean => {
        return tab.allowedRoles.includes(role);
      });

      systemSettingsTabsVariable({
        selectedTab: allowedTabs[0]?.id,
      });
    }
  }, [role]);

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: SystemSettingsTabsEnum
  ): void => {
    systemSettingsTabsVariable({
      selectedTab: newValue,
    });
  };

  const allowedTabs = SystemSettingsTabsList.filter((tab): boolean => {
    return tab.allowedRoles.includes(role);
  });

  const tabs = allowedTabs.map((tab): JSX.Element => {
    return <Tab key={tab.id} label={tab.label} value={tab.id} />;
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          onChange={handleChange}
          value={selectedTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs}
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={SystemSettingsTabsEnum.DEVICES}>
        <DevicesMakes />
      </TabPanel>
      <TabPanel value={selectedTab} index={SystemSettingsTabsEnum.ROLES}>
        <RolesTable />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={SystemSettingsTabsEnum.ADMIN_NAVIGATION}
        sx={{ padding: 0 }}
      >
        <AdminNavigation />
      </TabPanel>
      <TabPanel
        value={selectedTab}
        index={SystemSettingsTabsEnum.ALERT_MANAGEMENT}
        sx={{ padding: 0 }}
      >
        <AlertsTableContainer />
      </TabPanel>
    </Box>
  );
};

export default SystemSettingsTabs;
