import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";

const MenuLink = styled(Link)(() => ({
  cursor: "pointer",
  textDecoration: "none",
  overflow: "hidden",
  whiteSpace: "nowrap",
  color: "inherit",
}));

export default MenuLink;
