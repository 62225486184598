import * as THREE from "three";

export const getRaycastIntersectsPointByFromToVectors = (
  object,
  fromPoint,
  toPoint
) => {
  const raycaster = new THREE.Raycaster();

  const origin = fromPoint.clone();
  const direction = toPoint.clone().sub(fromPoint).normalize();

  raycaster.set(origin, direction);
  const intersects = raycaster.intersectObject(object);

  return intersects;
};
