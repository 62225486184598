export const allowedFileTypes = {
  images: ["image/*", ".jpg", ".jpeg", ".png", ".gif", ".svg", ".webp"],
  videos: [
    "video/*",
    ".mp4",
    ".avi",
    ".mov",
    ".wmv",
    ".flv",
    ".3gp",
    ".mkv",
    ".webm",
  ],
};

export const extractFileNameExt = (file: string) => {
  if (!file) {
    return null;
  }

  return file.split(".").slice(0, -1).join(".");
};

export const getImagePreview = (blob: Blob) => {
  return blob && URL.createObjectURL(blob);
};
