import ClearIcon from "@mui/icons-material/Clear";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { IAutocompleteOption } from "../../../models/autocomplete";

const options: IAutocompleteOption[] = [
  { value: "CS#service_1", title: "Service 1" },
];

interface ICustomServiceSelectProps {
  service: string;
  onServiceChange: (value: string) => void;
}

const CustomServiceSelect = ({
  service,
  onServiceChange,
}: ICustomServiceSelectProps): JSX.Element => {
  const handleSelectChange = (event: SelectChangeEvent<string>): void => {
    onServiceChange(event.target.value);
  };

  const handleClear = (): void => {
    onServiceChange("");
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="customServiceLabelId">Custom Service</InputLabel>
      <Select
        labelId="customServiceLabelId"
        id="customServiceSelect"
        label="Custom Service"
        value={service}
        onChange={handleSelectChange}
        endAdornment={
          service && (
            <InputAdornment
              position="end"
              sx={{
                cursor: "pointer",
                position: "absolute",
                right: "36px",
              }}
            >
              <ClearIcon onClick={handleClear} />
            </InputAdornment>
          )
        }
      >
        {options.map(
          (option): JSX.Element => (
            <MenuItem
              key={option?.value as string}
              value={option?.value as string}
            >
              {option.title}
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default CustomServiceSelect;
