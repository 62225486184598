import * as THREE from "three";

export const getObjectBoundingBox = object3d => {
  object3d.geometry.computeBoundingBox();

  return object3d.geometry.boundingBox;
};

export const calculateObject3dCenter = bbox => {
  const center = new THREE.Vector3();
  bbox.getCenter(center);

  return center;
};

export const getBufferGeometryFaceNormals = geometry => {
  const faceNormals = [];
  const normals = geometry.attributes.normal;
  const indices = geometry.index ? geometry.index.array : undefined;
  const numVertices = geometry.attributes.position.count;

  for (let i = 0; i < numVertices; i += 3) {
    const normal = new THREE.Vector3();

    const a = indices[i];
    const b = indices[i + 1];
    const c = indices[i + 2];

    normal
      .fromBufferAttribute(normals, a)
      .add(new THREE.Vector3().fromBufferAttribute(normals, b))
      .add(new THREE.Vector3().fromBufferAttribute(normals, c))
      .normalize();

    faceNormals.push(normal);
  }

  return faceNormals;
};
