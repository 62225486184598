import { ApolloError, gql, useMutation } from "@apollo/client";

import {
  PaginatedAlerts,
  UpdateAlertStatusInput,
  UpdateAlertStatusMutation,
  UpdateAlertStatusMutationVariables,
} from "../../../../../API";
import { UPDATE_ALERT_STATUS } from "../../../../../common/operations/mutations";
import { errorNotification } from "../../../../../common/variables/notification";

export const useUpdateAlertStatus = () => {
  const [updateAlertMutation, { data, loading }] = useMutation<
    UpdateAlertStatusMutation,
    UpdateAlertStatusMutationVariables
  >(UPDATE_ALERT_STATUS);

  const updateAlert = (input: UpdateAlertStatusInput) => {
    return updateAlertMutation({
      variables: {
        input,
      },
      onError: (error: ApolloError): void => {
        console.error(error);
        errorNotification(error.message);
      },
      update: (cache, response): void => {
        cache.modify({
          fields: {
            getAllAlerts(existing: PaginatedAlerts) {
              const newAlertRef = cache.writeFragment({
                data: response.data?.updateAlertStatus,
                fragment: gql`
                  fragment NewAlert on Alert {
                    additionalEmails
                    alertName
                    alertType
                    customService
                    email
                    id
                    jsonObject
                    location
                    locationTag
                    model
                    node
                    status
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: existing.items.map(item => {
                  if (item?.id !== response.data?.updateAlertStatus?.id) {
                    return item;
                  }

                  return newAlertRef;
                }),
              };
            },
          },
        });
      },
    });
  };

  return { updateAlert, data, loading };
};
