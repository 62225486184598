import { Box } from "@mui/material";

import { useAuthenticatedUser } from "../../../../common/hooks/useAuthenticatedUser";
import { RoleEnum } from "../../../../common/models/enums";
import AlertsTable from "./components/AlertsTable";
import { useAlertsTableColumns } from "./hooks/useAlertsTableColumns";
import { useAlertsTableRow } from "./hooks/useAlertsTableRow";
import { useUserAlertsTableColumns } from "./hooks/useUserAlertsTableColumns";
import { useUserAlertsTableRow } from "./hooks/useUserAlertsTableRow";

const ADMIN_ACCESS = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
  RoleEnum.CUSTOMER_ADMIN,
];

const NON_ADMIN_ACCESS = [
  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  RoleEnum.CUSTOMER_OPS_TEAM,
  RoleEnum.CUSTOMER_HSE,
  RoleEnum.CUSTOMER_SECURITY,
];

const AlertsTableContainer = (): JSX.Element | null => {
  const { role } = useAuthenticatedUser();

  const hasAdminAccess = ADMIN_ACCESS.includes(role);

  if (hasAdminAccess) {
    const { rows, loading } = useAlertsTableRow();
    const { columns } = useAlertsTableColumns();

    return <AlertsTable rows={rows} columns={columns} loading={loading} />;
  }

  const hasNonAdminAccess = NON_ADMIN_ACCESS.includes(role);

  if (hasNonAdminAccess) {
    const { rows, loading } = useUserAlertsTableRow();
    const { columns } = useUserAlertsTableColumns();

    return <AlertsTable rows={rows} columns={columns} loading={loading} />;
  }

  return <Box padding="1.5em">You do not have access</Box>;
};

export default AlertsTableContainer;
