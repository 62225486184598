import { useContext, useEffect, useState } from "react";
import {
  Box,
  OutlinedInput,
  ToggleButton,
  Typography,
  Divider,
} from "@mui/material";

import ProjectContext from "../../context/ProjectContext/ProjectContext";
import ConfiguratorPermissionContext from "../../context/ConfiguratorPermissionContext/ConfiguratorPermissionContext";

import { ObjectMode } from "../../enums";

import { DeleteIcon, MoveIcon, RotateIcon } from "../../assets/icons/svgAssets";

const ObjectControlPanel = () => {
  const {
    threeScene,
    isLoading,
    objects,
    towers,
    activeObjectId,
    deleteObjectCameraData,
    updateObjectTowerState,
    transformControlMode,
    setTransformControlMode,
    distanceMeasurementsMode,
  } = useContext(ProjectContext);

  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const activeObject = [...objects, ...towers].find(
    object => object.id === activeObjectId
  );

  const [prevId, setPrevId] = useState(0);
  const [angle, setAngle] = useState("");

  const onSetTransformControlModeButton = mode => {
    if (!activeObjectId) {
      return;
    }

    setTransformControlMode(mode);
  };

  const onDeleteObjectButton = () => {
    if (!activeObjectId) {
      return;
    }

    if (threeScene && threeScene.transformControl) {
      threeScene.transformControl.toggleControlledObject(null);
    }

    deleteObjectCameraData(activeObjectId);
  };

  const roundObjectAngleValue = (obj, value) => {
    const step = obj && obj.EntityType === "object" ? 5 : 10;
    return Math.round(value / step) * step;
  };

  const handleChangeAngleValue = (value, obj = activeObject) => {
    if (obj) {
      const roundedValue = roundObjectAngleValue(obj, value);

      threeScene.setObjectQuaternionByAngle(obj.id, roundedValue);
      updateObjectTowerState(obj.id);
    }
  };

  const onObjectAngleInputChange = e => {
    setAngle(e.target.value);

    if (!e.nativeEvent.inputType) {
      handleChangeAngleValue(e.target.value);
    }
  };

  const onObjectDisactivated = () => {
    const prevObj = [...objects, ...towers].find(
      object => object.id === prevId
    );

    handleChangeAngleValue(angle, prevObj);
  };

  const onObjectAngleInputKeyDown = e => {
    if (e.keyCode === 13) {
      handleChangeAngleValue(angle);
    }
  };

  const onObjectAngleInputBlur = () => {
    if (activeObject) {
      handleChangeAngleValue(angle);
    }
  };

  useEffect(() => {
    activeObject && setAngle(activeObject.angle);
  }, [objects, towers]);

  useEffect(() => {
    if (!activeObjectId && !activeObject) {
      onObjectDisactivated();
    } else {
      setAngle(activeObject.angle);
      setPrevId(activeObjectId);
    }
  }, [activeObjectId]);

  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        backgroundColor: "#F5F5F5",
        border: "1px solid #999999",
        borderRadius: "4px",
        width: "540px",
        height: "72px",
        overflow: "hidden",
      }}
    >
      <Typography
        sx={{
          background: activeObjectId ? "#326CFB" : "#999999",
          color: "white",
          padding: "2px 8px",
        }}
        variant="body12Medium"
      >
        {activeObject ? activeObject.name : "Nothing selected"}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "8px",
          gap: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {isAllowEditing && (
            <ToggleButton
              variant="icon-text"
              value="move"
              disabled={
                !activeObjectId || distanceMeasurementsMode || isLoading
              }
              selected={transformControlMode === ObjectMode.translateObject}
              onClick={() =>
                onSetTransformControlModeButton(ObjectMode.translateObject)
              }
            >
              <MoveIcon />
              <Typography variant="body8Regular" component="p">
                Move
              </Typography>
            </ToggleButton>
          )}
          <Typography
            sx={{
              width: "44px",
              color: activeObject ? "#202832" : "#999999",
            }}
            variant="body14Regular"
            component="p"
          >
            GPS:
          </Typography>
          <OutlinedInput
            disabled={
              true || distanceMeasurementsMode || isLoading || !isAllowEditing
            }
            type="number"
            value={
              activeObject && activeObject.coordinates
                ? activeObject.coordinates.latitude.toFixed(7)
                : ""
            }
            sx={{
              height: "30px",
              "& input::-webkit-inner-spin-button": {
                display: "none !important",
              },
            }}
          />
          <OutlinedInput
            disabled={
              true || distanceMeasurementsMode || isLoading || !isAllowEditing
            }
            type="number"
            value={
              activeObject && activeObject.coordinates
                ? activeObject.coordinates.longitude.toFixed(7)
                : ""
            }
            sx={{
              height: "30px",
              "& input::-webkit-inner-spin-button": {
                display: "none !important",
              },
            }}
          />
        </Box>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ backgroundColor: activeObject ? "#98A7BC" : "#999999" }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {isAllowEditing && (
            <ToggleButton
              variant="icon-text"
              value="rotate"
              disabled={
                !activeObjectId || distanceMeasurementsMode || isLoading
              }
              selected={transformControlMode === ObjectMode.rotateObject}
              onClick={() =>
                onSetTransformControlModeButton(ObjectMode.rotateObject)
              }
            >
              <RotateIcon />
              <Typography variant="body8Regular" component="p">
                Rotate
              </Typography>
            </ToggleButton>
          )}

          <Typography
            sx={{
              width: "44px",
              color: activeObject ? "#202832" : "#999999",
            }}
            variant="body14Regular"
            component="p"
          >
            Angle:
          </Typography>
          <OutlinedInput
            disabled={
              !activeObject ||
              distanceMeasurementsMode ||
              isLoading ||
              !isAllowEditing
            }
            type="number"
            val="true"
            onChange={onObjectAngleInputChange}
            onBlur={onObjectAngleInputBlur}
            onKeyDown={onObjectAngleInputKeyDown}
            value={activeObject ? angle : ""}
            placeholder={activeObject ? `${activeObject.angle}` : ""}
            inputProps={{
              step:
                activeObject && activeObject.EntityType === "object" ? 5 : 10,
            }}
            sx={{
              height: "30px",
              width: "55px",
            }}
          />
        </Box>
        {isAllowEditing && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: activeObject ? "#98A7BC" : "#999999" }}
          />
        )}
        {isAllowEditing && (
          <ToggleButton
            variant="icon-text"
            value="delete"
            disabled={!activeObjectId || isLoading}
            selected={false}
            onClick={onDeleteObjectButton}
          >
            <DeleteIcon />
            <Typography variant="body8Regular" component="p">
              Delete
            </Typography>
          </ToggleButton>
        )}
      </Box>
    </Box>
  );
};

export default ObjectControlPanel;
