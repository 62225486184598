import { useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import ProjectViewPageHeader from "./components/ProjectViewPageHeader";
import ConfiguratorContainer from "../../common/components/configurator/ConfiguratorContainer";
import Loader from "../../common/components/loader/Loader";

import ProjectContext from "../../common/context/ProjectContext/ProjectContext";

import { useReadDataFromS3 } from "../../common/hooks/useReadDataFromS3";
import { useGetDTProject } from "../../common/hooks/useGetDTProject";
import { CUSTOMER_ID } from "../dashboard/hooks/useGetDTProjects";

const WebglConfiguratorProjectViewerPage = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { fetchProject, data, loading, error } = useGetDTProject();
  const { projectData, setProjectData } = useContext(ProjectContext);
  const { getDataFromS3, loading: getDataFromS3Loading } = useReadDataFromS3();

  const handleSetProjectData = async data => {
    const fileData = await getDataFromS3(data.getDTProject.configURL);

    setProjectData(fileData.data);
  };

  useEffect(() => {
    if (projectId) {
      fetchProject(CUSTOMER_ID, projectId.replace("_", "#"));
    }
  }, [projectId]);

  useEffect(() => {
    if (data) {
      handleSetProjectData(data);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      navigate("/webgl-dashboard");
    }
  }, [error]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "10px",
      }}
    >
      {!loading && !getDataFromS3Loading && data && projectData ? (
        <>
          <ProjectViewPageHeader dtProject={data.getDTProject} />
          <ConfiguratorContainer />
        </>
      ) : (
        <Loader />
      )}
    </Box>
  );
};

export default WebglConfiguratorProjectViewerPage;
