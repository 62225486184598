import { useState } from "react";

import ModalAlertContext from "./ModalAlertContext";

const ModalAlertContextProvider = ({ children }) => {
  const [alertState, setAlertState] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [isDisplayDeleteProjectModal, setIsDisplayDeleteProjectModal] =
    useState(false);
  const [isDisplayDiscardChangesModal, setIsDisplayDiscardChangesModal] =
    useState(false);
  const [isDisplayShareModal, setIsDisplayShareModal] = useState(false);
  const [isDisplayCreateNewProjectModal, setIsDisplayCreateNewProjectModal] =
    useState(false);
  const [selectedProjectData, setSelectedProjectData] = useState();

  const closeAlert = () => {
    setAlertState(false);
  };

  const openAlert = type => {
    setAlertState(true);
    setAlertType(type);
  };

  const openDeleteProjectModal = data => {
    setAlertState(false);
    setIsDisplayDeleteProjectModal(true);
    setSelectedProjectData(data);
  };

  const closeDeleteProjectModal = () => {
    setSelectedProjectData(null);
    setIsDisplayDeleteProjectModal(false);
  };

  const openCreateNewProjectModal = () => {
    setIsDisplayCreateNewProjectModal(true);
  };

  const closeCreateNewProjectModal = () => {
    setIsDisplayCreateNewProjectModal(false);
  };

  return (
    <ModalAlertContext.Provider
      value={{
        alertState,
        closeAlert,
        alertType,
        openAlert,
        selectedProjectData,
        isDisplayDeleteProjectModal,
        openDeleteProjectModal,
        closeDeleteProjectModal,
        isDisplayCreateNewProjectModal,
        openCreateNewProjectModal,
        closeCreateNewProjectModal,
        isDisplayDiscardChangesModal,
        setIsDisplayDiscardChangesModal,
        isDisplayShareModal,
        setIsDisplayShareModal,
      }}
    >
      {children}
    </ModalAlertContext.Provider>
  );
};

export default ModalAlertContextProvider;
