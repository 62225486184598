import { Box } from "@mui/material";

import CreateDeviceMake from "./components/CreateDeviceMake";
import DevicesMakesTable from "./components/DevicesMakesTable";

const DevicesMakes = (): JSX.Element => {
  return (
    <Box>
      <Box padding="0.75em 1em">
        <CreateDeviceMake />
      </Box>
      <DevicesMakesTable />
    </Box>
  );
};

export default DevicesMakes;
