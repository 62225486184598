import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AIManagerIcon from "../../icons/AIManager";
import GasLeakEventsIcon from "../../icons/GasLeakEvents";
import SystemSettingsIcon from "../../icons/SystemSettings";

const listItemButtonCssNoAddIcon = {
  borderRadius: "8px",
  "&.MuiListItemButton-root": {
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: "4px",
    marginBottom: "4px",
    height: "40px",
  },
};

const listItemButtonCss = {
  borderRadius: "8px",
  "&.MuiListItemButton-root": {
    paddingBottom: 0,
    paddingTop: 0,
    marginTop: "4px",
    marginBottom: "4px",
  },
};

const listItemTextCss = {
  fontWeight: 500,
};

const CustomerOperatorForemanMenuView = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  return (
    <div>
      <Link to={"/model"}>
        <Box
          component="img"
          sx={{ width: "100%" }}
          alt="logo-image"
          src={
            theme.palette.mode === "dark"
              ? "/Autonomous365 White.png"
              : "/Autonomous365 Dark.png"
          }
        />
      </Link>
      <List sx={{ paddingTop: "52px" }}>
        <ListItem disablePadding onClick={(): void => navigate("/model")}>
          <ListItemButton
            selected={pathname === "/model" ?? pathname === "/model/create"}
            sx={{ ...listItemButtonCss }}
          >
            <ListItemIcon>
              <AIManagerIcon />
            </ListItemIcon>
            <ListItemText
              primary="AI Manager"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
            <IconButton
              onClick={(e): void => {
                e.stopPropagation();
                navigate("/model/create");
              }}
            >
              <AddCircleOutlineOutlinedIcon
                sx={{ color: theme.palette.text.primary }}
              />
            </IconButton>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            selected={pathname === "/gasLeakTest"}
            sx={{ ...listItemButtonCssNoAddIcon }}
            onClick={(): void => navigate("/gasLeakTest")}
          >
            <ListItemIcon>
              <GasLeakEventsIcon />
            </ListItemIcon>
            <ListItemText
              primary="Gas Leak Events"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
          </ListItemButton>
        </ListItem>

        <Divider />

        <ListItem disablePadding>
          <ListItemButton
            selected={pathname === "/system-settings"}
            sx={{ ...listItemButtonCssNoAddIcon }}
            onClick={(): void => navigate("/system-settings")}
          >
            <ListItemIcon>
              <SystemSettingsIcon />
            </ListItemIcon>
            <ListItemText
              primary="System Settings"
              primaryTypographyProps={{
                style: listItemTextCss,
              }}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export default CustomerOperatorForemanMenuView;
