import { makeVar, useReactiveVar } from "@apollo/client";
import { IFormValidationState } from "../../../common/models/formValidation";

interface ILocationForm {
  locationName: string;
  latitude: string;
  longitude: string;
  tags?: string[];
}

export const defaultLocationFormVariables = {
  locationName: "",
  latitude: "",
  longitude: "",
  tags: [],
};

interface ILocationFormValidationState {
  locationName: IFormValidationState;
  latitude: IFormValidationState;
  longitude: IFormValidationState;
}

export const defaultLocationFormValidationState: ILocationFormValidationState =
  {
    locationName: {
      hasError: false,
      errorMessage: "",
    },
    latitude: {
      hasError: false,
      errorMessage: "",
    },
    longitude: {
      hasError: false,
      errorMessage: "",
    },
  };

export const locationFormRules = {
  locationName: {
    required: true,
    unique: true,
    pattern: `^([a-zA-Z0-9]+\\s?)+$`,
    maxLength: 30,
  },
  latitude: {
    required: true,
  },
  longitude: {
    required: true,
  },
};

export const locationFormVariables = makeVar<ILocationForm>(
  defaultLocationFormVariables
);
export const useLocationFormVariables = () =>
  useReactiveVar(locationFormVariables);

export const locationFormValidationStateVariable =
  makeVar<ILocationFormValidationState>(defaultLocationFormValidationState);
export const useLocationFormValidationState = () =>
  useReactiveVar(locationFormValidationStateVariable);
