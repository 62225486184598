import { useEffect, useState } from "react";
import {
  Box,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

const INPUT_SIZE = {
  lableWidth: "105px",
  gapBetweebLableAndInput: "10px",
};

const CameraParametersListItem = ({
  title,
  inputData,
  inputWidth,
  handleOnChange = () => {},
}) => {
  const [value, setValue] = useState(inputData.value);
  const [isValid, setIsValid] = useState(true);

  const onNumberInputChange = event => {
    const value = event.target.value;
    const isValid = value >= inputData.min && value <= inputData.max;

    setIsValid(isValid);
    setValue(value);

    handleOnChange(inputData.type, value, !isValid);
  };

  const onSelectInputChange = event => {
    handleOnChange(inputData.type, event.target.value, false);
  };

  useEffect(() => {
    if (value !== inputData.value) {
      setValue(!inputData.value ? "" : inputData.value);
    }
  }, [inputData.value]);

  return (
    <ListItem
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        padding: "0px",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          width: INPUT_SIZE.lableWidth,
          height: "28px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography
          sx={{
            color: "#202832",
            fontWeight: 400,
          }}
          variant="body12Medium"
        >
          {`${title}`}
        </Typography>
      </Box>
      <Box
        sx={{
          width: `calc(100% - ${INPUT_SIZE.lableWidth} - ${INPUT_SIZE.gapBetweebLableAndInput})`,
          height: "28px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {inputData.inputType === "number" && (
          <OutlinedInput
            sx={{
              width: inputWidth || "70%",
              height: "30px",
            }}
            onChange={onNumberInputChange}
            error={!isValid}
            value={value}
            placeholder={inputData.value ? `${inputData.value}` : "0"}
            type="number"
            inputProps={{
              min: inputData.min,
              max: inputData.max,
              step: inputData.step,
            }}
            disabled={inputData.disabled}
          />
        )}

        {inputData.inputType === "select" && (
          <Select
            sx={{
              width: inputWidth || "100%",
              height: "30px",

              ...(inputData.value === inputData.placeholder && {
                "& .MuiSelect-select": {
                  color: "#B3B3B3 !important",
                },
              }),
            }}
            fullWidth
            value={inputData.value}
            onChange={onSelectInputChange}
            disabled={inputData.disabled}
          >
            {inputData.placeholder && (
              <MenuItem
                sx={{ display: "none" }}
                value={inputData.placeholder}
                disabled
              >
                {inputData.placeholder}
              </MenuItem>
            )}
            {inputData.options.map(value => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
        )}

        {inputData.inputType === "readonly" && (
          <Typography
            sx={{
              color: "#202832",
              fontWeight: 400,
              padding: "7px 6px",
            }}
            variant="body12Medium"
          >
            {inputData.value}
          </Typography>
        )}
      </Box>
    </ListItem>
  );
};

export default CameraParametersListItem;
