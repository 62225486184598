import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import TableNoDataOverlay from "../../../common/components/table/TableNoDataOverlay";

import { useEmailNotificationsTableColumns } from "../hooks/useEmailNotificationsTableColumns";
import { useEmailNotificationsTableData } from "../hooks/useEmailNotificationsTableData";

export const DEFAULT_TABLE_PAGE_SIZE = 10;

function EmailNotificationsPageTable(): JSX.Element {
  const { rows, loading } = useEmailNotificationsTableData();
  const { columns } = useEmailNotificationsTableColumns();
  // const { data, loading } = useGetPaginatedAlertSubscriptions();

  // const handleRowClick = (): void => {
  //   // Open location modal or redirect to location details/edit page
  // };

  return (
    <DataGrid
      sx={{
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 600,
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
      }}
      rowsPerPageOptions={[10]}
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: TableNoDataOverlay,
      }}
      autoHeight
      disableSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: string }): string => row.rowId}
      loading={loading}
      pageSize={DEFAULT_TABLE_PAGE_SIZE}
      sortingOrder={["desc", "asc"]}
    />
  );
}

export default EmailNotificationsPageTable;
