import { useContext, useEffect } from "react";
import { Box, Button } from "@mui/material";

import ProjectContext from "../../context/ProjectContext/ProjectContext";

import { EyeOffIcon, EyeOnIcon } from "../../assets/icons/svgAssets";

const SceneObjectVisibilityButtons = () => {
  const {
    sceneStarted,
    isLoading,
    sketchplanState,
    setSketchplanState,
    toggleSketchplanState,
    cameras,
    cameraHelperState,
    setCameraHelperState,
    toggleCameraHelper,
  } = useContext(ProjectContext);

  useEffect(() => {
    setSketchplanState(true);
    setCameraHelperState(true);
  }, []);

  useEffect(() => {
    !cameras.length && setCameraHelperState(true);
  }, [cameras]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "34px",
        justifyContent: "flex-end",
        gap: "10px",
        "& svg": {
          width: "16px",
        },
      }}
    >
      <Button
        variant={sketchplanState ? "outline" : "primary"}
        color="blue"
        onClick={toggleSketchplanState}
        disabled={!sceneStarted || isLoading}
      >
        {sketchplanState ? <EyeOffIcon /> : <EyeOnIcon />}
        Sketchplan
      </Button>
      <Button
        variant={cameraHelperState ? "outline" : "primary"}
        color="blue"
        disabled={!cameras.length || !sceneStarted || isLoading}
        onClick={toggleCameraHelper}
      >
        {cameraHelperState ? <EyeOffIcon /> : <EyeOnIcon />} Field of cameras
        view
      </Button>
    </Box>
  );
};

export default SceneObjectVisibilityButtons;
