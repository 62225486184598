import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type SyntheticEvent,
} from "react";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

import client from "../../../../configs/apolloClient";
import { selectedLocationDevicesVariable } from "../../../../pages/devices/variables/devices";
import { selectedLocationNodesVariable } from "../../../../pages/nodes/variables/nodes";
import { useCustomerIdGuard } from "../../../hooks/useCustomerIdGuard";
import { useGetCustomers } from "../../../hooks/useGetCustomers";
import { useToggle } from "../../../hooks/useToggle";
import type {
  AutocompleteOptionType,
  DisableClearableOptionsType,
  IDisableClearableOption,
} from "../../../models/autocomplete";
import { selectedCustomerVariable } from "../../../variables/selectedCustomer";
import AddCustomerDialog from "./AddCustomerDialog";

const defaultOption: IDisableClearableOption = {
  title: "Create new customer",
  value: "",
};

const getSelectedCustomerFromStorage = (): IDisableClearableOption => {
  try {
    const selectedCustomer = localStorage.getItem("customer");

    if (selectedCustomer !== "undefined" && selectedCustomer) {
      return JSON.parse(selectedCustomer);
    }

    const defaultOptionJson = JSON.stringify(defaultOption);

    return JSON.parse(defaultOptionJson);
  } catch (error) {
    console.error(error);
    return defaultOption;
  }
};

const selectedCustomerFromStorage = getSelectedCustomerFromStorage();

interface ICustomerSelectProps {
  size?: "small" | "medium";
}

const CustomerSelect = ({
  size = "medium",
}: ICustomerSelectProps): JSX.Element => {
  const customerId = useCustomerIdGuard();

  const [selectedCustomer, setSelectedCustomer] =
    useState<IDisableClearableOption>(selectedCustomerFromStorage);
  const { state: isDialogOpened, setToggleState } = useToggle({
    initialState: false,
  });

  const { customers } = useGetCustomers();

  useEffect((): void => {
    const selectedCustomerFromStorage = getSelectedCustomerFromStorage();

    if (selectedCustomerFromStorage?.value !== selectedCustomer?.value) {
      setSelectedCustomer(selectedCustomerFromStorage);
    }
  }, [customerId]);

  const memoizedOpenDialogCallback = useCallback((): void => {
    setToggleState(true);
  }, []);

  const memoizedCloseDialogCallback = useCallback((): void => {
    setToggleState(false);
  }, []);

  const handleOnChange = (
    _event: SyntheticEvent<Element, Event>,
    optionValue: IDisableClearableOption
  ): void => {
    if (!optionValue) {
      setSelectedCustomer(defaultOption);
      selectedCustomerVariable("");
      localStorage.setItem("customer", JSON.stringify(defaultOption));

      // clear previous customer data from cache
      client.clearStore();

      return;
    }

    const { value } = optionValue;

    setSelectedCustomer(optionValue);
    localStorage.setItem("customer", JSON.stringify(optionValue));
    selectedCustomerVariable(value);

    selectedLocationNodesVariable(null);
    selectedLocationDevicesVariable(null);
  };

  const options =
    useMemo<DisableClearableOptionsType>((): IDisableClearableOption[] => {
      const defaultOption = [
        {
          title: "Create new customer",
          value: "",
        },
      ];

      if (!Array.isArray(customers?.items)) {
        return defaultOption;
      }

      const customersOptions = customers?.items?.map(
        (customer): IDisableClearableOption => {
          return {
            title: customer?.name ?? "",
            value: customer?.id ?? "",
          };
        }
      );

      const defaultCustomersArray = [...defaultOption, ...customersOptions];

      return defaultCustomersArray;
    }, [customers]);

  useEffect((): void => {
    if (!selectedCustomerFromStorage?.value) {
      setSelectedCustomer(options[1]);
      selectedCustomerVariable(options[1]?.value);
      localStorage.setItem("customer", JSON.stringify(options[1]));
    } else {
      selectedCustomerVariable(selectedCustomerFromStorage?.value);
    }
  }, [options, selectedCustomerFromStorage]);

  // TODO: if no reactive variable customer, return null
  // call modal component to prompt user to select customer

  return (
    <>
      <Autocomplete
        sx={{ minWidth: 200, maxWidth: 240, width: "100%" }}
        options={options}
        disableClearable
        size={size}
        getOptionLabel={(option: AutocompleteOptionType): string =>
          option?.title ?? defaultOption.title
        }
        isOptionEqualToValue={(option, optionValue): boolean =>
          option?.value === "" ? true : option?.value === optionValue?.value
        }
        value={selectedCustomer}
        onChange={handleOnChange}
        popupIcon={<ExpandMoreOutlinedIcon />}
        renderOption={(props, option): JSX.Element => {
          // TODO: Look into redesigning getCustomers resolver since it is currently based on user_group
          return (
            <Fragment key={option?.value}>
              {option?.value === "" ? (
                <Divider>
                  <Button
                    sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    onClick={memoizedOpenDialogCallback}
                    variant="text"
                    color="primary"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                  >
                    Create
                  </Button>
                </Divider>
              ) : (
                <Box {...props} component="li">
                  {option?.title}
                </Box>
              )}
            </Fragment>
          );
        }}
        renderInput={(params): JSX.Element => (
          <TextField
            {...params}
            variant="standard"
            label="Select Customer"
            size="small"
            fullWidth
          />
        )}
      />
      {isDialogOpened && (
        <AddCustomerDialog
          isOpened={isDialogOpened}
          closeDialog={memoizedCloseDialogCallback}
        />
      )}
    </>
  );
};

export default CustomerSelect;
