import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Stack, Typography } from "@mui/material";

import StyledDialog from "../../../common/components/dialog/StyledDialog";
import { useDialog } from "../../../common/components/dialog/useDialog";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import { useSaveLocation } from "../hooks/useSaveLocation";
import {
  defaultLocationFormValidationState,
  defaultLocationFormVariables,
  locationFormRules,
  locationFormValidationStateVariable,
  locationFormVariables,
  useLocationFormValidationState,
  useLocationFormVariables,
} from "../variables/locationData";
import LocationForm from "./LocationForm";

const LocationsPageHeader = (): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();

  const form = useLocationFormVariables();
  const validation = useLocationFormValidationState();

  const [saveLocationItem, { loading }] = useSaveLocation();
  const { open, handleDialogOpen, handleDialogClose } = useDialog();

  const handleSubmit = (): void => {
    let validationState = {
      ...(validation ?? defaultLocationFormValidationState),
    };

    const key = form.locationName.toLowerCase().replace(/\s+/g, "_");
    const locationId = `${selectedCustomerId}#L#${key}`;

    const normalizedId = client.cache.identify({
      id: locationId,
      __typename: "Location",
    });

    const extract = client.cache.extract();

    if (normalizedId && extract[normalizedId]?.name === form.locationName) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: "Location name already exists",
        },
      };
    }

    if (!form.locationName) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: "Please type location name",
        },
      };
    }

    if (form.locationName.length > locationFormRules.locationName.maxLength) {
      validationState = {
        ...validationState,
        locationName: {
          hasError: true,
          errorMessage: `Location name cannot be longer than ${locationFormRules.locationName.maxLength} characters`,
        },
      };
    }

    if (!form.latitude) {
      validationState = {
        ...validationState,
        latitude: {
          hasError: true,
          errorMessage: "Please type latitude",
        },
      };
    }

    if (!form.longitude) {
      validationState = {
        ...validationState,
        longitude: {
          hasError: true,
          errorMessage: "Please type longitude",
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      locationFormValidationStateVariable(validationState);
      return;
    }

    saveLocationItem({
      input: {
        customerId: selectedCustomerId,
        id: form.locationName,
        name: form.locationName,
        locationData: JSON.stringify({
          lat: form.latitude,
          lon: form.longitude,
        }),
        tags: form.tags,
      },
    })
      .then((): void => {
        handleDialogClose();
        locationFormVariables(defaultLocationFormVariables);
        locationFormValidationStateVariable(defaultLocationFormValidationState);
        successNotification();
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      });
  };

  const openDialog = (): void => {
    handleDialogOpen();
    locationFormVariables(defaultLocationFormVariables);
    locationFormValidationStateVariable(defaultLocationFormValidationState);
  };

  const closeDialog = (): void => {
    handleDialogClose();
    locationFormVariables(defaultLocationFormVariables);
    locationFormValidationStateVariable(defaultLocationFormValidationState);
  };

  return (
    <Box padding="20px 24px 19px 24px">
      <Stack
        spacing={4}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h5" padding={0}>
          Locations
        </Typography>
        <Button
          variant="contained"
          color="primary"
          endIcon={<AddOutlinedIcon />}
          onClick={openDialog}
        >
          Create Location
        </Button>
        <StyledDialog
          open={open}
          title="Create a new location"
          onClose={closeDialog}
          SubmitButtonProps={{
            loading,
            onSubmit: handleSubmit,
          }}
        >
          <LocationForm />
        </StyledDialog>
      </Stack>
    </Box>
  );
};

export default LocationsPageHeader;
