import { Stack } from "@mui/material";

const StreamImage = ({ streamImage }: { streamImage: string }): JSX.Element => {
  // keep for now until we pass real image to UI
  return (
    <Stack spacing={4} direction="column">
      <img src={streamImage} alt="Stream Image" width={350} />
    </Stack>
  );
};

export default StreamImage;
