import { useContext } from "react";

import { Button } from "@mui/material";
import { RefreshIcon } from "../../assets/icons/svgAssets";

import ProjectContext from "../../context/ProjectContext/ProjectContext";

const ResetPositionButton = () => {
  const { sceneStarted, isLoading, secondaryCameraId, onResetCameraPosition } =
    useContext(ProjectContext);

  return (
    <Button
      variant="outline"
      color="blue"
      disabled={!!secondaryCameraId || !sceneStarted || isLoading}
      sx={{
        position: "absolute",
        top: "10px",
        right: "10px",
        "& svg": {
          width: "16px",
        },
      }}
      onClick={onResetCameraPosition}
    >
      <RefreshIcon />
      Reset my position
    </Button>
  );
};

export default ResetPositionButton;
