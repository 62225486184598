import { useEffect, useMemo } from "react";

import { useLazyQuery } from "@apollo/client";

import type {
  GetLocationsQuery,
  GetLocationsQueryVariables,
  Location,
} from "../../../API";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { GET_LOCATIONS } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";

export const LIMIT = 25;

interface LazyGetPaginatedLocationsInterface {
  locations: Location[];
  loading: boolean;
}

export const useLazyGetPaginatedLocations =
  (): LazyGetPaginatedLocationsInterface => {
    const selectedCustomerId = useCustomerIdGuard();

    const [getLocations, { data, loading }] = useLazyQuery<
      GetLocationsQuery,
      GetLocationsQueryVariables
    >(GET_LOCATIONS, {
      fetchPolicy: "network-only",
    });

    useEffect((): void => {
      if (selectedCustomerId) {
        fetchLocations(selectedCustomerId);
      }
    }, [selectedCustomerId]);

    const handleRequestError = async (): Promise<void> => {
      await client.cache.reset();
      errorNotification();
    };

    const fetchLocations = (customerId?: string, nextToken?: string): void => {
      getLocations({
        variables: {
          limit: LIMIT,
          customerId: customerId ?? "",
          nextToken,
        },
      })
        .then(async (response): Promise<void> => {
          if (response.data?.getLocations.nextToken) {
            fetchLocations(customerId, response.data?.getLocations.nextToken);
          }

          if (response.error) {
            await handleRequestError();
          }
        })
        .catch(async (): Promise<void> => {
          await handleRequestError();
        });
    };

    const locations: Location[] = useMemo(
      (): Location[] => (data?.getLocations.items as Location[]) || [],
      [data?.getLocations.items]
    );

    return { locations, loading };
  };
