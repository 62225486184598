import React from "react";

import { Box, SxProps, Theme } from "@mui/material";

interface TabPanelProps {
  index: number | string;
  value: number | string;
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, sx } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} sx={sx}>
      {value === index && children}
    </Box>
  );
};
export default TabPanel;
