import { useMemo, useState } from "react";

import { Autocomplete, CircularProgress, TextField } from "@mui/material";

import { useGetServicesByLocation } from "./useGetServicesByLocation";

interface IServiceOption {
  title: string | null | undefined;
  value: string | null | undefined;
  locationId: string | null | undefined;
}

interface IServiceSelectProps {
  serviceId?: string | null;
  locationId?: string | null;
  onServiceChange: (value: string) => void;
}

const AlertServiceTypeSelect = ({
  serviceId: serviceIdProp,
  locationId,
  onServiceChange,
}: IServiceSelectProps): JSX.Element => {
  const { data, loading } = useGetServicesByLocation(locationId);

  const [inputValue, setInputValue] = useState("");

  const memoizedOptions = useMemo((): Array<IServiceOption> => {
    let options =
      data?.getServices?.items.map((service): IServiceOption => {
        const value = service?.serviceType ?? "";
        return {
          title: service?.serviceType,
          value: value === "" ? value : value.toLowerCase().replace(" ", ""),
          locationId: service?.locationId ?? "",
        };
      }) ?? [];

    if (locationId) {
      options = options.filter((option): boolean => {
        return option.locationId === locationId;
      });
    }

    return options;
  }, [data?.getServices?.items]);

  const memoizedValue = useMemo((): IServiceOption | null => {
    if (!serviceIdProp) {
      return null;
    }

    return (
      memoizedOptions.find(
        (option): boolean => option?.value === serviceIdProp
      ) ?? null
    );
  }, [serviceIdProp, memoizedOptions]);

  return (
    <Autocomplete
      fullWidth
      loading={loading}
      value={memoizedValue}
      options={memoizedOptions}
      disabled={loading || !locationId}
      getOptionLabel={(option): string => option?.title ?? ""}
      onChange={(_event: any, newValue: IServiceOption | null): void => {
        if (newValue) {
          onServiceChange(newValue?.value ?? "");
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue): void => {
        setInputValue(newInputValue);
      }}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          label="Service"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AlertServiceTypeSelect;
