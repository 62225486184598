import { useState } from "react";
import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";

import HeaderTopBar from "./HeaderTopBar";
import NavigationSidebar from "./NavigationSidebar";
export interface LayoutProps {
  mobileOpen: boolean;
  toogleDrawer: () => void;
}

/**
 * `drawerWidth` is the width of the left sidebar
 */
export const drawerWidth = 318;

const GeneralLayout = (): JSX.Element => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (): void => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F7F8FA",
        height: "100%",
        minHeight: "100%",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <NavigationSidebar
          mobileOpen={mobileOpen}
          toogleDrawer={handleDrawerToggle}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            padding: "1.5em",
          }}
        >
          <HeaderTopBar
            mobileOpen={mobileOpen}
            toogleDrawer={handleDrawerToggle}
          />
          <Box
            component="main"
            sx={{
              paddingTop: "1.5em",
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralLayout;
