import { useContext } from "react";
import { Box, IconButton, ToggleButton, Typography } from "@mui/material";

import {
  CloseIcon,
  DistanceIcon,
  DistanceMeasurementAngleHelperIcon,
} from "../../../assets/icons/svgAssets";

import View360GridDivider from "../View360GridDivider";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";
import { SecondaryViewTypes } from "../../../data/SecondaryViewData";

const SecondaryViewport = ({ viewportRef, canvasRef, renderer2DRef }) => {
  const {
    isLoading,
    cameras,
    secondaryCameraId,
    setSecondaryCameraId,
    secondaryViewType,
    setSecondaryViewType,
    distanceMeasurementsMode,
    setDistanceMeasurementsMode,
  } = useContext(ProjectContext);

  const activeCamera = cameras.find(camera => camera.id === secondaryCameraId);

  const onCloseButton = () => {
    setSecondaryCameraId(null);
    setSecondaryViewType(null);
  };

  const onDistanceMeasurementButton = () => {
    setDistanceMeasurementsMode(!distanceMeasurementsMode);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(6, 24, 45, 0.3)",
        ...(!secondaryCameraId && { visibility: "hidden" }),
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "60px",
          left: "255px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "70%",
          width: "calc(100% - 265px - 265px)",
          minWidth: "50%",
          border: "1px solid rgba(6, 24, 45, 0.3)",
          borderRadius: "4px",
          overflow: "hidden",

          ["@media (max-width:1500px)"]: {
            top: "45px",
            width: "calc(100% - 265px - 100px)",
          },

          ...(secondaryViewType === SecondaryViewTypes.view360 && {
            width: "calc(100% - 255px - 10px)",
            height: "300px",

            ["@media (max-width:1500px)"]: {
              height: "210px",
            },
          }),
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "7px 0 7px 12px",
            alignItems: "center",
            width: "100%",
            height: "36px",
            backgroundColor: "#326CFB",
          }}
        >
          <Typography
            variant="body20Medium"
            sx={{
              color: "#ffffff",
            }}
          >
            {activeCamera && activeCamera.name}
          </Typography>
          <IconButton
            onClick={onCloseButton}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "4px",

              "& svg": {
                width: "24px",
                height: "24px",
              },
            }}
          >
            <CloseIcon color="#FFFFFF" />
          </IconButton>
        </Box>
        <Box
          ref={viewportRef}
          sx={{
            position: "relative",
            width: "100%",
            height: "calc(100% - 36px)",
            "& #three-canvas-1": {
              width: "100%",
              height: "100%",
            },
          }}
        >
          <canvas ref={canvasRef} id="three-canvas-2"></canvas>
          {secondaryViewType === SecondaryViewTypes.view360 && (
            <View360GridDivider />
          )}

          <Box
            ref={renderer2DRef}
            id="three-2d-renderer"
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></Box>
          {distanceMeasurementsMode && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <DistanceMeasurementAngleHelperIcon />
            </Box>
          )}

          {secondaryViewType === SecondaryViewTypes.viewRGB && (
            <ToggleButton
              variant="icon-text"
              sx={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                width: "42px",
                height: "42px",
              }}
              disabled={isLoading}
              value="distanceMeasurements"
              selected={distanceMeasurementsMode}
              onClick={onDistanceMeasurementButton}
            >
              <DistanceIcon />
              <Typography variant="body8Regular" component="p">
                Distance
              </Typography>
            </ToggleButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SecondaryViewport;
