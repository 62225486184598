import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import * as React from "react";
import { useState } from "react";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { usePublishNode } from "../hooks/usePublishNode";
import StreamImage from "./components/StreamImage";
import ZoneImage from "./components/ZoneImage";
import ZonesTableContainer from "./hooks/ZonesTableContainer";
import { useCreateZone } from "./hooks/useCreateZone";
import { useDeleteZone } from "./hooks/useDeleteZone";
import { useUpdateZone } from "./hooks/useUpdateZone";
import { useGetServices } from "../../model-manager/hooks/useGetServices";

interface PTConfigPageProps {
  handleDialogClose: () => void;
  streamImage: string;
  nodeId: string;
  deviceName: string;
  rtspSource: string;
  serviceId: string;
}
// TODO: will need to update skeleton for components
const PTConfigPage = ({
  handleDialogClose,
  streamImage,
  nodeId,
  deviceName,
  // rtspSource,
  serviceId,
}: PTConfigPageProps): JSX.Element => {
  const { createZone, loading } = useCreateZone();
  const [zoneName, setZoneName] = useState("");
  const [zoneOrder, setZoneOrder] = useState("");
  const [pan, setPan] = useState<number | string | Array<number | string>>(0);
  const [tilt, setTilt] = useState<number | string | Array<number | string>>(0);
  const [zoneStatus, setZoneStatus] = useState<boolean>(false);
  const [zoneKey, setZoneKey] = useState("");
  const [openNestedDialog, setOpenNestedDialog] = useState(false);
  const [selectedZoneName, setSelectedZoneName] = useState("");
  const [selectedZoneRow, setSelectedZoneRow] = useState<any>({});

  const { publishNode } = usePublishNode();
  const { deleteZone: deleteZoneHookFunction } = useDeleteZone();
  const { updateZone: updateZoneHookFunction } = useUpdateZone();
  const { data } = useGetServices();

  const servicesArray = data?.getServices.items;

  let savedServiceType: string | null = null;
  if (servicesArray) {
    for (const item of servicesArray) {
      if (item && item.id === serviceId && item.serviceType) {
        savedServiceType = item.serviceType;
        break;
      }
    }
  }

  const handlePanSliderChange = (
    event: Event,
    newValue: number | number[]
  ): void => {
    setPan(newValue);
  };

  const handleTiltSliderChange = (
    event: Event,
    newValue: number | number[]
  ): void => {
    setTilt(newValue);
  };

  const handlePanInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPan(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleTiltInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setTilt(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handlePanBlur = (): void => {
    if ((pan as number) < -180) {
      setPan(-180);
    } else if ((pan as number) > 180) {
      setPan(180);
    }
  };
  const handleTiltBlur = (): void => {
    if ((tilt as number) < -80) {
      setTilt(-80);
    } else if ((tilt as number) > 10) {
      setTilt(10);
    }
  };

  /**
   *  create a key for s3 to store/get a zone image
   *
   * @param nodeId
   * @param zoneOrder
   * @returns
   */
  const createS3Key = (nodeId: string, zoneOrder: string): string => {
    const pathComponents = nodeId.split("#").join("_").split("_");
    const path =
      pathComponents.slice(0, pathComponents.length - 1).join("_") +
      "_" +
      pathComponents[pathComponents.length - 1];
    return `${path}/pantilt-api/Zone${zoneOrder}` + ".jpg";
  };

  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    setZoneStatus(event.target.checked);
    await updateZoneHookFunction({
      deviceId: "DE#" + deviceName,
      name: selectedZoneName,
      pan: selectedZoneRow.pan,
      tilt: selectedZoneRow.tilt,
      status: event.target.checked,
      mediaUrl: selectedZoneRow.mediaUrl,
      orderNumber: selectedZoneRow.orderNumber,
    });
    // Need to update DDB with new status value
    // Need to send MQTT message to update status on the node agent
  };

  const saveZone = (): void => {
    createZone({
      deviceId: deviceName,
      mediaUrl: createS3Key(nodeId, zoneOrder),
      name: zoneName,
      orderNumber: zoneOrder,
      pan: String(pan),
      tilt: String(tilt),
      status: zoneStatus,
      serviceId: serviceId,
    })
      .then((response): void => {
        if (response?.data) {
          successNotification();
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      });
    // save image to s3
    saveImage();
  };

  // alert node agent to stop stream
  const endStream = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "CAMERA_MANAGER",
        ACTION: "STREAM_END",
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });
  };

  const startStream = (): void => {
    publishNode({
      message: JSON.stringify({
        TARGET: "CAMERA_MANAGER",
        ACTION: "STREAM_START",
        instance_name: serviceId,
        model_name: savedServiceType,
        device_name: deviceName,
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });
  };

  // matthewF: GOTO_ZONE
  const goToZone = (orderNumber: string): void => {
    publishNode({
      message: JSON.stringify({
        ACTION: "GOTO_ZONE",
        ZoneName: orderNumber,
        TARGET: "PANTILT",
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });
  };
  // matthewG: GOTO_PT
  const goToPT = (pan: any, tilt: any): void => {
    publishNode({
      message: JSON.stringify({
        ACTION: "GOTO_PT",
        pan,
        tilt,
        TARGET: "PANTILT",
        device_name: deviceName,
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((): void => {
        errorNotification("Something went wrong when publishing node");
      });
  };

  const saveImage = (): void => {
    publishNode({
      message: JSON.stringify({
        ACTION: "SAVE_IMAGE",
        ZoneName: zoneName,
        TARGET: "CAMERA_MANAGER",
      }),
      nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });
  };

  const handleRowClick = (e: any): void => {
    setZoneKey(e.row.mediaUrl);
    setOpenNestedDialog(true);
    goToZone(e.row.orderNumber);
    setZoneStatus(e.row.status === "Active" ? true : false);
    setSelectedZoneName(e.row.rowId);
    setSelectedZoneRow(e.row);
  };

  const handleDelete = (): void => {
    deleteZoneHookFunction("DE#" + deviceName, selectedZoneName);
    setOpenNestedDialog(false);
  };

  return (
    <>
      <Typography variant="h5"> PT Configuration </Typography>
      <br />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid item>
              <StreamImage streamImage={streamImage} />
            </Grid>
            <br />
            <Typography variant="body2">
              {" "}
              Please move the camera by any of the following methods: 1. typing
              in pan tilt values and clicking the move camera button 2. clicking
              on a row in the zone table.
            </Typography>
            <br />
            {/* TODO: Come back later maybe to implement arrow PT controls */}
            {/* <Grid item>
              <IconButton>
                <ArrowBackIcon fontSize="large" />
              </IconButton>
              <IconButton>
                <ArrowDownwardIcon fontSize="large" />
              </IconButton>
              <IconButton>
                <ArrowUpwardIcon fontSize="large" />
              </IconButton>
              <IconButton>
                <ArrowForwardIcon fontSize="large" />
              </IconButton>
            </Grid> */}

            <Grid
              container
              spacing={2}
              alignItems="center"
              marginLeft={0.5}
              paddingRight={4}
            >
              <Typography id="pan-input-slider" gutterBottom>
                Pan
              </Typography>
              <Grid item xs>
                <Slider
                  value={typeof pan === "number" ? pan : 0}
                  onChange={handlePanSliderChange}
                  min={-180}
                  max={180}
                  aria-labelledby="pan-input-slider"
                />
              </Grid>
              <Grid item>
                <Input
                  value={pan}
                  size="small"
                  onChange={handlePanInputChange}
                  onBlur={handlePanBlur}
                  inputProps={{
                    step: 1,
                    min: -180,
                    max: 180,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
              <Typography id="tilt-input-slider" gutterBottom>
                Tilt
              </Typography>
              <Grid item xs>
                <Slider
                  value={typeof tilt === "number" ? tilt : 0}
                  onChange={handleTiltSliderChange}
                  min={-80}
                  max={10}
                  aria-labelledby="tilt-input-slider"
                />
              </Grid>
              <Grid item>
                <Input
                  value={tilt}
                  size="small"
                  onChange={handleTiltInputChange}
                  onBlur={handleTiltBlur}
                  inputProps={{
                    step: 1,
                    min: -80,
                    max: 10,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
              <Grid container>
                <Button
                  variant="contained"
                  color="success"
                  onClick={(): void => goToPT(pan, tilt)}
                >
                  MOVE CAMERA
                </Button>
              </Grid>
            </Grid>
            <br />
            <Grid>
              <Typography>Create a new zone from the current image</Typography>
              <TextField
                margin="dense"
                required
                style={{ minWidth: 200 }}
                id="Zone Name"
                label="Zone Name"
                value={zoneName}
                onChange={(e): void => setZoneName(e.target.value)}
              />
              <TextField
                margin="dense"
                required
                style={{ minWidth: 200 }}
                id="Zone Order Number"
                label="Zone Order"
                value={zoneOrder}
                onChange={(e): void => setZoneOrder(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <LoadingButton
                loading={loading}
                loadingPosition="start"
                variant="contained"
                color="success"
                onClick={saveZone}
              >
                Save
              </LoadingButton>
            </Grid>
            <Grid
              item
              xs
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="contained"
                color="success"
                onClick={(): void => startStream()}
              >
                START
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={(): void => endStream()}
              >
                STOP
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid item>
              <ZonesTableContainer
                handleRowClick={handleRowClick}
                serviceId={serviceId}
                thingName={nodeId.replace(/#/g, "_")}
              />
            </Grid>
            <br />

            <Grid container justifyContent="flex-end">
              <Button
                color="success"
                variant="contained"
                onClick={handleDialogClose}
              >
                Done
              </Button>
            </Grid>
          </Grid>
          <Dialog
            open={openNestedDialog}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="lg"
            sx={{ boxShadow: 3 }}
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid container>
                  <ZoneImage zoneKey={zoneKey} />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={(): void => setOpenNestedDialog(false)}
                  >
                    CLOSE
                  </Button>
                  <FormControlLabel
                    control={
                      <Switch
                        value={zoneStatus}
                        checked={zoneStatus}
                        onChange={handleSwitchChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Activate Zone: "
                    labelPlacement="start"
                  />
                </Grid>
                <Grid
                  item
                  xs
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={(): void => handleDelete()}
                  >
                    DELETE ZONE
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </Box>
    </>
  );
};

export default PTConfigPage;
