import { Box, Typography } from "@mui/material";

import { useAuthenticatedUser } from "../../../common/hooks/useAuthenticatedUser";
import {
  AdminRoles,
  SystemSettingsTabsEnum,
  useSystemSettingsTabsVariable,
} from "../variables/systemSettings";
import CreateAdminAlertDialogContainer from "./alert-management/components/dialogs/CreateAdminAlertDialogContainer";
import CreateNonAdminAlertDialogContainer from "./alert-management/components/dialogs/user/CreateNonAdminAlertDialogContainer";

const SystemSettingsHeader = (): JSX.Element => {
  const { role } = useAuthenticatedUser();

  const { selectedTab } = useSystemSettingsTabsVariable();

  const isUserAllowedCreateAdminEmailAlert = AdminRoles.includes(role);

  const component = isUserAllowedCreateAdminEmailAlert ? (
    <CreateAdminAlertDialogContainer />
  ) : (
    <CreateNonAdminAlertDialogContainer />
  );

  return (
    <Box sx={{ padding: "20px 24px 19px 24px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5">System Settings</Typography>
        {selectedTab === SystemSettingsTabsEnum.ALERT_MANAGEMENT && component}
      </Box>
    </Box>
  );
};

export default SystemSettingsHeader;
