import { gql } from "@apollo/client";

import {
  getAllAlerts,
  getAllWildCardsForSubscriptionEntity,
  getAnnotations,
  getCustomers,
  getDTCustomerProjects,
  getDTProject,
  getDTProjects,
  getDevices,
  getDevicesMakes,
  getEventsPendingHumanValidation,
  getForm,
  getHumanValidatedEventsForAuditing,
  getLocations,
  getLocationsByTag,
  getNodes,
  getNodesByCustomer,
  getProfile,
  getRoles,
  getServices,
  getShadowNode,
  getTimestreamData,
  getUserAlerts,
  getUsersByCompany,
  getZones,
  getSendEmail,
} from "../../graphql/queries";

export const GET_CUSTOMERS = gql`
  ${getCustomers}
`;
export const GET_EVENTS_PENDING_HV = gql`
  ${getEventsPendingHumanValidation}
`;
export const GET_EVENTS_FOR_AUDITING_HV = gql`
  ${getHumanValidatedEventsForAuditing}
`;
export const GET_LOCATIONS = gql`
  ${getLocations}
`;
export const GET_LOCATIONS_BY_TAG = gql`
  ${getLocationsByTag}
`;
export const GET_PROFILE = gql`
  ${getProfile}
`;
export const GET_SERVICES = gql`
  ${getServices}
`;
export const GET_FORM = gql`
  ${getForm}
`;
export const GET_DEVICES = gql`
  ${getDevices}
`;
export const GET_DEVICES_MAKES = gql`
  ${getDevicesMakes}
`;
export const GET_NODES = gql`
  ${getNodes}
`;
export const GET_NODES_BY_CUSTOMER = gql`
  ${getNodesByCustomer}
`;

export const GET_TIMESTREAM_DATA = gql`
  ${getTimestreamData}
`;
export const GET_ZONES = gql`
  ${getZones}
`;
export const GET_ANNOTATIONS = gql`
  ${getAnnotations}
`;
export const GET_ALL_WILDCARD_ALERT_EMAIL_SUBSCRIPTIONS = gql`
  ${getAllWildCardsForSubscriptionEntity}
`;
export const GET_SHADOW = gql`
  ${getShadowNode}
`;

export const GET_DT_PROJECTS = gql`
  ${getDTProjects}
`;

export const GET_DT_CUSTOMER_PROJECTS = gql`
  ${getDTCustomerProjects}
`;

export const GET_DT_PROJECT = gql`
  ${getDTProject}
`;

export const GET_USERS_BY_COMPANY = gql`
  ${getUsersByCompany}
`;

export const GET_ROLES = gql`
  ${getRoles}
`;

export const GET_ALL_ALERTS = gql`
  ${getAllAlerts}
`;

export const GET_USER_ALERTS = gql`
  ${getUserAlerts}
`;

export const GET_SEND_EMAIL = gql`
  ${getSendEmail}
`;
