import { ApolloError, useMutation } from "@apollo/client";

import {
  GetZonesQuery,
  PaginatedZones,
  UpdateZoneInput,
  UpdateZoneMutation,
  UpdateZoneMutationVariables,
  Zone,
} from "../../../../API";
import { UPDATE_ZONE } from "../../../../common/operations/mutations";
import { GET_ZONES } from "../../../../common/operations/queries";
import { successNotification } from "../../../../common/variables/notification";

export const useUpdateZone = () => {
  const [updateZoneMutation, { data, loading }] = useMutation<
    UpdateZoneMutation,
    UpdateZoneMutationVariables
  >(UPDATE_ZONE);

  const updateZone = async (input: UpdateZoneInput) => {
    console.log("updateZoneHOOK");
    console.log(input);

    return await updateZoneMutation({
      variables: {
        input,
      },
      onCompleted: response => {
        if (response.updateZone) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        console.error(error);
      },
      update(cache, response) {
        const existingZones = cache.readQuery<GetZonesQuery>({
          query: GET_ZONES,
        });

        const newZones = existingZones?.getZones.items.map(
          (item: Zone | null) => {
            if (item?.id !== response.data?.updateZone.id) {
              return item;
            }

            return { ...item, ...response.data?.updateZone };
          }
        );

        cache.writeQuery<GetZonesQuery>({
          query: GET_ZONES,
          data: {
            getZones: {
              ...(existingZones?.getZones as PaginatedZones),
              items: [...(newZones as Zone[])],
            },
          },
        });
      },
    });
  };

  return { updateZone, data, loading };
};
