import { Grid, TextField, Typography } from "@mui/material";

import {
  ServiceTypeEnum,
  aiManagerVariable,
  useSelectedServiceModel,
} from "../../variables/modelManager";

const GasLeakForm = (): JSX.Element => {
  const values = useSelectedServiceModel();

  const handleFormChange = (key: string, value: string): void => {
    aiManagerVariable({
      ...values,
      [ServiceTypeEnum.GAS_LEAK]: {
        ...values[ServiceTypeEnum.GAS_LEAK],
        [key]: value,
      },
    });
  };

  return (
    <>
      <Typography variant="body1" paddingBottom="1em">
        Advanced Settings
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="docker-image"
            label="Docker Image"
            value={values[ServiceTypeEnum.GAS_LEAK].docker_image}
            onChange={(e): void =>
              handleFormChange("docker_image", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="minimum_leak_length"
            label="Min Leak Length"
            value={values[ServiceTypeEnum.GAS_LEAK].minimum_leak_length}
            onChange={(e): void =>
              handleFormChange("minimum_leak_length", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="gpu_id"
            label="GPU ID"
            value={values[ServiceTypeEnum.GAS_LEAK].gpu_id}
            onChange={(e): void => handleFormChange("gpu_id", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="video_duration"
            label="Video Duration"
            value={values[ServiceTypeEnum.GAS_LEAK].video_duration}
            onChange={(e): void =>
              handleFormChange("video_duration", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="display_rate"
            label="Display Rate"
            value={values[ServiceTypeEnum.GAS_LEAK].display_rate}
            onChange={(e): void =>
              handleFormChange("display_rate", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            margin="dense"
            required
            fullWidth
            disabled
            id="calc_flow_rate"
            label="Calc Flow Rate"
            value={values[ServiceTypeEnum.GAS_LEAK].calc_flow_rate}
            onChange={(e): void =>
              handleFormChange("calc_flow_rate", e.target.value)
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default GasLeakForm;
