import { makeVar, useReactiveVar } from "@apollo/client";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";

export enum ServiceTypeEnum {
  GAS_LEAK = "Gas Leak",
  LIQUID_LEAK = "Liquid Leak",
  HARD_HAT = "Hard Hat",
  FIRE_SMOKE = "Fire/Smoke",
  TANK_LEVEL = "Tank Level",
  GATE_GUARD = "Gate Guard",
}

interface IDefaultAIManagerModel {
  serviceType: string;
  locationId: AutocompleteOptionType;
  deviceId: string;
  nodeId: string;
}

export interface ILeakModel {
  docker_image?: string;
  minimum_leak_length?: string;
  video_duration?: string;
  display_rate?: string;
  calc_flow_rate?: string;
  gpu_id: number;
}

export interface IGasLeakModel extends ILeakModel, ILeakModel {}
export interface ILiquidLeakModel extends ILeakModel, ILeakModel {}

export interface IAIManagerFormState extends IDefaultAIManagerModel {
  [ServiceTypeEnum.GAS_LEAK]: IGasLeakModel;
  [ServiceTypeEnum.LIQUID_LEAK]: ILiquidLeakModel;
}

const defaultGasLeakModelValues: IGasLeakModel = {
  docker_image: "gcr.io/ccai-app/ccai-gasleak:latest",
  minimum_leak_length: "20",
  video_duration: "120",
  display_rate: "true",
  calc_flow_rate: "true",
  gpu_id: 0,
};

const defaultAIManagerValues: IDefaultAIManagerModel = {
  serviceType: ServiceTypeEnum.GAS_LEAK,
  locationId: null,
  deviceId: "",
  nodeId: "",
};

export const defualtAIManagerFormState: IAIManagerFormState = {
  ...defaultAIManagerValues,
  [ServiceTypeEnum.GAS_LEAK]: defaultGasLeakModelValues,
  [ServiceTypeEnum.LIQUID_LEAK]: defaultGasLeakModelValues,
};

export const aiManagerVariable = makeVar<IAIManagerFormState>(
  defualtAIManagerFormState
);

export const useSelectedServiceModel = () => useReactiveVar(aiManagerVariable);

export const selectedLocationAIManagerVariable =
  makeVar<AutocompleteOptionType>(null);

export const useSelectedLocationAIManager = () =>
  useReactiveVar(selectedLocationAIManagerVariable);
