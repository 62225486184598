import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import { useCreateDeviceMake } from "../hooks/useCreateDeviceMake";

interface IDeviceMakeState {
  name: string;
  model: string;
  type: string;
  rtspTemplate: string;
}

const defaultState: IDeviceMakeState = {
  name: "",
  model: "",
  type: "",
  rtspTemplate: "",
};

export const DEVICE_TYPES = [
  "License Plate Reader",
  "Optical",
  "Optical Multisensor",
  "Thermal",
  "CH4 OGI",
  "VOC OGI",
].sort();

const CreateDeviceMake = (): JSX.Element => {
  const { createDeviceMake, loading } = useCreateDeviceMake();

  const [newDeviceMake, setNewDeviceMake] =
    useState<IDeviceMakeState>(defaultState);

  const handleFormChange = (key: string, value: string): void => {
    setNewDeviceMake({
      ...newDeviceMake,
      [key]: value,
    });
  };

  const handleCreateButtonClick = (): void => {
    createDeviceMake(newDeviceMake);
    setNewDeviceMake(defaultState);
  };

  const isCreateButtonDisabled =
    loading ||
    Object.values(newDeviceMake).some((value: string): boolean => !value);

  return (
    <Stack marginBottom="1.5em" spacing={2} direction="row">
      <TextField
        required
        fullWidth
        label="Name"
        value={newDeviceMake.name}
        onChange={(e): void => handleFormChange("name", e.target.value)}
      />
      <TextField
        required
        fullWidth
        label="Model"
        value={newDeviceMake.model}
        onChange={(e): void => handleFormChange("model", e.target.value)}
      />
      <FormControl required fullWidth>
        <InputLabel id="device-make-select-label">Type</InputLabel>
        <Select
          labelId="device-make-select-label"
          value={newDeviceMake.type}
          label="Type"
          onChange={(e): void => handleFormChange("type", e.target.value)}
        >
          {DEVICE_TYPES.map((type: string): JSX.Element => {
            return (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        required
        fullWidth
        label="RTSP Template"
        value={newDeviceMake.rtspTemplate}
        onChange={(e): void => handleFormChange("rtspTemplate", e.target.value)}
      />
      <LoadingButton
        disabled={isCreateButtonDisabled}
        variant="contained"
        onClick={handleCreateButtonClick}
      >
        Create
      </LoadingButton>
    </Stack>
  );
};

export default CreateDeviceMake;
