import { Box, Typography } from "@mui/material";

const NoDataBanner = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      textAlign: "center",
      gap: "34px",
      width: "100%",
      flexGrow: 1,
      background: "#F0F4FD",
      borderRadius: "4px",
      marginTop: "20px",
      color: "#98A7BC",
    }}
  >
    <Typography variant="h1">
      You haven&apos;t created any project yet
    </Typography>
    <Typography
      variant="h3"
      sx={{
        maxWidth: "500px",
      }}
    >
      Please, use a button ‘’+ New project’’ to create one. Your future projects
      will appear here
    </Typography>
  </Box>
);

export default NoDataBanner;
