import { useState } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button } from "@mui/material";

import { addAlertEmailNotificationInput } from "../../../../../../../API";
import StyledDialog from "../../../../../../../common/components/dialog/StyledDialog";
import { useDialog } from "../../../../../../../common/components/dialog/useDialog";
import { useAuthenticatedUser } from "../../../../../../../common/hooks/useAuthenticatedUser";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";
import { useCreateAlertEmailNotification } from "../../../hooks/useCreateAlertEmailNotification";
import {
  defaultNonAdminAlertFormValues,
  nonAdminAlertFormVariables,
  useNonAdminAlertFormVariables,
} from "../../../variables/nonAdminAlerts";
import CreateNonAdminAlertForm from "./CreateNonAdminAlertForm";

const CreateNonAdminAlertDialog = (): JSX.Element => {
  const form = useNonAdminAlertFormVariables();
  const selectedCustomerId = useCustomerIdGuard();
  const { user } = useAuthenticatedUser();

  const { open, handleDialogOpen, handleDialogClose } = useDialog();

  const { createAlertEmailNotification, loading } =
    useCreateAlertEmailNotification();

  const [hasError, setHasError] = useState(false);
  const [alertNameError, setAlertNameError] = useState(false);

  const openDialog = (): void => {
    nonAdminAlertFormVariables(defaultNonAdminAlertFormValues);
    setHasError(false);
    setAlertNameError(false);
    handleDialogOpen();
  };

  const closeDialog = (): void => {
    nonAdminAlertFormVariables(defaultNonAdminAlertFormValues);
    setHasError(false);
    setAlertNameError(false);
    handleDialogClose();
  };

  const handleSubmit = (): void => {
    const input: addAlertEmailNotificationInput = {
      alertType: "EMAIL",
      customerId: selectedCustomerId,
      email: user?.attributes?.email,
      alertName: form.alertName,
      location: form.location?.value,
      model: form.model,
      status: true,
    };

    if (input.alertName === "") {
      setHasError(true);
      return;
    }

    createAlertEmailNotification(input)
      .then((response): void => {
        if (response?.data) {
          successNotification();
          handleDialogClose();
          console.log(response);
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      });
  };

  return (
    <>
      <Button
        sx={{
          marginLeft: "auto",
        }}
        variant="contained"
        color="primary"
        endIcon={<AddOutlinedIcon />}
        onClick={openDialog}
      >
        Create
      </Button>
      <StyledDialog
        open={open}
        title="Create Alert"
        maxWidth="md"
        onClose={closeDialog}
        SubmitButtonProps={{
          loading,
          onSubmit: handleSubmit,
        }}
      >
        <CreateNonAdminAlertForm
          hasError={hasError}
          alertNameError={alertNameError}
        />
      </StyledDialog>
    </>
  );
};

export default CreateNonAdminAlertDialog;
