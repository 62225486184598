import React, { useEffect } from "react";

import { Select, InputLabel, MenuItem } from "@mui/material";

interface DynamicDropdownInputProps {
  name: string;
  entries: Array<string | null> | null | undefined;
  serviceConfig: any;
  setServiceConfig: (serviceConfigValue: any) => void;
}

const DynamicDropdownInput = ({
  name,
  entries,
  serviceConfig,
  setServiceConfig,
}: DynamicDropdownInputProps): JSX.Element | null => {
  const handleEntryChange = (e: {
    target: { value: React.SetStateAction<string> };
  }): void => {
    setServiceConfig((prevServiceConfig: any): any => {
      return {
        ...prevServiceConfig,
        [name]: e.target.value,
      };
    });
  };

  useEffect((): void => {
    if (!serviceConfig[name] && entries) {
      setServiceConfig((prevServiceConfig: any): any => {
        return {
          ...prevServiceConfig,
          [name]: entries[0],
        };
      });
    }
  }, []);

  if (!serviceConfig[name]) return null;

  return (
    <>
      <InputLabel id={`label-${name}`}>{name}</InputLabel>
      <Select
        fullWidth
        labelId={`label-${name}`}
        id={name + "-id"}
        onChange={handleEntryChange}
        value={serviceConfig[name]}
      >
        {entries?.map(
          (entry): JSX.Element => (
            <MenuItem key={entry} value={entry ?? ""}>
              {entry}
            </MenuItem>
          )
        )}
      </Select>
    </>
  );
};

export default DynamicDropdownInput;
