import { type FetchResult, gql, useMutation } from "@apollo/client";

import { ADD_NODE } from "./../../../common/operations/mutations";
import type {
  AddNodeMutation,
  AddNodeMutationVariables,
  AddNodeInput,
} from "./../../../API";

type Data<T> = T | null | undefined;

interface CreateNodeInterface {
  createNode: (
    input: AddNodeInput
  ) => Promise<
    FetchResult<AddNodeMutation, Record<string, any>, Record<string, any>>
  >;
  data?: AddNodeMutation | null;
  loading: boolean;
}

export const useCreateNode = (): CreateNodeInterface => {
  const [saveNode, { data, loading }] = useMutation<
    AddNodeMutation,
    AddNodeMutationVariables
  >(ADD_NODE);

  const createNode = async (
    input: AddNodeInput
  ): Promise<
    FetchResult<AddNodeMutation, Record<string, any>, Record<string, any>>
  > => {
    return await saveNode({
      variables: {
        input,
      },
      update(cache, response): void {
        cache.modify({
          fields: {
            getNodes(existing): Data<AddNodeMutation> {
              const newNodeRef = cache.writeFragment({
                data: response.data?.addNode,
                fragment: gql`
                  fragment NewNode on Node {
                    customerId
                    deviceData
                    id
                    level
                    locationId
                    nodeName
                    onboardCommand
                    serviceData
                    tags
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newNodeRef],
              };
            },
          },
        });
      },
    });
  };

  return { createNode, data, loading };
};
