/**
 * Reusable colors
 */
export const light = {
  primaryLight02: "rgba(128, 226, 126, 0.2)",
};

export const dark = {
  primaryLight02: "rgba(227, 242, 253, 0.2)",
};
