import { useContext } from "react";
import { Button } from "@mui/material";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";

import TowersData from "../../../data/TowersData";

const AddTowerButton = () => {
  const { addTower, sceneStarted, isLoading } = useContext(ProjectContext);

  const handleAddTower = () => {
    addTower(TowersData[0]);
  };

  return (
    <Button
      variant="outline"
      color="blue"
      onClick={handleAddTower}
      disabled={!sceneStarted || isLoading}
    >
      + Add tower
    </Button>
  );
};

export default AddTowerButton;
