import { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";

import ModalWindow from "../../../common/components/ModalWindow";
import CameraParametersListItem from "../../../common/components/configurator/camerasPanel/CameraParametersListItem";

import {
  CameraParams,
  CameraTypeParam,
  CameraHeightParam,
  Camera360FielOfViewParam,
  getParamDataByInputType,
  getCameraModelDataByType,
} from "../../../common/components/configurator/camerasPanel/CameraDefaultParametrs";
import { CamerasType } from "../../../common/data/CamerasData";

const AddCameraModal = ({ isOpen, onClose, onAdd }) => {
  const [cameraTypeParam, setCameraTypeParam] = useState(
    JSON.parse(JSON.stringify(CameraTypeParam))
  );
  const [cameraHeightParam, setCameraHeightParam] = useState(
    JSON.parse(JSON.stringify(CameraHeightParam))
  );
  const [cameraParam, setCameraParam] = useState(
    JSON.parse(JSON.stringify(CameraParams))
  );

  const selectedCameraData = getParamDataByInputType(
    cameraTypeParam,
    "cameraType"
  )?.inputData;

  const selectedCameraModelData = getCameraModelDataByType(
    selectedCameraData.value
  );

  const handleCameraTypeOnChange = (type, value, isError) => {
    setCameraTypeParam(prev => {
      return prev.map(param => {
        if (param.type === type) {
          param.inputData.value = value;
          param.error = isError;
        }

        return param;
      });
    });

    setCameraHeightParam(JSON.parse(JSON.stringify(CameraHeightParam)));
    setCameraParam(JSON.parse(JSON.stringify(CameraParams)));
  };

  const handleOnChange = (type, value, isError) => {
    if (type === "height") {
      setCameraHeightParam(prev => {
        return prev.map(param => {
          if (param.type === type) {
            param.inputData.value = value;
            param.error = isError;
          }

          return param;
        });
      });
    } else {
      setCameraParam(prev => {
        return prev.map(param => {
          if (param.type === type) {
            param.inputData.value = value;
            param.error = isError;
          }

          return param;
        });
      });
    }
  };

  const checkIsError = () => {
    if (selectedCameraData.value === selectedCameraData.placeholder) {
      return true;
    }

    if (selectedCameraModelData?.cameraType === CamerasType.MinervaCamera) {
      return cameraHeightParam.some(p => p.error);
    }

    return [...cameraHeightParam, ...cameraParam].some(p => p.error);
  };

  const handleAddCamera = () => {
    const params = {
      height: getParamDataByInputType(cameraHeightParam, "height").inputData
        .value,
      fov: getParamDataByInputType(cameraParam, "fieldOfView").inputData.value,
      verticalAngle: getParamDataByInputType(cameraParam, "verticalAngle")
        .inputData.value,
      horizontalAngle: getParamDataByInputType(cameraParam, "horizontalAngle")
        .inputData.value,
      ...getCameraModelDataByType(
        getParamDataByInputType(cameraTypeParam, "cameraType").inputData.value
      ),
    };

    onAdd(params);
  };

  useEffect(() => {
    setCameraTypeParam(JSON.parse(JSON.stringify(CameraTypeParam)));
    setCameraHeightParam(JSON.parse(JSON.stringify(CameraHeightParam)));
    setCameraParam(JSON.parse(JSON.stringify(CameraParams)));
  }, [isOpen]);

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose} modalWidth="388px">
      <Typography id="modal-modal-title" variant="h3" component="h2">
        Add new Camera
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          margin: "26px 0 34px",
          width: "100%",
        }}
      >
        {selectedCameraData.value === selectedCameraData.placeholder && (
          <Typography
            sx={{ fontWeight: 400, textAlign: "center", padding: "10px 0px" }}
            variant="body12Medium"
            color="#202832"
          >
            First select the camera type and then configure it
          </Typography>
        )}

        {cameraTypeParam.map(param => {
          return (
            <CameraParametersListItem
              key={`${param.title}-${param.type}`}
              title={param.title}
              inputData={param.inputData}
              inputWidth="100%"
              handleOnChange={handleCameraTypeOnChange}
            />
          );
        })}

        {selectedCameraModelData?.cameraType !== CamerasType.RGBCamera &&
          selectedCameraData.value !== selectedCameraData.placeholder &&
          Camera360FielOfViewParam.map(param => {
            return (
              <CameraParametersListItem
                key={`${param.title}-${param.type}`}
                title={param.title}
                inputData={param.inputData}
              />
            );
          })}

        {selectedCameraData.value !== selectedCameraData.placeholder &&
          cameraHeightParam.map(param => {
            return (
              <CameraParametersListItem
                key={`${param.title}-${param.type}`}
                title={param.title}
                inputData={param.inputData}
                inputWidth="35%"
                handleOnChange={handleOnChange}
              />
            );
          })}

        {selectedCameraModelData?.cameraType ===
          CamerasType.MinervaGasCamera && (
          <Typography
            sx={{ paddingTop: "6px" }}
            variant="body14Medium"
            color="#202832"
          >
            Gas camera settings
          </Typography>
        )}

        {selectedCameraModelData?.cameraType === CamerasType.RGBCamera && (
          <Typography
            sx={{ paddingTop: "6px" }}
            variant="body14Medium"
            color="#202832"
          >
            RGB camera settings
          </Typography>
        )}

        {selectedCameraModelData?.cameraType !== CamerasType.MinervaCamera &&
          selectedCameraData.value !== selectedCameraData.placeholder &&
          cameraParam.map(param => {
            return (
              <CameraParametersListItem
                key={`${param.title}-${param.type}`}
                title={param.title}
                inputData={param.inputData}
                inputWidth="35%"
                handleOnChange={handleOnChange}
              />
            );
          })}
      </Box>

      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <Button
          onClick={onClose}
          borders="rectangle"
          variant="secondary"
          color="blue"
        >
          Cancel
        </Button>
        <Button
          disabled={checkIsError()}
          onClick={handleAddCamera}
          borders="rectangle"
          variant="primary"
          color="blue"
        >
          Add
        </Button>
      </Stack>
    </ModalWindow>
  );
};
export default AddCameraModal;
