import * as THREE from "three";
import SceneObjectBuilder from "./SceneObjectBuilder";

import { setMaterial } from "../MaterilsUtils";
import { getObjectBoundingBox } from "../GeometryUtils";
import { createGroup } from "../CreateMesh";

import TowerSideData from "../../data/TowerSideData";

class TowerBuilder extends SceneObjectBuilder {
  createObjectInstance(
    objectClone,
    materialClone,
    objectData,
    faceNormal,
    defaultObjectPosition
  ) {
    const object = objectClone.clone(true);
    setMaterial(object, materialClone.clone());

    object.ID = objectData.id;
    object.EntityType = objectData.EntityType;

    this.setAxisDependOnGround(object, faceNormal);

    this.setRotation(object, objectData.rotation);
    this.setPosition(object, objectData.position, defaultObjectPosition);

    object.initialQuaternion = objectData.initialQuaternion
      ? new THREE.Quaternion().fromArray(objectData.initialQuaternion)
      : object.quaternion.clone();

    this.addTowerSideWrapper(object);

    return object;
  }

  addTowerSideWrapper(object) {
    const towerWrapper = createGroup("TowerWrapper", object);

    const size = new THREE.Vector3();
    const boundingBox = getObjectBoundingBox(object.children[0]);
    boundingBox.getSize(size);

    TowerSideData.forEach(data => {
      const geometry = new THREE.PlaneGeometry(size.x / 3, size.z);
      const material = new THREE.MeshBasicMaterial({
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0,
      });

      geometry.applyMatrix4(
        new THREE.Matrix4().makeTranslation(0, size.z / 2, 0)
      );

      const planeMesh = new THREE.Mesh(geometry, material);

      planeMesh.name = data.name;
      planeMesh.EntityType = data.entityType;
      planeMesh.SideIndex = data.index;

      planeMesh.rotation.y = data.angleInRad;

      planeMesh.translateZ(size.x / 5);

      towerWrapper.add(planeMesh);
    });
  }
}

export default TowerBuilder;
