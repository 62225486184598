import * as THREE from "three";
import { TransformControls } from "three/addons/controls/TransformControls.js";

import { getRaycastIntersectsPointByFromToVectors } from "../RaycastUtils";

import { ObjectMode } from "../../enums";

class TransformObjectControl {
  constructor(camera, domElement, terrain, onChangeCallback) {
    this.camera = camera;
    this.terrain = terrain;
    this.onChangeCallback = onChangeCallback;
    this.prevObjectPosition = new THREE.Vector3();

    this.towerRotationStep = THREE.MathUtils.degToRad(10);
    this.objectRotationStep = THREE.MathUtils.degToRad(5);

    this.controls = new TransformControls(camera, domElement);
    this.controls.setSpace("local");
    this.setMode(ObjectMode.translateObject);

    this.controls.addEventListener("objectChange", () => this.onObjectChange());
  }

  toggleVisibility(isVisible) {
    this.controls.visible = isVisible && !!this.controls.object;
  }

  toggleControlledObject(object) {
    if (object) {
      this.controls.attach(object);

      this.setControlledObjectRotationStep(object);

      this.prevObjectPosition.copy(object.position);
    } else {
      this.controls.detach();
    }
  }

  setControlledObjectRotationStep(object) {
    if (object.EntityType === "tower") {
      this.controls.setRotationSnap(this.towerRotationStep);
    } else if (object.EntityType === "object") {
      this.controls.setRotationSnap(this.objectRotationStep);
    }
  }

  setMode(mode) {
    if (mode === ObjectMode.rotateObject) {
      this.controls.showX = false;
      this.controls.showY = true;
      this.controls.showZ = false;
    } else if (mode === ObjectMode.translateObject) {
      this.controls.showX = true;
      this.controls.showY = false;
      this.controls.showZ = true;
    }

    this.controls.setMode(mode);
  }

  onObjectChange() {
    this.limitObjectPosition();

    if (
      this.controls.object &&
      this.onChangeCallback &&
      typeof this.onChangeCallback === "function"
    ) {
      this.onChangeCallback(this.controls.object.ID);
    }
  }

  limitObjectPosition() {
    if (this.controls.mode !== ObjectMode.translateObject) {
      return;
    }

    if (this.prevObjectPosition.equals(this.controls.object.position)) {
      return;
    }

    const intersects = getRaycastIntersectsPointByFromToVectors(
      this.terrain,
      this.camera.position,
      this.controls.object.position
    );

    if (intersects.length > 0) {
      this.prevObjectPosition.copy(intersects[0].point);
    } else {
      this.controls.object.position.copy(this.prevObjectPosition);
    }
  }
}

export default TransformObjectControl;
