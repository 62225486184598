import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import uuid from "react-uuid";

import ProjectContext from "./ProjectContext";

import { ObjectMode } from "../../enums";
import TowerSideData from "../../data/TowerSideData";

const ProjectContextProvider = ({ children }) => {
  const [threeScene, setThreeScene] = useState(null);
  const [sceneStarted, setSceneStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [projectData, setProjectData] = useState();

  const [objects, setObjects] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [towers, setTowers] = useState([]);

  const [transformControlMode, setTransformControlMode] = useState(
    ObjectMode.translateObject
  );
  const [distancesData, setDistancesData] = useState({});
  const [distanceMeasurementsMode, setDistanceMeasurementsMode] =
    useState(false);

  const [activeObjectId, setActiveObjectId] = useState(null);
  const [activeCameraId, setActiveCameraId] = useState(null);
  const [activeTowerSideIndex, setActiveTowerSideIndex] = useState(null);
  const [hoveredObjectId, setHoveredObjectId] = useState(null);
  const [hoveredCameraId, setHoveredCameraId] = useState(null);
  const [hoveredTowerSideIndex, setHoveredTowerSideIndex] = useState(null);

  const [secondaryCameraId, setSecondaryCameraId] = useState(null);
  const [secondaryViewType, setSecondaryViewType] = useState(null);

  const [sketchplanState, setSketchplanState] = useState(true);
  const [cameraHelperState, setCameraHelperState] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (projectData) {
      setObjects(projectData.obj);
      setTowers(projectData.towers);
      setCameras(projectData.cameras);
    }
  }, [projectData]);

  const addObject = obj => {
    const newObjData = {
      ...obj,
      EntityType: "object",
      id: uuid(),
    };
    setObjects(prev => [...prev, newObjData]);
  };

  const removeObject = id => {
    setObjects(prev => prev.filter(obj => obj.id !== id));
  };

  const updateObjectProperties = (id, state) => {
    setObjects(prev =>
      prev.map(obj => {
        if (obj.id === id) {
          return {
            ...obj,
            ...state,
          };
        }

        return obj;
      })
    );
  };

  const addTower = data => {
    const newTower = {
      ...data,
      EntityType: "tower",
      id: uuid(),
      sides: [...TowerSideData],
    };

    setTowers(prev => {
      const count = prev.length > 0 ? prev[prev.length - 1].count + 1 : 1;

      newTower.name = `Tower ${count.toString().padStart(3, "0")}`;
      newTower.count = count;

      return [...prev, newTower];
    });
  };

  const removeTower = id => {
    setCameras(prev => prev.filter(camera => camera.towerId !== id));
    setTowers(prev => prev.filter(obj => obj.id !== id));
  };

  const updateTowerProperties = (id, state) => {
    setTowers(prev =>
      prev.map(obj => {
        if (obj.id === id) {
          return {
            ...obj,
            ...state,
          };
        }

        return obj;
      })
    );
  };

  const addCamera = data => {
    const newCamera = {
      ...data,
      EntityType: "camera",
      id: uuid(),
    };

    setCameras(prev => [...prev, newCamera]);
  };

  const removeCamera = id => {
    setCameras(prev => prev.filter(camera => camera.id !== id));
  };

  const updateCameraProperties = (id = activeCameraId) => {
    if (!id || !threeScene) {
      return;
    }

    const camera = threeScene.getCameraById(id);

    if (camera) {
      const state = threeScene.getCameraState(camera);

      setCameras(prev =>
        prev.map(camera => {
          if (camera.id === id) {
            return {
              ...camera,
              ...state,
            };
          }

          return camera;
        })
      );
    }
  };

  const handleSetActiveObjectId = id => {
    if (id && threeScene.transformControl) {
      setTransformControlMode(ObjectMode.translateObject);
    }

    setActiveObjectId(id);
    setActiveTowerSideIndex(null);
    setActiveCameraId(null);
    setSecondaryCameraId(null);
    setSecondaryViewType(null);
  };

  const handleSetActiveTowerSideIndex = index => {
    setActiveTowerSideIndex(index);
    setActiveCameraId(null);
    setSecondaryCameraId(null);
    setSecondaryViewType(null);
  };

  const handleSetActiveCameraId = id => {
    setActiveCameraId(id);
  };

  const handleSetHoveredObjectId = id => {
    setHoveredObjectId(id);
  };

  const handleSetHoveredTowerSideIndex = index => {
    setHoveredTowerSideIndex(index);
  };

  const handleSetHoveredCameraId = id => {
    setHoveredCameraId(id);
  };

  const updateObjectTowerState = id => {
    if (!id || !threeScene) {
      return;
    }

    const object = threeScene.getObjectById(id);
    const tower = threeScene.getTowerById(id);

    if (object) {
      const state = threeScene.getObjectState(object);
      updateObjectProperties(id, state);
    }

    if (tower) {
      const state = threeScene.getObjectState(tower);
      updateTowerProperties(id, state);
    }
  };

  const deleteObjectCameraData = id => {
    const isObject = threeScene.getObjectById(id);
    const isCamera = threeScene.getCameraById(id);
    const isTower = threeScene.getTowerById(id);

    isObject && removeObject(id);

    if (isTower) {
      removeTower(id);
    }

    if (isCamera) {
      setSecondaryCameraId(null);
      setSecondaryViewType(null);
      removeCamera(id);
    }
  };

  const handleSetDistancesData = item => {
    setDistancesData(prev => {
      return { ...prev, [item.id]: item };
    });
  };

  const handelSetInitialDistanceData = () => {
    setDistancesData({});
  };

  const getProjectConfigData = () => {
    const savedData = {
      ...projectData,
      obj: objects,
      cameras: cameras,
      towers: towers,
    };

    return savedData;
  };

  const exitFromProjectConfigurator = () => {
    handleSetActiveObjectId(null);
    handleSetActiveTowerSideIndex(null);
    handleSetActiveCameraId(null);
    setSecondaryCameraId(null);
    setSecondaryViewType(null);
    handleSetHoveredObjectId(null);
    handleSetHoveredTowerSideIndex(null);
    handleSetHoveredCameraId(null);
    handelSetInitialDistanceData();
    setCameras([]);
    setObjects([]);
    setTowers([]);
    setProjectData();
    navigate("/webgl-dashboard");
  };

  const toggleSketchplanState = () => {
    setSketchplanState(!sketchplanState);
    threeScene.toggleShape(!sketchplanState);
  };

  const toggleCameraHelper = () => {
    setCameraHelperState(!cameraHelperState);
    threeScene.toggleCameraHelper(!cameraHelperState);
  };

  const onResetCameraPosition = () => {
    if (threeScene && threeScene.orbitControl) {
      threeScene.orbitControl.reset();
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        projectData,
        setProjectData,
        threeScene,
        setThreeScene,
        isLoading,
        setIsLoading,
        sceneStarted,
        setSceneStarted,
        objects,
        activeObjectId,
        handleSetActiveObjectId,
        activeTowerSideIndex,
        handleSetActiveTowerSideIndex,
        activeCameraId,
        handleSetActiveCameraId,
        secondaryCameraId,
        setSecondaryCameraId,
        secondaryViewType,
        setSecondaryViewType,
        hoveredObjectId,
        handleSetHoveredObjectId,
        hoveredTowerSideIndex,
        handleSetHoveredTowerSideIndex,
        hoveredCameraId,
        handleSetHoveredCameraId,
        addObject,
        removeObject,
        cameras,
        addCamera,
        removeCamera,
        towers,
        addTower,
        updateCameraProperties,
        updateObjectTowerState,
        deleteObjectCameraData,
        exitFromProjectConfigurator,
        sketchplanState,
        setSketchplanState,
        toggleSketchplanState,
        cameraHelperState,
        setCameraHelperState,
        toggleCameraHelper,
        onResetCameraPosition,
        distancesData,
        handleSetDistancesData,
        handelSetInitialDistanceData,
        transformControlMode,
        setTransformControlMode,
        distanceMeasurementsMode,
        setDistanceMeasurementsMode,
        getProjectConfigData,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectContextProvider;
