import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import { GET_DEVICES } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import type { GetDevicesQuery, GetDevicesQueryVariables } from "./../../../API";

export interface useGetDevicesInterface {
  locationId?: string;
  locationName?: string;
  nodeId?: string;
  nodeName?: string;
}

export const DEVICES_TABLE_LIMIT = 10;

export const useGetDevices = () => {
  let { locationId: locationIdSearchParameter, nodeId: nodeIdSearchParameter } =
    useParams();

  // NOTE: since id contains '#' sign, useParams returns only 'C' from the id, rest part is in the hash
  const windowPath = window.location.hash && `C${window.location.hash}`;
  nodeIdSearchParameter = windowPath.includes("#N#") ? windowPath : undefined;
  locationIdSearchParameter =
    !nodeIdSearchParameter && locationIdSearchParameter
      ? windowPath
      : undefined;

  const selectedCustomerId = useCustomerIdGuard();

  useEffect(() => {
    if (
      selectedCustomerId &&
      (!!locationIdSearchParameter || !!nodeIdSearchParameter)
    ) {
      fetchDevices(
        selectedCustomerId,
        undefined,
        locationIdSearchParameter,
        nodeIdSearchParameter
      );
    }
  }, [selectedCustomerId, locationIdSearchParameter, nodeIdSearchParameter]);

  const handleRequestError = () => {
    client.cache
      .reset()
      .then(() => {
        errorNotification();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const [getDevices, { data, loading }] = useLazyQuery<
    GetDevicesQuery,
    GetDevicesQueryVariables
  >(GET_DEVICES, {
    fetchPolicy: "network-only",
  });

  const fetchDevices = (
    customerId: string,
    nextToken?: string,
    locationId?: string | null,
    nodeId?: string | null
  ) => {
    getDevices({
      variables: {
        limit: DEVICES_TABLE_LIMIT,
        customerId,
        locationId,
        nodeId,
        nextToken,
      },
    })
      .then(response => {
        if (response.data?.getDevices.nextToken) {
          fetchDevices(
            customerId,
            response.data?.getDevices.nextToken,
            locationId,
            nodeId
          );
        }

        if (response.error) {
          handleRequestError();
        }
      })
      .catch(e => {
        console.error(e);
        handleRequestError();
      });
  };

  return { data, loading };
};
