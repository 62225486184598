import { CC_ADMIN_ROLES } from "../models/enums";
import { useSelectedCustomerId } from "../variables/selectedCustomer";
import { useAuthenticatedUser } from "./useAuthenticatedUser";

export const useCustomerIdGuard = () => {
  const selectedCustomerId = useSelectedCustomerId();
  const { customerId, role } = useAuthenticatedUser();

  const canRoleAccessMultipleCustomers = CC_ADMIN_ROLES.includes(role);

  if (canRoleAccessMultipleCustomers) {
    return selectedCustomerId;
  }

  return customerId;
};
