import type { ThemeOptions } from "@mui/material";

import { MuiAppBarLightStyleOverrides } from "./mui/AppBar/light";
import { MuiListItemButtonLightStyleOverrides } from "./mui/ListItemButton/light";
import { MuiListItemIconLightStyleOverrides } from "./mui/ListItemIcon/light";
import { MuiInputLabelLightStyleOverrides } from "./mui/MuiInputLabel/light";
import { MuiPaperLightStyleOverrides } from "./mui/Paper/light";
import { MuiToolBarLightStyleOverrides } from "./mui/Toolbar/light";

/**
 * NOTE: the list of links which can help with customization:
 * 1. https://mui.com/material-ui/customization/how-to-customize/
 * 2. https://mui.com/material-ui/customization/theme-components/
 */
export const lightThemeToken: ThemeOptions = {
  typography: {
    fontFamily: "Roboto, sans-serif",
    th: {
      fontSize: "0.875rem",
      fontWeight: 600,
    },
  },
  palette: {
    mode: "light",
    text: {
      primary: "rgba(19, 35, 60, 0.89)",
      secondary: "rgba(0, 0, 0, 0.6)",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    primary: {
      main: "#4CAF50",
      dark: "#087F23",
      light: "#80E27E",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#9C27B0",
      dark: "#7B1FA2",
      light: "#BA68C8",
      contrastText: "#FFFFFF",
    },
    info: {
      main: "#0288D1",
      dark: "#01579B",
      light: "#03A9F4",
      contrastText: "#FFFFFF",
      shades: {
        "4p": "rgba(2, 136, 209, 0.04)",
        "8p": "rgba(2, 136, 209, 0.08)",
        "12p": "rgba(2, 136, 209, 0.12)",
        "30p": "rgba(2, 136, 209, 0.3)",
        "50p": "rgba(2, 136, 209, 0.5)",
        "160p": "rgba(2, 136, 209, 0.4)",
        "190p": "rgba(2, 136, 209, 0.1)",
      },
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: MuiAppBarLightStyleOverrides,
    },
    MuiToolbar: {
      styleOverrides: MuiToolBarLightStyleOverrides,
    },
    MuiPaper: {
      styleOverrides: MuiPaperLightStyleOverrides,
    },
    MuiListItemButton: {
      styleOverrides: MuiListItemButtonLightStyleOverrides,
    },
    MuiListItemIcon: {
      styleOverrides: MuiListItemIconLightStyleOverrides,
    },
    MuiInputLabel: {
      styleOverrides: MuiInputLabelLightStyleOverrides,
    },
  },
};
