import { List } from "@mui/material";

import TowerCameraListItem from "./TowerCameraListItem";

const TowerCameraList = ({ camerasData }) => {
  return (
    <List
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        gap: "15px",
      }}
    >
      {camerasData.map(data => (
        <TowerCameraListItem key={data.id} data={data} />
      ))}
    </List>
  );
};

export default TowerCameraList;
