import { useState, useRef, useMemo } from "react";
import {
  Box,
  Button,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";

import DragAndDropForm from "../DragAndDropForm";
import CustomInputLabel from "./CustomInputLabel";
import ClearInputButton from "./ClearInputButton";
import LoadFileIcon from "./LoadFileIcon";

const FileInput = ({
  fileState,
  onClearInput,
  onFileUpload,
  fileTypeName,
  supportedFormats,
  multiline,
  required,
  isLoading,
}) => {
  const [isDragActive, setIsDragActive] = useState(false);
  const inputRef = useRef(null);

  const textInputValue = useMemo(() => {
    if (isLoading) {
      return "Loading ...";
    }

    if (fileState.file) {
      return fileState.file.name;
    }

    return "Select file";
  }, [fileState.file, isLoading]);

  const [hintMessageText, hintMessageColor] = useMemo(() => {
    if ((!fileState.file && !fileState.error) || isLoading) {
      return [`Supported files formats: ${supportedFormats}`, "#787878"];
    }

    if (fileState.file && !fileState.error) {
      return ["File uploaded successfully", "#58BF62"];
    }

    if (fileState.error) {
      return [fileState.errorMessage, "#E17575"];
    }

    return [`Supported files formats: ${supportedFormats}`, "#787878"];
  }, [fileState.file, fileState.error, isLoading]);

  const handleChange = e => {
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      onFileUpload(e.target.files);
    }
  };

  const onBrowseFile = () => {
    inputRef.current.click();
  };

  const onClearButton = e => {
    e.preventDefault();
    e.stopPropagation();

    inputRef.current.value = "";

    onClearInput();
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "110px",
        padding: "18px 34px",

        ...(isDragActive && {
          backgroundColor: "#F5F5F5",
          border: "1px solid #0C64D9",
          borderRadius: "4px",
        }),
      }}
    >
      <DragAndDropForm
        onFileUpload={onFileUpload}
        handleIsDragActive={setIsDragActive}
        allowDrag={!isLoading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: "4px",
          }}
        >
          <CustomInputLabel
            title={fileTypeName}
            required={required}
            id={`${fileTypeName}-label`}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <OutlinedInput
              sx={{
                flexGrow: 1,
                paddingRight: "0px",
              }}
              disabled={isLoading}
              onClick={onBrowseFile}
              readOnly={true}
              type="text"
              value={textInputValue}
              error={!!fileState.error}
              endAdornment={
                <InputAdornment position="end">
                  {isLoading && <LoadFileIcon />}
                  {fileState.file && (
                    <ClearInputButton onClick={onClearButton} />
                  )}
                </InputAdornment>
              }
            />
            <OutlinedInput
              sx={{
                display: "none",
              }}
              id={`${fileTypeName}-input`}
              inputRef={inputRef}
              onChange={handleChange}
              inputProps={{
                accept: supportedFormats,
              }}
              readOnly
              type="file"
              multiline={multiline}
            />
            <Button
              disabled={isLoading}
              onClick={onBrowseFile}
              borders="rectangle"
              variant="secondary"
              color="blue"
            >
              Browse...
            </Button>
          </Box>
          <Typography variant="body12Regular" color={hintMessageColor}>
            {hintMessageText}
          </Typography>
        </Box>
      </DragAndDropForm>
    </Box>
  );
};

export default FileInput;
