import { useContext } from "react";
import { Box } from "@mui/material";

import ThreeCanvas from "./threeCanvas/ThreeCanvas";
import ObjectsPanel from "./objectsPanel/ObjectsPanel";
import TowersPanel from "./towersPanel/TowersPanel";
import ObjectControlPanel from "./ObjectControlPanel";
import CameraControlPanel from "./camerasPanel/CameraControlPanel";
import SceneObjectVisibilityButtons from "./SceneObjectVisibilityButtons";
import ResetPositionButton from "./ResetPositionButton";
import Loader from "../loader/Loader";

import ProjectContext from "../../context/ProjectContext/ProjectContext";
import ConfiguratorPermissionContext from "../../context/ConfiguratorPermissionContext/ConfiguratorPermissionContext";

import { UIConstants } from "../../enums";

const ConfiguratorContainer = () => {
  const { isLoading } = useContext(ProjectContext);
  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "calc(100% - 70px)",
        gap: "20px",
      }}
    >
      <TowersPanel />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: `calc(100% - ${UIConstants.CAMERA_CONTAINER_WIDTH}px)`,
          height: "100%",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "calc(100% - 66px - 34px - 20px)",
            display: "flex",
            position: "relative",
            border: "1px solid #000000",
            borderRadius: "4px",
            overflow: "hidden",
          }}
        >
          {isLoading && <Loader background="transparent" />}
          <ThreeCanvas />
          <CameraControlPanel />
          <ObjectControlPanel />
          <ResetPositionButton />
        </Box>

        <SceneObjectVisibilityButtons />
        {isAllowEditing && <ObjectsPanel />}
      </Box>
    </Box>
  );
};

export default ConfiguratorContainer;
