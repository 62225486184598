import { IconButton } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const ClearInputButton = ({ onClick }) => (
  <IconButton onClick={onClick}>
    <HighlightOffIcon
      sx={{
        width: "14px",
        height: "14px",
      }}
    />
  </IconButton>
);

export default ClearInputButton;
