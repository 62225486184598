import { useContext, useEffect, useRef } from "react";
import { Box } from "@mui/material";

import MainViewport from "./MainViewport";
import SecondaryViewport from "./SecondaryViewport";

import ThreeScene from "../../../threeWebGl/ThreeScene";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";
import ConfiguratorPermissionContext from "../../../context/ConfiguratorPermissionContext/ConfiguratorPermissionContext";

import { findObjectAncestorsInditificator } from "../../../threeWebGl/FindObjectAncestors";

const ThreeCanvas = () => {
  const {
    threeScene,
    sceneStarted,
    setSceneStarted,
    setIsLoading,
    setThreeScene,
    activeObjectId,
    activeTowerSideIndex,
    activeCameraId,
    handleSetActiveObjectId,
    handleSetActiveTowerSideIndex,
    handleSetActiveCameraId,
    hoveredObjectId,
    hoveredTowerSideIndex,
    hoveredCameraId,
    handleSetHoveredObjectId,
    handleSetHoveredTowerSideIndex,
    handleSetHoveredCameraId,
    objects,
    cameras,
    towers,
    projectData,
    updateObjectTowerState,
    updateCameraProperties,
    secondaryCameraId,
    setSecondaryCameraId,
    secondaryViewType,
    setSecondaryViewType,
    cameraHelperState,
    distancesData,
    handleSetDistancesData,
    handelSetInitialDistanceData,
    transformControlMode,
    distanceMeasurementsMode,
    setDistanceMeasurementsMode,
  } = useContext(ProjectContext);

  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const canvasMainRef = useRef(null);
  const canvasSecondaryRef = useRef(null);
  const mainViewportRef = useRef(null);
  const secondaryViewportRef = useRef(null);
  const renderer2DRef = useRef(null);

  const resizeViewport = () => {
    const main = {
      width: mainViewportRef.current.clientWidth,
      height: mainViewportRef.current.clientHeight,
    };

    const secondary = {
      width: secondaryViewportRef.current.clientWidth,
      height: secondaryViewportRef.current.clientHeight,
    };

    threeScene.onWindowResize(main, secondary);
  };

  const handleObjectSelection = intersection => {
    if (!intersection) {
      handleSetActiveObjectId(null);
    } else {
      const { objectId, sideIndex, cameraId } =
        findObjectAncestorsInditificator(intersection.object);

      handleSetActiveObjectId(objectId);
      handleSetActiveTowerSideIndex(sideIndex);
      handleSetActiveCameraId(cameraId);
    }
  };

  const handleObjectHower = intersection => {
    if (!intersection) {
      handleSetHoveredObjectId(null);
      handleSetHoveredTowerSideIndex(null);
      handleSetHoveredCameraId(null);
    } else {
      const { objectId, sideIndex, cameraId } =
        findObjectAncestorsInditificator(intersection.object);

      handleSetHoveredObjectId(objectId);
      handleSetHoveredTowerSideIndex(sideIndex);
      handleSetHoveredCameraId(cameraId);
    }
  };

  // Setup scene.
  useEffect(() => {
    const threeScene = new ThreeScene(
      canvasMainRef.current,
      canvasSecondaryRef.current,
      mainViewportRef.current,
      secondaryViewportRef.current,
      renderer2DRef.current
    );

    setSceneStarted(false);
    setThreeScene(threeScene);
  }, []);

  // Start scene.
  useEffect(() => {
    if (!threeScene) {
      return;
    }

    const render = () => {
      requestAnimationFrame(render);
      threeScene.update();
    };

    const startScene = async () => {
      setIsLoading(true);

      const { terrainData, shapeData, groundTexturePath } =
        projectData.terrainData;

      terrainData && threeScene.addTerrainByJson(terrainData);
      shapeData && threeScene.addShapeByJson(shapeData);

      threeScene.setSceneSettingValues();

      await threeScene.addSkybox();
      await threeScene.addGround(groundTexturePath);

      threeScene.addOrbitControl();

      isAllowEditing && threeScene.addTransformControl(updateObjectTowerState);

      isAllowEditing && threeScene.addLookCameraControl(updateCameraProperties);

      threeScene.initObjectSelection(handleObjectSelection, handleObjectHower);
      threeScene.initDistanceMeasurement(handleSetDistancesData);

      for (const obj of objects) {
        await threeScene.addObject(obj);
      }

      for (const tower of towers) {
        await threeScene.addTower(tower);
      }

      for (const camera of cameras) {
        const tower = threeScene.getTowerById(camera.towerId);
        if (tower) {
          await threeScene.addCamera(camera, tower);
        }
      }

      resizeViewport();
      render();

      setIsLoading(false);
      setSceneStarted(true);
    };

    startScene();

    window.addEventListener("resize", resizeViewport);

    return () => {
      window.removeEventListener("resize", resizeViewport);
    };
  }, [threeScene]);

  // Add and remove object.
  useEffect(() => {
    if (!threeScene || !sceneStarted) {
      return;
    }

    const addObjectToScene = async () => {
      setIsLoading(true);

      for (const object of objects) {
        const isObjectExist = threeScene.getObjectById(object.id);

        if (!isObjectExist) {
          const sceneObject = await threeScene.addObject(object);

          updateObjectTowerState(sceneObject.ID);
          handleSetActiveObjectId(sceneObject.ID);
        }
      }

      setIsLoading(false);
    };

    const removeObjectFromScene = async () => {
      threeScene.objectsGroup.children.forEach(obj => {
        const objectData = objects.find(el => el.id === obj.ID);

        if (!objectData) {
          threeScene.deleteObject(obj);
          handleSetActiveObjectId(null);
        }
      });
    };

    addObjectToScene();
    removeObjectFromScene();
  }, [objects]);

  // Add and remove cameras.
  useEffect(() => {
    if (!threeScene || !sceneStarted) {
      return;
    }

    const addCameraToScene = async () => {
      setIsLoading(true);

      for (const camera of cameras) {
        const towerObject = threeScene.getTowerById(camera.towerId);

        const isCameraExist = threeScene.getCameraByIdInTower(
          camera.id,
          towerObject
        );

        if (!isCameraExist) {
          const cameraSceneObject = await threeScene.addCamera(
            camera,
            towerObject
          );

          threeScene.toggleCameraHelper(cameraHelperState);
          updateObjectTowerState(cameraSceneObject.ID);
          handleSetActiveTowerSideIndex(camera.sideIndex);
          handleSetActiveCameraId(cameraSceneObject.ID);

          setSecondaryCameraId(null);
          setSecondaryViewType(null);
        }
      }

      setIsLoading(false);
    };

    const removeCameraFromScene = async () => {
      const allCamerasSceneObjects =
        threeScene.towersGroup.getObjectsByProperty("EntityType", "camera");

      allCamerasSceneObjects.forEach(cameraSceneObject => {
        const cameraData = cameras.find(el => el.id === cameraSceneObject.ID);

        if (!cameraData) {
          threeScene.deleteCamera(cameraSceneObject);
          handleSetActiveCameraId(null);
          setSecondaryCameraId(null);
        }
      });
    };

    addCameraToScene();
    removeCameraFromScene();
  }, [cameras]);

  // Add remove towers.
  useEffect(() => {
    if (!threeScene || !sceneStarted) {
      return;
    }

    const addTowerToScene = async () => {
      setIsLoading(true);

      for (const tower of towers) {
        const existedTower = threeScene.getTowerById(tower.id);

        if (!existedTower) {
          const sceneTower = await threeScene.addTower(tower);

          handleSetActiveObjectId(sceneTower.ID);
          updateObjectTowerState(sceneTower.ID);
        }
      }

      setIsLoading(false);
    };

    const removeTowerFromScene = async () => {
      threeScene.towersGroup.children.forEach(towerSceneObject => {
        const towerData = towers.find(el => el.id === towerSceneObject.ID);

        if (!towerData) {
          threeScene.deleteTower(towerSceneObject);
          handleSetActiveObjectId(null);
          handleSetActiveTowerSideIndex(null);
        }
      });
    };

    addTowerToScene();
    removeTowerFromScene();
  }, [towers]);

  // Set transform control mode.
  useEffect(() => {
    if (threeScene && threeScene.transformControl) {
      threeScene.transformControl.setMode(transformControlMode);
    }
  }, [transformControlMode]);

  // Set scene secondary camera mode viewer.
  useEffect(() => {
    if (threeScene && sceneStarted) {
      if (secondaryCameraId && secondaryViewType) {
        const camera = threeScene.getCameraById(secondaryCameraId);
        threeScene.secondaryCamera = camera;
        threeScene.secondaryViewType = secondaryViewType;

        resizeViewport();
      } else {
        threeScene.secondaryCamera = null;
        threeScene.secondaryViewType = null;
      }

      handelSetInitialDistanceData();
      setDistanceMeasurementsMode(false);
      threeScene.changeCortrolMode();
    }
  }, [sceneStarted, threeScene, secondaryCameraId, secondaryViewType]);

  // Toggle transform control object.
  useEffect(() => {
    if (threeScene) {
      const object = threeScene.getObjectById(activeObjectId);
      const tower = threeScene.getTowerById(activeObjectId);

      const activeObject = object || tower;

      if (threeScene.transformControl) {
        threeScene.transformControl.toggleControlledObject(activeObject);
      }

      threeScene.changeCortrolMode();
    }
  }, [threeScene, activeObjectId]);

  // Toggle objects highlight.
  useEffect(() => {
    if (threeScene) {
      threeScene.toggleActiveObjectHighlight(
        activeObjectId,
        activeTowerSideIndex,
        activeCameraId,
        hoveredObjectId,
        hoveredTowerSideIndex,
        hoveredCameraId
      );
    }
  }, [
    activeObjectId,
    activeTowerSideIndex,
    activeCameraId,
    hoveredObjectId,
    hoveredTowerSideIndex,
    hoveredCameraId,
  ]);

  // Set distance mesurement mode.
  useEffect(() => {
    if (threeScene) {
      handelSetInitialDistanceData();
      threeScene.distanceMeasurementsMode = distanceMeasurementsMode;
      threeScene.changeCortrolMode();
    }
  }, [threeScene, distanceMeasurementsMode]);

  // Update distances value visualization.
  useEffect(() => {
    if (!threeScene) {
      return;
    }

    threeScene.removeDistanceMeasurementLable();

    if (distanceMeasurementsMode) {
      Object.values(distancesData).forEach(data => {
        threeScene.addDistanceMeasurementLable(
          data.lablePosition,
          data.distance
        );
      });
    }
  }, [threeScene, distancesData, distanceMeasurementsMode]);
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <MainViewport viewportRef={mainViewportRef} canvasRef={canvasMainRef} />
      <SecondaryViewport
        viewportRef={secondaryViewportRef}
        canvasRef={canvasSecondaryRef}
        renderer2DRef={renderer2DRef}
      />
    </Box>
  );
};

export default ThreeCanvas;
