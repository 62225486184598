import type { Location } from "../../../API";
import { useLazyGetPaginatedLocations } from "./useLazyGetPaginatedLocations";

interface LocationRowInterface {
  rowId: string;
  name: string;
  coordinates?: any;
  stopMerrickId?: any;
  tags?: Array<string | null> | null;
}

interface LocationsTableDataInterface {
  rows: LocationRowInterface[];
  loading: boolean;
}

export const useLocationsTableData = (): LocationsTableDataInterface => {
  const { locations, loading } = useLazyGetPaginatedLocations();

  const rows: LocationRowInterface[] =
    locations
      ?.map((item: Location): LocationRowInterface => {
        let locationData = null;

        if (item.locationData) {
          locationData = JSON.parse(item.locationData);
        }

        return {
          rowId: item.id,
          name: item.name,
          coordinates: {
            lat: locationData?.lat,
            lon: locationData?.lon,
          },
          stopMerrickId: locationData?.stopMerrickId,
          tags: item?.tags,
        };
      })
      .filter((item): LocationRowInterface => item) || [];

  return { rows, loading };
};
