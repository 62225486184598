import { useState } from "react";
import axios from "axios";

export const useReadDataFromS3 = () => {
  const [loading, setLoading] = useState(false);

  const getDataFromS3 = async url => {
    setLoading(true);

    // Make the GET request to the pre-signed URL.
    let response;

    try {
      response = await axios.get(url);
    } catch (error) {
      console.error("Error useReadDataFromS3", error);
    } finally {
      setLoading(false);
    }

    return response;
  };

  return { getDataFromS3, loading };
};
