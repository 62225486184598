import { createTheme } from "@mui/material";

import { darkThemeToken } from "./dark-theme-tokens";
import { lightThemeToken } from "./light-theme-tokens";

/**
 * NOTE: adding new variant for Typography
 * https://mui.com/material-ui/customization/typography/#adding-amp-disabling-variants
 */
declare module "@mui/material/styles" {
  interface TypographyVariants {
    th: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    th?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    th: true;
  }
}
declare module "@mui/material/styles" {
  interface SimplePaletteColorOptions {
    shades?: {
      "4p"?: string;
      "8p"?: string;
      "12p"?: string;
      "30p"?: string;
      "50p"?: string;
      "160p"?: string;
      "190p"?: string;
    };
  }

  interface PaletteColor {
    shades?: {
      "4p"?: string;
      "8p"?: string;
      "12p"?: string;
      "30p"?: string;
      "50p"?: string;
      "160p"?: string;
      "190p"?: string;
    };
  }
}

const lightTheme = createTheme(lightThemeToken);
const darkTheme = createTheme(darkThemeToken);

export const themes = {
  light: lightTheme,
  dark: darkTheme,
  default: lightTheme,
};
