import { gql, useMutation } from "@apollo/client";

import type {
  AddAnnotationInput,
  AddAnnotationMutation,
  AddAnnotationMutationVariables,
} from "../../../../API";
import { ADD_ANNOTATION } from "../../../../common/operations/mutations";

export const useCreateAnnotation = () => {
  const [saveAnnotation, { data, loading }] = useMutation<
    AddAnnotationMutation,
    AddAnnotationMutationVariables
  >(ADD_ANNOTATION);

  const createAnnotation = async (input: AddAnnotationInput) => {
    return await saveAnnotation({
      variables: {
        input,
      },
      update(cache, response) {
        cache.modify({
          fields: {
            getAnnotations(existing) {
              const newAnnotationRef = cache.writeFragment({
                data: response.data?.addAnnotation,
                fragment: gql`
                  fragment NewAnnotation on Annotation {
                    annotationType
                    id
                    name
                    polygon
                    serviceId
                    zoneId
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newAnnotationRef],
              };
            },
          },
        });
      },
    });
  };

  return { createAnnotation, data, loading };
};
