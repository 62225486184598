import { ApolloProvider } from "@apollo/client";
import client from "../../configs/apolloClient";

const withApolloProvider = (ComposedComponent) => {
  return (props) => {
    return (
      <ApolloProvider client={client}>
        <ComposedComponent {...props} />
      </ApolloProvider>
    );
  };
};

export default withApolloProvider;
