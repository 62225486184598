import { Typography, InputLabel } from "@mui/material";

const CustomInputLabel = ({ title, required, id }) => (
  <InputLabel id={id}>
    <Typography variant="body14Regular" color="#202832">
      {title}
    </Typography>
    {required && (
      <Typography sx={{ marginLeft: "4px" }} variant="body14Regular">
        *
      </Typography>
    )}
    {required && (
      <Typography
        sx={{ marginLeft: "10px" }}
        variant="body12Italic"
        color="#B3B3B3"
      >
        Required
      </Typography>
    )}
  </InputLabel>
);

export default CustomInputLabel;
