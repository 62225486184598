const ObjectsData = [
  {
    name: "Asset 01",
    path: require("../assets/3dmodels/SM_Asset_01/SM_Asset_01.fbx"),
    previewPath: require("../assets/3dmodels/SM_Asset_01/Asset_01.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_Asset_01/T_Asset_01_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_Asset_01/T_Asset_01_Metal.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_Asset_01/T_Asset_01_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_Asset_01/T_Asset_01_Rough.jpg"),
      },
    ],
  },
  {
    name: "Asset 02",
    path: require("../assets/3dmodels/SM_Asset_02/SM_Asset_02.fbx"),
    previewPath: require("../assets/3dmodels/SM_Asset_02/Asset_02.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_Asset_02/T_Asset_02_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_Asset_02/T_Asset_02_Metal.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_Asset_02/T_Asset_02_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_Asset_02/T_Asset_02_Rough.jpg"),
      },
    ],
  },
  {
    name: "HEATER TREATER DFTRT",
    path: require("../assets/3dmodels/SM_HEATER_TREATER_DFTRT/SM_HEATER_TREATER_DFTRT.fbx"),
    previewPath: require("../assets/3dmodels/SM_HEATER_TREATER_DFTRT/HEATER_TREATER_DFTRT.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_HEATER_TREATER_DFTRT/T_HEATER_TREATER_DFTRT_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_HEATER_TREATER_DFTRT/T_HEATER_TREATER_DFTRT_Metal.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_HEATER_TREATER_DFTRT/T_HEATER_TREATER_DFTRT_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_HEATER_TREATER_DFTRT/T_HEATER_TREATER_DFTRT_Rough.jpg"),
      },
    ],
  },
  {
    name: "BULK VESSEL",
    path: require("../assets/3dmodels/SM_BULK_VESSEL/SM_BULK_VESSEL.fbx"),
    previewPath: require("../assets/3dmodels/SM_BULK_VESSEL/BULK_VESSEL.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_BULK_VESSEL/T_BULK_VESSEL_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_BULK_VESSEL/T_BULK_VESSEL_Metal.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_BULK_VESSEL/T_BULK_VESSEL_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_BULK_VESSEL/T_BULK_VESSEL_Rough.jpg"),
      },
    ],
  },
  {
    name: "SURGE TANK VRU",
    path: require("../assets/3dmodels/SM_SURGE_TANK_VRU/SM_SURGE_TANK_VRU.fbx"),
    previewPath: require("../assets/3dmodels/SM_SURGE_TANK_VRU/SURGE_TANK_VRU.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_SURGE_TANK_VRU/SM_SURGE_TANK_VRU_M_SURGE_TANK_VRU_BaseColor.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_SURGE_TANK_VRU/SM_SURGE_TANK_VRU_M_SURGE_TANK_VRU_Metallic.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_SURGE_TANK_VRU/SM_SURGE_TANK_VRU_M_SURGE_TANK_VRU_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_SURGE_TANK_VRU/SM_SURGE_TANK_VRU_M_SURGE_TANK_VRU_Roughness.jpg"),
      },
    ],
  },
  {
    name: "200HP JGQ2 10",
    path: require("../assets/3dmodels/SM_200HP_JGQ2_10/SM_200HP_JGQ2_10.fbx"),
    previewPath: require("../assets/3dmodels/SM_200HP_JGQ2_10/200HP_JGQ2_10.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_200HP_JGQ2_10/T_200HP_JGQ2_10_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_200HP_JGQ2_10/T_200HP_JGQ2_10_Metalness.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_200HP_JGQ2_10/T_200HP_JGQ2_10_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_200HP_JGQ2_10/T_200HP_JGQ2_10_Rough.jpg"),
      },
    ],
  },
  {
    name: "LP VRU",
    path: require("../assets/3dmodels/SM_LP_VRU/SM_LP_VRU.fbx"),
    previewPath: require("../assets/3dmodels/SM_LP_VRU/LP_VRU.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_LP_VRU/SM_LP_VRU_M_LP_VRU_BaseColor.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_LP_VRU/SM_LP_VRU_M_LP_VRU_Metallic.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_LP_VRU/SM_LP_VRU_M_LP_VRU_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_LP_VRU/SM_LP_VRU_M_LP_VRU_Roughness.jpg"),
      },
    ],
  },
  {
    name: "Sales Gas Scrubber Skid",
    path: require("../assets/3dmodels/SM_Sales_Gas_Scrubber_Skid/SM_Sales_Gas_Scrubber_Skid.fbx"),
    previewPath: require("../assets/3dmodels/SM_Sales_Gas_Scrubber_Skid/Sales_Gas_Scrubber_Skid.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_Sales_Gas_Scrubber_Skid/SM_Sales_Gas_Scrubber_Skid_M_Sales_Gas_Scrubber_Skid_BaseColor.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_Sales_Gas_Scrubber_Skid/SM_Sales_Gas_Scrubber_Skid_M_Sales_Gas_Scrubber_Skid_Metallic.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_Sales_Gas_Scrubber_Skid/SM_Sales_Gas_Scrubber_Skid_M_Sales_Gas_Scrubber_Skid_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_Sales_Gas_Scrubber_Skid/SM_Sales_Gas_Scrubber_Skid_M_Sales_Gas_Scrubber_Skid_Roughness.jpg"),
      },
    ],
  },
  {
    name: "3 PACK",
    path: require("../assets/3dmodels/SM_3PACK/SM_3PACK.fbx"),
    previewPath: require("../assets/3dmodels/SM_3PACK/3PACK.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_3PACK/T_3PACK_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_3PACK/T_3PACK_Metal.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_3PACK/T_3PACK_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_3PACK/T_3PACK_Rough.jpg"),
      },
    ],
  },
  {
    name: "INJECTION GAS METER SKID",
    path: require("../assets/3dmodels/SM_INJECTION_GAS_METER_SKID/SM_INJECTION_GAS_METER_SKID.fbx"),
    previewPath: require("../assets/3dmodels/SM_INJECTION_GAS_METER_SKID/INJECTION_GAS_METER_SKID.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_INJECTION_GAS_METER_SKID/SM_INJECTION_GAS_METER_SKID_M_INJECTION_GAS_METER_SKID_BaseColor.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_INJECTION_GAS_METER_SKID/SM_INJECTION_GAS_METER_SKID_M_INJECTION_GAS_METER_SKID_Metallic.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_INJECTION_GAS_METER_SKID/SM_INJECTION_GAS_METER_SKID_M_INJECTION_GAS_METER_SKID_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_INJECTION_GAS_METER_SKID/SM_INJECTION_GAS_METER_SKID_M_INJECTION_GAS_METER_SKID_Roughness.jpg"),
      },
    ],
  },
  {
    name: "OIL LACT",
    path: require("../assets/3dmodels/SM_OIL_LACT/SM_OIL_LACT.fbx"),
    previewPath: require("../assets/3dmodels/SM_OIL_LACT/OIL_LACT.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_OIL_LACT/SM_OIL_LACT_M_OIL_LACT_BaseColor.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_OIL_LACT/SM_OIL_LACT_M_OIL_LACT_Metallic.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_OIL_LACT/SM_OIL_LACT_M_OIL_LACT_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_OIL_LACT/SM_OIL_LACT_M_OIL_LACT_Roughness.jpg"),
      },
    ],
  },
  {
    name: "500 BBL TANK",
    path: require("../assets/3dmodels/SM_500_BBL_TANK/SM_500_BBL_TANK.fbx"),
    previewPath: require("../assets/3dmodels/SM_500_BBL_TANK/500_BBL_TANK.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_500_BBL_TANK/T_500_BBL_TANK_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_500_BBL_TANK/T_500_BBL_TANK_Metalness.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_500_BBL_TANK/T_500_BBL_TANK_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_500_BBL_TANK/T_500_BBL_TANK_Rough.jpg"),
      },
    ],
  },
  {
    name: "Combustor",
    path: require("../assets/3dmodels/SM_Combustor/SM_Combustor.fbx"),
    previewPath: require("../assets/3dmodels/SM_Combustor/Combustor.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_Combustor/T_Combustor_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_Combustor/T_Combustor_Metal.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_Combustor/T_Combustor_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_Combustor/T_Combustor_Rough.jpg"),
      },
    ],
  },
  {
    name: "COMBUSTOR IES",
    path: require("../assets/3dmodels/SM_COMBUSTOR_IES/SM_COMBUSTOR_IES.fbx"),
    previewPath: require("../assets/3dmodels/SM_COMBUSTOR_IES/COMBUSTOR_IES.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES/T_COMBUSTOR_IES_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES/T_COMBUSTOR_IES_Metal.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES/T_COMBUSTOR_IES_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES/T_COMBUSTOR_IES_Rough.jpg"),
      },
    ],
  },
  {
    name: "COMBUSTOR IES 2f",
    path: require("../assets/3dmodels/SM_COMBUSTOR_IES_2ft/SM_COMBUSTOR_IES_2ft.fbx"),
    previewPath: require("../assets/3dmodels/SM_COMBUSTOR_IES_2ft/COMBUSTOR_IES_2ft.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES_2ft/T_COMBUSTOR_IES_2ft_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES_2ft/T_COMBUSTOR_IES_2ft_Metal.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES_2ft/T_COMBUSTOR_IES_2ft_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES_2ft/T_COMBUSTOR_IES_2ft_Rough.jpg"),
      },
    ],
  },
  {
    name: "COMBUSTOR IES 12ft",
    path: require("../assets/3dmodels/SM_COMBUSTOR_IES_12ft/SM_COMBUSTOR_IES_12ft.fbx"),
    previewPath: require("../assets/3dmodels/SM_COMBUSTOR_IES_12ft/COMBUSTOR_IES_12ft.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES_12ft/T_COMBUSTOR_IES_12ft_Albedo.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES_12ft/T_COMBUSTOR_IES_12ft_Metal.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES_12ft/T_COMBUSTOR_IES_12ft_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_COMBUSTOR_IES_12ft/T_COMBUSTOR_IES_12ft_Rough.jpg"),
      },
    ],
  },
  {
    name: "VRT 60in",
    path: require("../assets/3dmodels/SM_VRT_60inches/SM_VRT60in.fbx"),
    previewPath: require("../assets/3dmodels/SM_VRT_60inches/VRT60in.jpg"),
    textures: [
      {
        mapName: "map",
        path: require("../assets/3dmodels/SM_VRT_60inches/SM_VRT60in_SM_VRT60in_BaseColor.jpg"),
      },
      {
        mapName: "metalnessMap",
        path: require("../assets/3dmodels/SM_VRT_60inches/SM_VRT60in_SM_VRT60in_Metallic.jpg"),
      },
      {
        mapName: "normalMap",
        path: require("../assets/3dmodels/SM_VRT_60inches/SM_VRT60in_SM_VRT60in_Normal.jpg"),
      },
      {
        mapName: "roughnessMap",
        path: require("../assets/3dmodels/SM_VRT_60inches/SM_VRT60in_SM_VRT60in_Roughness.jpg"),
      },
    ],
  },
];

export default ObjectsData;
