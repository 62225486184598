import * as THREE from "three";

const TowerSideData = [
  {
    index: "1",
    entityName: "Side-1",
    entityType: "towerSide",
    angleInRad: THREE.MathUtils.degToRad(90),
  },
  {
    index: "2",
    entityName: "Side-2",
    entityType: "towerSide",
    angleInRad: THREE.MathUtils.degToRad(-180),
  },
  {
    index: "3",
    entityName: "Side-3",
    entityType: "towerSide",
    angleInRad: THREE.MathUtils.degToRad(-90),
  },
  {
    index: "4",
    entityName: "Side-4",
    entityType: "towerSide",
    angleInRad: THREE.MathUtils.degToRad(0),
  },
];

export default TowerSideData;
