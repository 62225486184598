import { GridColDef } from "@mui/x-data-grid";

export const useZonesTableColumns = (): {
  columns: GridColDef[];
} => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 120,
      hideable: false,
    },
    {
      field: "pan",
      headerName: "Pan",
      width: 120,
      hideable: false,
    },
    {
      field: "tilt",
      headerName: "Tilt",
      width: 120,
      hideable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      hideable: false,
    },
  ];

  return { columns };
};
