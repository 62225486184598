import { Fragment, useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";

import HtmlTooltip from "../../../common/components/tooltip/HtmlTooltip";

import {
  errorNotification,
  notificationVariable,
} from "../../../common/variables/notification";
import {
  causeOptions,
  detectedSelectOptions,
  showConfirmButtonList,
} from "../constants";
import { useValidateEvent } from "../hooks/useValidateEvent";
import {
  type EVENT_VALIDATE_STATUS,
  VALIDATION_SETTINGS_ENUM,
} from "../models";
import { humanValidatorTableVariable } from "../variables/humanValidatorTable";

const ValidationForm = (): JSX.Element => {
  const { tableData } = useReactiveVar(humanValidatorTableVariable);

  const [detectedValue, setDetectedValue] =
    useState<EVENT_VALIDATE_STATUS>(null);

  const [causeValue, setCauseValue] = useState<{
    title: string;
    description: string;
  } | null>(null);

  const [activeStep, setActiveStep] = useState(Number(0));
  const [validationSettings, setValidationSettings] =
    useState<VALIDATION_SETTINGS_ENUM | null>(null);

  useEffect((): void => {
    resetState();
  }, [tableData]);

  const resetState = (): void => {
    setDetectedValue(null);
    setCauseValue(null);
    setActiveStep(0);
    setValidationSettings(null);
  };

  const handleNext = (): void => {
    setActiveStep((prevActiveStep): number => prevActiveStep + 1);
    setDetectedValue(null);
    setCauseValue(null);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep): number => prevActiveStep - 1);
  };

  const handleAlert = (): void => {
    handleNext();
    setValidationSettings(VALIDATION_SETTINGS_ENUM.ALERT);
  };

  const handleNoAlert = (): void => {
    handleNext();
    setValidationSettings(VALIDATION_SETTINGS_ENUM.NO_ALERT);
  };

  const [validateEventItem, { loading }] = useValidateEvent();

  const confirmValidate = ({
    real,
    reason,
  }: {
    real: boolean;
    reason: string | null;
  }): void => {
    if (!tableData) return;

    const explanation = reason ?? "";

    validateEventItem({
      input: {
        customerId: tableData?.customerId,
        eventId: tableData?.eventId,
        eventTime: tableData?.eventTime,
        real,
        explanation,
      },
    })
      .then((): void => {
        notificationVariable({
          isOpen: true,
          message: "Done!",
          severity: "success",
        });

        humanValidatorTableVariable({
          showDetails: false,
          tableData: null,
        });
      })
      .catch((e): void => {
        console.error(e);
        errorNotification();
      });
  };

  const model = tableData ? tableData.model : "";
  const options = model ? causeOptions.get(model) : [];
  const showConfirmButton = showConfirmButtonList.includes(
    tableData?.model ?? ""
  );

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      <Step>
        <StepLabel>
          Select validation settings{" "}
          {validationSettings && <Chip label={validationSettings} />}
        </StepLabel>
        <StepContent>
          <ButtonGroup>
            <Button variant="contained" color="success" onClick={handleAlert}>
              ALERT!
            </Button>
            <Button variant="contained" color="info" onClick={handleNoAlert}>
              NO ALERT
            </Button>
          </ButtonGroup>
        </StepContent>
      </Step>
      <Step>
        <StepLabel>
          <span>Select the reason or </span>
          <Button
            disabled={activeStep === 0}
            variant="outlined"
            onClick={handleBack}
          >
            Back
          </Button>
        </StepLabel>
        <StepContent sx={{ padding: "1em 0", marginLeft: 0, maxWidth: 320 }}>
          {validationSettings === VALIDATION_SETTINGS_ENUM.ALERT && (
            <Grid container direction="column">
              <Grid item xs={12} md={3}>
                {showConfirmButton ? (
                  <LoadingButton
                    fullWidth
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<ErrorOutlineRoundedIcon />}
                    variant="contained"
                    color="success"
                    onClick={(): void =>
                      confirmValidate({
                        real: true,
                        reason: detectedValue as string,
                      })
                    }
                  >
                    Confirm
                  </LoadingButton>
                ) : (
                  <>
                    <Autocomplete
                      fullWidth
                      options={detectedSelectOptions}
                      value={detectedValue}
                      popupIcon={<ExpandMoreOutlinedIcon />}
                      onChange={(_event, newValue): void =>
                        setDetectedValue(
                          (newValue as EVENT_VALIDATE_STATUS) ?? null
                        )
                      }
                      renderInput={(params): JSX.Element => (
                        <TextField {...params} label="Detected" />
                      )}
                    />
                    <br />
                    <LoadingButton
                      fullWidth
                      disabled={!detectedValue}
                      loading={loading}
                      loadingPosition="start"
                      startIcon={<ErrorOutlineRoundedIcon />}
                      variant="contained"
                      color="success"
                      onClick={(): void =>
                        confirmValidate({
                          real: true,
                          reason: detectedValue as string,
                        })
                      }
                    >
                      Alert
                    </LoadingButton>
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {validationSettings === VALIDATION_SETTINGS_ENUM.NO_ALERT && (
            <Grid container direction="column">
              <Grid item xs={12} md={3}>
                <Autocomplete
                  fullWidth
                  getOptionLabel={(option): string => option?.title}
                  isOptionEqualToValue={(option, optionValue): boolean =>
                    option?.title === optionValue?.title
                  }
                  options={options}
                  value={causeValue}
                  onChange={(_event, newValue): void =>
                    setCauseValue(newValue ?? null)
                  }
                  popupIcon={<ExpandMoreOutlinedIcon />}
                  renderOption={(props, option, { index }): JSX.Element => {
                    return (
                      <Fragment key={index}>
                        <HtmlTooltip
                          arrow
                          title={option?.description}
                          placement="left"
                        >
                          <Box {...props} component="li">
                            {option?.title}
                          </Box>
                        </HtmlTooltip>
                      </Fragment>
                    );
                  }}
                  renderInput={(params): JSX.Element => (
                    <TextField {...params} label="Cause" />
                  )}
                />
                <br />
                <LoadingButton
                  fullWidth
                  disabled={!causeValue}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<CheckRoundedIcon />}
                  variant="contained"
                  color="info"
                  onClick={(): void =>
                    confirmValidate({
                      real: false,
                      reason: causeValue?.title ?? "",
                    })
                  }
                >
                  No Alert
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </StepContent>
      </Step>
    </Stepper>
  );
};

export default ValidationForm;
