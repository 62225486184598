import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  AssignFirstRoleToUserMutation,
  AssignFirstRoleToUserMutationVariables,
  PaginatedUsers,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { ASSIGN_FIRST_ROLE_TO_USER } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useFirstUpdateRole = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [updateRole, { data, loading }] = useMutation<
    AssignFirstRoleToUserMutation,
    AssignFirstRoleToUserMutationVariables
  >(ASSIGN_FIRST_ROLE_TO_USER);

  const firstUpdateUserRole = (
    userId: string,
    roleId: string,
    groupId?: string | null,
    userPoolId?: string | null
  ) => {
    return updateRole({
      variables: {
        customerId: selectedCustomerId,
        userId,
        roleId,
        groupId: groupId ?? "1670918124#PARTNER",
        userPoolId,
      },
      onCompleted: response => {
        if (response.assignFirstRoleToUser) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);
        console.error(error.message);
      },
      update: (cache, response): void => {
        // todo: bugfix cache is not updated upon firstUpdate
        cache.modify({
          fields: {
            getUsersByCompany(existing: PaginatedUsers) {
              const newUserRef = cache.writeFragment({
                data: response.data?.assignFirstRoleToUser,
                fragment: gql`
                  fragment NewUser on User {
                    customer_id
                    email
                    firstName
                    id
                    lastName
                    user_group
                    user_role
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: existing.items.map(item => {
                  if (item?.id !== response.data?.assignFirstRoleToUser?.id) {
                    return item;
                  }

                  return newUserRef;
                }),
              };
            },
          },
        });
      },
    });
  };

  return { firstUpdateUserRole, data, loading };
};
