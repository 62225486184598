import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import TableNoDataOverlay from "../../../common/components/table/TableNoDataOverlay";

import { lightDataGrid } from "../../../common/providers/theme/design-tokens/DataGrid/light";
import { useLocationsTableColumns } from "../hooks/useLocationsTableColumns";
import { useLocationsTableData } from "../hooks/useLocationsTableData";

export const DEFAULT_TABLE_PAGE_SIZE = 10;

const LocationsTable = (): JSX.Element => {
  const { rows, loading } = useLocationsTableData();
  const { columns } = useLocationsTableColumns();

  // eslint-disable-next-line
  const handleRowClick = (params: any): void => {
    // Open location modal or redirect to location details/edit page
  };

  return (
    <DataGrid
      sx={lightDataGrid.sx}
      rowsPerPageOptions={[10]}
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: TableNoDataOverlay,
      }}
      onRowClick={handleRowClick}
      autoHeight
      disableSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: string }): string => row.rowId}
      loading={loading}
      pageSize={DEFAULT_TABLE_PAGE_SIZE}
      sortingOrder={["desc", "asc"]}
    />
  );
};

export default LocationsTable;
