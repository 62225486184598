export const findObjectAncestorsInditificator = child => {
  let objectId = null;
  let sideIndex = null;
  let cameraId = null;

  const setValues = object => {
    if (object.EntityType === "tower" || object.EntityType === "object") {
      objectId = object.ID;
    } else if (object.EntityType === "towerSide") {
      sideIndex = object.SideIndex;
    } else if (object.EntityType === "camera") {
      cameraId = object.ID;
    }
  };

  setValues(child);
  child.traverseAncestors(ancestor => setValues(ancestor));

  return { objectId, sideIndex, cameraId };
};
