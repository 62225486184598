import React from "react";

import Notification from "../components/notification/Notification";

const withNotificationMessageProvider = (
  ComposedComponent: React.ComponentType
): React.FC => {
  ComposedComponent.displayName =
    "withNotificationMessageProviderComposedComponent";

  return (props): JSX.Element => {
    return (
      <>
        <Notification />
        <ComposedComponent {...props} />
      </>
    );
  };
};

export default withNotificationMessageProvider;
