import { makeVar, useReactiveVar } from "@apollo/client";

import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import { IFormValidationState } from "../../../common/models/formValidation";

interface INodeForm {
  locationId: AutocompleteOptionType;
  nodeName: string;
}

interface INodeFormValidationState {
  locationId: IFormValidationState;
  nodeName: IFormValidationState;
}

export const defaultNodeFormValues: INodeForm = {
  locationId: null,
  nodeName: "",
};

export const nodeFormRules = {
  locationId: {
    required: true,
  },
  nodeName: {
    required: true,
    unique: true,
    pattern: `^([a-zA-Z0-9]+\\s?)+$`,
    maxLength: 30,
  },
};

export const defaultNodeFormValidationState: INodeFormValidationState = {
  locationId: {
    hasError: false,
    errorMessage: "",
  },
  nodeName: {
    hasError: false,
    errorMessage: "",
  },
};

export const nodeFormVariables = makeVar<INodeForm>(defaultNodeFormValues);
export const useNodeFormVariables = () => useReactiveVar(nodeFormVariables);

export const nodeFormValidationStateVariable =
  makeVar<INodeFormValidationState>(defaultNodeFormValidationState);
export const useNodeFormValidationState = () =>
  useReactiveVar(nodeFormValidationStateVariable);

export const selectedLocationNodesVariable =
  makeVar<AutocompleteOptionType>(null);
export const useSelectedLocationNodes = () =>
  useReactiveVar(selectedLocationNodesVariable);
