import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import TableNoDataOverlay from "../../../common/components/table/TableNoDataOverlay";

import { lightDataGrid } from "../../../common/providers/theme/design-tokens/DataGrid/light";
import { NODES_TABLE_LIMIT } from "../hooks/useGetNodes";
import { useNodeTableColumns } from "../hooks/useNodeTableColumns";
import { useNodeTableRows } from "../hooks/useNodeTableRows";

const NodesTable = (): JSX.Element => {
  const { rows, loading } = useNodeTableRows();
  const { columns } = useNodeTableColumns();

  // eslint-disable-next-line
  const handleRowClick = (params: any): void => {
    // Open node modal
  };

  return (
    <DataGrid
      sx={lightDataGrid.sx}
      rowsPerPageOptions={[NODES_TABLE_LIMIT]}
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: TableNoDataOverlay,
      }}
      onRowClick={handleRowClick}
      autoHeight
      disableColumnMenu
      disableSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: any }): string => row.rowId as string}
      loading={loading}
      pageSize={NODES_TABLE_LIMIT}
    />
  );
};

export default NodesTable;
