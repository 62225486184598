/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addAlertEmailNotification = /* GraphQL */ `
  mutation AddAlertEmailNotification($input: addAlertEmailNotificationInput) {
    addAlertEmailNotification(input: $input) {
      additionalEmails
      alertName
      alertType
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      status
    }
  }
`;
export const addAlertSubscription = /* GraphQL */ `
  mutation AddAlertSubscription($input: AddAlertSubscriptionInput!) {
    addAlertSubscription(input: $input) {
      email
      entity
    }
  }
`;
export const addAlertWildCardSubscription = /* GraphQL */ `
  mutation AddAlertWildCardSubscription(
    $input: AddAlertWildCardSubscriptionInput!
  ) {
    addAlertWildCardSubscription(input: $input) {
      email
      entity
      wildCard
    }
  }
`;
export const addAnnotation = /* GraphQL */ `
  mutation AddAnnotation($input: AddAnnotationInput!) {
    addAnnotation(input: $input) {
      annotationType
      id
      name
      polygon
      serviceId
      zoneId
    }
  }
`;
export const addCustomer = /* GraphQL */ `
  mutation AddCustomer(
    $additionalAdminGroupId: String
    $bucketName: String
    $customerName: String!
    $domains: [String!]
    $userPoolId: String
  ) {
    addCustomer(
      additionalAdminGroupId: $additionalAdminGroupId
      bucketName: $bucketName
      customerName: $customerName
      domains: $domains
      userPoolId: $userPoolId
    ) {
      domains
      id
      name
      pointOfContact
    }
  }
`;
export const addDTProject = /* GraphQL */ `
  mutation AddDTProject($input: AddDTProjectInput!) {
    addDTProject(input: $input) {
      configURL
      customerId
      displayName
      id
      projectId
    }
  }
`;
export const addDevice = /* GraphQL */ `
  mutation AddDevice($input: AddDeviceInput!) {
    addDevice(input: $input) {
      customerId
      deviceData
      id
      locationId
      makeModelId
      name
      nodeId
    }
  }
`;
export const addDeviceMake = /* GraphQL */ `
  mutation AddDeviceMake($input: AddDeviceMakeInput!) {
    addDeviceMake(input: $input) {
      id
      model
      name
      rtspTemplate
      type
    }
  }
`;
export const addDomain = /* GraphQL */ `
  mutation AddDomain($customerId: String!, $domain: String!) {
    addDomain(customerId: $customerId, domain: $domain) {
      customerId
    }
  }
`;
export const addLocation = /* GraphQL */ `
  mutation AddLocation($input: LocationInput!) {
    addLocation(input: $input) {
      id
      locationData
      name
      tags
    }
  }
`;
export const addNode = /* GraphQL */ `
  mutation AddNode($input: AddNodeInput!) {
    addNode(input: $input) {
      customerId
      deviceData
      id
      level
      locationId
      nodeId
      nodeName
      onboardCommand
      serviceData
      tags
    }
  }
`;
export const addService = /* GraphQL */ `
  mutation AddService($input: AddServiceInput!) {
    addService(input: $input) {
      configuration
      customerId
      deviceId
      id
      locationId
      nodeId
      serviceType
    }
  }
`;
export const addZone = /* GraphQL */ `
  mutation AddZone($input: AddZoneInput!) {
    addZone(input: $input) {
      deviceId
      id
      mediaUrl
      name
      orderNumber
      pan
      serviceId
      status
      tilt
    }
  }
`;
export const assignFirstRoleToUser = /* GraphQL */ `
  mutation AssignFirstRoleToUser(
    $customerId: String!
    $groupId: String!
    $roleId: String!
    $userId: String!
    $userPoolId: String
  ) {
    assignFirstRoleToUser(
      customerId: $customerId
      groupId: $groupId
      roleId: $roleId
      userId: $userId
      userPoolId: $userPoolId
    ) {
      customer_id
      email
      firstName
      id
      lastName
      user_group
      user_role
    }
  }
`;
export const changeNodeStatus = /* GraphQL */ `
  mutation ChangeNodeStatus($input: ChangeNodeStatusInput!) {
    changeNodeStatus(input: $input)
  }
`;
export const deleteAllAnnotations = /* GraphQL */ `
  mutation DeleteAllAnnotations($annotationsJSON: AWSJSON) {
    deleteAllAnnotations(annotationsJSON: $annotationsJSON) {
      id
      serviceId
      zoneId
    }
  }
`;
export const deleteDTProject = /* GraphQL */ `
  mutation DeleteDTProject($input: DTProjectInput!) {
    deleteDTProject(input: $input) {
      configURL
      customerId
      displayName
      id
      projectId
    }
  }
`;
export const deleteDeviceMake = /* GraphQL */ `
  mutation DeleteDeviceMake($id: String!) {
    deleteDeviceMake(id: $id)
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($locationId: String!) {
    deleteLocation(locationId: $locationId)
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService($input: DeleteServiceInput!) {
    deleteService(input: $input) {
      configuration
      customerId
      deviceId
      id
      locationId
      nodeId
      serviceType
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $customerId: String!
    $userId: String!
    $userPoolId: String!
  ) {
    deleteUser(
      customerId: $customerId
      userId: $userId
      userPoolId: $userPoolId
    ) {
      customer_id
      email
      firstName
      id
      lastName
      user_group
      user_role
    }
  }
`;
export const deleteZone = /* GraphQL */ `
  mutation DeleteZone($input: DeleteZoneInput!) {
    deleteZone(input: $input) {
      id
      name
    }
  }
`;
export const overruleHumanValidatedEvents = /* GraphQL */ `
  mutation OverruleHumanValidatedEvents($input: ValidateEventInput!) {
    overruleHumanValidatedEvents(input: $input) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const publishNode = /* GraphQL */ `
  mutation PublishNode($input: publishNodeInput!) {
    publishNode(input: $input) {
      message
      nodeId
    }
  }
`;
export const sendToListenToHumanValidationEvents = /* GraphQL */ `
  mutation SendToListenToHumanValidationEvents(
    $humanValidationEvent: AWSJSON!
  ) {
    sendToListenToHumanValidationEvents(
      humanValidationEvent: $humanValidationEvent
    ) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const sendToListenToNodeFiles = /* GraphQL */ `
  mutation SendToListenToNodeFiles($nodeFile: AWSJSON!) {
    sendToListenToNodeFiles(nodeFile: $nodeFile) {
      downloadURL
      file
      internalNodeId
    }
  }
`;
export const sendToListenToNodeMessages = /* GraphQL */ `
  mutation SendToListenToNodeMessages($nodeMessage: AWSJSON!) {
    sendToListenToNodeMessages(nodeMessage: $nodeMessage) {
      internalNodeId
      message
    }
  }
`;
export const setPendingState = /* GraphQL */ `
  mutation SetPendingState($customerId: String, $eventId: String) {
    setPendingState(customerId: $customerId, eventId: $eventId) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const takeEventToAudit = /* GraphQL */ `
  mutation TakeEventToAudit($customerId: String, $eventId: String) {
    takeEventToAudit(customerId: $customerId, eventId: $eventId) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const takeEventToValidate = /* GraphQL */ `
  mutation TakeEventToValidate($customerId: String, $eventId: String) {
    takeEventToValidate(customerId: $customerId, eventId: $eventId) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const updateAdminEmailAlert = /* GraphQL */ `
  mutation UpdateAdminEmailAlert($input: UpdateAdminEmailAlertInput!) {
    updateAdminEmailAlert(input: $input) {
      additionalEmails
      alertName
      alertType
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      status
    }
  }
`;
export const updateAdminSystemAlert = /* GraphQL */ `
  mutation UpdateAdminSystemAlert($input: UpdateAdminSystemAlertInput!) {
    updateAdminSystemAlert(input: $input) {
      additionalEmails
      alertName
      alertType
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      status
    }
  }
`;
export const updateAlert = /* GraphQL */ `
  mutation UpdateAlert($input: UpdateAlertInput!) {
    updateAlert(input: $input) {
      additionalEmails
      alertName
      alertType
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      status
    }
  }
`;
export const updateAlertStatus = /* GraphQL */ `
  mutation UpdateAlertStatus($input: UpdateAlertStatusInput!) {
    updateAlertStatus(input: $input) {
      additionalEmails
      alertName
      alertType
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      status
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: CustomerInput) {
    updateCustomer(input: $input) {
      domains
      id
      name
      pointOfContact
    }
  }
`;
export const updateDTProject = /* GraphQL */ `
  mutation UpdateDTProject($input: DTProjectInput!) {
    updateDTProject(input: $input) {
      configURL
      customerId
      displayName
      id
      projectId
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      customerId
      deviceData
      id
      locationId
      makeModelId
      name
      nodeId
    }
  }
`;
export const updateDeviceMake = /* GraphQL */ `
  mutation UpdateDeviceMake($input: UpdateDeviceMakeInput!) {
    updateDeviceMake(input: $input) {
      id
      model
      name
      rtspTemplate
      type
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: LocationInput!) {
    updateLocation(input: $input) {
      id
      locationData
      name
      tags
    }
  }
`;
export const updateNode = /* GraphQL */ `
  mutation UpdateNode($input: UpdateNodeInput!) {
    updateNode(input: $input) {
      customerId
      deviceData
      id
      level
      locationId
      nodeId
      nodeName
      onboardCommand
      serviceData
      tags
    }
  }
`;
export const updateProfile = /* GraphQL */ `
  mutation UpdateProfile($input: ProfileInput) {
    updateProfile(input: $input) {
      file
      id
      name
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService($input: UpdateServiceInput!) {
    updateService(input: $input) {
      configuration
      customerId
      deviceId
      id
      locationId
      nodeId
      serviceType
    }
  }
`;
export const updateShadowNode = /* GraphQL */ `
  mutation UpdateShadowNode(
    $payload: AWSJSON
    $shadowName: String
    $thingName: String
  ) {
    updateShadowNode(
      payload: $payload
      shadowName: $shadowName
      thingName: $thingName
    ) {
      shadow
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UserInput!) {
    updateUser(input: $input) {
      customer_id
      email
      firstName
      id
      lastName
      user_group
      user_role
    }
  }
`;
export const updateUserAlert = /* GraphQL */ `
  mutation UpdateUserAlert($input: UpdateUserAlertInput!) {
    updateUserAlert(input: $input) {
      additionalEmails
      alertName
      alertType
      customService
      email
      id
      jsonObject
      location
      locationTag
      model
      node
      status
    }
  }
`;
export const updateZone = /* GraphQL */ `
  mutation UpdateZone($input: UpdateZoneInput!) {
    updateZone(input: $input) {
      deviceId
      id
      mediaUrl
      name
      orderNumber
      pan
      serviceId
      status
      tilt
    }
  }
`;
export const uploadDTObject = /* GraphQL */ `
  mutation UploadDTObject($input: UploadDTObject!) {
    uploadDTObject(input: $input)
  }
`;
export const validateEvent = /* GraphQL */ `
  mutation ValidateEvent($input: ValidateEventInput!) {
    validateEvent(input: $input) {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
