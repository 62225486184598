import { CssBaseline } from "@mui/material";

import WebglConfiguratorMuiTheme from "./WebglConfigurtorMuiTheme";
import { ThemeProvider } from "@emotion/react";

const WebglConfigurtorMuiThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={WebglConfiguratorMuiTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default WebglConfigurtorMuiThemeProvider;
