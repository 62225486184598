import { AppBar, Box, Toolbar, Stack } from "@mui/material";

import AvatarPopover from "./components/AvatarPopover";
import UserSettingsPopover from "./components/UserSettingsPopover";

const WebglConfiguratorHeaderTopBar = (): JSX.Element => {
  return (
    <AppBar enableColorOnDark color="primary" position="static">
      <Toolbar sx={{ height: "100%" }}>
        <Box sx={{ marginLeft: "auto" }}>
          <Stack spacing={2} direction="row">
            <UserSettingsPopover />
            <AvatarPopover />
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default WebglConfiguratorHeaderTopBar;
