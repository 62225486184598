import { Box, Modal, IconButton } from "@mui/material";
import { CloseIcon } from "../assets/icons/svgAssets";

const ModalWindow = ({ isOpen, onClose, modalWidth, children }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          width: modalWidth || "472px",
          backgroundColor: "#ffffff",
          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
          border: "none",
          borderRadius: "4px",
          padding: "20px 24px 24px 24px",
          outline: "none",
        }}
      >
        {children}
        <IconButton
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& svg": {
              width: "24px",
              height: "24px",
            },
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  );
};

export default ModalWindow;
