import { makeVar } from '@apollo/client';
import type { PendingEventRow } from '../hooks/useHumanValidatorTableData';

export interface HumanValidatorTableVariableInterface {
	tableData: PendingEventRow | null;
	showDetails: boolean;
};

export const humanValidatorTableVariable = makeVar<HumanValidatorTableVariableInterface>({
	tableData: null,
	showDetails: false,
});

export const hvConnection = makeVar(true);
