import { Auth } from "aws-amplify";
import { AccountTypeEnum } from "../models/enums";

// todo: Replace this function by storing accountType inside a react variable as opposed to generating accountType each time it's needed
export const getUserAccountType = async () => {
  const session = await Auth.currentSession();

  const idToken = session.getIdToken();
  const { payload } = idToken;
  // eslint-disable-next-line
  const { user_groups } = payload;
  let arrayOfAdminGroups = [];
  // convert user_groups from string to array
  if (!user_groups) return;
  try {
    arrayOfAdminGroups = JSON.parse(user_groups || {});
  } catch (error) {
    console.log(error);
    throw new Error("Error parsing user_groups");
  }
  console.log(arrayOfAdminGroups);
  // depending on the state of user_groups of user's session we can deduct what type of user the account is
  if (arrayOfAdminGroups[0] === "1670918983") return AccountTypeEnum.CC_ADMIN;
  if (arrayOfAdminGroups[1] === "1670918125")
    return AccountTypeEnum.CC_OPS_ADMIN;
  if (arrayOfAdminGroups[1] === "1670918126") return AccountTypeEnum.CC_OPS;
  if (arrayOfAdminGroups[1] === "1670918127") return AccountTypeEnum.CC_SALES;
  if (arrayOfAdminGroups[1] === "1670918128")
    return AccountTypeEnum.PARTNER_ADMIN;
  if (arrayOfAdminGroups[1] === "1670918129")
    return AccountTypeEnum.CUSTOMER_ADMIN;
  if (arrayOfAdminGroups[1] === "1670918130")
    return AccountTypeEnum.CUSTOMER_OPERATOR_FOREMAN;
  if (arrayOfAdminGroups[1] === "1670918131")
    return AccountTypeEnum.CUSTOMER_OPS_TEAM;
  if (arrayOfAdminGroups[1] === "1670918132")
    return AccountTypeEnum.CUSTOMER_HSE;
  if (arrayOfAdminGroups[1] === "1670918133")
    return AccountTypeEnum.CUSTOMER_SERCURITY;
  if (arrayOfAdminGroups[1] === "1670918123")
    return AccountTypeEnum.CC_PROCTORS;
  if (arrayOfAdminGroups[1] === "1670918124")
    return AccountTypeEnum.PENDING_CUSTOMER;
};

export const getAccountType = (userGroups: string) => {
  if (!userGroups) return;

  let arrayOfAdminGroups = [];

  try {
    arrayOfAdminGroups = JSON.parse(userGroups || "[]");
  } catch (error) {
    console.log(error);
    throw new Error("Error parsing user_groups");
  }

  // depending on the state of user_groups of user's session we can deduct what type of user the account is
  if (arrayOfAdminGroups.length > 0) return AccountTypeEnum.ADMIN;
  if (arrayOfAdminGroups.length === 0) return AccountTypeEnum.PARTNER;
  if (!arrayOfAdminGroups) return AccountTypeEnum.CUSTOMER;
};

export const getAdminGroupId = async () => {
  return await Auth.currentSession()
    .then(session => {
      const idToken = session.getIdToken();
      const { payload } = idToken;
      // eslint-disable-next-line
      const { user_groups } = payload;
      let arrayOfAdminGroups = [];

      // convert user_groups from string to array
      try {
        arrayOfAdminGroups = JSON.parse(user_groups);
      } catch (error) {
        console.log(error);
        throw new Error("Error parsing user_groups");
      }

      // depending on the state of user_groups of user's session we can deduct what type of user the account is
      if (arrayOfAdminGroups.length > 0) return arrayOfAdminGroups[0];
    })
    .catch(err => console.log(err));
};
