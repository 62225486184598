import { useState } from "react";

import { useGetTerrainTexture } from "./useGetTerrainTexture";

import { loadGeoJson } from "../../../common/threeWebGl/AssetLoaders";
import { getMeshMultilinestring } from "../../../common/threeWebGl/MathUtils";
import { getDate } from "../../../common/utils/date";

export const useGetProjectConfig = () => {
  const [loading, setLoading] = useState(false);
  const [getTerrainTextureUrl] = useGetTerrainTexture();

  const getGeoJsonData = async file => {
    const topojsonData = await loadGeoJson(file);
    return getMeshMultilinestring(topojsonData);
  };

  const getProjectInitialConfig = async ({
    terrainGeoJsonFile,
    shapeGeoJsonFile,
  }) => {
    setLoading(true);

    const terrainData = await getGeoJsonData(terrainGeoJsonFile);
    const textureUrl = await getTerrainTextureUrl(terrainData);

    const shapeData = shapeGeoJsonFile
      ? await getGeoJsonData(shapeGeoJsonFile)
      : null;

    setLoading(false);

    return {
      terrainData: {
        terrainData: terrainData,
        shapeData: shapeData,
        groundTexturePath: textureUrl,
      },
      obj: [],
      cameras: [],
      towers: [],
      lastEditDate: getDate(),
    };
  };

  return { getProjectInitialConfig, loading };
};
