import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { Box } from "@mui/material";

import LocationIdSelect from "../../../common/components/layout/components/LocationIdSelect";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  selectedLocationDevicesVariable,
  useSelectedLocationDevices,
} from "../variables/devices";

const LocationSelectContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const locationVariable = useSelectedLocationDevices();

  const hashValue = window.location.hash ? "C" + window.location.hash : "";

  const defaultLocationIdFromUrl = hashValue.includes("#N#")
    ? hashValue.split("#N#").at(-2)
    : hashValue;

  const [defaultLocationValue, setDefaultLocationValue] = useState<string>(
    defaultLocationIdFromUrl ?? ""
  );

  useEffect((): void => {
    if (window.location.hash) {
      const hash = window.location.hash ? "C" + window.location.hash : "";

      const defaultLocationId = hash.includes("#N#")
        ? hash.split("#N#").at(-2) ?? ""
        : hash;

      if (defaultLocationValue !== defaultLocationId) {
        setDefaultLocationValue(defaultLocationId);
      }
    }
  }, [window.location.hash]);

  useEffect((): void => {
    const hash = window.location.hash ? window.location.hash : "";

    if (locationVariable?.value && locationVariable?.value !== hash) {
      const hashValue = hash.includes("#N#") ? hash : locationVariable?.value;

      const to = hash.includes("#N#")
        ? `${window.location.pathname.slice(0, -2)}/${
            locationVariable?.value
          }#N#${hashValue.split("#N#").at(-1) ?? ""}`
        : `/devices/${locationVariable?.value}`;

      navigate(to, {
        replace: true,
      });
    }
  }, [locationVariable?.value, window.location.pathname]);

  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    selectedLocationDevicesVariable(locationValue);
  };

  return (
    <Box sx={{ maxWidth: 400, width: 280 }}>
      <LocationIdSelect
        disableClearable
        variant="standard"
        setDefault={!locationVariable || !window.location.hash}
        defaultValue={defaultLocationValue}
        location={locationVariable}
        setLocation={handleLocationChange}
      />
    </Box>
  );
};

export default LocationSelectContainer;
