import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import { GET_ANNOTATIONS } from "../../../../common/operations/queries";
import { errorNotification } from "../../../../common/variables/notification";

import client from "../../../../configs/apolloClient";
import {
  GetAnnotationsQuery,
  GetAnnotationsQueryVariables,
} from "../../../../API";

export type useGetAnnotationsType = {
  polygon?: JSON;
};

export const ANNOTATIONS_TABLE_LIMIT = 5;

export const useGetAnnotations = (zoneId: string, serviceId: string) => {
  useEffect(() => {
    if (zoneId && serviceId) {
      fetchAnnotations(zoneId, serviceId);
    }
  }, [zoneId, serviceId]);

  const handleRequestError = () => {
    client.cache.reset();
    errorNotification();
  };

  const [getAnnotations, { data, loading }] = useLazyQuery<
    GetAnnotationsQuery,
    GetAnnotationsQueryVariables
  >(GET_ANNOTATIONS, {
    fetchPolicy: "network-only",
  });

  const fetchAnnotations = (
    zoneId: string,
    serviceId: string,
    nextToken?: string
  ) => {
    getAnnotations({
      variables: {
        limit: ANNOTATIONS_TABLE_LIMIT,
        zoneId,
        serviceId,
        nextToken,
      },
    })
      .then(response => {
        if (response.data?.getAnnotations.nextToken) {
          fetchAnnotations(
            zoneId,
            serviceId,
            response.data?.getAnnotations.nextToken
          );
        }

        if (response.error) {
          handleRequestError();
        }
      })
      .catch(() => {
        handleRequestError();
      });
  };

  return { data, loading };
};
