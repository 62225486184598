// OBSOLETE: replaced with RoleEum
// TODO: remove after testing
export const enum AccountTypeEnum {
  ADMIN = "ADMIN",
  CUSTOMER = "CUSTOMER",
  PARTNER = "PARTNER",
  CC_ADMIN = "CC ADMIN",
  CC_OPS_ADMIN = "CC OPS ADMIN",
  CC_OPS = "CC OPS",
  CC_SALES = "CC SALES",
  PARTNER_ADMIN = "PARTNER ADMIN",
  CUSTOMER_ADMIN = "CUSTOMER ADMIN",
  CUSTOMER_OPERATOR_FOREMAN = "CUSTOMER OPERATOR FOREMAN",
  CUSTOMER_OPS_TEAM = "CUSTOMER OPS TEAM",
  CUSTOMER_HSE = "CUSTOMER HSE",
  CUSTOMER_SERCURITY = "CUSTOMER SECURITY",
  CC_PROCTORS = "CC PROCTORS",
  PENDING_CUSTOMER = "PENDING CUSTOMER",
}

export type AccountType =
  | AccountTypeEnum.ADMIN
  | AccountTypeEnum.CUSTOMER
  | AccountTypeEnum.PARTNER
  | AccountTypeEnum.CC_ADMIN
  | AccountTypeEnum.CC_OPS_ADMIN
  | AccountTypeEnum.CC_OPS
  | AccountTypeEnum.CC_SALES
  | AccountTypeEnum.PARTNER_ADMIN
  | AccountTypeEnum.CUSTOMER_ADMIN
  | AccountTypeEnum.CUSTOMER_OPERATOR_FOREMAN
  | AccountTypeEnum.CUSTOMER_OPS_TEAM
  | AccountTypeEnum.CUSTOMER_HSE
  | AccountTypeEnum.CUSTOMER_SERCURITY
  | AccountTypeEnum.CC_PROCTORS
  | AccountTypeEnum.PENDING_CUSTOMER;

export enum RoleEnum {
  ROOT = "R#ROOT",
  CC_OPS_ADMIN = "R#CC_OPS_ADMIN",
  CC_OPS = "R#CC_OPS",
  CC_SALES = "R#CC_SALES",
  PARTNER_ADMIN = "R#PARTNER_ADMIN",
  CUSTOMER_ADMIN = "R#CUSTOMER_ADMIN",
  CUSTOMER_OPERATOR_FOREMAN = "R#CUSTOMER_OPERATOR_FOREMAN",
  CUSTOMER_OPS_TEAM = "R#CUSTOMER_OPS_TEAM",
  CUSTOMER_HSE = "R#CUSTOMER_HSE",
  CUSTOMER_SECURITY = "R#CUSTOMER_SECURITY",
  CC_PROCTORS = "R#HUMAN_VALIDATOR",
  PENDING = "R#PENDING",
}

export const CC_ADMIN_ROLES = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
];

export const ROLES = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
  RoleEnum.CUSTOMER_ADMIN,
  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  RoleEnum.CUSTOMER_OPS_TEAM,
  RoleEnum.CUSTOMER_HSE,
  RoleEnum.CUSTOMER_SECURITY,
  RoleEnum.CC_PROCTORS,
  RoleEnum.PENDING,
];
