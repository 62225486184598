import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import ConfiguratorPermissionContext from "./ConfiguratorPermissionContext";

const ConfiguratorPermissionContextProvider = ({ children }) => {
  const [isAllowEditing, setIsAllowEditing] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsAllowEditing(location.pathname.includes("editor"));
  }, [location]);

  return (
    <ConfiguratorPermissionContext.Provider value={{ isAllowEditing }}>
      {children}
    </ConfiguratorPermissionContext.Provider>
  );
};

export default ConfiguratorPermissionContextProvider;
