import { ApolloError } from "@apollo/client";

import { addAlertEmailNotificationInput } from "../../../../../../../API";
import StyledDialog from "../../../../../../../common/components/dialog/StyledDialog";
import { useAuthenticatedUser } from "../../../../../../../common/hooks/useAuthenticatedUser";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";
import { useCreateAlertEmailNotification } from "../../../hooks/useCreateAlertEmailNotification";
import {
  AlertTypeEnum,
  alertDialogVariables,
  defaultAlertDialogVariables,
  useAlertDialog,
} from "../../../variables/alerts";
import {
  defaultSystemAlertFormVariables,
  systemAlertFormVariables,
  useSystemAlertFormVariables,
} from "../../../variables/systemAlerts";
import SystemAlertDialogForm from "./SystemAlertDialogForm";

const CreateSystemAlertDialog = (): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();
  const { user } = useAuthenticatedUser();

  const { isOpen, alertType, rowId } = useAlertDialog();
  const alertForm = useSystemAlertFormVariables();

  const { createAlertEmailNotification, loading } =
    useCreateAlertEmailNotification();

  const handleDialogClose = (): void => {
    alertDialogVariables(defaultAlertDialogVariables);

    systemAlertFormVariables({
      ...defaultSystemAlertFormVariables,
    });
  };

  const handleSubmit = (): void => {
    const input: addAlertEmailNotificationInput = {
      customerId: selectedCustomerId,
      alertName: "System Alert",
      alertType: AlertTypeEnum.SYSTEM,
      email: user?.attributes?.email,
      status: true,
      node: alertForm.node,
      customService: alertForm.customService,
      jsonObject: JSON.stringify(
        alertForm.jsonObject === "" ? {} : alertForm.jsonObject
      ),
      location: "",
      model: "",
    };

    createAlertEmailNotification(input)
      .then((response): void => {
        if (response?.data) {
          successNotification("Alert was successfully created");
          handleDialogClose();
          console.log(response);
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error: ApolloError): void => {
        errorNotification();
        console.error(error.message);
      });
  };

  return (
    <StyledDialog
      open={isOpen && alertType === AlertTypeEnum.SYSTEM && rowId === ""}
      title="Create System Alert"
      maxWidth="sm"
      onClose={handleDialogClose}
      SubmitButtonProps={{
        loading,
        onSubmit: handleSubmit,
      }}
    >
      <SystemAlertDialogForm />
    </StyledDialog>
  );
};

export default CreateSystemAlertDialog;
