import { addAlertEmailNotificationInput } from "../../../../../../../API";
import StyledDialog from "../../../../../../../common/components/dialog/StyledDialog";
import { useAuthenticatedUser } from "../../../../../../../common/hooks/useAuthenticatedUser";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";
import { useCreateAlertEmailNotification } from "../../../hooks/useCreateAlertEmailNotification";
import {
  AlertTypeEnum,
  alertDialogVariables,
  defaultAlertDialogVariables,
  useAlertDialog,
} from "../../../variables/alerts";
import {
  defaultEmailAlertFormVariables,
  emailAlertFormValidationState,
  emailAlertFormVariables,
  useEmailAlertFormValidationState,
  useEmailAlertFormVariables,
} from "../../../variables/emailAlerts";
import EmailAlertDialogForm from "./EmailAlertDialogForm";

const CreateEmailAlertDialog = (): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();
  const { user } = useAuthenticatedUser();

  const { isOpen, alertType, rowId } = useAlertDialog();
  const alertForm = useEmailAlertFormVariables();
  const validation = useEmailAlertFormValidationState();

  const { createAlertEmailNotification, loading } =
    useCreateAlertEmailNotification();

  const handleDialogClose = (): void => {
    alertDialogVariables(defaultAlertDialogVariables);
    emailAlertFormVariables({
      ...defaultEmailAlertFormVariables,
    });
  };

  const handleSubmit = (): void => {
    let validationState = { ...validation };

    if (!alertForm.alertName) {
      validationState = {
        ...validationState,
        alertName: {
          hasError: true,
          errorMessage: "Please type alert name",
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      emailAlertFormValidationState(validationState);
      return;
    }

    const input: addAlertEmailNotificationInput = {
      customerId: selectedCustomerId,
      alertName: alertForm.alertName,
      alertType: AlertTypeEnum.EMAIL,
      email: user?.attributes?.email,
      location: alertForm.location?.value,
      model: alertForm.model,
      status: true,
      additionalEmails: alertForm.additionalEmails.filter(
        (email): boolean => email !== ""
      ),
      node: "",
      customService: "",
      jsonObject: JSON.stringify({}),
    };

    createAlertEmailNotification(input)
      .then((response): void => {
        if (response?.data) {
          successNotification("Alert was successfully created");
          handleDialogClose();
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      });
  };

  return (
    <StyledDialog
      open={isOpen && alertType === AlertTypeEnum.EMAIL && rowId === ""}
      title="Create Email Alert"
      maxWidth="sm"
      onClose={handleDialogClose}
      SubmitButtonProps={{
        onSubmit: handleSubmit,
        loading,
      }}
    >
      <EmailAlertDialogForm />
    </StyledDialog>
  );
};

export default CreateEmailAlertDialog;
