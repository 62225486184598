import mapboxSdk from "@mapbox/mapbox-sdk";
import staticMap from "@mapbox/mapbox-sdk/services/static";

import {
  calculateZoomLevel,
  calculateTerainCenter,
} from "../../../common/utils/mapboxUtils";

const mapboxClient = mapboxSdk({
  accessToken: process.env.REACT_APP_MAP_BOX_TOKEN,
});
const staticClient = staticMap(mapboxClient);

export const useGetTerrainTexture = () => {
  const getTerrainTextureUrl = async terrainData => {
    const [latitude, longitude] = calculateTerainCenter(terrainData);
    const zoomLevel = calculateZoomLevel();

    const position = { coordinates: [longitude, latitude], zoom: zoomLevel };

    const mapOptions = {
      position: position,
      width: 1280,
      height: 1280,
      styleId: "satellite-streets-v11",
      ownerId: "mapbox",
      logo: false,
    };

    const request = staticClient.getStaticImage(mapOptions);

    return request.url();
  };

  return [getTerrainTextureUrl];
};
