import { Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { useGetRoles } from "../../../../../common/components/select/RoleSelect/useGetRoles";
import DeleteRoleContainer from "../components/DeleteRoleContainer";
import AssignRoleContainer from "./../components/AssignRoleContainer";

export const useRolesTableColumns = (): { columns: GridColDef[] } => {
  const { data } = useGetRoles();

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.email}</Typography>
        ) : null,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        const currentRoleName = data?.getRoles?.items
          .find((r): boolean => r?.id === params.row?.roleId)
          ?.name.toUpperCase();

        return <Typography variant="body2">{currentRoleName}</Typography>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        const role = params.row?.roleId;
        const userId = params.row?.rowId;
        const email = params.row?.email;

        return (
          <Stack direction="row" spacing={1}>
            <AssignRoleContainer userId={userId} email={email} role={role} />
            <DeleteRoleContainer userId={userId} email={email} />
          </Stack>
        );
      },
    },
  ];

  return { columns };
};
