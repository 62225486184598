import { useContext } from "react";
import { Box } from "@mui/material";

import ConfiguratorPermissionContext from "../../../context/ConfiguratorPermissionContext/ConfiguratorPermissionContext";

import TowerList from "./TowerList";

import { UIConstants } from "../../../enums";
import AddTowerButton from "./AddTowerButton";

const TowersPanel = () => {
  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  return (
    <Box
      sx={{
        width: `${UIConstants.CAMERA_CONTAINER_WIDTH}px`,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        background: "#F0F4FD",
        borderRadius: "8px",
        padding: "10px 15px",
      }}
    >
      {isAllowEditing && <AddTowerButton />}
      <TowerList />
    </Box>
  );
};

export default TowersPanel;
