import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import type { GetFormQuery, GetFormQueryVariables } from "../../API";
import { GET_FORM } from "../operations/queries";
import { useCustomerIdGuard } from "./useCustomerIdGuard";

// eslint-disable-next-line
export const useGetForm = (formId: string) => {
  const selectedCustomerId = useCustomerIdGuard();

  const itemId = `${selectedCustomerId}#F#${formId}`;

  const { data, loading } = useQuery<GetFormQuery, GetFormQueryVariables>(
    GET_FORM,
    { variables: { customerId: selectedCustomerId, itemId } }
  );

  const form = useMemo(() => data?.getForm, [data?.getForm]);

  return { form, loading } as const;
};
