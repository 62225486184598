import { createTheme } from "@mui/material";

const palette = {
  blue: {
    default: "#3483EB",
    hover: "#0C64D9",
    presed: "#06182D",
    border: "#5298E5",
    borderBg: "#F0F4FD",
    focusBg: "#84B2E4",
    focusBorder: "#5298E5",
  },

  black: {
    default: "black",
  },

  red: {
    default: "#E17575",
    hover: "#CF1E1E",
    presed: " #7C1212",
    border: "#CF1E1E",
    borderBg: "#E17575",
    focusBg: "#E17575",
    focusBorder: "#CF1E1E",
  },

  yellow: {
    default: "#FFD747",
    hover: "#F9CA23",
    presed: "#ECBE1C",
    border: "#FDF5B6",
    borderBg: "#FFFAD6",
    focusBg: "#FFD747",
    focusBorder: "#FFFAD6",
  },

  green: {
    default: "#1FC559",
    hover: "#5CA762",
    presed: "#37663B",
    border: "#CEEED1",
    borderBg: "#F9FDFA",
    focusBg: "#B6E5BB",
    focusBorder: "#CEEED1",
  },
  disabled: {
    main: "#D9D9D9",
    secondary: "#999999",
  },
};

const theme = createTheme({
  palette: {
    blue: {},
    green: {},
    red: {},
  },
  components: {
    // Name of the component
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: "#0D254A",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px solid transparent",
          gap: "6px",
          "& svg ": {
            fill: "white",
          },
          ...(ownerState.color === "yellow" && {
            color: "black !important",
            "& svg ": {
              fill: "black",
            },
          }),
          ...(ownerState.variant === "primary" && {
            backgroundColor: `${palette[ownerState.color].default}`,
            "& svg ": {
              fill: "white",
            },
            "& path ": {
              fill: "white",
            },

            "&:hover": {
              backgroundColor: `${palette[ownerState.color].hover}`,
            },
            "&:focus": {
              backgroundColor: `${palette[ownerState.color].focusBg}`,
              border: `1px solid ${palette[ownerState.color].focusBorder}`,
            },
            "&:active": {
              backgroundColor: `${palette[ownerState.color].presed}`,
            },
          }),
          ...(ownerState.variant === "secondary" && {
            backgroundColor: `${palette[ownerState.color].borderBg}`,
            color: `${
              ownerState.color === "yellow"
                ? "black"
                : palette[ownerState.color].default
            }`,
            "& svg ": {
              fill: `${
                ownerState.color === "yellow"
                  ? "black"
                  : palette[ownerState.color].default
              }`,
            },
            "&:hover": {
              backgroundColor: `${palette[ownerState.color].borderBg}`,
              color: `${
                ownerState.color === "yellow"
                  ? "black"
                  : palette[ownerState.color].hover
              }`,
              "& svg ": {
                fill: `${
                  ownerState.color === "yellow"
                    ? "black"
                    : palette[ownerState.color].hover
                }`,
              },
            },
            "&:focus": {
              backgroundColor: `${palette[ownerState.color].focusBg}`,
              border: `1px solid ${palette[ownerState.color].focusBorder}`,
            },
            "&:active": {
              backgroundColor: `${palette[ownerState.color].borderBg}`,
              color: `${
                ownerState.color === "yellow"
                  ? "black"
                  : palette[ownerState.color].presed
              }`,
              "& svg ": {
                fill: `${
                  ownerState.color === "yellow"
                    ? "black"
                    : palette[ownerState.color].presed
                }`,
              },
            },
          }),
          ...(ownerState.variant === "outline" && {
            backgroundColor: `${palette[ownerState.color].borderBg}`,
            border: `1px solid ${palette[ownerState.color].border}`,
            color: `${palette[ownerState.color].default}`,
            "& svg ": {
              fill: `${palette[ownerState.color].default}`,
            },
            "& path ": {
              fill: `${palette[ownerState.color].default}`,
            },
            "&:hover": {
              color: `${palette[ownerState.color].hover}`,
              border: `1px solid ${palette[ownerState.color].hover}`,
              "& svg ": {
                fill: `${palette[ownerState.color].hover}`,
              },
            },
            "&:focus": {
              backgroundColor: `${palette[ownerState.color].focusBg}`,
              border: `1px solid ${palette[ownerState.color].focusBorder}`,
            },
            "&:active": {
              color: `${palette[ownerState.color].presed}`,
              border: `1px solid ${palette[ownerState.color].presed}`,

              "& svg ": {
                fill: `${palette[ownerState.color].presed}`,
              },
            },
          }),
          ...(ownerState.variant === "textBtn" && {
            color: `${palette[ownerState.color].default}`,
            "& svg ": {
              fill: `${palette[ownerState.color].default}`,
            },
            "&:hover": {
              color: `${palette[ownerState.color].hover}`,
              "& svg ": {
                fill: `${palette[ownerState.color].hover}`,
              },
              "& path ": {
                fill: `${palette[ownerState.color].hover}`,
              },
            },
            "&:focus": {
              border: `1px solid ${palette[ownerState.color].focusBorder}`,
            },
            "&:active": {
              color: `${palette[ownerState.color].presed}`,
              "& svg ": {
                fill: `${palette[ownerState.color].presed}`,
              },
              "& path ": {
                fill: `${palette[ownerState.color].presed}`,
              },
            },
          }),
          ...(ownerState.variant !== "textBtn" &&
            ownerState.disabled && {
              backgroundColor: `${palette.disabled.main} !important`,
              color: `${palette.disabled.secondary} !important`,
              border: "1px solid transparent",
              "& svg ": {
                fill: `${palette.disabled.secondary} !important`,
              },
              "& path ": {
                fill: `${palette.disabled.secondary} !important`,
              },
            }),
          ...(ownerState.variant === "textBtn" &&
            ownerState.disabled && {
              color: `${palette.disabled.secondary} !important`,
              "& svg ": {
                fill: `${palette.disabled.secondary} !important`,
              },
              "& path ": {
                fill: `${palette.disabled.secondary} !important`,
              },
            }),
        }),
      },

      variants: [
        {
          props: { borders: "rectangle" },
          style: {
            borderRadius: "4px",
          },
        },
        {
          props: { borders: "round" },
          style: {
            borderRadius: "30px",
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "6px",
          borderRadius: "4px",
          backgroundColor: "#F0F4FD",
          border: "2px solid transparent",
          color: "#3483EB",

          "& svg ": {
            fill: "#3483EB",
          },
          "& path ": {
            fill: "#3483EB",
          },
          "& circle ": {
            fill: "#3483EB",
          },

          "&:hover": {
            backgroundColor: "#F0F4FD",
            border: "2px solid #F0F4FD",
            color: "#0C64D9",

            "& svg ": {
              fill: "#0C64D9",
            },
            "& path ": {
              fill: "#0C64D9",
            },
            "& circle ": {
              fill: "#0C64D9",
            },
          },

          "&:focus": {
            backgroundColor: "#F0F4FD",
            border: "2px solid #5298E5",
            color: "#3483EB",

            "& svg ": {
              fill: "#3483EB",
            },
            "& path ": {
              fill: "#3483EB",
            },
            "& circle ": {
              fill: "#3483EB",
            },
          },

          "&:active": {
            backgroundColor: "#F0F4FD",
            border: "2px solid #F0F4FD",
            color: "#06182D",

            "& svg ": {
              fill: "#06182D",
            },
            "& path ": {
              fill: "#06182D",
            },
            "& circle ": {
              fill: "#06182D",
            },
          },

          "&.Mui-selected": {
            backgroundColor: "#F0F4FD",
            border: "2px solid #06182D",
            color: "#06182D",

            "& svg ": {
              fill: "#06182D",
            },
            "& path ": {
              fill: "#06182D",
            },
            "& circle ": {
              fill: "#06182D",
            },

            "&:hover": {
              backgroundColor: "#F0F4FD",
              color: "#0C64D9",

              "& svg ": {
                fill: "#0C64D9",
              },
              "& path ": {
                fill: "#0C64D9",
              },
              "& circle ": {
                fill: "#0C64D9",
              },
            },
          },

          "&.Mui-disabled": {
            backgroundColor: "#D9D9D9",
            border: "2px solid #D9D9D9",
            color: "#999999",

            "& svg ": {
              fill: "#999999",
            },
            "& path ": {
              fill: "#999999",
            },
            "& circle ": {
              fill: "#999999",
            },
          },

          ...(ownerState.variant === "outline-text" && {
            backgroundColor: `${palette.blue.borderBg}`,
            border: `1px solid ${palette.blue.border}`,
            color: `${palette.blue.default}`,
            height: "36px",
            padding: "8px",

            "&:hover": {
              backgroundColor: `${palette.blue.borderBg}`,
              color: `${palette.blue.hover}`,
              border: `1px solid ${palette.blue.hover}`,
            },
            "&:focus": {
              backgroundColor: `${palette.blue.focusBg}`,
              border: `1px solid ${palette.blue.focusBorder}`,
            },
            "&:active": {
              color: `${palette.blue.presed}`,
              border: `1px solid ${palette.blue.presed}`,
            },

            "&.Mui-selected": {
              backgroundColor: `${palette.blue.borderBg}`,
              border: "1px solid transparent",
              outline: "2px solid #06182D",
              color: "#06182D",

              "&:hover": {
                backgroundColor: `${palette.blue.borderBg}`,
                color: "#0C64D9",
              },
            },

            "&.Mui-disabled": {
              backgroundColor: "#D9D9D9",
              border: "1px solid #D9D9D9",
              color: "#999999",
            },
          }),

          ...(ownerState.variant === "icon" && {
            width: "36px",
            height: "36px",
            padding: "8px",
          }),

          ...(ownerState.variant === "icon-text" && {
            flexDirection: "column",
            gap: "1px",
            width: "36px",
            height: "36px",
            padding: "4px",
          }),
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16px",
        },
        input: {
          padding: "6px ",
          color: "#202832",
        },
      },
    },
  },
  typography: {
    h1: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "36px",
      lineHeight: "44px",
      "@media (max-width:1500px)": {
        fontSize: "30px",
        lineHeight: "38px",
      },
    },
    h2: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "28px",
      lineHeight: "36px",
      "@media (max-width:1500px)": {
        fontSize: "23px",
        lineHeight: "31px",
      },
    },
    h3: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "38px",
      "@media (max-width:1500px)": {
        fontSize: "15px",
        lineHeight: "33px",
      },
    },
    title: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "28px",
      lineHeight: "36px",
      color: "#202832",
      "@media (max-width:1500px)": {
        fontSize: "23px",
        lineHeight: "31px",
      },
    },
    body20Medium: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "28px",
      "@media (max-width:1500px)": {
        fontSize: "15px",
        lineHeight: "31px",
      },
    },
    body16Medium: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      "@media (max-width:1500px)": {
        fontSize: "14px",
        lineHeight: "22px",
      },
    },
    body16Regular: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      "@media (max-width:1500px)": {
        fontSize: "14px",
        lineHeight: "22px",
      },
    },
    body14Medium: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
    },
    body14Regular: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
    },
    body12Medium: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
    },
    body12Regular: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },
    body10Medium: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "13px",
    },
    body8Regular: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "8px",
      lineHeight: "10px",
    },
    body12Italic: {
      fontFamily: "Inter",
      fontStyle: "italic",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "16px",
    },

    button: {
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "none",
      "@media (max-width:1500px)": {
        fontSize: "12px",
        lineHeight: "18px",
      },
    },
  },
});

export default theme;
