import type { AlertWildCardSubscription } from "../../../API";
import { useLazyGetPaginatedAlertSubscriptions } from "./useLazyGetPaginatedAlertEmailSubscriptions";

interface SubscriptionRowInterface {
  rowId: string;
  email: string;
  customer: string;
  location: string;
  model: string;
}

interface SubscriptionsTableDataInterface {
  rows: SubscriptionRowInterface[];
  loading: boolean;
}

export const useEmailNotificationsTableData =
  (): SubscriptionsTableDataInterface => {
    const { alertSubscriptions, loading } =
      useLazyGetPaginatedAlertSubscriptions();

    const rows: SubscriptionRowInterface[] =
      alertSubscriptions
        ?.map((item: AlertWildCardSubscription): SubscriptionRowInterface => {
          return {
            rowId: `${item.email}+${item.entity}`,
            email: item.email,
            customer:
              item.wildCard == "SUBWILDCARD#CUSTOMER" && item.entity == "C#"
                ? "*"
                : item.entity.split("#")[1],
            location:
              item.wildCard == "SUBWILDCARD#CUSTOMER" &&
              (item.entity != "C#" || item.entity.indexOf("*") != -1)
                ? "*"
                : item.wildCard == "SUBWILDCARD#CUSTOMER" && item.entity == "C#"
                ? "*"
                : item.entity.split("#")[3],
            model:
              item.entity.indexOf("*") != -1 ? item.entity.split("#")[5] : "*",
          };
        })
        .filter((item): SubscriptionRowInterface => item) || [];

    return { rows, loading };
  };
