import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiPaperLightStyleOverrides: ComponentsOverrides<Theme>["MuiPaper"] =
  {
    root: {
      padding: 0,
      border: "1px solid #F4F4F4",
      boxShadow:
        "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
      borderRadius: "8px",
    },
  };
