import { Suspense } from "react";

import { CircularProgress } from "@mui/material";

import withApolloProvider from "./common/providers/ApolloProvider";
import withAuthenticatorProvider from "./common/providers/AuthenticatorProvider";
import withNotificationMessageProvider from "./common/providers/NotificationMessageProvider";
import withThemeProvider from "./common/providers/theme/ThemeProvider";
import AppRoutes from "./routes/AppRoutes";

const App = (): JSX.Element => (
  <Suspense fallback={<CircularProgress />}>
    <AppRoutes />
  </Suspense>
);

export default withApolloProvider(
  withAuthenticatorProvider(
    withThemeProvider(withNotificationMessageProvider(App))
  )
);
