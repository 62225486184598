import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Typography, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import OutlinedBox from "../../../common/components/OutlinedBox";
import FileInput from "../../../common/components/input/FileInput";
import TextInput from "../../../common/components/input/TextInput";
import ModalWindow from "../../../common/components/ModalWindow";
import ModalAlertContext from "../../../common/context/ModalAlertContext/ModalAlertContext";

import { useCreateDTProject } from "../hooks/useCreateDTProject";
import { useGetProjectConfig } from "../hooks/useGetProjectConfig";
import { useUploadFileState } from "../../../common/hooks/useUploadFileState";
import { useUploadDataToS3 } from "../../../common/hooks/useUploadDataToS3";
import { CUSTOMER_ID } from "../hooks/useGetDTProjects";

import { geojsonValidation } from "../../../common/utils/geojsonValidation";

const CreateProjectModal = () => {
  const { createDTProject, loading: createDTProjectLoading } =
    useCreateDTProject();
  const { getProjectInitialConfig, loading: initialConfigLoading } =
    useGetProjectConfig();
  const { isDisplayCreateNewProjectModal, closeCreateNewProjectModal } =
    useContext(ModalAlertContext);
  const navigate = useNavigate();

  const { upalodDataToS3, loading: upalodDataToS3Loading } =
    useUploadDataToS3();

  const [projectNameState, setProjectNameState] = useState({
    name: "",
    error: true,
    trackError: false,
  });

  const {
    onLoadFile: onProjectTerrainLoadFile,
    resetState: resetProjectTerrainFileState,
    state: projectTerrainFileState,
    loading: projectTerrainFileLoading,
  } = useUploadFileState(geojsonValidation);

  const {
    onLoadFile: onProjectShapeLoadFile,
    resetState: resetProjectShapeFileState,
    state: projectShapeFileState,
    loading: projectShapeFileLoading,
  } = useUploadFileState(geojsonValidation);

  const onProjectNameInputChange = event => {
    const value = event.target.value;
    const isError = value.length < 3 || value.length > 30;

    setProjectNameState({
      ...projectNameState,
      error: isError,
      name: value,
      trackError: true,
    });
  };

  const onProjectTerrainFileInputChange = files => {
    onProjectTerrainLoadFile(files[0]);
  };

  const onProjectShapeFileInputChange = files => {
    onProjectShapeLoadFile(files[0]);
  };

  const onCloseModal = () => {
    if (createDTProjectLoading || initialConfigLoading || upalodDataToS3Loading)
      return;

    setProjectNameState({
      name: "",
      error: false,
      trackError: false,
    });

    resetProjectTerrainFileState();
    resetProjectShapeFileState();

    closeCreateNewProjectModal();
  };

  const openCreatedProject = data => {
    const projectId = data.projectId.replace("#", "_");
    navigate(`/webgl-project-editor/${projectId}`);
  };

  const onCreateProjectButton = async () => {
    const initialConfig = await getProjectInitialConfig({
      terrainGeoJsonFile: projectTerrainFileState.fileData,
      shapeGeoJsonFile: projectShapeFileState.fileData,
    });

    const input = {
      customerId: CUSTOMER_ID,
      displayName: projectNameState.name,
    };

    const response = await createDTProject(input);

    if (response.error) {
      console.error(response.error);
    }

    if (response.data) {
      await upalodDataToS3(
        response.data.addDTProject.configURL,
        JSON.stringify(initialConfig),
        "json"
      );

      onCloseModal();

      openCreatedProject(response.data.addDTProject);
    }
  };

  return (
    <ModalWindow isOpen={isDisplayCreateNewProjectModal} onClose={onCloseModal}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "18px" }}>
        <Typography id="modal-modal-title" variant="h3" component="h2">
          Create new project
        </Typography>

        <TextInput
          labelText="Project name"
          error={projectNameState.trackError && projectNameState.error}
          onChange={onProjectNameInputChange}
          required
        />

        <OutlinedBox
          paddingSpacing="19px 0 0"
          label={
            <Typography variant="body16Medium" color="#202832">
              Drag and drop here or browse files
            </Typography>
          }
          borderRadius="4px"
          borderColor="#D9D9D9"
          backgroundColor="#FFFFFF"
        >
          <FileInput
            fileState={projectTerrainFileState}
            onClearInput={resetProjectTerrainFileState}
            onFileUpload={onProjectTerrainFileInputChange}
            fileTypeName="Terrain"
            supportedFormats=".geojson"
            required
            isLoading={projectTerrainFileLoading}
          />
          <FileInput
            fileState={projectShapeFileState}
            onClearInput={resetProjectShapeFileState}
            onFileUpload={onProjectShapeFileInputChange}
            fileTypeName=".shp analogue"
            supportedFormats=".geojson"
            required={false}
            isLoading={projectShapeFileLoading}
          />
        </OutlinedBox>

        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button
            disabled={
              createDTProjectLoading ||
              initialConfigLoading ||
              upalodDataToS3Loading
            }
            onClick={onCloseModal}
            borders="rectangle"
            variant="secondary"
            color="blue"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={
              projectNameState.error ||
              !projectTerrainFileState.file ||
              (projectTerrainFileState.file && projectTerrainFileState.error) ||
              (projectShapeFileState.file && projectShapeFileState.error) ||
              projectTerrainFileLoading ||
              projectShapeFileLoading ||
              createDTProjectLoading ||
              initialConfigLoading ||
              upalodDataToS3Loading
            }
            loading={
              createDTProjectLoading ||
              initialConfigLoading ||
              upalodDataToS3Loading
            }
            onClick={onCreateProjectButton}
            borders="rectangle"
            variant="primary"
            color="blue"
          >
            Create
          </LoadingButton>
        </Stack>
      </Box>
    </ModalWindow>
  );
};

export default CreateProjectModal;
