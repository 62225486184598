import DeviceDynamicDropDownInput from "../components/DynamicInputs/DeviceDynamicDropDownInput";
import DeviceDynamicTextInput from "../components/DynamicInputs/DeviceDynamicTextInput";

const fields = [
  { name: "Camera Type", type: "STRING" },
  { name: "IP", type: "STRING" },
  { name: "Source Video", type: "STRING" },
  { name: "RTSP Host", type: "STRING" },
  {
    name: "Pan Tilt",
    type: "DROPDOWN",
    entries: [
      { value: "TRUE", display: "Yes" },
      { value: "FALSE", display: "No" },
    ],
  },
];

interface DeviceDynamicFieldsProps {
  hasError?: boolean;
  deviceType: string;
  deviceConfig: string;
  setDeviceConfig: (name: string, value: string) => void;
}

const DeviceDynamicFields = ({
  hasError,
  deviceConfig,
  setDeviceConfig,
}: DeviceDynamicFieldsProps): JSX.Element => {
  // TODO (maybe?): Replace switch with Conditional Rendering with Enum.
  return (
    <>
      {fields?.map((field): JSX.Element | null => {
        switch (field?.type) {
          case "STRING":
            return (
              <DeviceDynamicTextInput
                key={field.name}
                hasError={hasError}
                name={field.name}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
              />
            );
          // case "BOOLEAN":
          //   return (
          //     <DeviceDynamicCheckboxInput
          //       key={field.name}
          //       name={field.name}
          //       deviceConfig={deviceConfig}
          //       setDeviceConfig={setDeviceConfig}
          //     />
          //   );

          // case "NUMBER":
          //   return (
          //     <DeviceDynamicNumberInput
          //       key={field.name}
          //       name={field.name}
          //       deviceConfig={deviceConfig}
          //       setDeviceConfig={setDeviceConfig}
          //     />
          //   );

          case "DROPDOWN":
            return (
              <DeviceDynamicDropDownInput
                key={field.name}
                name={field.name}
                entries={field.entries}
                deviceConfig={deviceConfig}
                setDeviceConfig={setDeviceConfig}
              />
            );

          default:
            break;
        }

        return null;
      })}
    </>
  );
};

export default DeviceDynamicFields;
