import { useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { GET_NODES } from "../../../common/operations/queries";
import { errorNotification } from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import { useSelectedLocationNodes } from "../variables/nodes";
import type { GetNodesQuery, GetNodesQueryVariables } from "./../../../API";

interface GetNodesDataInterface {
  data?: GetNodesQuery;
  loading: boolean;
}

export const NODES_TABLE_LIMIT = 10;

export const useGetNodes = (): GetNodesDataInterface => {
  const locationVariable = useSelectedLocationNodes();

  useEffect((): void => {
      fetchNodes(locationVariable?.value ?? "");
  }, [locationVariable?.value]);

  const handleRequestError = async (): Promise<void> => {
    await client.cache.reset();
    errorNotification();
  };

  const [getNodes, { data, loading }] = useLazyQuery<
    GetNodesQuery,
    GetNodesQueryVariables
  >(GET_NODES, {
    fetchPolicy: "network-only",
  });

  const fetchNodes = (locationId: string, nextToken?: string): void => {
    getNodes({
      variables: {
        limit: NODES_TABLE_LIMIT,
        locationId,
        nextToken,
      },
    })
      .then(async (response): Promise<void> => {
        if (response.data?.getNodes.nextToken) {
          fetchNodes(locationId, response.data?.getNodes.nextToken);
        }

        if (response.error) {
          await handleRequestError();
        }
      })
      .catch(async (): Promise<void> => {
        await handleRequestError();
      });
  };

  return { data, loading };
};
