import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, ToggleButton, Typography } from "@mui/material";

import { DeleteIcon } from "../../../common/assets/icons/svgAssets";

import ModalAlertContext from "../../../common/context/ModalAlertContext/ModalAlertContext";

const ProjectCard = ({ data }) => {
  const navigate = useNavigate();
  const { openDeleteProjectModal, selectedProjectData } =
    useContext(ModalAlertContext);

  const handleOnDeleteButton = e => {
    e.preventDefault();
    e.stopPropagation();
    openDeleteProjectModal(data);
  };

  const handleOnOpenProject = () => {
    const projectId = data.projectId.replace("#", "_");
    navigate(`/webgl-project-editor/${projectId}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "calc((100% - 60px)/4)",
        minWidth: "450px",
        height: "94px",
        border: "1px solid #98A7BC",
        borderRadius: "4px",
        color: "#202832",
        padding: "35px 20px",
        "&:hover": {
          border: "1px solid #0C64D9",
        },
        "&:active": {
          border: "1px solid #06182D",
        },
      }}
      onClick={handleOnOpenProject}
    >
      <Typography variant="body16Medium">{data.displayName}</Typography>
      <ToggleButton
        variant="icon-text"
        value="delete-project"
        selected={
          selectedProjectData &&
          selectedProjectData.projectId === data.projectId
        }
        onClick={handleOnDeleteButton}
      >
        <DeleteIcon />
        <Typography variant="body8Regular" component="p">
          Delete
        </Typography>
      </ToggleButton>
    </Box>
  );
};

export default ProjectCard;
