import { Box } from "@mui/material";

const MainViewport = ({ viewportRef, canvasRef }) => {
  return (
    <Box
      ref={viewportRef}
      sx={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& #three-canvas-1": { width: "100%", height: "100%" },
      }}
    >
      <canvas ref={canvasRef} id="three-canvas-1"></canvas>
    </Box>
  );
};

export default MainViewport;
