import { useEffect } from "react";
import { Select, InputLabel, MenuItem, SelectChangeEvent } from "@mui/material";

interface Entry {
  value: string;
  display: string;
}
interface DeviceDynamicDropdownInputProps {
  name: string;
  entries: Entry[] | null | undefined;
  deviceConfig: any;
  setDeviceConfig: (name: string, value: string) => void;
}

const DeviceDynamicDropdownInput = ({
  name,
  entries,
  deviceConfig,
  setDeviceConfig,
}: DeviceDynamicDropdownInputProps): JSX.Element | null => {
  const handleEntryChange = (e: SelectChangeEvent<any>): void => {
    setDeviceConfig(name, e.target.value as string);
  };

  useEffect((): void => {
    if (!deviceConfig[name] && entries && entries?.length) {
      setDeviceConfig(name, entries[0]?.value ?? "");
    }
  }, []);

  if (!deviceConfig[name]) return null;

  return (
    <div>
      <InputLabel id={`${name}-label`}>{name}</InputLabel>
      <Select
        labelId={`${name}-label`}
        fullWidth
        id={name}
        value={deviceConfig[name]}
        onChange={handleEntryChange}
      >
        {entries &&
          entries.map(
            (entry, index): JSX.Element => (
              <MenuItem key={index} value={entry.value}>
                {entry.display}
              </MenuItem>
            )
          )}
      </Select>
    </div>
  );
};

export default DeviceDynamicDropdownInput;
