import { Paper } from "@mui/material";

import LocationsPageHeader from "./components/LocationsPageHeader";
import LocationsTable from "./components/LocationsTable";

const LocationsPage = (): JSX.Element => {
  return (
    <Paper>
      <LocationsPageHeader />
      <LocationsTable />
    </Paper>
  );
};

export default LocationsPage;
