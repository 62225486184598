import { gql, useMutation } from "@apollo/client";

import { ADD_DT_PROJECT } from "../../../../common/operations/mutations";

export const useCreateDTProject = () => {
  const [addDTProject, { data, loading, error }] = useMutation(ADD_DT_PROJECT);

  const createDTProject = async input => {
    return await addDTProject({
      variables: {
        input,
      },
      update(cache, response) {
        cache.modify({
          fields: {
            getDTCustomerProjects(existing) {
              const newDTProjectRef = cache.writeFragment({
                data: response.data?.addDTProject,
                fragment: gql`
                  fragment NewDTProject on DTProject {
                    id
                    configURL
                    customerId
                    displayName
                    projectId
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newDTProjectRef],
              };
            },
          },
        });
      },
    });
  };

  return { createDTProject, data, loading, error };
};
