import { useState } from "react";
import axios from "axios";

export const useUploadDataToS3 = () => {
  const [loading, setLoading] = useState(false);

  const upalodDataToS3 = async (url, data, contentType) => {
    setLoading(true);

    const headers = {
      "Content-Type": contentType,
    };

    // Make the PUT request to the pre-signed URL.
    let response;

    try {
      response = await axios.put(url, data, { headers });
    } catch (error) {
      console.error("Error useUploadDataToS3", error);
    } finally {
      setLoading(false);
    }

    return response;
  };

  return { upalodDataToS3, loading };
};
