import React from "react";
import { Waypoint } from "react-waypoint";

import { NetworkStatus, useReactiveVar } from "@apollo/client";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { uniqBy } from "lodash";

import {
  type GetEventsPendingHumanValidationQuery,
  ValidationState,
} from "../../../API";
import { notificationVariable } from "../../../common/variables/notification";
import {
  type PendingEventRow,
  useGetHumanValidatorTableData,
} from "../hooks/useHumanValidatorTableData";
import { useTakeToValidate } from "../hooks/useTakeToValidate";
import { humanValidatorTableVariable } from "../variables/humanValidatorTable";

const PRELOAD_ITEMS = 25;

const HumanValidatorTable = (): JSX.Element => {
  const { tableData, showDetails } = useReactiveVar(
    humanValidatorTableVariable
  );

  const { rows, nextToken, loading, networkStatus, fetchMore } =
    useGetHumanValidatorTableData();

  const { takeToValidate } = useTakeToValidate();

  const apiMutationTakeEventToValidate = (
    row: PendingEventRow,
    customerId: string,
    eventId: string
  ): void => {
    takeToValidate({
      variables: {
        customerId,
        eventId,
      },
      update: (cache): void => {
        cache.evict({
          id: cache.identify({
            __typename: "HumanValidationEvent",
            _id: row.rowId,
          }),
        });
      },
    })
      .then((response): void => {
        humanValidatorTableVariable({
          tableData: {
            ...row,
            ...response.data?.takeEventToValidate,
          },
          showDetails: true,
        });

        notificationVariable({
          isOpen: true,
          message: `Event ${row.rowId} was taken for validation`,
          severity: "info",
        });
      })
      .catch((error): void => {
        console.error(error);
      });
  };

  const handleRowClick = (
    _event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: PendingEventRow
  ): void => {
    if (showDetails) {
      return;
    }

    apiMutationTakeEventToValidate(row, row.customerId, row.eventId);
  };

  const networkStatusChanged = [NetworkStatus.fetchMore].includes(
    networkStatus
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Event ID</TableCell>
          <TableCell align="right">Date</TableCell>
          <TableCell align="right">Model</TableCell>
          <TableCell align="right">Location</TableCell>
          <TableCell align="right">Camera</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {React.Children.toArray(
          rows.map((row, index): JSX.Element => {
            const date = row.date ? new Date(row.date).toLocaleString() : "n/a";

            return (
              <React.Fragment key={row.rowId}>
                <TableRow
                  key={row.rowId}
                  hover
                  selected={row.rowId === tableData?.rowId}
                  onClick={(event): void => handleRowClick(event, row)}
                  sx={{
                    cursor:
                      showDetails ||
                      row.validationState === ValidationState.IN_VALIDATION
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  <TableCell>{row.rowId}</TableCell>
                  <TableCell align="right">{date}</TableCell>
                  <TableCell align="right">{row.model}</TableCell>
                  <TableCell align="right" sx={{ wordBreak: "break-all" }}>
                    {row.locationId}
                  </TableCell>
                  <TableCell align="right">{row.cameraName}</TableCell>
                </TableRow>
                <Waypoint
                  onEnter={(): void => {
                    if (
                      nextToken &&
                      (index === rows.length - PRELOAD_ITEMS ||
                        rows.length < PRELOAD_ITEMS)
                    ) {
                      void fetchMore({
                        variables: {
                          nextToken,
                        },
                        updateQuery: (
                          pv,
                          { fetchMoreResult }
                        ): GetEventsPendingHumanValidationQuery => {
                          const items = uniqBy(
                            [
                              ...(pv?.getEventsPendingHumanValidation.items ||
                                []),
                              ...fetchMoreResult.getEventsPendingHumanValidation
                                .items,
                            ],
                            (item): string | undefined => item?.id
                          );

                          return {
                            getEventsPendingHumanValidation: {
                              ...fetchMoreResult.getEventsPendingHumanValidation,
                              items,
                              nextToken:
                                fetchMoreResult.getEventsPendingHumanValidation
                                  .nextToken,
                            },
                          };
                        },
                      });
                    }
                  }}
                />
              </React.Fragment>
            );
          })
        )}

        {(loading || networkStatusChanged) && (
          <TableRow>
            <TableCell colSpan={5}>
              {[...Array(5)].map(
                (_, index): JSX.Element => (
                  <Skeleton key={index} variant="rectangular" sx={{ my: 3 }} />
                )
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default HumanValidatorTable;
