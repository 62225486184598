import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import StyledDialog from "../../../../common/components/dialog/StyledDialog";
import Thumbnail from "../../../../common/components/media/Thumbnail";
import { S3Helper } from "../../../../common/utils/s3helper";
import { useGetTimestreamData } from "../hooks/useGetTimestreamData";

import { useState } from "react";

function GasLeakData(): JSX.Element {
  // react state
  const [open, setOpen] = useState(false);
  const [objectUrl, setObjectUrl] = useState("");

  // react hooks
  const { data: getTimestreamData, loading } = useGetTimestreamData();

  const handleMediaClick = async (mediaLink: string): Promise<any> => {
    setOpen(true);
    const url = await S3Helper.getObject(mediaLink ?? "");
    setObjectUrl(url);
  };

  const closeDialog = (): void => {
    setOpen(false);
    setObjectUrl("");
  };

  const handleSubmit = (): void => {
    setOpen(false);
    setObjectUrl("");
  };

  return (
    <>
      <StyledDialog
        open={open}
        title="View Media"
        onClose={closeDialog}
        SubmitButtonProps={{
          loading,
          onSubmit: handleSubmit,
          title: "close",
        }}
      >
        <Thumbnail src={objectUrl} controls />
      </StyledDialog>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Event ID</TableCell>
            <TableCell align="right">Time</TableCell>
            <TableCell align="right">Zone Id</TableCell>
            <TableCell align="right">Location</TableCell>
            <TableCell align="right">Volume</TableCell>
            <TableCell align="right">Media Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getTimestreamData?.map((row: any): JSX.Element => {
            return (
              <TableRow key={row[0]?.ScalarValue} hover>
                <TableCell>{row[0]?.ScalarValue}</TableCell>
                <TableCell align="right">{row[1]?.ScalarValue}</TableCell>
                <TableCell align="right">{row[3]?.ScalarValue}</TableCell>
                <TableCell align="right">{row[2]?.ScalarValue}</TableCell>
                <TableCell align="right">{row[4]?.ScalarValue}</TableCell>
                <TableCell align="right">
                  <div
                    onClick={(): any => handleMediaClick(row[5]?.ScalarValue)}
                    style={{
                      cursor: "pointer",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    Media
                  </div>
                </TableCell>
              </TableRow>
            );
          })}

          {loading && (
            <TableRow>
              <TableCell colSpan={5}>
                {[...Array(5)].map(
                  (_, index): JSX.Element => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{ my: 3 }}
                    />
                  )
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
}

export default GasLeakData;
