import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";

import { GET_ZONES } from "../../../../common/operations/queries";
import { errorNotification } from "../../../../common/variables/notification";
import client from "../../../../configs/apolloClient";
import { GetZonesQuery, GetZonesQueryVariables } from "./../../../../API";

export type useGetZonesType = {
  locationId?: string;
  locationName?: string;
  nodeId?: string;
  nodeName?: string;
};

export const ZONES_TABLE_LIMIT = 50;

export const useGetZones = (serviceId: string) => {
  useEffect(() => {
    if (serviceId) {
      fetchZones(serviceId);
    }
  }, [serviceId]);

  const handleRequestError = () => {
    client.cache.reset();
    errorNotification();
  };

  const [getZones, { data, loading }] = useLazyQuery<
    GetZonesQuery,
    GetZonesQueryVariables
  >(GET_ZONES, {
    fetchPolicy: "network-only",
  });

  const fetchZones = (
    serviceId: string,
    nextToken?: string,
    locationId?: string | null,
    nodeId?: string | null
  ) => {
    getZones({
      variables: {
        limit: ZONES_TABLE_LIMIT,
        serviceId: serviceId,
        nextToken,
      },
    })
      .then(response => {
        if (response.data?.getZones.nextToken) {
          fetchZones(
            serviceId,
            response.data?.getZones.nextToken,
            locationId,
            nodeId
          );
        }

        if (response.error) {
          handleRequestError();
        }
      })
      .catch(() => {
        handleRequestError();
      });
  };

  return { data, loading };
};
