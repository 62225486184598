import { Grid, Paper, Typography } from "@mui/material";

import ZonesTable from "../components/ZonesTable";

const ZonesTableContainer = ({
  serviceId,
  handleRowClick,
  thingName,
}: {
  serviceId: string;
  handleRowClick: any;
  thingName?: string;
}): JSX.Element => {
  return (
    <Paper sx={{ padding: "1em" }} elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs="auto"></Grid>
      </Grid>
      <br />
      <Typography>Zones List</Typography>
      {/* deviceId hardcoded for now */}
      <ZonesTable
        handleRowClick={handleRowClick}
        serviceId={serviceId}
        thingName={thingName}
      />
    </Paper>
  );
};

export default ZonesTableContainer;
