import { useState } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Button } from "@mui/material";

import { AddServiceInput } from "../../../API";
import StyledDialog from "../../../common/components/dialog/StyledDialog";
import { useDialog } from "../../../common/components/dialog/useDialog";
import { useCustomerIdGuard } from "../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useCreateService } from "../hooks/useCreateService";
import {
  aiManagerVariable,
  defualtAIManagerFormState,
  useSelectedServiceModel,
} from "../variables/modelManager";
import CreateServiceForm from "./CreateServiceForm";

const CreateServiceDialog = (): JSX.Element => {
  const selectedServiceVars = useSelectedServiceModel();
  const selectedCustomerId = useCustomerIdGuard();

  const configuration = (selectedServiceVars as any)[
    selectedServiceVars.serviceType
  ];

  const { open, handleDialogOpen, handleDialogClose } = useDialog();
  const { createService, loading } = useCreateService();

  const [showError, setShowError] = useState(false);

  const closeDialog = (): void => {
    handleDialogClose();
    aiManagerVariable({
      ...defualtAIManagerFormState,
      locationId: selectedServiceVars.locationId,
    });
  };

  const openDialog = (): void => {
    handleDialogOpen();
    setShowError(false);
    aiManagerVariable({
      ...defualtAIManagerFormState,
      locationId: selectedServiceVars.locationId,
    });
  };

  const handleSubmit = (): void => {
    const input: AddServiceInput = {
      serviceType: selectedServiceVars.serviceType,
      nodeId: selectedServiceVars.nodeId,
      deviceId: selectedServiceVars.deviceId,
      locationId: selectedServiceVars.locationId?.value ?? "",
      customerId: selectedCustomerId,
      configuration: configuration
        ? JSON.stringify(
            (selectedServiceVars as any)[selectedServiceVars.serviceType]
          )
        : "{}",
    };

    if (input.locationId === "") {
      setShowError(true);
      return;
    }

    if (input.nodeId === "") {
      setShowError(true);
      return;
    }

    if (input.deviceId === "") {
      setShowError(true);
      return;
    }

    if (input.serviceType === "") {
      setShowError(true);
      return;
    }

    createService(input)
      .then((response): void => {
        if (response?.data) {
          successNotification();
          handleDialogClose();
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      });
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        endIcon={<AddOutlinedIcon />}
        onClick={openDialog}
      >
        Create
      </Button>
      <StyledDialog
        open={open}
        title="Create Model Service Instance"
        maxWidth="md"
        onClose={closeDialog}
        SubmitButtonProps={{
          loading,
          onSubmit: handleSubmit,
        }}
      >
        <CreateServiceForm showError={showError} />
      </StyledDialog>
    </>
  );
};

export default CreateServiceDialog;
