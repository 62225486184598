import { Box } from "@mui/material";

const View360GridDivider = () => (
  <Box
    sx={{
      position: "absolute",
      top: 0,
      display: "flex",
      width: "100%",
      height: "100%",
    }}
  >
    <Box
      sx={{
        width: "25%",
        height: "100%",
        border: "2px solid #3C4F66",
      }}
    ></Box>
    <Box
      sx={{
        width: "25%",
        height: "100%",
        border: "2px solid #3C4F66",
      }}
    ></Box>
    <Box
      sx={{
        width: "25%",
        height: "100%",
        border: "2px solid #3C4F66",
      }}
    ></Box>
    <Box
      sx={{
        width: "25%",
        height: "100%",
        border: "2px solid #3C4F66",
      }}
    ></Box>
  </Box>
);

export default View360GridDivider;
