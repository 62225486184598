import { gql } from "@apollo/client";

import {
  addAlertEmailNotification,
  addAlertSubscription,
  addAlertWildCardSubscription,
  addAnnotation,
  addCustomer,
  addDTProject,
  addDevice,
  addDeviceMake,
  addDomain,
  addLocation,
  addNode,
  addService,
  addZone,
  assignFirstRoleToUser,
  changeNodeStatus,
  deleteAllAnnotations,
  deleteDTProject,
  deleteDeviceMake,
  deleteService,
  deleteUser,
  deleteZone,
  overruleHumanValidatedEvents,
  publishNode,
  setPendingState,
  takeEventToValidate,
  updateAdminEmailAlert,
  updateAdminSystemAlert,
  updateAlert,
  updateAlertStatus,
  updateDTProject,
  updateDevice,
  updateDeviceMake,
  updateProfile,
  updateService,
  updateShadowNode,
  updateUser,
  updateUserAlert,
  updateZone,
  validateEvent,
} from "../../graphql/mutations";

export const ADD_CUSTOMER = gql`
  ${addCustomer}
`;
export const ADD_DOMAIN = gql`
  ${addDomain}
`;
export const ADD_LOCATION = gql`
  ${addLocation}
`;
export const ADD_NODE = gql`
  ${addNode}
`;
export const ADD_SERVICE = gql`
  ${addService}
`;
export const ADD_DEVICE = gql`
  ${addDevice}
`;
export const ADD_ALERT_EMAIL_NOTIFICATION = gql`
  ${addAlertEmailNotification}
`;
export const ADD_DEVICE_MAKE = gql`
  ${addDeviceMake}
`;
export const UPDATE_DEVICE_MAKE = gql`
  ${updateDeviceMake}
`;
export const DELETE_DEVICE_MAKE = gql`
  ${deleteDeviceMake}
`;
export const OVERRULE_HV_EVENTS = gql`
  ${overruleHumanValidatedEvents}
`;
export const PUBLISH_NODE = gql`
  ${publishNode}
`;
export const CHANGE_NODE_STATUS = gql`
  ${changeNodeStatus}
`;
export const UPDATE_PROFILE = gql`
  ${updateProfile}
`;
export const VALIDATE_EVENT = gql`
  ${validateEvent}
`;
export const TAKE_EVENT_TO_VALIDATE = gql`
  ${takeEventToValidate}
`;
export const SET_PENDING_STATE = gql`
  ${setPendingState}
`;
export const UPDATE_ZONE = gql`
  ${updateZone}
`;
export const UPDATE_ALERT = gql`
  ${updateAlert}
`;
export const UPDATE_ALERT_STATUS = gql`
  ${updateAlertStatus}
`;
export const UPDATE_ADMIN_EMAIL_ALERT = gql`
  ${updateAdminEmailAlert}
`;
export const UPDATE_ADMIN_SYSTEM_ALERT = gql`
  ${updateAdminSystemAlert}
`;
export const UPDATE_USER_ALERT = gql`
  ${updateUserAlert}
`;
export const DELETE_ZONE = gql`
  ${deleteZone}
`;
export const ADD_ANNOTATION = gql`
  ${addAnnotation}
`;
export const ADD_ALERT_SUBSCRIPTION = gql`
  ${addAlertSubscription}
`;
export const ADD_ALERT_WILDCARD_SUBSCRIPTION = gql`
  ${addAlertWildCardSubscription}
`;
export const ADD_ZONE = gql`
  ${addZone}
`;
export const DELETE_ALL_ANNOTATIONS = gql`
  ${deleteAllAnnotations}
`;
export const UPDATE_SHADOW = gql`
  ${updateShadowNode}
`;

export const ADD_DT_PROJECT = gql`
  ${addDTProject}
`;

export const DELETE_DT_PROJECT = gql`
  ${deleteDTProject}
`;

export const UPDATE_DT_PROJECT = gql`
  ${updateDTProject}
`;

export const UPDATE_DEVICE = gql`
  ${updateDevice}
`;

export const UPDATE_USER_ROLE = gql`
  ${updateUser}
`;

export const DELETE_USER = gql`
  ${deleteUser}
`;

export const ASSIGN_FIRST_ROLE_TO_USER = gql`
  ${assignFirstRoleToUser}
`;

export const DELETE_SERVICE = gql`
  ${deleteService}
`;
export const UPDATE_SERVICE = gql`
  ${updateService}
`;
