import env from "../../../../environment.json";
import appSyncConfig from "../aws-exports.json";

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { Auth } from "aws-amplify";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

import { getJwtToken } from "../common/utils/getJwtToken";

const url = appSyncConfig.aws_appsync_graphqlEndpoint;
const region = appSyncConfig.aws_appsync_region;

const httpLink = new HttpLink({
  uri: url,
});

const auth = {
  type: appSyncConfig.aws_appsync_authenticationType,
  jwtToken: async () => {
    if (window.location.hostname.includes("localhost")) {
      if (env?.stacks.webapp.domain.subdomains.length) {
        const targetDomain = `${env.name}.${env.stacks.webapp.domain.apex}`;

        Auth.configure({
          ...appSyncConfig,
          endpoint: `https://${targetDomain}/auth`,
        });
      }
    } else {
      Auth.configure({
        ...appSyncConfig,
        endpoint: `https://${window.location.hostname}/auth`,
      });
    }

    return await getJwtToken();
  },
};

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

const getMergedItems = refKey => {
  return {
    // NOTE: Don't cache separate results based on any of this field's arguments.
    keyArgs: false,
    merge(existing, incoming, { variables, cache }) {
      if (!incoming) return existing;
      if (!existing) return incoming; // existing will be empty the first time

      // eslint-disable-next-line
      const { items, ...rest } = incoming;

      if (refKey) {
        const [prefix, prefixValue] = variables[refKey].split("#");

        const existingItem = existing?.items ? existing?.items[existing?.items?.length - 1] : existing[existing.length - 1];
        const resetCache = existingItem?.__ref.split(prefix).indexOf(prefixValue) === -1;

        if (resetCache) {
          cache.reset();

          return incoming;
        }

        if (existing.items.length && incoming.length === 0) {
          return incoming;
        }
      }

      // NOTE: fixed duplication issues when user goes trough navigation and back to Locations page
      // this a quick solution, bad performance for >10k items
      // possible solution: https://stackoverflow.com/a/68734886/6772862
      const ids = new Set(existing.items.map(d => d.__ref));
      const merged = [
        ...existing.items,
        ...incoming.items.filter(d => !ids.has(d.__ref)),
      ];

      const result = {
        ...rest,
        items: merged,
      };

      return result;
    },
  };
};

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getAllWildCardsForSubscriptionEntity: getMergedItems(),
          getLocations: getMergedItems("customerId"),
          getDevices: getMergedItems("customerId"),
          // there is an issue with merging for annotations, where changing query params still shows old results; commenting out merge policy for now
          // getAnnotations: getMergedItems("serviceId"),
          getZones: getMergedItems("serviceId"),
          getDevicesMakes: getMergedItems(),
          getRoles: getMergedItems(),
          getHumanValidatedEventsForAuditing: getMergedItems("customerId"),
          getServices: getMergedItems("customerId"),
          getDTCustomerProjects: getMergedItems("customerId"),
          getUsersByCompany: getMergedItems("customerId"),
          getAllAlerts: getMergedItems("customerId"),
          getUserAlerts: getMergedItems("customerId"),
        },
      },
    },
  }),
});

export default client;
