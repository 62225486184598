import { makeVar, useReactiveVar } from "@apollo/client";

import { AutocompleteOptionType } from "../../../../../common/models/autocomplete";
import { IFormValidationState } from "../../../../../common/models/formValidation";

interface IEmailAlertForm {
  alertName: string;
  location: AutocompleteOptionType;
  model: string;
  additionalEmails: string[];
}

export const defaultEmailAlertFormVariables: IEmailAlertForm = {
  alertName: "",
  location: null,
  model: "",
  additionalEmails: [""],
};

interface IEmailAlertFormValidationState {
  alertName: IFormValidationState;
}

export const defaultEmailAlertFormValidationState: IEmailAlertFormValidationState =
  {
    alertName: {
      hasError: false,
      errorMessage: "",
    },
  };

export const IEmailAlertFormRules = {
  alertName: {
    required: true,
  },
};

export const emailAlertFormVariables = makeVar<IEmailAlertForm>(
  defaultEmailAlertFormVariables
);

export const useEmailAlertFormVariables = () =>
  useReactiveVar(emailAlertFormVariables);

export const emailAlertFormValidationState =
  makeVar<IEmailAlertFormValidationState>(defaultEmailAlertFormValidationState);

export const useEmailAlertFormValidationState = () =>
  useReactiveVar(emailAlertFormValidationState);
