import { ApolloError, Reference, useMutation } from "@apollo/client";

import {
  DeleteUserMutation,
  DeleteUserMutationVariables,
} from "../../../../../API";
import { useCustomerIdGuard } from "../../../../../common/hooks/useCustomerIdGuard";
import { DELETE_USER } from "../../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../../common/variables/notification";

export const useDeleteUser = () => {
  const selectedCustomerId = useCustomerIdGuard();

  const [deleteUser, { data, loading }] = useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DELETE_USER);

  const deleteUserById = (userId: string, userPoolId: string) => {
    deleteUser({
      variables: {
        customerId: selectedCustomerId,
        userId,
        userPoolId,
      },
      onCompleted: response => {
        if (response.deleteUser) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);
        console.error(error.message);
      },
      update(cache) {
        cache.modify({
          fields: {
            getUsersByCompany(existingRefs, { readField }) {
              return existingRefs?.items.filter(
                (userRef: Reference): boolean =>
                  userId !== readField("id", userRef)
              );
            },
          },
        });
      },
    });
  };

  return { deleteUserById, data, loading };
};
