import { useContext } from "react";
import { Box, Collapse, ListItem } from "@mui/material";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";

import TowerPanelListItemHeader from "./TowerPanelListItemHeader";
import TowerSideList from "./TowerSideList";

const TowerListItem = ({ data, open }) => {
  const {
    sceneStarted,
    handleSetActiveObjectId,
    activeTowerSideIndex,
    handleSetHoveredObjectId,
  } = useContext(ProjectContext);

  const handleOnItemClick = () => {
    sceneStarted && handleSetActiveObjectId(open ? null : data.id);
  };

  const handleOnItemOver = () => {
    sceneStarted && handleSetHoveredObjectId(data.id);
  };

  const handleItemLeaved = () => {
    sceneStarted && handleSetHoveredObjectId(null);
  };

  return (
    <ListItem
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        background: open ? "rgba(255, 255, 255, 0.60)" : "transparent",
        padding: "0px",
        borderRadius: "4px",
      }}
      onMouseOver={handleOnItemOver}
      onMouseLeave={handleItemLeaved}
    >
      <TowerPanelListItemHeader
        withCollapseIcon
        open={open}
        selected={open && !activeTowerSideIndex}
        text={data.name}
        onItemClick={handleOnItemClick}
      />
      <Collapse in={open} timeout="auto" sx={{ width: "100%" }}>
        <Box sx={{ padding: "9px 0 0 24px" }}>
          <TowerSideList towerData={data} />
        </Box>
      </Collapse>
    </ListItem>
  );
};

export default TowerListItem;
