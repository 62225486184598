import { useContext, useState } from "react";
import { Box, Collapse, ListItem } from "@mui/material";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";

import TowerPanelListItemHeader from "./TowerPanelListItemHeader";
import TowerCameraList from "./TowerCameraList";
import AddCameraModal from "../../../../pages/project-editor/components/AddCameraModal";

const TowerSideListItem = ({ sideData, towerId, open }) => {
  const {
    sceneStarted,
    addCamera,
    handleSetActiveTowerSideIndex,
    handleSetHoveredTowerSideIndex,
    activeCameraId,
    cameras,
  } = useContext(ProjectContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const sideCamerasData = cameras.filter(
    camera => camera.sideIndex === sideData.index && camera.towerId === towerId
  );

  const handleOnItemClick = () => {
    sceneStarted && handleSetActiveTowerSideIndex(open ? null : sideData.index);
  };

  const handleOnItemOver = () => {
    sceneStarted && handleSetHoveredTowerSideIndex(sideData.index);
  };

  const handleItemLeaved = () => {
    sceneStarted && handleSetHoveredTowerSideIndex(null);
  };

  const handleAddCamera = cameraData => {
    const data = {
      ...cameraData,
      sideIndex: sideData.index,
      towerId: towerId,
    };

    addCamera(data);
    setIsModalOpen(false);
  };

  const handleOnAddClick = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <ListItem
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        background: "transparent",
        padding: "0px",
      }}
      onMouseOver={handleOnItemOver}
      onMouseLeave={handleItemLeaved}
    >
      <TowerPanelListItemHeader
        withCollapseIcon
        withAddIcon
        open={open}
        selected={open && !activeCameraId}
        text={`${sideData.index}. Side (${
          sideCamerasData && sideCamerasData.length
        })`}
        onItemClick={handleOnItemClick}
        onAddClick={handleOnAddClick}
      />
      <Collapse in={open} timeout="auto" sx={{ width: "100%" }}>
        <Box sx={{ padding: "9px 0 0 24px" }}>
          <TowerCameraList camerasData={sideCamerasData} />
        </Box>
      </Collapse>
      <AddCameraModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onAdd={handleAddCamera}
      />
    </ListItem>
  );
};

export default TowerSideListItem;
