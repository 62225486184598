import { LoadingButton } from "@mui/lab";
import { Box, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { AddAlertWildCardSubscriptionInput } from "../../../API";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useCreateWildCardSubscription } from "../hooks/useCreateWildCardSubscription";

const EmailNotificationsPageContent = (): JSX.Element => {
  const {
    createWildCardSubscription,
    loading: createWildCardSubscriptionLoading,
  } = useCreateWildCardSubscription();

  const [email, setEmail] = useState("");
  const [customer, setCustomer] = useState("");
  const [location, setLocation] = useState("");
  const [model, setModel] = useState("");

  const saveEntity = (): void => {
    let wildCard = "";
    let entity = "";
    if (customer == "" && location == "" && model == "") {
      wildCard = "CUSTOMER";
      entity = "C#";
    } else if (customer != "" && location == "" && model == "") {
      wildCard = "CUSTOMER";
      entity = `C#${customer}`;
    } else if (customer != "" && location != "" && model == "") {
      wildCard = "CUSTOMER";
      entity = `C#${customer}#L#${location}`;
    } else if (customer != "" && location == "" && model != "") {
      wildCard = "CUSTOMER";
      entity = `C#${customer}#L#*#M#${model}`;
    }
    const input: AddAlertWildCardSubscriptionInput = {
      email: email,
      entity,
      wildCard,
    };

    createWildCardSubscription(input)
      .then((response): void => {
        if (response?.data) {
          successNotification();
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error: any): void => {
        errorNotification();
        console.error(error);
      });
  };

  const loading = createWildCardSubscriptionLoading;

  return (
    <Grid spacing={4} direction="column">
      <Box>
        <Grid item xs={6}>
          <br />
          <Grid container justifyContent="left" alignItems="flex-start">
            <TextField
              margin="dense"
              required
              style={{ minWidth: 200 }}
              id="Email"
              label="Email"
              value={email}
              onChange={(e): void => setEmail(e.target.value)}
            />
            <TextField
              margin="dense"
              style={{ minWidth: 200 }}
              id="Customer"
              label="Customer"
              value={customer}
              onChange={(e): void => setCustomer(e.target.value)}
            />
            <TextField
              margin="dense"
              style={{ minWidth: 200 }}
              id="Location"
              label="Location"
              value={location}
              onChange={(e): void => setLocation(e.target.value)}
            />
            <TextField
              margin="dense"
              style={{ minWidth: 200 }}
              id="Model"
              label="Model"
              value={model}
              onChange={(e): void => setModel(e.target.value)}
            />
          </Grid>
          <br />
        </Grid>
        <Grid item xs={6}>
          <br />
          <Grid container justifyContent="flex-end">
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              variant="contained"
              color="success"
              onClick={saveEntity}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default EmailNotificationsPageContent;
