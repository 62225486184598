import { Box, Paper } from "@mui/material";

import HumanValidatorPageHeader from "./components/HumanValidatorPageHeader";
import HumanValidatorTable from "./components/HumanValidatorTable";
import HumanValidatorTableItemDetailsContent from "./components/HumanValidatorTableItemDetailsContent";

const HumanValidatorPage = (): JSX.Element => {
  return (
    <Box sx={{ display: "flex" }}>
      <Box
        sx={{
          flexGrow: 1,
          backgroundColor: "transparent",
          paddingRight: "1.25em",
        }}
      >
        <Paper sx={{ padding: "1em", marginBottom: "2em", marginTop: "2em" }}>
          <HumanValidatorPageHeader />
        </Paper>
        <Paper sx={{ padding: "1em" }}>
          <HumanValidatorTable />
        </Paper>
      </Box>
      <HumanValidatorTableItemDetailsContent />
    </Box>
  );
};

export default HumanValidatorPage;
