import * as THREE from "three";
import { pairs } from "d3";
import { mesh } from "topojson-client";

import { REAL_EARTH_RADIUS_IN_KM } from "./Constants";

export const getCoordinatesFromLatLng = (
  height,
  radius,
  latitude,
  longitude
) => {
  const lambda = THREE.MathUtils.degToRad(longitude);
  const phi = THREE.MathUtils.degToRad(latitude);

  return new THREE.Vector3(
    (height + radius) * Math.cos(phi) * Math.cos(lambda),
    (height + radius) * Math.sin(phi),
    -(height + radius) * Math.cos(phi) * Math.sin(lambda)
  );
};

export const getLatLonFromCoordinates = (radius, coordinates) => {
  const { x, y, z } = coordinates;

  const latitude = THREE.MathUtils.radToDeg(Math.asin(y / radius));
  const longitude = -THREE.MathUtils.radToDeg(Math.atan2(z, x));

  return [latitude, longitude];
};

export const getPointsFromMultilinestring = (multilinestring, height = 0) => {
  const points = [];

  multilinestring.coordinates.forEach(line => {
    pairs(
      line.map(data => {
        const [longitude, latitude] = data;

        return getCoordinatesFromLatLng(
          height,
          REAL_EARTH_RADIUS_IN_KM,
          latitude,
          longitude
        );
      }),
      (a, b) => points.push(a, b)
    );
  });

  return points;
};

export const getMeshMultilinestring = topojsonData => {
  if (!topojsonData || !topojsonData.objects) {
    return [];
  }

  return Object.values(topojsonData.objects).map(obj =>
    mesh(topojsonData, obj)
  );
};

export const calcDistanceBetweenTwoLatitudeLongitudePoints = (
  lat1,
  lon1,
  lat2,
  lon2
) => {
  const p = Math.PI / 180;
  const a =
    0.5 -
    Math.cos((lat2 - lat1) * p) / 2 +
    (Math.cos(lat1 * p) *
      Math.cos(lat2 * p) *
      (1 - Math.cos((lon2 - lon1) * p))) /
      2;

  return 2 * REAL_EARTH_RADIUS_IN_KM * Math.asin(Math.sqrt(a));
};

export const calculateBboxCenterFromPoints = points => {
  const center = new THREE.Vector3();
  const bbox = new THREE.Box3().setFromPoints(points);
  bbox.getCenter(center);

  return center;
};

export const constrainAngle = angle => {
  if (angle > 180 || angle < -180) {
    return angle - 360 * Math.floor((angle + 180) / 360);
  }

  return angle;
};

export const metersToFeet = value => {
  return value * 3.2808399;
};

export const feetToMeters = value => {
  return value / 3.2808399;
};
