import { LinearProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import TableNoDataOverlay from "../../../../../common/components/table/TableNoDataOverlay";
import { lightDataGrid } from "../../../../../common/providers/theme/design-tokens/DataGrid/light";
import { IAlertsTableRow } from "../hooks/useAlertsTableRow";
import { ALERTS_TABLE_LIMIT } from "../hooks/useGetLazyPaginatedAlerts";
import { IUserAlertsTableRow } from "../hooks/useUserAlertsTableRow";

interface IAlertsTableProps {
  rows: IUserAlertsTableRow[] | IAlertsTableRow[];
  columns: GridColDef[];
  loading: boolean;
}

const AlertsTable = ({
  rows,
  columns,
  loading,
}: IAlertsTableProps): JSX.Element => {
  return (
    <DataGrid
      sx={lightDataGrid.sx}
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: TableNoDataOverlay,
      }}
      autoHeight
      disableSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: string }): string => row.rowId}
      loading={loading}
      pageSize={ALERTS_TABLE_LIMIT}
      sortingOrder={["desc", "asc"]}
    />
  );
};

export default AlertsTable;
