import { useTheme } from "@mui/material";

const EmailAlertIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49992 8H56.4999C57.9727 8 59.1666 9.19392 59.1666 10.6667V53.3333C59.1666 54.8061 57.9727 56 56.4999 56H8.49992C7.02717 56 5.83325 54.8061 5.83325 53.3333V10.6667C5.83325 9.19392 7.02717 8 8.49992 8ZM53.8332 19.3011L32.6914 38.2347L11.1666 19.2425V50.6667H53.8332V19.3011ZM12.5305 13.3333L32.665 31.0987L52.5026 13.3333H12.5305Z"
        fill={theme.palette.primary.dark}
      />
    </svg>
  );
};

export default EmailAlertIcon;
