import { useMutation } from "@apollo/client";

import { UPDATE_DT_PROJECT } from "../../../../common/operations/mutations";

export const useUpdateDTProject = () => {
  const [updateDTProjectMutation, { data, loading, error }] =
    useMutation(UPDATE_DT_PROJECT);

  const updateDTProject = async input => {
    return await updateDTProjectMutation({
      variables: {
        input,
      },
    });
  };

  return { updateDTProject, data, error, loading };
};
