import { ChangeEvent, useEffect } from "react";

import { Grid, TextField } from "@mui/material";

import LocationIdSelect from "../../../common/components/layout/components/LocationIdSelect";
import type { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  nodeFormRules,
  nodeFormValidationStateVariable,
  nodeFormVariables,
  useNodeFormValidationState,
  useNodeFormVariables,
  useSelectedLocationNodes,
} from "../variables/nodes";

const CreateNodeForm = (): JSX.Element => {
  const selectedLocation = useSelectedLocationNodes();
  const form = useNodeFormVariables();
  const validation = useNodeFormValidationState();

  useEffect((): void => {
    if (!form.locationId?.value && selectedLocation?.value) {
      nodeFormVariables({
        ...form,
        locationId: selectedLocation,
      });
    }
  }, [form.locationId?.value, selectedLocation?.value]);

  const setNodeName = (event: ChangeEvent<HTMLInputElement>): void => {
    const nodeName = event.target.value;

    nodeFormVariables({
      ...form,
      nodeName,
    });

    const hasError = !nodeName.match(nodeFormRules.nodeName.pattern);
    nodeFormValidationStateVariable({
      ...validation,
      nodeName: {
        hasError,
        errorMessage: "Valid characters: A-Z, a-z, 0-9",
      },
    });
  };

  const setLocation = (locationValue: AutocompleteOptionType): void => {
    nodeFormVariables({
      ...form,
      locationId: locationValue,
    });
  };

  return (
    <>
      <Grid container justifyContent="center" direction="column" spacing={2}>
        <Grid item>
          <LocationIdSelect
            hasError={
              validation?.locationId.hasError && !form.locationId?.value
            }
            disableClearable
            setDefault
            defaultValue={selectedLocation?.value}
            location={form.locationId ?? selectedLocation}
            setLocation={setLocation}
          />
        </Grid>
        <Grid item>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            id="node-name"
            label="Name"
            error={validation?.nodeName.hasError}
            helperText={
              validation?.nodeName.hasError && validation?.nodeName.errorMessage
            }
            value={form.nodeName}
            onChange={setNodeName}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CreateNodeForm;
