import { setMaterialColorToMesh, setMaterialColor } from "./MaterilsUtils";

import { OBJECT_HIGHLIGHT_COLOR } from "./Constants";

class ObjectHighLight {
  constructor() {
    // Selected state.
    this.prevSelectedObject = {
      entity: null,
      color: null,
    };

    this.prevSelectedSide = {
      entity: null,
      color: null,
    };

    this.prevSelectedCamera = {
      entity: null,
      color: null,
    };

    // Hovered state.
    this.prevHoveredObject = {
      entity: null,
      color: null,
    };

    this.prevHoveredSide = {
      entity: null,
      color: null,
    };

    this.prevHoveredCamera = {
      entity: null,
      color: null,
    };
  }

  toggleHighlight(selected, hovered) {
    this.toggleHoveredHighlight(hovered);
    this.toggleSelectedHighlight(selected);
  }

  toggleSelectedHighlight(selected) {
    const { selectedObject, selectedTowerSide, selectedCamera } = selected;

    // Selected object.
    if (this.prevSelectedObject.entity) {
      setMaterialColorToMesh(
        this.prevSelectedObject.entity.children[0],
        this.prevSelectedObject.color
      );
    }

    if (
      selectedObject &&
      this.prevHoveredObject.entity &&
      selectedObject.uuid === this.prevHoveredObject.entity.uuid
    ) {
      setMaterialColorToMesh(
        this.prevHoveredObject.entity.children[0],
        this.prevHoveredObject.color
      );

      this.prevHoveredObject.entity = null;
      this.prevHoveredObject.color = null;
    }

    if (selectedObject) {
      this.prevSelectedObject.entity = selectedObject;
      this.prevSelectedObject.color =
        selectedObject.children[0].material.color.clone();

      setMaterialColorToMesh(
        selectedObject.children[0],
        OBJECT_HIGHLIGHT_COLOR
      );
    } else {
      this.prevSelectedObject.entity = null;
      this.prevSelectedObject.color = null;
    }

    // Selected side.
    if (this.prevSelectedSide.entity) {
      setMaterialColorToMesh(
        this.prevSelectedSide.entity,
        this.prevSelectedSide.color
      );

      this.prevSelectedSide.entity.material.opacity = 0;
    }

    if (
      selectedTowerSide &&
      this.prevHoveredSide.entity &&
      selectedTowerSide.uuid === this.prevHoveredSide.entity.uuid
    ) {
      setMaterialColorToMesh(
        this.prevHoveredSide.entity,
        this.prevHoveredSide.color
      );

      this.prevHoveredSide.entity.material.opacity = 0;

      this.prevHoveredSide.entity = null;
      this.prevHoveredSide.color = null;
    }

    if (selectedTowerSide) {
      this.prevSelectedSide.entity = selectedTowerSide;
      this.prevSelectedSide.color = selectedTowerSide.material.color.clone();

      selectedTowerSide.material.opacity = 0.6;

      setMaterialColorToMesh(selectedTowerSide, OBJECT_HIGHLIGHT_COLOR);
    } else {
      this.prevSelectedSide.entity = null;
      this.prevSelectedSide.color = null;
    }

    // Selected camera.
    if (this.prevSelectedCamera.entity) {
      setMaterialColor(
        this.prevSelectedCamera.entity,
        this.prevSelectedCamera.color
      );
    }

    if (
      selectedCamera &&
      this.prevHoveredCamera.entity &&
      selectedCamera.uuid === this.prevHoveredCamera.entity.uuid
    ) {
      setMaterialColor(
        this.prevHoveredCamera.entity,
        this.prevHoveredCamera.color
      );

      this.prevHoveredCamera.entity = null;
      this.prevHoveredCamera.color = null;
    }

    if (selectedCamera) {
      this.prevSelectedCamera.entity = selectedCamera;
      this.prevSelectedCamera.color =
        selectedCamera.children[0].material.color.clone();

      setMaterialColor(selectedCamera, OBJECT_HIGHLIGHT_COLOR);
    } else {
      this.prevSelectedCamera.entity = null;
      this.prevSelectedCamera.color = null;
    }
  }

  toggleHoveredHighlight(hovered) {
    const { hoveredObject, hoveredTowerSide, hoveredCamera } = hovered;

    // Hovered object.
    if (this.prevHoveredObject.entity) {
      setMaterialColorToMesh(
        this.prevHoveredObject.entity.children[0],
        this.prevHoveredObject.color
      );
    }

    if (
      hoveredObject &&
      (!this.prevSelectedObject.entity ||
        hoveredObject.uuid !== this.prevSelectedObject.entity.uuid)
    ) {
      this.prevHoveredObject.entity = hoveredObject;
      this.prevHoveredObject.color =
        hoveredObject.children[0].material.color.clone();

      setMaterialColorToMesh(hoveredObject.children[0], OBJECT_HIGHLIGHT_COLOR);
    } else {
      this.prevHoveredObject.entity = null;
      this.prevHoveredObject.color = null;
    }

    // Hovered side.
    if (this.prevHoveredSide.entity) {
      setMaterialColorToMesh(
        this.prevHoveredSide.entity,
        this.prevHoveredSide.color
      );

      this.prevHoveredSide.entity.material.opacity = 0;
    }

    if (
      hoveredTowerSide &&
      (!this.prevSelectedSide.entity ||
        hoveredTowerSide.uuid !== this.prevSelectedSide.entity.uuid)
    ) {
      this.prevHoveredSide.entity = hoveredTowerSide;
      this.prevHoveredSide.color = hoveredTowerSide.material.color.clone();

      hoveredTowerSide.material.opacity = 0.6;

      setMaterialColorToMesh(hoveredTowerSide, OBJECT_HIGHLIGHT_COLOR);
    } else {
      this.prevHoveredSide.entity = null;
      this.prevHoveredSide.color = null;
    }

    // Hovered camera.
    if (this.prevHoveredCamera.entity) {
      setMaterialColor(
        this.prevHoveredCamera.entity,
        this.prevHoveredCamera.color
      );
    }

    if (
      hoveredCamera &&
      (!this.prevSelectedCamera.entity ||
        hoveredCamera.uuid !== this.prevSelectedCamera.entity.uuid)
    ) {
      this.prevHoveredCamera.entity = hoveredCamera;
      this.prevHoveredCamera.color =
        hoveredCamera.children[0].material.color.clone();

      setMaterialColor(hoveredCamera, OBJECT_HIGHLIGHT_COLOR);
    } else {
      this.prevHoveredCamera.entity = null;
      this.prevHoveredCamera.color = null;
    }
  }
}

export default ObjectHighLight;
