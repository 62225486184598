import { TextField } from "@mui/material";

// TODO: DRY problem detected. Please create base interface with common properties,
//      then extend it with specific one
interface DynamicTextInputProps {
  name: string;
  serviceConfig: any;
  setServiceConfig: (serviceConfigValue: any) => void;
}

const DynamicTextInput = ({
  name,
  serviceConfig,
  setServiceConfig,
}: DynamicTextInputProps): JSX.Element => {
  return (
    <TextField
      margin="dense"
      required
      fullWidth
      id={name + "-id"}
      label={name}
      value={serviceConfig[name]}
      onChange={(e): void =>
        setServiceConfig((prevServiceConfig: any): any => {
          return {
            ...prevServiceConfig,
            [name]: e.target.value,
          };
        })
      }
    />
  );
};

export default DynamicTextInput;
