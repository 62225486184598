import { Button, Chip, Stack, Typography } from "@mui/material";
import type { GridColDef } from "@mui/x-data-grid";

import MenuLink from "../../../common/components/MenuLink";

export const useLocationsTableColumns = (): { columns: GridColDef[] } => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 220,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.name}</Typography>
        ) : null,
    },
    {
      field: "coordinates",
      headerName: "Coordinates",
      width: 220,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">
            {`N: ${(params?.row?.coordinates.lat as string) ?? ""}, W: ${
              (params?.row?.coordinates.lon as string) ?? ""
            }`}
          </Typography>
        ) : null,
    },
    {
      field: "tags",
      headerName: "Tags",
      flex: 0.5,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        return params?.row?.tags?.map((tag: string): JSX.Element => {
          return <Chip key={tag} label={tag} />;
        });
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        return (
          <Stack direction="row" spacing={1}>
            <Button>
              <MenuLink
                to={`/devices/${params.row?.rowId as string}`}
                state={{
                  locationId: params.row?.rowId,
                  locationName: params.row?.name,
                }}
              >
                VIEW DEVICES
              </MenuLink>
            </Button>
            <Button>EDIT</Button>
            <Button>EVENTS</Button>
          </Stack>
        );
      },
    },
  ];

  return { columns };
};
