import { Box } from "@mui/material";

import { Spinner } from "../../assets/icons/svgAssets";

import "./Loader.css";

const Loader = ({ background }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 5,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        background: background ? background : "white",
      }}
    >
      <Box
        sx={{
          width: "250px",
          height: "250px",
        }}
        id="spinner"
      >
        <Spinner />
      </Box>
    </Box>
  );
};

export default Loader;
