import { useMemo, useState } from "react";

import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";

import { useDevicesMakesTableRows } from "../../../../pages/system-settings/tabs/devices/hooks/useDevicesMakesTableRows";
import { IDisableClearableOption } from "../../../models/autocomplete";

interface IMakeSelect {
  makeId: string | null;
  hasError?: boolean;
  onMakeChange: (value: string) => void;
}

const MakeSelect = ({
  makeId,
  hasError,
  onMakeChange,
}: IMakeSelect): JSX.Element => {
  const { rows, loading } = useDevicesMakesTableRows();

  const [inputValue, setInputValue] = useState("");

  const options = useMemo((): IDisableClearableOption[] => {
    return rows?.map((item): IDisableClearableOption => {
      return {
        title: `${item.name}, ${item.model} (${item.type})`,
        value: item.model ?? "",
      };
    });
  }, [rows]);

  const memoizedValue = useMemo((): IDisableClearableOption | null => {
    if (!makeId) {
      return null;
    }

    return options.find((option): boolean => option?.value === makeId) ?? null;
  }, [makeId, options]);

  const filterOptions = createFilterOptions({
    stringify: (option: IDisableClearableOption): string => option.title,
  });

  return (
    <Autocomplete
      fullWidth
      loading={loading}
      filterOptions={filterOptions}
      value={memoizedValue as IDisableClearableOption}
      disableClearable={true}
      onChange={(
        _event: any,
        newValue: IDisableClearableOption | null
      ): void => {
        if (newValue) {
          onMakeChange(newValue.value);
        }
      }}
      getOptionLabel={(option: IDisableClearableOption): string =>
        option?.title ?? ""
      }
      isOptionEqualToValue={(option, optionValue): boolean =>
        option && option?.value === ""
          ? true
          : option?.value === optionValue?.value
      }
      inputValue={inputValue}
      onInputChange={(_event, newInputValue): void => {
        setInputValue(newInputValue);
      }}
      options={options}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          error={hasError && !memoizedValue?.value}
          helperText={
            hasError && !memoizedValue?.value
              ? "Please select a Make/Model"
              : ""
          }
          label="Make/Model"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default MakeSelect;
