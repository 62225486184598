import { useTheme } from "@mui/material";

const SystemAlertIcon = (): JSX.Element => {
  const theme = useTheme();

  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.8066 8.00029L60.2098 52.0004C60.9463 53.2758 60.5092 54.9068 59.2338 55.643C58.8284 55.8772 58.3687 56.0004 57.9004 56.0004H7.09367C5.6209 56.0004 4.427 54.8065 4.427 53.3337C4.427 52.8654 4.5502 52.4057 4.78426 52.0004L30.1876 8.00029C30.9242 6.72483 32.5548 6.28784 33.8303 7.02421C34.2359 7.25827 34.5724 7.59491 34.8066 8.00029ZM11.7125 50.667H53.2818L32.497 14.667L11.7125 50.667ZM29.8303 42.667H35.1636V48.0004H29.8303V42.667ZM29.8303 24.0003H35.1636V37.3337H29.8303V24.0003Z"
        fill={theme.palette.primary.dark}
      />
    </svg>
  );
};

export default SystemAlertIcon;
