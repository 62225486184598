import { useEffect, useMemo, useRef, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Dictionary, groupBy, isEmpty, isEqual, List } from "lodash";

import { Service } from "../../../API";
import { useGetServicesByLocation } from "../../../common/components/select/ServiceSelect/useGetServicesByLocation";
import TableNoDataOverlay from "../../../common/components/table/TableNoDataOverlay";
import { useUpdateShadow } from "../../../common/hooks/useUpdateShadow";
import { useSelectedLocationAIManager } from "../variables/modelManager";
import UpdateServiceDialogContainer from "./UpdateServiceDialogContainer";
import DeleteServiceDialogContainer from "./DeleteServiceDialogContainer";
import { usePublishNode } from "../../devices/hooks/usePublishNode";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";

interface IModelManagerRowProps {
  location: string;
  models: Array<Service>;
}

const Row = (props: { row: IModelManagerRowProps }): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { publishNode } = usePublishNode();

  const { row } = props;

  const location = row.location.split("#L#").at(-1);

  const deployService = (model: any): void => {
    console.log(model);
    // TODO: currently only deploys based on serviceType assuming only one serviceType/modelType per NA
    publishNode({
      message: JSON.stringify({
        TARGET: "MODEL",
        ACTION: "DEPLOY",
        DATA: {
          serviceType: model.serviceType,
        },
      }),
      nodeId: model.nodeId,
    })
      .then((response): void => {
        if (response.data) {
          successNotification();
        }
      })
      .catch((error): void => {
        errorNotification("Something went wrong when publishing node");
        console.error(error);
      });
  };

  return (
    <>
      <TableRow
        onClick={(): void => setOpen(!open)}
        sx={{
          cursor: "pointer",
        }}
      >
        <TableCell component="th">
          <Typography variant="body1">{location}</Typography>
        </TableCell>
        <TableCell component="th" scope="row" sx={{ display: "flex" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{
              marginLeft: "auto",
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1.25 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell component="th">
                      <Typography variant="th">Type</Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography variant="th">Node</Typography>
                    </TableCell>
                    <TableCell component="th">
                      <Typography variant="th" />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.models.map((model): JSX.Element => {
                    const node = model.nodeId?.split("#N#").at(-1);

                    return (
                      <TableRow key={model.id}>
                        <TableCell>
                          <Typography variant="body2">
                            {model.serviceType}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{node}</Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            <UpdateServiceDialogContainer
                              nodeId={model.nodeId ?? ""}
                              // todo: modify service schema type to more easily return the serviceId
                              serviceId={`S#${model?.id?.split("S#")[1] ?? ""}`}
                            />
                            <DeleteServiceDialogContainer
                              nodeId={model.nodeId ?? ""}
                              // todo: modify service schema type to more easily return the serviceId
                              serviceId={`S#${model?.id?.split("S#")[1] ?? ""}`}
                            />
                            <Button
                              variant="contained"
                              onClick={(): void => deployService(model)}
                            >
                              Deploy
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ModelManagerTable = (): JSX.Element => {
  const locationVariable = useSelectedLocationAIManager();

  const { data, loading } = useGetServicesByLocation(locationVariable?.value);

  const { updateDeviceShadow } = useUpdateShadow();

  const listOfServices = data?.getServices.items;

  function getUniqueNodeIds(
    listOfServices:
      | ({
          __typename: "Service";
          configuration?: string | null | undefined;
          customerId?: string | null | undefined;
          deviceId?: string | null | undefined;
          id?: string | null | undefined;
          locationId?: string | null | undefined;
          nodeId?: string | null | undefined;
          serviceType?: string | null | undefined;
        } | null)[]
      | undefined
  ): any {
    if (!listOfServices || !Array.isArray(listOfServices)) {
      return [];
    }
    const nodeIds = new Set();

    for (const service of listOfServices) {
      if (service && service.nodeId) {
        nodeIds.add(service.nodeId);
      }
    }
    return Array.from(nodeIds);
  }

  const uniqueNodeIds = getUniqueNodeIds(listOfServices); // list of unique nodeIds

  function generateThingNamesFromNodeIds(
    nodeIds: (string | undefined)[]
  ): (string | undefined)[] {
    return nodeIds.map((nodeId): any => {
      if (nodeId) {
        return nodeId.replace(/#/g, "_");
      }
      return nodeId;
    });
  }

  const thingNames = generateThingNamesFromNodeIds(uniqueNodeIds); // create list of thingNames from unique nodeIds

  const groupRowsByNode = (): { [node: string]: typeof listOfServices } => {
    if (!listOfServices || !Array.isArray(listOfServices)) {
      return {};
    }
    const groups: { [node: string]: typeof listOfServices } = {};

    listOfServices.forEach((service): any => {
      const node = service?.nodeId;
      if (node) {
        if (!groups[node]) {
          groups[node] = [];
        }
        groups[node].push(service);
      }
    });

    return groups;
  };

  const groupedRows = groupRowsByNode(); // devices grouped into arrays by their nodeId's

  const thingNameGroupedRows: { [key: string]: typeof listOfServices } = {};

  Object.keys(groupedRows).forEach((key): any => {
    const modifiedKey = key.replace(/#/g, "_");
    thingNameGroupedRows[modifiedKey] = groupedRows[key];
  });

  const previousRows = useRef(thingNameGroupedRows);

  useEffect((): void => {
    if (!isEqual(previousRows.current, thingNameGroupedRows)) {
      for (const nodeId of thingNames) {
        updateDeviceShadow(nodeId as string, {
          models: thingNameGroupedRows[nodeId as string] as any,
        });
        previousRows.current = thingNameGroupedRows;
      }
    }
  }, [listOfServices]);

  const memoizedData = useMemo((): Dictionary<Service[]> | null => {
    if (isEmpty(data?.getServices.items)) {
      return null;
    }

    const items = locationVariable?.value
      ? data?.getServices.items.filter(
          (item): boolean => item?.locationId === locationVariable.value
        )
      : data?.getServices.items;

    return groupBy<Service>(
      items as List<Service>,
      (x): string | null | undefined => x?.locationId
    );
  }, [data?.getServices.items, locationVariable?.value]);

  return (
    <>
      <Table aria-label="collapsible table">
        <TableBody>
          {memoizedData &&
            Object.entries(memoizedData).map((row): JSX.Element => {
              const [locationId, items] = row;
              const rowItem: IModelManagerRowProps = {
                location: locationId,
                models: items,
              };

              return <Row key={locationId} row={rowItem} />;
            })}

          {loading && (
            <TableRow>
              <TableCell colSpan={5}>
                {[...Array(5)].map(
                  (_, index): JSX.Element => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      sx={{ my: 3 }}
                    />
                  )
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isEmpty(memoizedData) && !loading && <TableNoDataOverlay />}
    </>
  );
};

export default ModelManagerTable;
