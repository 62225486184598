import { Box, Typography } from "@mui/material";

import {
  CollapseArrowSVG,
  IconCirclePlus,
} from "../../../assets/icons/svgAssets";

const TowerPanelListItemHeader = ({
  withCollapseIcon = false,
  withAddIcon = false,
  open,
  selected,
  onItemClick,
  onAddClick = () => {},
  text,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
        width: "100%",
        height: "24px",
        padding: "0px 6px 0px 6px",
        cursor: "pointer",
        borderRadius: "4px",
        border: "2px solid transparent",
        ...(selected && {
          border: "2px solid #5298E5",
        }),

        "&:hover": {
          "& .tower-list-item-text": {
            textDecorationLine: "underline",
          },
        },
      }}
      onClick={onItemClick}
    >
      {withCollapseIcon && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transform: open ? "rotate(0deg)" : "rotate(270deg)",
          }}
        >
          <CollapseArrowSVG fillColor={"#0D254A"} />
        </Box>
      )}
      <Typography
        className="tower-list-item-text"
        sx={{
          marginLeft: "8px",
        }}
        variant="body14Medium"
        color="#0D254A"
      >
        {text}
      </Typography>
      <Box sx={{ flexGrow: 1 }}></Box>
      {withAddIcon && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={onAddClick}
        >
          <IconCirclePlus size={18} />
        </Box>
      )}
    </Box>
  );
};

export default TowerPanelListItemHeader;
