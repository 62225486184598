import { useGetAnnotations } from "./useGetAnnotations";

export function useAnnotationsTableRows({
  zoneId,
  serviceId,
}: {
  zoneId: string;
  serviceId: string;
}) {
  const { data, loading } = useGetAnnotations(zoneId, serviceId);
  const rows =
    data?.getAnnotations?.items?.map((item, index) => {
      return {
        id: index + 1,
        rowId: item?.id ?? "A#" + item?.name,
        name: item?.name,
        polygon: item?.polygon,
        annotationType: item?.annotationType,
        zoneId: item?.zoneId,
        serviceId: item?.serviceId,
      };
    }) ?? [];

  return { rows, loading };
}
