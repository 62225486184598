import {
  type FetchResult,
  useMutation,
  type OperationVariables,
} from "@apollo/client";
import type {
  AddLocationMutation,
  AddLocationMutationVariables,
} from "../../../API";
import { ADD_LOCATION } from "../../../common/operations/mutations";

export const useSaveLocation = (): [
  saveLocationItem: ({
    input: { customerId, locationData, name, tags },
  }: AddLocationMutationVariables) => Promise<FetchResult<AddLocationMutation>>,
  variables: OperationVariables
] => {
  const [saveLocation, { data, loading, error }] = useMutation(ADD_LOCATION);

  const saveLocationItem = async ({
    input: { customerId, locationData, name, tags },
  }: AddLocationMutationVariables): Promise<
    FetchResult<AddLocationMutation>
  > => {
    return await saveLocation({
      variables: {
        input: {
          customerId,
          locationData,
          name,
          tags: tags?.length === 0 ? null : tags,
        },
      },
      refetchQueries: ["GetLocations"],
    });
  };

  return [saveLocationItem, { data, loading, error }];
};
