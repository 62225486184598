/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type addAlertEmailNotificationInput = {
  additionalEmails?: Array< string | null > | null,
  alertName: string,
  alertType: string,
  customService?: string | null,
  customerId: string,
  email?: string | null,
  jsonObject?: string | null,
  location?: string | null,
  model?: string | null,
  node?: string | null,
  status: boolean,
};

export type Alert = {
  __typename: "Alert",
  additionalEmails?: Array< string | null > | null,
  alertName?: string | null,
  alertType?: string | null,
  customService?: string | null,
  email?: string | null,
  id?: string | null,
  jsonObject?: string | null,
  location?: string | null,
  locationTag?: Array< string | null > | null,
  model?: string | null,
  node?: string | null,
  status?: boolean | null,
};

export type AddAlertSubscriptionInput = {
  email: string,
  entity: string,
};

export type AlertSubscription = {
  __typename: "AlertSubscription",
  email: string,
  entity?: string | null,
};

export type AddAlertWildCardSubscriptionInput = {
  email: string,
  entity: string,
  wildCard: string,
};

export type AlertWildCardSubscription = {
  __typename: "AlertWildCardSubscription",
  email: string,
  entity: string,
  wildCard: string,
};

export type AddAnnotationInput = {
  annotationType: string,
  name?: string | null,
  polygon?: string | null,
  serviceId: string,
  zoneId: string,
};

export type Annotation = {
  __typename: "Annotation",
  annotationType: string,
  id?: string | null,
  name?: string | null,
  polygon?: string | null,
  serviceId: string,
  zoneId: string,
};

export type Customer = {
  __typename: "Customer",
  domains?: Array< string > | null,
  id: string,
  name?: string | null,
  pointOfContact?: string | null,
};

export type AddDTProjectInput = {
  customerId: string,
  displayName: string,
};

export type DTProject = {
  __typename: "DTProject",
  configURL?: string | null,
  customerId?: string | null,
  displayName?: string | null,
  id?: string | null,
  projectId?: string | null,
};

export type AddDeviceInput = {
  customerId: string,
  deviceData?: string | null,
  locationId: string,
  makeModelId: string,
  name: string,
  nodeId: string,
};

export type Device = {
  __typename: "Device",
  customerId: string,
  deviceData?: string | null,
  id: string,
  locationId: string,
  makeModelId: string,
  name: string,
  nodeId: string,
};

export type AddDeviceMakeInput = {
  model: string,
  name: string,
  rtspTemplate: string,
  type: string,
};

export type DeviceMake = {
  __typename: "DeviceMake",
  id: string,
  model: string,
  name: string,
  rtspTemplate: string,
  type: string,
};

export type Domain = {
  __typename: "Domain",
  customerId?: string | null,
};

export type LocationInput = {
  customerId: string,
  id?: string | null,
  locationData?: string | null,
  name: string,
  tags?: Array< string | null > | null,
};

export type Location = {
  __typename: "Location",
  id: string,
  locationData?: string | null,
  name: string,
  tags?: Array< string | null > | null,
};

export type AddNodeInput = {
  level: NodeLevel,
  locationId: string,
  nodeName: string,
  tags?: Array< string | null > | null,
};

export enum NodeLevel {
  CUSTOMER = "CUSTOMER",
  LOCATION = "LOCATION",
  NODE = "NODE",
}


export type Node = {
  __typename: "Node",
  customerId?: string | null,
  deviceData?: string | null,
  id: string,
  level?: NodeLevel | null,
  locationId?: string | null,
  nodeId?: string | null,
  nodeName?: string | null,
  onboardCommand?: string | null,
  serviceData?: string | null,
  tags?: Array< string | null > | null,
};

export type AddServiceInput = {
  configuration?: string | null,
  customerId: string,
  deviceId: string,
  locationId: string,
  nodeId: string,
  serviceType: string,
};

export type Service = {
  __typename: "Service",
  configuration?: string | null,
  customerId?: string | null,
  deviceId?: string | null,
  id?: string | null,
  locationId?: string | null,
  nodeId?: string | null,
  serviceType?: string | null,
};

export type AddZoneInput = {
  deviceId: string,
  mediaUrl?: string | null,
  name: string,
  orderNumber?: string | null,
  pan: string,
  serviceId?: string | null,
  status: boolean,
  tilt: string,
};

export type Zone = {
  __typename: "Zone",
  deviceId: string,
  id: string,
  mediaUrl?: string | null,
  name: string,
  orderNumber?: string | null,
  pan: string,
  serviceId?: string | null,
  status: boolean,
  tilt: string,
};

export type User = {
  __typename: "User",
  customer_id?: string | null,
  email?: string | null,
  firstName?: string | null,
  id?: string | null,
  lastName?: string | null,
  user_group?: string | null,
  user_role?: string | null,
};

export type ChangeNodeStatusInput = {
  action: string,
  nodeId: string,
};

export type RemovedAnnotations = {
  __typename: "RemovedAnnotations",
  id?: string | null,
  serviceId?: string | null,
  zoneId?: string | null,
};

export type DTProjectInput = {
  customerId: string,
  displayName?: string | null,
  projectId: string,
};

export type DeleteServiceInput = {
  nodeId: string,
  serviceId: string,
};

export type DeleteZoneInput = {
  deviceId: string,
  name: string,
};

export type RemovedZone = {
  __typename: "RemovedZone",
  id: string,
  name: string,
};

export type ValidateEventInput = {
  auditorsExplanation?: string | null,
  customerId: string,
  eventId: string,
  eventTime: string,
  explanation?: string | null,
  real: boolean,
};

export type HumanValidationEvent = {
  __typename: "HumanValidationEvent",
  audited_by?: string | null,
  auditorsExplanation?: string | null,
  customerId: string,
  data?: string | null,
  eventTime: string,
  explanation?: string | null,
  id: string,
  locationId: string,
  nodeId: string,
  serviceId: string,
  transitionInfo?: string | null,
  validated_by?: string | null,
  validationState?: ValidationState | null,
};

export enum ValidationState {
  EXPIRED = "EXPIRED",
  IN_VALIDATION = "IN_VALIDATION",
  NOT_REAL = "NOT_REAL",
  PENDING = "PENDING",
  REAL = "REAL",
}


export type publishNodeInput = {
  message: string,
  nodeId: string,
};

export type publishNode = {
  __typename: "publishNode",
  message: string,
  nodeId: string,
};

export type NodeFile = {
  __typename: "NodeFile",
  downloadURL: string,
  file?: string | null,
  internalNodeId: string,
};

export type NodeMessage = {
  __typename: "NodeMessage",
  internalNodeId: string,
  message?: string | null,
};

export type UpdateAdminEmailAlertInput = {
  additionalEmails?: Array< string | null > | null,
  alertName: string,
  customerId: string,
  id: string,
  location?: string | null,
  model?: string | null,
  status?: boolean | null,
};

export type UpdateAdminSystemAlertInput = {
  customService?: string | null,
  customerId: string,
  id: string,
  jsonObject?: string | null,
  node?: string | null,
  status?: boolean | null,
};

export type UpdateAlertInput = {
  additionalEmails?: Array< string | null > | null,
  alertName: string,
  alertType: string,
  customService?: string | null,
  customerId: string,
  email?: string | null,
  jsonObject?: string | null,
  location?: string | null,
  model?: string | null,
  node?: string | null,
  status: boolean,
};

export type UpdateAlertStatusInput = {
  customerId: string,
  id: string,
  status?: boolean | null,
};

export type CustomerInput = {
  additionalAdminGroupId?: string | null,
  domains?: Array< string | null > | null,
  name?: string | null,
  pointOfContact?: string | null,
  vid: string,
};

export type UpdateDeviceInput = {
  customerId: string,
  deviceData?: string | null,
  deviceId: string,
};

export type UpdateDeviceMakeInput = {
  id: string,
  model: string,
  name: string,
  rtspTemplate: string,
  type: string,
};

export type UpdateNodeInput = {
  deviceData?: string | null,
  nodeId: string,
  serviceData?: string | null,
};

export type ProfileInput = {
  file?: string | null,
  name: string,
};

export type Profile = {
  __typename: "Profile",
  file?: string | null,
  id: string,
  name?: string | null,
};

export type UpdateServiceInput = {
  configuration?: string | null,
  nodeId: string,
  serviceId: string,
};

export type Shadow = {
  __typename: "Shadow",
  shadow?: string | null,
};

export type UserInput = {
  customerId: string,
  groupId?: string | null,
  roleId: string,
  userId: string,
};

export type UpdateUserAlertInput = {
  alertName: string,
  customerId: string,
  id: string,
  location?: string | null,
  model?: string | null,
  status?: boolean | null,
};

export type UpdateZoneInput = {
  deviceId: string,
  mediaUrl?: string | null,
  name: string,
  orderNumber?: string | null,
  pan: string,
  serviceId?: string | null,
  status: boolean,
  tilt: string,
};

export type UploadDTObject = {
  file: string,
  filetype: string,
  name: string,
};

export type PaginatedAlerts = {
  __typename: "PaginatedAlerts",
  items:  Array<Alert | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedResponse = {
  __typename: "PaginatedResponse",
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedAlertSubscriptions = {
  __typename: "PaginatedAlertSubscriptions",
  items:  Array<AlertSubscription | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedAllEmailsForSubscriptionEntity = {
  __typename: "PaginatedAllEmailsForSubscriptionEntity",
  items:  Array<AlertSubscription | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedAnnotations = {
  __typename: "PaginatedAnnotations",
  items:  Array<Annotation | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedCustomers = {
  __typename: "PaginatedCustomers",
  items:  Array<Customer | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedDTProjects = {
  __typename: "PaginatedDTProjects",
  items:  Array<DTProject | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedDevices = {
  __typename: "PaginatedDevices",
  items:  Array<Device | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedDevicesMakes = {
  __typename: "PaginatedDevicesMakes",
  items:  Array<DeviceMake | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedHumanValidationEvents = {
  __typename: "PaginatedHumanValidationEvents",
  items:  Array<HumanValidationEvent | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedLocations = {
  __typename: "PaginatedLocations",
  items:  Array<Location | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedNodes = {
  __typename: "PaginatedNodes",
  items:  Array<Node | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedRoles = {
  __typename: "PaginatedRoles",
  items:  Array<Role | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type Role = {
  __typename: "Role",
  id: string,
  name: string,
  userGroup?: Array< string | null > | null,
};

export type PaginatedServices = {
  __typename: "PaginatedServices",
  items:  Array<Service | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedSubscriptionsForEmail = {
  __typename: "PaginatedSubscriptionsForEmail",
  items:  Array<AlertSubscription | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedUsers = {
  __typename: "PaginatedUsers",
  items:  Array<User | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedWildCardsForSubscriptionEntity = {
  __typename: "PaginatedWildCardsForSubscriptionEntity",
  items:  Array<AlertWildCardSubscription | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaginatedZones = {
  __typename: "PaginatedZones",
  items:  Array<Zone | null >,
  limit?: number | null,
  nextToken?: string | null,
};

export type Form = {
  __typename: "Form",
  customerId?: string | null,
  fields?:  Array<Field | null > | null,
  id?: string | null,
};

export type Field = {
  __typename: "Field",
  entries?: Array< string | null > | null,
  itemToQuery?: string | null,
  name: string,
  type: string,
};

export type AddAlertEmailNotificationMutationVariables = {
  input?: addAlertEmailNotificationInput | null,
};

export type AddAlertEmailNotificationMutation = {
  addAlertEmailNotification?:  {
    __typename: "Alert",
    additionalEmails?: Array< string | null > | null,
    alertName?: string | null,
    alertType?: string | null,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    status?: boolean | null,
  } | null,
};

export type AddAlertSubscriptionMutationVariables = {
  input: AddAlertSubscriptionInput,
};

export type AddAlertSubscriptionMutation = {
  addAlertSubscription?:  {
    __typename: "AlertSubscription",
    email: string,
    entity?: string | null,
  } | null,
};

export type AddAlertWildCardSubscriptionMutationVariables = {
  input: AddAlertWildCardSubscriptionInput,
};

export type AddAlertWildCardSubscriptionMutation = {
  addAlertWildCardSubscription?:  {
    __typename: "AlertWildCardSubscription",
    email: string,
    entity: string,
    wildCard: string,
  } | null,
};

export type AddAnnotationMutationVariables = {
  input: AddAnnotationInput,
};

export type AddAnnotationMutation = {
  addAnnotation:  {
    __typename: "Annotation",
    annotationType: string,
    id?: string | null,
    name?: string | null,
    polygon?: string | null,
    serviceId: string,
    zoneId: string,
  },
};

export type AddCustomerMutationVariables = {
  additionalAdminGroupId?: string | null,
  bucketName?: string | null,
  customerName: string,
  domains?: Array< string > | null,
  userPoolId?: string | null,
};

export type AddCustomerMutation = {
  addCustomer?:  {
    __typename: "Customer",
    domains?: Array< string > | null,
    id: string,
    name?: string | null,
    pointOfContact?: string | null,
  } | null,
};

export type AddDTProjectMutationVariables = {
  input: AddDTProjectInput,
};

export type AddDTProjectMutation = {
  addDTProject?:  {
    __typename: "DTProject",
    configURL?: string | null,
    customerId?: string | null,
    displayName?: string | null,
    id?: string | null,
    projectId?: string | null,
  } | null,
};

export type AddDeviceMutationVariables = {
  input: AddDeviceInput,
};

export type AddDeviceMutation = {
  addDevice?:  {
    __typename: "Device",
    customerId: string,
    deviceData?: string | null,
    id: string,
    locationId: string,
    makeModelId: string,
    name: string,
    nodeId: string,
  } | null,
};

export type AddDeviceMakeMutationVariables = {
  input: AddDeviceMakeInput,
};

export type AddDeviceMakeMutation = {
  addDeviceMake?:  {
    __typename: "DeviceMake",
    id: string,
    model: string,
    name: string,
    rtspTemplate: string,
    type: string,
  } | null,
};

export type AddDomainMutationVariables = {
  customerId: string,
  domain: string,
};

export type AddDomainMutation = {
  addDomain?:  {
    __typename: "Domain",
    customerId?: string | null,
  } | null,
};

export type AddLocationMutationVariables = {
  input: LocationInput,
};

export type AddLocationMutation = {
  addLocation?:  {
    __typename: "Location",
    id: string,
    locationData?: string | null,
    name: string,
    tags?: Array< string | null > | null,
  } | null,
};

export type AddNodeMutationVariables = {
  input: AddNodeInput,
};

export type AddNodeMutation = {
  addNode:  {
    __typename: "Node",
    customerId?: string | null,
    deviceData?: string | null,
    id: string,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    serviceData?: string | null,
    tags?: Array< string | null > | null,
  },
};

export type AddServiceMutationVariables = {
  input: AddServiceInput,
};

export type AddServiceMutation = {
  addService?:  {
    __typename: "Service",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    id?: string | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  } | null,
};

export type AddZoneMutationVariables = {
  input: AddZoneInput,
};

export type AddZoneMutation = {
  addZone:  {
    __typename: "Zone",
    deviceId: string,
    id: string,
    mediaUrl?: string | null,
    name: string,
    orderNumber?: string | null,
    pan: string,
    serviceId?: string | null,
    status: boolean,
    tilt: string,
  },
};

export type AssignFirstRoleToUserMutationVariables = {
  customerId: string,
  groupId: string,
  roleId: string,
  userId: string,
  userPoolId?: string | null,
};

export type AssignFirstRoleToUserMutation = {
  assignFirstRoleToUser:  {
    __typename: "User",
    customer_id?: string | null,
    email?: string | null,
    firstName?: string | null,
    id?: string | null,
    lastName?: string | null,
    user_group?: string | null,
    user_role?: string | null,
  },
};

export type ChangeNodeStatusMutationVariables = {
  input: ChangeNodeStatusInput,
};

export type ChangeNodeStatusMutation = {
  changeNodeStatus: string,
};

export type DeleteAllAnnotationsMutationVariables = {
  annotationsJSON?: string | null,
};

export type DeleteAllAnnotationsMutation = {
  deleteAllAnnotations?:  {
    __typename: "RemovedAnnotations",
    id?: string | null,
    serviceId?: string | null,
    zoneId?: string | null,
  } | null,
};

export type DeleteDTProjectMutationVariables = {
  input: DTProjectInput,
};

export type DeleteDTProjectMutation = {
  deleteDTProject?:  {
    __typename: "DTProject",
    configURL?: string | null,
    customerId?: string | null,
    displayName?: string | null,
    id?: string | null,
    projectId?: string | null,
  } | null,
};

export type DeleteDeviceMakeMutationVariables = {
  id: string,
};

export type DeleteDeviceMakeMutation = {
  deleteDeviceMake: string,
};

export type DeleteLocationMutationVariables = {
  locationId: string,
};

export type DeleteLocationMutation = {
  deleteLocation: string,
};

export type DeleteServiceMutationVariables = {
  input: DeleteServiceInput,
};

export type DeleteServiceMutation = {
  deleteService?:  {
    __typename: "Service",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    id?: string | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  customerId: string,
  userId: string,
  userPoolId: string,
};

export type DeleteUserMutation = {
  deleteUser:  {
    __typename: "User",
    customer_id?: string | null,
    email?: string | null,
    firstName?: string | null,
    id?: string | null,
    lastName?: string | null,
    user_group?: string | null,
    user_role?: string | null,
  },
};

export type DeleteZoneMutationVariables = {
  input: DeleteZoneInput,
};

export type DeleteZoneMutation = {
  deleteZone:  {
    __typename: "RemovedZone",
    id: string,
    name: string,
  },
};

export type OverruleHumanValidatedEventsMutationVariables = {
  input: ValidateEventInput,
};

export type OverruleHumanValidatedEventsMutation = {
  overruleHumanValidatedEvents:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type PublishNodeMutationVariables = {
  input: publishNodeInput,
};

export type PublishNodeMutation = {
  publishNode?:  {
    __typename: "publishNode",
    message: string,
    nodeId: string,
  } | null,
};

export type SendToListenToHumanValidationEventsMutationVariables = {
  humanValidationEvent: string,
};

export type SendToListenToHumanValidationEventsMutation = {
  sendToListenToHumanValidationEvents:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type SendToListenToNodeFilesMutationVariables = {
  nodeFile: string,
};

export type SendToListenToNodeFilesMutation = {
  sendToListenToNodeFiles:  {
    __typename: "NodeFile",
    downloadURL: string,
    file?: string | null,
    internalNodeId: string,
  },
};

export type SendToListenToNodeMessagesMutationVariables = {
  nodeMessage: string,
};

export type SendToListenToNodeMessagesMutation = {
  sendToListenToNodeMessages?:  {
    __typename: "NodeMessage",
    internalNodeId: string,
    message?: string | null,
  } | null,
};

export type SetPendingStateMutationVariables = {
  customerId?: string | null,
  eventId?: string | null,
};

export type SetPendingStateMutation = {
  setPendingState:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type TakeEventToAuditMutationVariables = {
  customerId?: string | null,
  eventId?: string | null,
};

export type TakeEventToAuditMutation = {
  takeEventToAudit:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type TakeEventToValidateMutationVariables = {
  customerId?: string | null,
  eventId?: string | null,
};

export type TakeEventToValidateMutation = {
  takeEventToValidate:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type UpdateAdminEmailAlertMutationVariables = {
  input: UpdateAdminEmailAlertInput,
};

export type UpdateAdminEmailAlertMutation = {
  updateAdminEmailAlert?:  {
    __typename: "Alert",
    additionalEmails?: Array< string | null > | null,
    alertName?: string | null,
    alertType?: string | null,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    status?: boolean | null,
  } | null,
};

export type UpdateAdminSystemAlertMutationVariables = {
  input: UpdateAdminSystemAlertInput,
};

export type UpdateAdminSystemAlertMutation = {
  updateAdminSystemAlert?:  {
    __typename: "Alert",
    additionalEmails?: Array< string | null > | null,
    alertName?: string | null,
    alertType?: string | null,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    status?: boolean | null,
  } | null,
};

export type UpdateAlertMutationVariables = {
  input: UpdateAlertInput,
};

export type UpdateAlertMutation = {
  updateAlert?:  {
    __typename: "Alert",
    additionalEmails?: Array< string | null > | null,
    alertName?: string | null,
    alertType?: string | null,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    status?: boolean | null,
  } | null,
};

export type UpdateAlertStatusMutationVariables = {
  input: UpdateAlertStatusInput,
};

export type UpdateAlertStatusMutation = {
  updateAlertStatus?:  {
    __typename: "Alert",
    additionalEmails?: Array< string | null > | null,
    alertName?: string | null,
    alertType?: string | null,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    status?: boolean | null,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input?: CustomerInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    domains?: Array< string > | null,
    id: string,
    name?: string | null,
    pointOfContact?: string | null,
  } | null,
};

export type UpdateDTProjectMutationVariables = {
  input: DTProjectInput,
};

export type UpdateDTProjectMutation = {
  updateDTProject?:  {
    __typename: "DTProject",
    configURL?: string | null,
    customerId?: string | null,
    displayName?: string | null,
    id?: string | null,
    projectId?: string | null,
  } | null,
};

export type UpdateDeviceMutationVariables = {
  input: UpdateDeviceInput,
};

export type UpdateDeviceMutation = {
  updateDevice?:  {
    __typename: "Device",
    customerId: string,
    deviceData?: string | null,
    id: string,
    locationId: string,
    makeModelId: string,
    name: string,
    nodeId: string,
  } | null,
};

export type UpdateDeviceMakeMutationVariables = {
  input: UpdateDeviceMakeInput,
};

export type UpdateDeviceMakeMutation = {
  updateDeviceMake?:  {
    __typename: "DeviceMake",
    id: string,
    model: string,
    name: string,
    rtspTemplate: string,
    type: string,
  } | null,
};

export type UpdateLocationMutationVariables = {
  input: LocationInput,
};

export type UpdateLocationMutation = {
  updateLocation?:  {
    __typename: "Location",
    id: string,
    locationData?: string | null,
    name: string,
    tags?: Array< string | null > | null,
  } | null,
};

export type UpdateNodeMutationVariables = {
  input: UpdateNodeInput,
};

export type UpdateNodeMutation = {
  updateNode:  {
    __typename: "Node",
    customerId?: string | null,
    deviceData?: string | null,
    id: string,
    level?: NodeLevel | null,
    locationId?: string | null,
    nodeId?: string | null,
    nodeName?: string | null,
    onboardCommand?: string | null,
    serviceData?: string | null,
    tags?: Array< string | null > | null,
  },
};

export type UpdateProfileMutationVariables = {
  input?: ProfileInput | null,
};

export type UpdateProfileMutation = {
  updateProfile?:  {
    __typename: "Profile",
    file?: string | null,
    id: string,
    name?: string | null,
  } | null,
};

export type UpdateServiceMutationVariables = {
  input: UpdateServiceInput,
};

export type UpdateServiceMutation = {
  updateService?:  {
    __typename: "Service",
    configuration?: string | null,
    customerId?: string | null,
    deviceId?: string | null,
    id?: string | null,
    locationId?: string | null,
    nodeId?: string | null,
    serviceType?: string | null,
  } | null,
};

export type UpdateShadowNodeMutationVariables = {
  payload?: string | null,
  shadowName?: string | null,
  thingName?: string | null,
};

export type UpdateShadowNodeMutation = {
  updateShadowNode?:  {
    __typename: "Shadow",
    shadow?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UserInput,
};

export type UpdateUserMutation = {
  updateUser:  {
    __typename: "User",
    customer_id?: string | null,
    email?: string | null,
    firstName?: string | null,
    id?: string | null,
    lastName?: string | null,
    user_group?: string | null,
    user_role?: string | null,
  },
};

export type UpdateUserAlertMutationVariables = {
  input: UpdateUserAlertInput,
};

export type UpdateUserAlertMutation = {
  updateUserAlert?:  {
    __typename: "Alert",
    additionalEmails?: Array< string | null > | null,
    alertName?: string | null,
    alertType?: string | null,
    customService?: string | null,
    email?: string | null,
    id?: string | null,
    jsonObject?: string | null,
    location?: string | null,
    locationTag?: Array< string | null > | null,
    model?: string | null,
    node?: string | null,
    status?: boolean | null,
  } | null,
};

export type UpdateZoneMutationVariables = {
  input: UpdateZoneInput,
};

export type UpdateZoneMutation = {
  updateZone:  {
    __typename: "Zone",
    deviceId: string,
    id: string,
    mediaUrl?: string | null,
    name: string,
    orderNumber?: string | null,
    pan: string,
    serviceId?: string | null,
    status: boolean,
    tilt: string,
  },
};

export type UploadDTObjectMutationVariables = {
  input: UploadDTObject,
};

export type UploadDTObjectMutation = {
  uploadDTObject: string,
};

export type ValidateEventMutationVariables = {
  input: ValidateEventInput,
};

export type ValidateEventMutation = {
  validateEvent:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  },
};

export type GetAllAlertsQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllAlertsQuery = {
  getAllAlerts:  {
    __typename: "PaginatedAlerts",
    items:  Array< {
      __typename: "Alert",
      additionalEmails?: Array< string | null > | null,
      alertName?: string | null,
      alertType?: string | null,
      customService?: string | null,
      email?: string | null,
      id?: string | null,
      jsonObject?: string | null,
      location?: string | null,
      locationTag?: Array< string | null > | null,
      model?: string | null,
      node?: string | null,
      status?: boolean | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAllEmailsForSubscriptionEntityQueryVariables = {
  entity: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllEmailsForSubscriptionEntityQuery = {
  getAllEmailsForSubscriptionEntity:  {
    __typename: "PaginatedAllEmailsForSubscriptionEntity",
    items:  Array< {
      __typename: "AlertSubscription",
      email: string,
      entity?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAllSubscriptionsQueryVariables = {
  entity: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllSubscriptionsQuery = {
  getAllSubscriptions:  {
    __typename: "PaginatedAlertSubscriptions",
    items:  Array< {
      __typename: "AlertSubscription",
      email: string,
      entity?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAllWildCardsForSubscriptionEntityQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllWildCardsForSubscriptionEntityQuery = {
  getAllWildCardsForSubscriptionEntity:  {
    __typename: "PaginatedWildCardsForSubscriptionEntity",
    items:  Array< {
      __typename: "AlertWildCardSubscription",
      email: string,
      entity: string,
      wildCard: string,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetAnnotationsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  serviceId: string,
  zoneId: string,
};

export type GetAnnotationsQuery = {
  getAnnotations:  {
    __typename: "PaginatedAnnotations",
    items:  Array< {
      __typename: "Annotation",
      annotationType: string,
      id?: string | null,
      name?: string | null,
      polygon?: string | null,
      serviceId: string,
      zoneId: string,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetCustomersQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetCustomersQuery = {
  getCustomers:  {
    __typename: "PaginatedCustomers",
    items:  Array< {
      __typename: "Customer",
      domains?: Array< string > | null,
      id: string,
      name?: string | null,
      pointOfContact?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetDTCustomerProjectsQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDTCustomerProjectsQuery = {
  getDTCustomerProjects?:  {
    __typename: "PaginatedDTProjects",
    items:  Array< {
      __typename: "DTProject",
      configURL?: string | null,
      customerId?: string | null,
      displayName?: string | null,
      id?: string | null,
      projectId?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  } | null,
};

export type GetDTObjectQueryVariables = {
  key: string,
};

export type GetDTObjectQuery = {
  getDTObject: string,
};

export type GetDTObjectsQuery = {
  getDTObjects?: Array< string | null > | null,
};

export type GetDTProjectQueryVariables = {
  customerId: string,
  projectId: string,
};

export type GetDTProjectQuery = {
  getDTProject?:  {
    __typename: "DTProject",
    configURL?: string | null,
    customerId?: string | null,
    displayName?: string | null,
    id?: string | null,
    projectId?: string | null,
  } | null,
};

export type GetDTProjectsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDTProjectsQuery = {
  getDTProjects?:  {
    __typename: "PaginatedDTProjects",
    items:  Array< {
      __typename: "DTProject",
      configURL?: string | null,
      customerId?: string | null,
      displayName?: string | null,
      id?: string | null,
      projectId?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  } | null,
};

export type GetDevicesQueryVariables = {
  customerId: string,
  limit?: number | null,
  locationId?: string | null,
  nextToken?: string | null,
  nodeId?: string | null,
};

export type GetDevicesQuery = {
  getDevices:  {
    __typename: "PaginatedDevices",
    items:  Array< {
      __typename: "Device",
      customerId: string,
      deviceData?: string | null,
      id: string,
      locationId: string,
      makeModelId: string,
      name: string,
      nodeId: string,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetDevicesMakesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDevicesMakesQuery = {
  getDevicesMakes:  {
    __typename: "PaginatedDevicesMakes",
    items:  Array< {
      __typename: "DeviceMake",
      id: string,
      model: string,
      name: string,
      rtspTemplate: string,
      type: string,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetEventsPendingHumanValidationQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetEventsPendingHumanValidationQuery = {
  getEventsPendingHumanValidation:  {
    __typename: "PaginatedHumanValidationEvents",
    items:  Array< {
      __typename: "HumanValidationEvent",
      audited_by?: string | null,
      auditorsExplanation?: string | null,
      customerId: string,
      data?: string | null,
      eventTime: string,
      explanation?: string | null,
      id: string,
      locationId: string,
      nodeId: string,
      serviceId: string,
      transitionInfo?: string | null,
      validated_by?: string | null,
      validationState?: ValidationState | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetFormQueryVariables = {
  customerId: string,
  itemId: string,
};

export type GetFormQuery = {
  getForm:  {
    __typename: "Form",
    customerId?: string | null,
    fields?:  Array< {
      __typename: "Field",
      entries?: Array< string | null > | null,
      itemToQuery?: string | null,
      name: string,
      type: string,
    } | null > | null,
    id?: string | null,
  },
};

export type GetHumanValidatedEventsForAuditingQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetHumanValidatedEventsForAuditingQuery = {
  getHumanValidatedEventsForAuditing:  {
    __typename: "PaginatedHumanValidationEvents",
    items:  Array< {
      __typename: "HumanValidationEvent",
      audited_by?: string | null,
      auditorsExplanation?: string | null,
      customerId: string,
      data?: string | null,
      eventTime: string,
      explanation?: string | null,
      id: string,
      locationId: string,
      nodeId: string,
      serviceId: string,
      transitionInfo?: string | null,
      validated_by?: string | null,
      validationState?: ValidationState | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetLocationsQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetLocationsQuery = {
  getLocations:  {
    __typename: "PaginatedLocations",
    items:  Array< {
      __typename: "Location",
      id: string,
      locationData?: string | null,
      name: string,
      tags?: Array< string | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetLocationsByTagQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
  tag: string,
};

export type GetLocationsByTagQuery = {
  getLocationsByTag:  {
    __typename: "PaginatedLocations",
    items:  Array< {
      __typename: "Location",
      id: string,
      locationData?: string | null,
      name: string,
      tags?: Array< string | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetNodesQueryVariables = {
  limit?: number | null,
  locationId: string,
  nextToken?: string | null,
};

export type GetNodesQuery = {
  getNodes:  {
    __typename: "PaginatedNodes",
    items:  Array< {
      __typename: "Node",
      customerId?: string | null,
      deviceData?: string | null,
      id: string,
      level?: NodeLevel | null,
      locationId?: string | null,
      nodeId?: string | null,
      nodeName?: string | null,
      onboardCommand?: string | null,
      serviceData?: string | null,
      tags?: Array< string | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetNodesByCustomerQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetNodesByCustomerQuery = {
  getNodesByCustomer:  {
    __typename: "PaginatedNodes",
    items:  Array< {
      __typename: "Node",
      customerId?: string | null,
      deviceData?: string | null,
      id: string,
      level?: NodeLevel | null,
      locationId?: string | null,
      nodeId?: string | null,
      nodeName?: string | null,
      onboardCommand?: string | null,
      serviceData?: string | null,
      tags?: Array< string | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetNodesByTagQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  tagId: string,
};

export type GetNodesByTagQuery = {
  getNodesByTag:  {
    __typename: "PaginatedNodes",
    items:  Array< {
      __typename: "Node",
      customerId?: string | null,
      deviceData?: string | null,
      id: string,
      level?: NodeLevel | null,
      locationId?: string | null,
      nodeId?: string | null,
      nodeName?: string | null,
      onboardCommand?: string | null,
      serviceData?: string | null,
      tags?: Array< string | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetPendingUsersByCompanyQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetPendingUsersByCompanyQuery = {
  getPendingUsersByCompany:  {
    __typename: "PaginatedUsers",
    items:  Array< {
      __typename: "User",
      customer_id?: string | null,
      email?: string | null,
      firstName?: string | null,
      id?: string | null,
      lastName?: string | null,
      user_group?: string | null,
      user_role?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetProfileQuery = {
  getProfile?:  {
    __typename: "Profile",
    file?: string | null,
    id: string,
    name?: string | null,
  } | null,
};

export type GetRolesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetRolesQuery = {
  getRoles:  {
    __typename: "PaginatedRoles",
    items:  Array< {
      __typename: "Role",
      id: string,
      name: string,
      userGroup?: Array< string | null > | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetSendEmailQueryVariables = {
  email?: string | null,
};

export type GetSendEmailQuery = {
  getSendEmail?: string | null,
};

export type GetServicesQueryVariables = {
  customerId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetServicesQuery = {
  getServices:  {
    __typename: "PaginatedServices",
    items:  Array< {
      __typename: "Service",
      configuration?: string | null,
      customerId?: string | null,
      deviceId?: string | null,
      id?: string | null,
      locationId?: string | null,
      nodeId?: string | null,
      serviceType?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetShadowNodeQueryVariables = {
  shadowName?: string | null,
  thingName?: string | null,
};

export type GetShadowNodeQuery = {
  getShadowNode?:  {
    __typename: "Shadow",
    shadow?: string | null,
  } | null,
};

export type GetSubscriptionsForEmailQueryVariables = {
  email: string,
  entity: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetSubscriptionsForEmailQuery = {
  getSubscriptionsForEmail:  {
    __typename: "PaginatedSubscriptionsForEmail",
    items:  Array< {
      __typename: "AlertSubscription",
      email: string,
      entity?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetTimestreamDataQueryVariables = {
  customerId: string,
};

export type GetTimestreamDataQuery = {
  getTimestreamData?: string | null,
};

export type GetUserAlertsQueryVariables = {
  customerId: string,
  email: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUserAlertsQuery = {
  getUserAlerts:  {
    __typename: "PaginatedAlerts",
    items:  Array< {
      __typename: "Alert",
      additionalEmails?: Array< string | null > | null,
      alertName?: string | null,
      alertType?: string | null,
      customService?: string | null,
      email?: string | null,
      id?: string | null,
      jsonObject?: string | null,
      location?: string | null,
      locationTag?: Array< string | null > | null,
      model?: string | null,
      node?: string | null,
      status?: boolean | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetUsersByCompanyQueryVariables = {
  customerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUsersByCompanyQuery = {
  getUsersByCompany:  {
    __typename: "PaginatedUsers",
    items:  Array< {
      __typename: "User",
      customer_id?: string | null,
      email?: string | null,
      firstName?: string | null,
      id?: string | null,
      lastName?: string | null,
      user_group?: string | null,
      user_role?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetUsersByCompanyAndFirstNameQueryVariables = {
  customerId: string,
  firstName: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUsersByCompanyAndFirstNameQuery = {
  getUsersByCompanyAndFirstName:  {
    __typename: "PaginatedUsers",
    items:  Array< {
      __typename: "User",
      customer_id?: string | null,
      email?: string | null,
      firstName?: string | null,
      id?: string | null,
      lastName?: string | null,
      user_group?: string | null,
      user_role?: string | null,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type GetZonesQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
  serviceId: string,
};

export type GetZonesQuery = {
  getZones:  {
    __typename: "PaginatedZones",
    items:  Array< {
      __typename: "Zone",
      deviceId: string,
      id: string,
      mediaUrl?: string | null,
      name: string,
      orderNumber?: string | null,
      pan: string,
      serviceId?: string | null,
      status: boolean,
      tilt: string,
    } | null >,
    limit?: number | null,
    nextToken?: string | null,
  },
};

export type ListenToHumanValidationEventsSubscription = {
  listenToHumanValidationEvents?:  {
    __typename: "HumanValidationEvent",
    audited_by?: string | null,
    auditorsExplanation?: string | null,
    customerId: string,
    data?: string | null,
    eventTime: string,
    explanation?: string | null,
    id: string,
    locationId: string,
    nodeId: string,
    serviceId: string,
    transitionInfo?: string | null,
    validated_by?: string | null,
    validationState?: ValidationState | null,
  } | null,
};

export type ListenToNodeFilesSubscriptionVariables = {
  nodeId: string,
};

export type ListenToNodeFilesSubscription = {
  listenToNodeFiles?:  {
    __typename: "NodeFile",
    downloadURL: string,
    file?: string | null,
    internalNodeId: string,
  } | null,
};

export type ListenToNodeMessagesSubscriptionVariables = {
  nodeId: string,
};

export type ListenToNodeMessagesSubscription = {
  listenToNodeMessages?:  {
    __typename: "NodeMessage",
    internalNodeId: string,
    message?: string | null,
  } | null,
};
