/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAllAlerts = /* GraphQL */ `
  query GetAllAlerts($customerId: String!, $limit: Int, $nextToken: String) {
    getAllAlerts(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        additionalEmails
        alertName
        alertType
        customService
        email
        id
        jsonObject
        location
        locationTag
        model
        node
        status
      }
      limit
      nextToken
    }
  }
`;
export const getAllEmailsForSubscriptionEntity = /* GraphQL */ `
  query GetAllEmailsForSubscriptionEntity(
    $entity: String!
    $limit: Int
    $nextToken: String
  ) {
    getAllEmailsForSubscriptionEntity(
      entity: $entity
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        entity
      }
      limit
      nextToken
    }
  }
`;
export const getAllSubscriptions = /* GraphQL */ `
  query GetAllSubscriptions($entity: String!, $limit: Int, $nextToken: String) {
    getAllSubscriptions(entity: $entity, limit: $limit, nextToken: $nextToken) {
      items {
        email
        entity
      }
      limit
      nextToken
    }
  }
`;
export const getAllWildCardsForSubscriptionEntity = /* GraphQL */ `
  query GetAllWildCardsForSubscriptionEntity($limit: Int, $nextToken: String) {
    getAllWildCardsForSubscriptionEntity(limit: $limit, nextToken: $nextToken) {
      items {
        email
        entity
        wildCard
      }
      limit
      nextToken
    }
  }
`;
export const getAnnotations = /* GraphQL */ `
  query GetAnnotations(
    $limit: Int
    $nextToken: String
    $serviceId: String!
    $zoneId: String!
  ) {
    getAnnotations(
      limit: $limit
      nextToken: $nextToken
      serviceId: $serviceId
      zoneId: $zoneId
    ) {
      items {
        annotationType
        id
        name
        polygon
        serviceId
        zoneId
      }
      limit
      nextToken
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($limit: Int, $nextToken: String) {
    getCustomers(limit: $limit, nextToken: $nextToken) {
      items {
        domains
        id
        name
        pointOfContact
      }
      limit
      nextToken
    }
  }
`;
export const getDTCustomerProjects = /* GraphQL */ `
  query GetDTCustomerProjects(
    $customerId: String!
    $limit: Int
    $nextToken: String
  ) {
    getDTCustomerProjects(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        configURL
        customerId
        displayName
        id
        projectId
      }
      limit
      nextToken
    }
  }
`;
export const getDTObject = /* GraphQL */ `
  query GetDTObject($key: String!) {
    getDTObject(key: $key)
  }
`;
export const getDTObjects = /* GraphQL */ `
  query GetDTObjects {
    getDTObjects
  }
`;
export const getDTProject = /* GraphQL */ `
  query GetDTProject($customerId: String!, $projectId: String!) {
    getDTProject(customerId: $customerId, projectId: $projectId) {
      configURL
      customerId
      displayName
      id
      projectId
    }
  }
`;
export const getDTProjects = /* GraphQL */ `
  query GetDTProjects($limit: Int, $nextToken: String) {
    getDTProjects(limit: $limit, nextToken: $nextToken) {
      items {
        configURL
        customerId
        displayName
        id
        projectId
      }
      limit
      nextToken
    }
  }
`;
export const getDevices = /* GraphQL */ `
  query GetDevices(
    $customerId: String!
    $limit: Int
    $locationId: String
    $nextToken: String
    $nodeId: String
  ) {
    getDevices(
      customerId: $customerId
      limit: $limit
      locationId: $locationId
      nextToken: $nextToken
      nodeId: $nodeId
    ) {
      items {
        customerId
        deviceData
        id
        locationId
        makeModelId
        name
        nodeId
      }
      limit
      nextToken
    }
  }
`;
export const getDevicesMakes = /* GraphQL */ `
  query GetDevicesMakes($limit: Int, $nextToken: String) {
    getDevicesMakes(limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        name
        rtspTemplate
        type
      }
      limit
      nextToken
    }
  }
`;
export const getEventsPendingHumanValidation = /* GraphQL */ `
  query GetEventsPendingHumanValidation($limit: Int, $nextToken: String) {
    getEventsPendingHumanValidation(limit: $limit, nextToken: $nextToken) {
      items {
        audited_by
        auditorsExplanation
        customerId
        data
        eventTime
        explanation
        id
        locationId
        nodeId
        serviceId
        transitionInfo
        validated_by
        validationState
      }
      limit
      nextToken
    }
  }
`;
export const getForm = /* GraphQL */ `
  query GetForm($customerId: String!, $itemId: String!) {
    getForm(customerId: $customerId, itemId: $itemId) {
      customerId
      fields {
        entries
        itemToQuery
        name
        type
      }
      id
    }
  }
`;
export const getHumanValidatedEventsForAuditing = /* GraphQL */ `
  query GetHumanValidatedEventsForAuditing($limit: Int, $nextToken: String) {
    getHumanValidatedEventsForAuditing(limit: $limit, nextToken: $nextToken) {
      items {
        audited_by
        auditorsExplanation
        customerId
        data
        eventTime
        explanation
        id
        locationId
        nodeId
        serviceId
        transitionInfo
        validated_by
        validationState
      }
      limit
      nextToken
    }
  }
`;
export const getLocations = /* GraphQL */ `
  query GetLocations($customerId: String!, $limit: Int, $nextToken: String) {
    getLocations(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        locationData
        name
        tags
      }
      limit
      nextToken
    }
  }
`;
export const getLocationsByTag = /* GraphQL */ `
  query GetLocationsByTag(
    $customerId: String!
    $limit: Int
    $nextToken: String
    $tag: String!
  ) {
    getLocationsByTag(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
      tag: $tag
    ) {
      items {
        id
        locationData
        name
        tags
      }
      limit
      nextToken
    }
  }
`;
export const getNodes = /* GraphQL */ `
  query GetNodes($limit: Int, $locationId: String!, $nextToken: String) {
    getNodes(limit: $limit, locationId: $locationId, nextToken: $nextToken) {
      items {
        customerId
        deviceData
        id
        level
        locationId
        nodeId
        nodeName
        onboardCommand
        serviceData
        tags
      }
      limit
      nextToken
    }
  }
`;
export const getNodesByCustomer = /* GraphQL */ `
  query GetNodesByCustomer(
    $customerId: String!
    $limit: Int
    $nextToken: String
  ) {
    getNodesByCustomer(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        customerId
        deviceData
        id
        level
        locationId
        nodeId
        nodeName
        onboardCommand
        serviceData
        tags
      }
      limit
      nextToken
    }
  }
`;
export const getNodesByTag = /* GraphQL */ `
  query GetNodesByTag($limit: Int, $nextToken: String, $tagId: String!) {
    getNodesByTag(limit: $limit, nextToken: $nextToken, tagId: $tagId) {
      items {
        customerId
        deviceData
        id
        level
        locationId
        nodeId
        nodeName
        onboardCommand
        serviceData
        tags
      }
      limit
      nextToken
    }
  }
`;
export const getPendingUsersByCompany = /* GraphQL */ `
  query GetPendingUsersByCompany(
    $customerId: String!
    $limit: Int
    $nextToken: String
  ) {
    getPendingUsersByCompany(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        customer_id
        email
        firstName
        id
        lastName
        user_group
        user_role
      }
      limit
      nextToken
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile {
    getProfile {
      file
      id
      name
    }
  }
`;
export const getRoles = /* GraphQL */ `
  query GetRoles($limit: Int, $nextToken: String) {
    getRoles(limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        userGroup
      }
      limit
      nextToken
    }
  }
`;
export const getSendEmail = /* GraphQL */ `
  query GetSendEmail($email: String) {
    getSendEmail(email: $email)
  }
`;
export const getServices = /* GraphQL */ `
  query GetServices($customerId: String, $limit: Int, $nextToken: String) {
    getServices(customerId: $customerId, limit: $limit, nextToken: $nextToken) {
      items {
        configuration
        customerId
        deviceId
        id
        locationId
        nodeId
        serviceType
      }
      limit
      nextToken
    }
  }
`;
export const getShadowNode = /* GraphQL */ `
  query GetShadowNode($shadowName: String, $thingName: String) {
    getShadowNode(shadowName: $shadowName, thingName: $thingName) {
      shadow
    }
  }
`;
export const getSubscriptionsForEmail = /* GraphQL */ `
  query GetSubscriptionsForEmail(
    $email: String!
    $entity: String!
    $limit: Int
    $nextToken: String
  ) {
    getSubscriptionsForEmail(
      email: $email
      entity: $entity
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        entity
      }
      limit
      nextToken
    }
  }
`;
export const getTimestreamData = /* GraphQL */ `
  query GetTimestreamData($customerId: String!) {
    getTimestreamData(customerId: $customerId)
  }
`;
export const getUserAlerts = /* GraphQL */ `
  query GetUserAlerts(
    $customerId: String!
    $email: String!
    $limit: Int
    $nextToken: String
  ) {
    getUserAlerts(
      customerId: $customerId
      email: $email
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        additionalEmails
        alertName
        alertType
        customService
        email
        id
        jsonObject
        location
        locationTag
        model
        node
        status
      }
      limit
      nextToken
    }
  }
`;
export const getUsersByCompany = /* GraphQL */ `
  query GetUsersByCompany(
    $customerId: String!
    $limit: Int
    $nextToken: String
  ) {
    getUsersByCompany(
      customerId: $customerId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        customer_id
        email
        firstName
        id
        lastName
        user_group
        user_role
      }
      limit
      nextToken
    }
  }
`;
export const getUsersByCompanyAndFirstName = /* GraphQL */ `
  query GetUsersByCompanyAndFirstName(
    $customerId: String!
    $firstName: String!
    $limit: Int
    $nextToken: String
  ) {
    getUsersByCompanyAndFirstName(
      customerId: $customerId
      firstName: $firstName
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        customer_id
        email
        firstName
        id
        lastName
        user_group
        user_role
      }
      limit
      nextToken
    }
  }
`;
export const getZones = /* GraphQL */ `
  query GetZones($limit: Int, $nextToken: String, $serviceId: String!) {
    getZones(limit: $limit, nextToken: $nextToken, serviceId: $serviceId) {
      items {
        deviceId
        id
        mediaUrl
        name
        orderNumber
        pan
        serviceId
        status
        tilt
      }
      limit
      nextToken
    }
  }
`;
