import { Grid, Paper, Typography } from "@mui/material";

import AnnotationsTable from "../components/AnnotationsTable";

const AnnotationsTableContainer = ({
  rows,
  loading
}: {
  rows: any;
  loading: any;
}): JSX.Element => {
  return (
    <Paper sx={{ padding: "1em" }} elevation={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}></Grid>
      </Grid>
      <br />
      <Typography>Annotations List</Typography>
      <AnnotationsTable
        rows={rows}
        loading={loading}
        zoneId={"Z#rjZone"}
        serviceId={"S#gasLeakService"}
      />
    </Paper>
  );
};

export default AnnotationsTableContainer;
