import { useMemo } from "react";

import { useGetLazyPaginatedUserAlerts } from "./useGetLazyPaginatedUserAlerts";

export interface IUserAlertsTableRow {
  rowId: string;
  alertName: string;
  status: boolean;
}

export interface IUseUserAlertsTableRow {
  rows: IUserAlertsTableRow[];
  loading: boolean;
}

export const useUserAlertsTableRow = (): IUseUserAlertsTableRow => {
  const { data, loading } = useGetLazyPaginatedUserAlerts();

  const rows = useMemo((): IUserAlertsTableRow[] => {
    return (
      data?.getUserAlerts.items?.map(item => ({
        rowId: item?.id ?? "",
        alertName: item?.alertName ?? "",
        email: item?.email ?? "",
        status: item?.status ?? false,
      })) ?? []
    );
  }, [data?.getUserAlerts.items]);

  return { rows, loading };
};
