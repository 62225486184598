/* eslint-disable */
// this is caylent's file, will keep their original formatting

import { Amplify, API, Auth, Storage } from "aws-amplify";
import React from "react";
import Observable from "zen-observable-ts";
import aws_exports from "./aws-exports.json";
import { getUserAccountType } from "./common/utils/getUserAccountType";
import {
  addAlertSubscription,
  addAlertWildCardSubscription,
  addCustomer,
  addDomain,
  addLocation,
  addNode,
  addService,
  addZone,
  changeNodeStatus,
  deleteLocation,
  overruleHumanValidatedEvents,
  publishNode,
  sendToListenToNodeMessages,
  takeEventToAudit,
  takeEventToValidate,
  updateCustomer,
  updateLocation,
  updateNode,
  updateProfile,
  updateShadowNode,
  validateEvent,
  addDTProject,
} from "./graphql/mutations";
import {
  getAllEmailsForSubscriptionEntity,
  getAllSubscriptions,
  getAllWildCardsForSubscriptionEntity,
  getCustomers,
  getEventsPendingHumanValidation,
  getForm,
  getHumanValidatedEventsForAuditing,
  getLocations,
  getLocationsByTag,
  getNodes,
  getNodesByCustomer,
  getNodesByTag,
  getProfile,
  getServices,
  getShadowNode,
  getSubscriptionsForEmail,
} from "./graphql/queries";
import {
  listenToHumanValidationEvents,
  listenToNodeFiles,
  listenToNodeMessages,
} from "./graphql/subscriptions";

Amplify.configure({
  ...aws_exports,
});

if (window.location.hostname.indexOf("localhost") !== -1) {
  let params = new URL(window.location.toString()).searchParams;
  let targetDomain = params.get("targetDomain");

  if (targetDomain) {
    Auth.configure({
      endpoint: `https://${targetDomain}/auth`,
    });
  } else {
    Auth.configure({
      endpoint: "https://dev.autonomous365.ai/auth",
    });
  }
} else {
  Auth.configure({
    endpoint: `https://${window.location.hostname}/auth`,
  });
}

class App extends React.Component {
  private observables: any[] = [];

  componentDidMount() {}

  componentDidUpdate() {}

  signIn() {
    return Auth.signIn(prompt("What's your email?") ?? "", "")
      .then(user => {
        if (user.challengeName === "CUSTOM_CHALLENGE") {
          Auth.sendCustomChallengeAnswer(
            user,
            prompt("What's the code you received in the email?") ?? ""
          )
            .then(user => console.log(user))
            .catch(err => console.log(err));
        } else {
          console.log(user);
        }
      })
      .catch(err => console.log(err));
  }

  signUp() {
    try {
      return Auth.signUp({
        username: prompt("What's your email?") ?? "",
        password: "Uwe27Ft$$90A",
      }).then(user => {
        console.log(JSON.stringify(user));
      });
    } catch (error) {
      console.log("error signing up:", error);
    }
  }

  signOut() {
    try {
      return Auth.signOut();
    } catch (error) {
      console.log("error signing up:", error);
    }
  }

  currentSession() {
    return Auth.currentSession()
      .then(session => console.log(session))
      .catch(err => console.log(err));
  }

  refreshToken() {
    return Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then(user => console.log(user))
      .catch(err => console.log(err));
  }

  updateAuthenticatedUserAttribute() {
    return Auth.currentAuthenticatedUser({
      bypassCache: false,
    }).then(user => {
      const attribute: any = {};
      attribute[prompt("What's the name of the attribute?") ?? ""] =
        prompt("What's the value of the attribute?") ?? "";
      return Auth.updateUserAttributes(user, attribute);
    });
  }

  verifyAuthenticatedUserAttribute() {
    return Auth.currentAuthenticatedUser({
      bypassCache: false,
    }).then(user => {
      return Auth.verifyUserAttributeSubmit(
        user,
        prompt("What's the name of the attribute?") ?? "",
        prompt("What's the verification code?") ?? ""
      );
    });
  }

  async printAccountUserType() {
    console.log(await getUserAccountType());
  }

  async apiMutationAddNode() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: addNode,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            locationId: prompt("What's the locationId"),
            nodeName: prompt("What's the nodeName"),
            level: prompt("What's the level? (LOCATION, NODE, CUSTOMER)"),
            tags: ["hi", "hello"],
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationUpdateNode() {
    try {
      const graphqlApiResponse = API.graphql({
        query: updateNode,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            nodeId: prompt("What's the nodeId"),
            serviceData: prompt("What's the serviceData"),
            deviceData: prompt("What's device Data"),
          },
        },
      });
      if (graphqlApiResponse instanceof Promise) {
        const result = await graphqlApiResponse;
        console.log(result.data);
        return graphqlApiResponse;
      }

      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationChangeNodeStatus() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: changeNodeStatus,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            nodeId: prompt("What's the nodeId"),
            action: prompt("What's the action (DELETE OR DISABLE OR ACTIVATE)"),
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationUpdateProfile() {
    try {
      let sub = await Auth.currentSession()
        .then(session => {
          return session.getAccessToken().payload.sub;
        })
        .catch(err => console.log(err));
      const file = new File(["foo"], "/foo.txt", {
        type: "text/plain",
      });
      let response = await Storage.put(sub + "/foo.txt", file, {
        level: "private",
      });
      const graphqlApiResponse = await API.graphql({
        query: updateProfile,
        variables: {
          input: {
            name: prompt("What's the name?"),
            file: response.key,
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetProfile() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: getProfile,
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationAddDTProject(customerId: string, displayName: string) {
    try {
      const graphqlApiResponse = await API.graphql({
        query: addDTProject,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          customerId,
          displayName,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationAddCustomer() {
    let additionalGroupId;
    try {
      const graphqlApiResponse = await API.graphql({
        query: addCustomer,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          customerName: prompt("What's the customer name?"),
          additionalAdminGroupId:
            (additionalGroupId = prompt(
              "What's the additional admin group id? ('X' if none)"
            )) === "X"
              ? undefined
              : additionalGroupId,
          domains: ["appOld.com"], // domains field is required; adding it to Caylent UI
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationAddDomain(domain: string) {
    try {
      const graphqlApiResponse = API.graphql({
        query: addDomain,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          domain,
          customerId: "C#shell",
        },
      });

      if (graphqlApiResponse instanceof Promise) {
        const result = await graphqlApiResponse;
        console.log(result);
      }

      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationUpdateCustomer() {
    let additionalGroupId;
    try {
      const graphqlApiResponse = await API.graphql({
        query: updateCustomer,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            id: prompt("What's the customer id?"),
            name: prompt("What's the customer name?"),
            pointOfContact: prompt("What's the point of contact name?"),
            additionalAdminGroupId:
              (additionalGroupId = prompt(
                "What's the additional admin group id? ('X' if none)"
              )) === "X"
                ? undefined
                : additionalGroupId,
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetLocations() {
    let temp: any;
    try {
      const graphqlApiResponse = await API.graphql({
        query: getLocations,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          customerId: prompt("What's the customer ID?"),
          limit:
            (temp = prompt("What's the limit of the page? ('X' if none)")) ===
            "X"
              ? undefined
              : parseInt(temp),
          nextToken:
            (temp = prompt(
              "What's the 'nextToken' of the page? ('X' if none)"
            )) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationAddLocation() {
    let temp: any;
    try {
      const graphqlApiResponse = await API.graphql({
        query: addLocation,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            customerId: prompt("What's the customer ID?"),
            name: prompt("What's the new location name?"),
            locationData:
              (temp = prompt("What's the location data? ('X' if none)")) === "X"
                ? undefined
                : temp,
            tags:
              (temp = prompt("What' are the tags? ('X' if none)")) === "X"
                ? undefined
                : JSON.parse(temp),
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationUpdateLocation() {
    let temp: any;
    try {
      const graphqlApiResponse = await API.graphql({
        query: updateLocation,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            customerId: prompt("What's the customer ID?"),
            id: prompt("LocationId"),
            name: prompt("What's the new location name?"),
            locationData:
              (temp = prompt("What's the location data? ('X' if none)")) === "X"
                ? undefined
                : temp,
            tags:
              (temp = prompt("What' are the tags? ('X' if none)")) === "X"
                ? undefined
                : JSON.parse(temp),
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationDeleteLocation() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: deleteLocation,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          locationId: prompt("LocationId"),
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetCustomers() {
    let temp: any;
    try {
      const graphqlApiResponse = await API.graphql({
        query: getCustomers,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          limit:
            (temp = prompt("What's the limit of the page? ('X' if none)")) ===
            "X"
              ? undefined
              : parseInt(temp),
          nextToken:
            (temp = prompt(
              "What's the 'nextToken' of the page? ('X' if none)"
            )) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetNodes() {
    let temp: any;
    try {
      const graphqlApiResponse = await API.graphql({
        query: getNodes,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          locationId: prompt("What's the location ID?"),
          limit:
            (temp = prompt("What's the limit of the page? ('X' if none)")) ===
            "X"
              ? undefined
              : parseInt(temp),
          nextToken:
            (temp = prompt(
              "What's the 'nextToken' of the page? ('X' if none)"
            )) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetNodesByCustomer() {
    let temp: any;
    try {
      const graphqlApiResponse = await API.graphql({
        query: getNodesByCustomer,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          customerId: prompt("What's the customer ID?"),
          limit:
            (temp = prompt("What's the limit of the page? ('X' if none)")) ===
            "X"
              ? undefined
              : parseInt(temp),
          nextToken:
            (temp = prompt(
              "What's the 'nextToken' of the page? ('X' if none)"
            )) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetNodesByTag() {
    let temp: any;
    try {
      const graphqlApiResponse = await API.graphql({
        query: getNodesByTag,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          tagId: prompt("What's the tag ID?"),
          limit:
            (temp = prompt("What's the limit of the page? ('X' if none)")) ===
            "X"
              ? undefined
              : parseInt(temp),
          nextToken:
            (temp = prompt(
              "What's the 'nextToken' of the page? ('X' if none)"
            )) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationAddService() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: addService,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            nodeId: "nodeIDTest",
            customerId: "customerIDTest",
            locationId: "locationIDTest",
            calc_flow_rate: "String",
            display_rate: "String",
            docker_image: "String",
            minimum_leak_length: "String",
            ml_model_path: "String",
            type: "String3",
            video_duration: "String",
            configuration: JSON.stringify({
              x: "x",
              y: 1,
            }),
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetServices() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: getServices,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          customerId: "C#chevron",
          limit:
            (temp = prompt("What's the limit of the page? ('X' if none)")) ===
            "X"
              ? undefined
              : parseInt(temp),
          nextToken:
            (temp = prompt(
              "What's the 'nextToken' of the page? ('X' if none)"
            )) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async uploadFile() {
    try {
      let sub = await Auth.currentSession()
        .then(session => {
          return session.getAccessToken().payload.sub;
        })
        .catch(err => console.log(err));
      console.log(sub);
      const file = new File(["foo"], "/foo.txt", {
        type: "text/plain",
      });
      let response = await Storage.put(sub + "/foo.txt", file, {
        level: "private",
      });

      return response;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetEventsPendingHumanValidation() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: getEventsPendingHumanValidation,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          limit:
            (temp = prompt("What's the limit of the page? ('X' if none)")) ===
            "X"
              ? undefined
              : parseInt(temp),
          nextToken:
            (temp = prompt(
              "What's the 'nextToken' of the page? ('X' if none)"
            )) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetLocationsByTag() {
    let temp: any;
    try {
      const graphqlApiResponse = await API.graphql({
        query: getLocationsByTag,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          customerId: prompt("What's the customer ID?"),
          tag: prompt("What tag do you want to search"),
          limit:
            (temp = prompt("What's the limit of the page? ('X' if none)")) ===
            "X"
              ? undefined
              : parseInt(temp),
          nextToken:
            (temp = prompt(
              "What's the 'nextToken' of the page? ('X' if none)"
            )) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationPublishMessage() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: publishNode,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            nodeId: prompt("What's the node ID?"),
            subtopic: prompt("What's the level (subtopic)?"),
            message: prompt("What's the message?"),
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationGetShadowFromNode() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: getShadowNode,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          thingName: "C_shell_L_merrit_N_demotest7",
          shadowName: "",
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationUpdateShadowFromNode() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: updateShadowNode,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          thingName: "C_shell_L_merrit_N_demotest7",
          shadowName: "",
          payload: JSON.stringify({
            state: {
              desired: {
                myFirstMessage: "helloWorld!3",
                zones: {
                  wall: {
                    pan: -0.2,
                    tilt: -0.7,
                  },
                },
              },
            },
          }),
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async closeSubscriptions() {
    while (this.observables.length > 0) {
      this.observables.pop().unsubscribe();
    }
  }

  async apiSubscriptionListenToNodeFiles() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: listenToNodeFiles,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          nodeId: prompt("What's the node ID?"),
        },
      });
      if (graphqlApiResponse instanceof Observable) {
        this.observables.push(
          graphqlApiResponse.subscribe(
            next => console.log(next),
            error => console.log(error),
            () => console.log("Completed a subscription")
          )
        );
      }
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  // async apiMutationSendToListenToNodeMessages() {
  //   try {
  //     const graphqlApiResponse = await API.graphql({
  //       query: sendToListenToNodeMessages,
  //       authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
  //       variables: {
  //         nodeMessage: JSON.stringify({
  //           internalNodeId: "C_shell_L_merritt_N_lmrc",
  //           message: "hello",
  //         }),
  //       },
  //     });
  //     console.log(graphqlApiResponse);
  //     return graphqlApiResponse;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  // async apiMutationSendToListenToNodeFiles() {
  //   try {
  //     const graphqlApiResponse = await API.graphql({
  //       query: sendToListenToNodeFiles,
  //       authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
  //       variables: {
  //         nodeFile: JSON.stringify({
  //           internalNodeId: prompt("What's the node ID?"),
  //           downloadUrl: "hello",
  //           file: "hello",
  //         }),
  //       },
  //     });
  //     console.log(graphqlApiResponse);
  //     return graphqlApiResponse;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  async apiSubscriptionListenToNodeMessages() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: listenToNodeMessages,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          nodeId: prompt("What's the node ID?"),
        },
      });

      if (graphqlApiResponse instanceof Observable) {
        this.observables.push(
          graphqlApiResponse.subscribe(
            next => console.log(next),
            error => console.log(error),
            () => console.log("Completed a subscription")
          )
        );
      }
      console.log(this.observables);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationTakeEventToValidate() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: takeEventToValidate,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          customerId:
            (temp = prompt("Enter customer ID starting with 'C#")) === "X"
              ? undefined
              : temp,
          eventId:
            (temp = prompt("Enter event ID starting with 'HV#")) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationTakeEventToAudit() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: takeEventToAudit,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          customerId:
            (temp = prompt("Enter customer ID starting with 'C#")) === "X"
              ? undefined
              : temp,
          eventId:
            (temp = prompt("Enter event ID starting with 'HV#")) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiSubscriptionListenToHumanValidationEvents() {
    try {
      const graphqlApiResponse = await API.graphql({
        query: listenToHumanValidationEvents,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
      });
      if (graphqlApiResponse instanceof Observable) {
        this.observables.push(
          graphqlApiResponse.subscribe(
            next => console.log(next),
            error => console.log(error),
            () => console.log("Completed a subscription")
          )
        );
      }
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationValidateEvent() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: validateEvent,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            customerId: prompt("Enter customer ID starting with 'C#'"),
            eventId: prompt("Enter event ID starting with 'HV#'"),
            eventTime: prompt(
              "Enter the event time with format 'YYYY-MM-DDThh:mm:ss.0000'"
            ),
            real:
              prompt(
                "Is the event REAL? (Acceptable values: REAL and NOT_REAL)"
              ) === "REAL",
            explanation:
              (temp = prompt("What's the explanation? ('X' if none)")) === "X"
                ? undefined
                : temp,
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetHumanValidatedEventsForAuditing() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: getHumanValidatedEventsForAuditing,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          limit:
            (temp = prompt("What's the limit of the page? ('X' if none)")) ===
            "X"
              ? undefined
              : parseInt(temp),
          nextToken:
            (temp = prompt(
              "What's the 'nextToken' of the page? ('X' if none)"
            )) === "X"
              ? undefined
              : temp,
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetForm() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: getForm,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          customerId: "C#shell",
          itemId: "C#shell#I#GasLeak001a",
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryAddZone() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: addZone,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            pan: prompt("PAN"),
            tilt: prompt("TILT"),
            mediaUrl: prompt("MEDIAURL"),
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryAlertSubscription() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: addAlertSubscription,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            email: prompt("EMAIL"),
            entity: prompt("SUB#"),
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetSubscriptionsForEmail() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: getSubscriptionsForEmail,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          email: "email",
          entity: "entity",
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetAllWildCardsForSubscriptionEntity() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: getAllWildCardsForSubscriptionEntity,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          wildCard: "SUBWILDCARD#wildcard",
          entity: "entity",
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetAllSubscriptions() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: getAllSubscriptions,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          entity: "entity",
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryGetAllEmailsForSubscriptionEntity() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: getAllEmailsForSubscriptionEntity,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          entity: "entity",
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiQueryAlertWildCardSubscription() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: addAlertWildCardSubscription,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            email: prompt("EMAIL"),
            entity: prompt("ENTITY"),
            wildCard: prompt("WILDCARD"),
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  async apiMutationOverruleHumanValidatedEvents() {
    try {
      let temp: any;
      const graphqlApiResponse = await API.graphql({
        query: overruleHumanValidatedEvents,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          input: {
            customerId: prompt("Enter customer ID starting with 'C#"),
            eventId: prompt("Enter event ID starting with 'HV#"),
            eventTime: prompt(
              "Enter the event time with format 'YYYY-MM-DDThh:mm:ss.0000'"
            ),
            real:
              prompt(
                "Is the event REAL? (Acceptable values: REAL and NOT_REAL)"
              ) === "REAL",
            auditorsExplanation:
              (temp = prompt("What's the explanation? ('X' if none)")) === "X"
                ? undefined
                : temp,
          },
        },
      });
      console.log(graphqlApiResponse);
      return graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div>
        <label>Web Application:</label>
        <p />
        <button onClick={this.signIn}>Sign In</button>
        <button onClick={this.signUp}>Sign Up</button>
        <button onClick={this.printAccountUserType}>
          Get Account User Type
        </button>
        <p />
        <button onClick={this.signOut}>Sign Out</button>
        <button onClick={this.currentSession}>Current Session</button>
        <p />
        <button onClick={this.refreshToken}>Refresh Token</button>
        <button onClick={() => this.closeSubscriptions()}>
          Close All Subscriptions
        </button>
        <p />
        <label>Cognito:</label>
        <p />
        <button onClick={this.updateAuthenticatedUserAttribute}>
          Update Authenticated User Attribute
        </button>
        <button onClick={this.verifyAuthenticatedUserAttribute}>
          Verify Authenticated User Attribute
        </button>
        <p />
        <label>Web User's Profile:</label>
        <p />
        <button onClick={() => this.apiMutationUpdateProfile()}>
          GraphQL Mutation: updateProfile
        </button>
        <button onClick={() => this.apiQueryGetProfile()}>
          GraphQL Query: getProfile
        </button>
        <p />
        <label>Customer:</label>
        <p />
        <button onClick={() => this.apiMutationAddCustomer()}>
          GraphQL Mutation: addCustomer
        </button>
        <button onClick={() => this.apiMutationUpdateCustomer()}>
          GraphQL Mutation: updateCustomer
        </button>
        <button onClick={() => this.apiQueryGetCustomers()}>
          GraphQL Query: getCustomers
        </button>
        <p />
        <label>Location:</label>
        <p />
        <button onClick={() => this.apiMutationAddLocation()}>
          GraphQL Mutation: addLocation
        </button>
        <button onClick={() => this.apiQueryGetLocations()}>
          GraphQL Query: getLocations
        </button>
        <p />
        <button onClick={() => this.apiMutationUpdateLocation()}>
          GraphQL Mutation: updateLocation
        </button>
        <button onClick={() => this.apiMutationDeleteLocation()}>
          GraphQL Mutation: deleteLocation
        </button>
        <p />
        <button onClick={() => this.apiQueryGetLocationsByTag()}>
          GraphQL Query: getLocationsByTag
        </button>
        <p />
        <label>Services:</label>
        <p />
        <button onClick={() => this.apiMutationAddService()}>
          GraphQL Mutation: addService
        </button>
        <button onClick={() => this.apiQueryGetServices()}>
          GraphQL Query: getServices
        </button>
        <p />
        <label>Node:</label>
        <p />
        <button onClick={() => this.apiMutationAddNode()}>
          GraphQL Mutation: addNode
        </button>
        <button onClick={() => this.apiMutationChangeNodeStatus()}>
          GraphQL Mutation: updateNodeState
        </button>
        <button onClick={() => this.apiMutationUpdateNode()}>
          GraphQL Mutation: updateNode
        </button>
        <p />
        <button onClick={() => this.apiMutationPublishMessage()}>
          GraphQL Mutation: publishNode
        </button>
        <button onClick={() => this.apiMutationGetShadowFromNode()}>
          GraphQL Mutation: getShadowFromNode
        </button>
        <button onClick={() => this.apiMutationUpdateShadowFromNode()}>
          GraphQL Mutation: updateShadowFromNode
        </button>
        <button onClick={() => this.apiSubscriptionListenToNodeFiles()}>
          GraphQL Subscription: listenToNodeFiles
        </button>
        <p />
        <button onClick={() => this.apiQueryGetNodes()}>
          GraphQL Query: getNodes
        </button>
        <button onClick={() => this.apiQueryGetNodesByCustomer()}>
          GraphQL Query: getNodesByCustomer
        </button>
        <button onClick={() => this.apiQueryGetNodesByTag()}>
          GraphQL Query: getNodesByTag
        </button>
        <p />
        <label>File Upload:</label>
        <p />
        <button onClick={() => this.uploadFile()}>
          GraphQL Mutation: uploadFile
        </button>
        <p />
        <label>Human Validation:</label>
        <p />
        <button onClick={() => this.apiQueryGetEventsPendingHumanValidation()}>
          GraphQL Query: getEventsPendingHumanValidation
        </button>
        <button onClick={() => this.apiMutationTakeEventToValidate()}>
          GraphQL Mutation: takeEventToValidate
        </button>
        <button onClick={() => this.apiMutationValidateEvent()}>
          GraphQL Mutation: validateEvent
        </button>
        <p />
        <button
          onClick={() => this.apiSubscriptionListenToHumanValidationEvents()}
        >
          GraphQL Subscription: listenToHumanValidationEvents
        </button>
        <p />
        <button onClick={() => this.apiSubscriptionListenToNodeMessages()}>
          GraphQL Subscription: listenToNodeMessages
        </button>
        {/* <button onClick={() => this.apiMutationSendToListenToNodeFiles()}>
          GraphQL Subscription: sendToListenToNodeFiles
        </button>
        <button onClick={() => this.apiMutationSendToListenToNodeMessages()}>
          GraphQL Subscription: sendToListenToNodeMessages
        </button>
        <p /> */}
        <button
          onClick={() => this.apiQueryGetHumanValidatedEventsForAuditing()}
        >
          GraphQL Query: getHumanValidatedEventsForAuditing
        </button>
        <button onClick={() => this.apiMutationTakeEventToAudit()}>
          GraphQL Mutation: takeEventToAudit
        </button>
        <button onClick={() => this.apiMutationOverruleHumanValidatedEvents()}>
          GraphQL Mutation: overruleHumanValidatedEvents
        </button>
        <p />
        <button onClick={() => this.apiQueryGetForm()}>
          GraphQL Query: getForm
        </button>
        <button onClick={() => this.apiQueryAddZone()}>
          GraphQL Query: addZone
        </button>
        <p>
          <button onClick={() => this.apiQueryAlertSubscription()}>
            GraphQL Query: addAlertSubscription
          </button>
          <button onClick={() => this.apiQueryAlertWildCardSubscription()}>
            GraphQL Query: addAlertWildCardSubscription
          </button>
          <button onClick={() => this.apiQueryGetSubscriptionsForEmail()}>
            GraphQL Query: getSubscriptionsForEmail
          </button>
          <button onClick={() => this.apiMutationAddService()}>
            GraphQL Mutation: addService
          </button>
        </p>
      </div>
    );
  }
}

export default App;
