import { Button, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import MenuLink from "../../../common/components/MenuLink";

export const useDeviceTableColumns = (): { columns: GridColDef[] } => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      hideable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.name}</Typography>
        ) : null,
    },
    {
      field: "node",
      headerName: "Node",
      hideable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        if (!params.row) {
          return null;
        }

        const node = params.row.node.split("#N#").at(-1);

        return <Typography variant="body2">{node}</Typography>;
      },
    },
    {
      field: "model",
      headerName: "Model",
      hideable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.model}</Typography>
        ) : null,
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        const { deviceData } = params.row;
        let deviceDataParsed;
        if (deviceData) deviceDataParsed = JSON.parse(deviceData);
        return (
          <Stack direction="row" spacing={1}>
            <Button>
              <MenuLink
                to="/devices/zone-setup"
                state={{
                  nodeId: params.row.node,
                  deviceName: params.row.name,
                  rtspSource: deviceDataParsed
                    ? deviceDataParsed["Source Video"]
                    : "",
                }}
              >
                VIEW DEVICES
              </MenuLink>
            </Button>
            <Button>EDIT</Button>
          </Stack>
        );
      },
    },
  ];

  return { columns };
};
