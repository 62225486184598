import { Paper } from "@mui/material";

import DevicesPageHeader from "./components/DevicesPageHeader";
import DevicesTable from "./components/DevicesTable";

const DevicesPage = (): JSX.Element => {
  return (
    <Paper>
      <DevicesPageHeader />
      <DevicesTable />
    </Paper>
  );
};

export default DevicesPage;
