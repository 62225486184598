import { useState } from "react";

import { UpdateUserAlertInput } from "../../../../../../../API";
import StyledDialog from "../../../../../../../common/components/dialog/StyledDialog";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";
import { useUpdateUserAlert } from "../../../hooks/useUpdateUserAlert";
import {
  alertDialogVariables,
  defaultAlertDialogVariables,
  useAlertDialog,
} from "../../../variables/alerts";
import {
  defaultNonAdminAlertFormValues,
  nonAdminAlertFormVariables,
  useNonAdminAlertFormVariables,
} from "../../../variables/nonAdminAlerts";
import CreateNonAdminAlertForm from "./CreateNonAdminAlertForm";

interface IUpdateUserAlertDialogProps {
  id: string;
  status: boolean;
}

const UpdateUserAlertDialog = ({
  id,
  status,
}: IUpdateUserAlertDialogProps): JSX.Element => {
  const alertForm = useNonAdminAlertFormVariables();
  const selectedCustomerId = useCustomerIdGuard();

  const { isOpen, rowId } = useAlertDialog();
  const { updateAlert, loading } = useUpdateUserAlert();

  const [hasError, setHasError] = useState(false);
  const [alertNameError, setAlertNameError] = useState(false);

  const closeDialog = (): void => {
    nonAdminAlertFormVariables(defaultNonAdminAlertFormValues);
    setHasError(false);
    setAlertNameError(false);
    alertDialogVariables(defaultAlertDialogVariables);
  };

  const handleSubmit = (): void => {
    const input: UpdateUserAlertInput = {
      id: rowId,
      customerId: selectedCustomerId,
      alertName: alertForm.alertName,
      location: alertForm.location?.value ?? "",
      model: alertForm.model ?? "",
      status,
    };

    if (input.alertName === "") {
      setHasError(true);
      return;
    }

    updateAlert(input)
      .then((response): void => {
        if (response?.data) {
          successNotification();
          alertDialogVariables(defaultAlertDialogVariables);
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      });
  };

  return (
    <>
      <StyledDialog
        open={isOpen && rowId === id}
        title="Update Alert"
        maxWidth="sm"
        onClose={closeDialog}
        SubmitButtonProps={{
          loading,
          onSubmit: handleSubmit,
          title: "Update",
        }}
      >
        <CreateNonAdminAlertForm
          hasError={hasError}
          alertNameError={alertNameError}
        />
      </StyledDialog>
    </>
  );
};

export default UpdateUserAlertDialog;
