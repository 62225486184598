import { useMemo } from "react";

import { useGetDevices } from "./useGetDevices";

export const useDeviceTableRows = () => {
  const { data, loading } = useGetDevices();

  const rows = useMemo(
    () =>
      data?.getDevices?.items
        ?.map((item, index) => {
          return {
            id: index + 1,
            rowId: item?.id,
            name: item?.name,
            location: item?.locationId,
            node: item?.nodeId,
            model: item?.makeModelId,
            deviceData: item?.deviceData,
          };
        })
        .filter(item => {
          if (!window.location.hash.includes("#N#")) {
            const byLocation = item.location === `C${window.location.hash}`;

            return byLocation;
          }

          if (window.location.hash.includes("#N#")) {
            const byNodeId = item.node === `C${window.location.hash}`;

            return byNodeId;
          }

          return item;
        }) ?? [],
    [data?.getDevices?.items, window.location.hash]
  );

  return { rows, loading };
};
