import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiAppBarDarkStyleOverrides: ComponentsOverrides<Theme>["MuiAppBar"] =
  {
    root: ({ theme }) => {
      return {
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: "8px",
        padding: "1em 1.5em",
      };
    },
  };
