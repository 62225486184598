import { Button, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import AlertLocationModel from "../components/AlertLocationModel";
import AlertStatus from "../components/AlertStatus";
import TurnOffButton from "../components/actions/TurnOffButton";
import TurnOnButton from "../components/actions/TurnOnButton";
import { useTestSendEmail } from "../../../../../common/hooks/useTestSendEmail";
import ConfigureEmailAlertDialogContainer from "../components/dialogs/email/ConfigureEmailAlertDialogContainer";
import ConfigureSystemAlertDialogContainer from "../components/dialogs/system/ConfigureSystemAlertDialogContainer";
import { AlertTypeEnum } from "../variables/alerts";
import ConfigureUserAlertDialogContainer from "./../components/dialogs/user/ConfigureUserAlertDialogContainer";

export const useAlertsTableColumns = (): { columns: GridColDef[] } => {
  const { fetchSendEmail } = useTestSendEmail();

  const handleClick = (email: string): void => {
    fetchSendEmail(email);
  };

  const columns: GridColDef[] = [
    {
      field: "alertName",
      headerName: "Name",
      flex: 1,
      minWidth: 200,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.alertName}</Typography>
        ) : null,
    },
    {
      field: "location",
      headerName: "Location/Model",
      flex: 1,
      minWidth: 300,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null => {
        if (!params) {
          return null;
        }

        const location = params?.row?.location;
        const model = params?.row?.model;

        return <AlertLocationModel location={location} model={model} />;
      },
    },
    {
      field: "alertType",
      headerName: "Alert Type",
      flex: 0.25,
      minWidth: 100,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.alertType}</Typography>
        ) : null,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.25,
      minWidth: 100,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? <AlertStatus status={params?.row.status} /> : null,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 360,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        const status = params?.row.status;
        const alertType = params?.row.alertType;

        return (
          <Stack direction="row" spacing={1}>
            {alertType === AlertTypeEnum.EMAIL && (
              <ConfigureEmailAlertDialogContainer row={params?.row} />
            )}
            {alertType === AlertTypeEnum.SYSTEM && (
              <ConfigureSystemAlertDialogContainer row={params?.row} />
            )}
            {alertType === AlertTypeEnum.USER && (
              <ConfigureUserAlertDialogContainer row={params?.row} />
            )}

            <Button onClick={(): void => handleClick(params?.row.email)}>
              TEST ALERT
            </Button>

            {status && <TurnOffButton row={params.row} />}
            {!status && <TurnOnButton row={params.row} />}
          </Stack>
        );
      },
    },
  ];

  return { columns };
};
