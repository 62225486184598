import { Route, Routes } from "react-router-dom";

import AppOld from "../AppOld";
import GeneralLayout from "../common/components/layout/GeneralLayout";
import HumanValidatorLayout from "../common/components/layout/HumanValidatorLayout";
import { useAuthenticatedUser } from "../common/hooks/useAuthenticatedUser";
import { RoleEnum } from "../common/models/enums";
import DevicesPage from "../pages/devices/DevicesPage";
import CreateDevicePage from "../pages/devices/devices-create/CreateDevicePage";
import ZonesSetupPage from "../pages/devices/zones-setup-page/ZonesSetupPage";
import EmailNotificationsPage from "../pages/email-notifications/EmailNotificationsPage";
import HumanValidatorPage from "../pages/human-validator/HumanValidatorPage";
import HvReviewPage from "../pages/hv-review/HvReviewPage";
import CreateLocationPage from "../pages/locations/CreateLocationPage";
import LocationsPage from "../pages/locations/LocationsPage";
import LoginPage from "../pages/login/LoginPage";
import ModelManagerPage from "../pages/model-manager/ModelManagerPage";
import CreateServicePage from "../pages/model-manager/service-create/CreateServicePage";
import GasLeakData from "../pages/models/gasleak/components/GasLeakData";
import NodesPage from "../pages/nodes/NodesPage";
import CreateNodePage from "../pages/nodes/nodes-create/CreateNodePage";
import NotAuthorizedPage from "../pages/not-authorized-page/NotAuthorizedPage";
import PendingUserPage from "../pages/pending-user-page/PendingUserPage";
import SystemSettingsPage from "../pages/system-settings/SystemSettingsPage";
import WebglConfiguratorLayout from "../webgl/common/components/layout/WebglConfiguratorLayout";
import WebglConfiguratorDashboardPage from "../webgl/pages/dashboard/WebglConfiguratorDashboardPage";
import WebglConfiguratorProjectEditorPage from "../webgl/pages/project-editor/WebglConfiguratorProjectEditorPage";
import WebglConfiguratorProjectViewerPage from "../webgl/pages/project-viewer/WebglConfiguratorProjectViewerPage";
import RoleBasedRoute from "./RoleBasedRoute";

export const itemsToExclude = [RoleEnum.ROOT, RoleEnum.PENDING];

const AppRoutes = (): JSX.Element => {
  const { route } = useAuthenticatedUser();

  const element = route === "authenticated" ? <GeneralLayout /> : <LoginPage />;

  return (
    <Routes>
      <Route path="/" element={element} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/pending-user" element={<PendingUserPage />} />
      <Route path="/unauthorized" element={<NotAuthorizedPage />} />
      <Route
        path="/products"
        element={
          <main style={{ padding: "1em" }}>
            <p>
              Please contact CleanConnect on how to become a customer to gain
              access to its large library of products!
            </p>
            <button onClick={(): void => window.location.replace("/login")}>
              Go Back To Login page
            </button>
          </main>
        }
      />
      <Route
        path="/success"
        element={
          <main style={{ padding: "1em" }}>
            <p>
              Account created successfully! Please wait for an administrator to
              approve your account.
            </p>
            <button onClick={(): void => window.location.replace("/login")}>
              Go Back To Login page
            </button>
          </main>
        }
      />
      <Route element={<GeneralLayout />}>
        <Route
          path="/locations/create"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
              ]}
            >
              <CreateLocationPage />
            </RoleBasedRoute>
          }
        />
        <Route
          path="/locations"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
              ]}
            >
              <LocationsPage />
            </RoleBasedRoute>
          }
        />

        <Route
          path="/nodes/create"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
              ]}
            >
              <CreateNodePage />
            </RoleBasedRoute>
          }
        />
        <Route
          path="/nodes"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
              ]}
            >
              <NodesPage />
            </RoleBasedRoute>
          }
        />

        <Route
          path="/devices/create"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
              ]}
            >
              <CreateDevicePage />
            </RoleBasedRoute>
          }
        />
        <Route
          path="/devices/zone-setup"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
              ]}
            >
              <ZonesSetupPage />
            </RoleBasedRoute>
          }
        />

        <Route
          path="/devices/:locationId"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
              ]}
            >
              <DevicesPage />
            </RoleBasedRoute>
          }
        />

        <Route
          path="/devices/:nodeId"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
              ]}
            >
              <DevicesPage />
            </RoleBasedRoute>
          }
        />
        <Route
          path="/devices"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
              ]}
            >
              <DevicesPage />
            </RoleBasedRoute>
          }
        />

        <Route
          path="/model"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
                RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
              ]}
            >
              <ModelManagerPage />
            </RoleBasedRoute>
          }
        />
        <Route
          path="/model/create"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
                RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
              ]}
            >
              <CreateServicePage />
            </RoleBasedRoute>
          }
        />

        <Route
          path="/gasLeakTest"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
                RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                RoleEnum.CUSTOMER_OPS_TEAM,
                RoleEnum.CUSTOMER_HSE,
              ]}
            >
              <GasLeakData />
            </RoleBasedRoute>
          }
        />

        <Route
          path="/system-settings"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_OPS,
                RoleEnum.CC_SALES,
                RoleEnum.PARTNER_ADMIN,
                RoleEnum.CUSTOMER_ADMIN,
                RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
                RoleEnum.CUSTOMER_OPS_TEAM,
                RoleEnum.CUSTOMER_HSE,
                RoleEnum.CUSTOMER_SECURITY,
              ]}
            >
              <SystemSettingsPage />
            </RoleBasedRoute>
          }
        />

        <Route
          path="/email-notifications"
          element={
            <RoleBasedRoute allowedRoles={[RoleEnum.ROOT]}>
              <EmailNotificationsPage />
            </RoleBasedRoute>
          }
        />
      </Route>

      <Route
        element={
          <RoleBasedRoute
            allowedRoles={[
              RoleEnum.ROOT,
              RoleEnum.CC_OPS_ADMIN,
              RoleEnum.CC_PROCTORS,
            ]}
          >
            <HumanValidatorLayout />
          </RoleBasedRoute>
        }
      >
        <Route
          path="/human-validator"
          element={
            <RoleBasedRoute
              allowedRoles={[
                RoleEnum.ROOT,
                RoleEnum.CC_OPS_ADMIN,
                RoleEnum.CC_PROCTORS,
              ]}
            >
              <HumanValidatorPage />
            </RoleBasedRoute>
          }
        />
        <Route
          path="/hv-review"
          element={
            <RoleBasedRoute
              allowedRoles={[RoleEnum.ROOT, RoleEnum.CC_OPS_ADMIN]}
            >
              <HvReviewPage />
            </RoleBasedRoute>
          }
        />
      </Route>

      <Route
        element={
          <RoleBasedRoute allowedRoles={[RoleEnum.ROOT]}>
            <WebglConfiguratorLayout />
          </RoleBasedRoute>
        }
      >
        <Route
          path="/webgl-dashboard"
          element={
            <RoleBasedRoute allowedRoles={[RoleEnum.ROOT]}>
              <WebglConfiguratorDashboardPage />
            </RoleBasedRoute>
          }
        />
        <Route
          path="/webgl-project-editor/:projectId"
          element={
            <RoleBasedRoute allowedRoles={[RoleEnum.ROOT]}>
              <WebglConfiguratorProjectEditorPage />
            </RoleBasedRoute>
          }
        />
        <Route
          path="/webgl-project-viewer/:projectId"
          element={
            <RoleBasedRoute allowedRoles={[RoleEnum.ROOT]}>
              <WebglConfiguratorProjectViewerPage />
            </RoleBasedRoute>
          }
        />
      </Route>

      <Route
        path="old"
        element={
          <RoleBasedRoute allowedRoles={[RoleEnum.ROOT]}>
            <AppOld />
          </RoleBasedRoute>
        }
      />

      <Route
        path="*"
        element={
          <main style={{ padding: "1em" }}>
            <p>There&apos;s nothing here!</p>
            <button
              onClick={(): void => {
                window.location.href = "/";
              }}
            >
              Go back to home page
            </button>
          </main>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
