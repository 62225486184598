import * as THREE from "three";

export const setTexturesToMaterial = (object, texturesData) => {
  const setTexures = (material, texturesData) => {
    texturesData.forEach(tData => {
      material[tData.mapName] = tData.texture;
    });
  };

  if (object instanceof THREE.Mesh) {
    setTexures(object.material, texturesData);
  }

  object.traverse(child => {
    if (child instanceof THREE.Mesh) {
      setTexures(child.material, texturesData);
    }
  });
};

export const setMaterial = (object, material) => {
  if (object instanceof THREE.Mesh) {
    object.material = material;
  }

  object.traverse(child => {
    if (child instanceof THREE.Mesh) {
      child.material = material;
    }
  });
};

export const setMaterialColor = (object, color) => {
  if (object instanceof THREE.Mesh) {
    object.material.color = color;
  }

  object.traverse(child => {
    if (child instanceof THREE.Mesh) {
      child.material.color = color;
    }
  });
};

export const setMaterialColorToMesh = (object, color) => {
  if (object instanceof THREE.Mesh) {
    object.material.color = color;
  }
};
