import { Stack, Typography } from "@mui/material";

const ZonesSetupPageHeader = (): JSX.Element => {
  return (
    <Stack
      spacing={4}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="h5">Service Annotations</Typography>
    </Stack>
  );
};

export default ZonesSetupPageHeader;
