import { Box, Stack, Typography } from "@mui/material";

import LocationIdSelect from "../../../common/components/layout/components/LocationIdSelect";
import { AutocompleteOptionType } from "../../../common/models/autocomplete";
import {
  selectedLocationNodesVariable,
  useSelectedLocationNodes,
} from "../variables/nodes";
import CreateNodeDialog from "./CreateNodeDialog";

const NodesPageHeader = (): JSX.Element => {
  const locationVariable = useSelectedLocationNodes();

  const handleLocationChange = (
    locationValue: AutocompleteOptionType
  ): void => {
    selectedLocationNodesVariable(locationValue);
  };

  return (
    <Box padding="20px 24px 19px 24px">
      <Stack spacing={4} direction="row" alignItems="center">
        <Typography variant="h5" padding={0} sx={{ flexGrow: 1 }}>
          Nodes
        </Typography>
        <Box sx={{ maxWidth: 400, width: 280 }}>
          <LocationIdSelect
            variant="standard"
            setDefault
            disableClearable
            location={locationVariable}
            setLocation={handleLocationChange}
          />
        </Box>
        <CreateNodeDialog />
      </Stack>
    </Box>
  );
};

export default NodesPageHeader;
