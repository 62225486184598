import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import type {
  GetTimestreamDataQuery,
  GetTimestreamDataQueryVariables,
} from "../../../../API";
import { useCustomerIdGuard } from "../../../../common/hooks/useCustomerIdGuard";
import { GET_TIMESTREAM_DATA } from "../../../../common/operations/queries";

export const useGetTimestreamData = () => {
  const selectedCustomerId = useCustomerIdGuard();

  console.log("selectedCustomerId", selectedCustomerId);
  const formattedCustomerId = selectedCustomerId?.replace("#", "_");

  const { data, loading } = useQuery<
    GetTimestreamDataQuery,
    GetTimestreamDataQueryVariables
  >(GET_TIMESTREAM_DATA, {
    variables: {
      customerId: formattedCustomerId,
    },
    skip: !formattedCustomerId || formattedCustomerId === "",
  });

  const flattenData: any = (oldArr: any): any => {
    if (!oldArr) return [];
    if (!oldArr.Rows) return [];
    return oldArr.Rows.map((row: any): any => row.Data.flat());
  };

  const formattedData: any | null = useMemo(
    () =>
      data ? flattenData(JSON.parse(data.getTimestreamData as string)) : [],
    [data]
  );

  return { data: formattedData, loading } as const;
};
