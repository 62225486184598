import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiToolBarLightStyleOverrides: ComponentsOverrides<Theme>["MuiToolbar"] =
  {
    root: () => {
      return {
        padding: 0,
        height: "40px",
      };
    },
  };
