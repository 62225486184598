import React, { useMemo } from "react";

import CheckIcon from "@mui/icons-material/Check";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Breakpoint, styled } from "@mui/material/styles";

import StyledLoadingButton from "../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import StyledDialogTitle from "./StyledDialogTitle";

interface IStyledDialog {
  open: boolean;
  onClose: () => void;
  title: string;
  maxWidth?: Breakpoint | false;
  content?: React.ReactNode;
  children?: React.ReactNode;
  SubmitButtonProps: {
    loading?: boolean;
    title?: string;
    icon?: React.ReactElement | JSX.Element;
    onSubmit?: () => void;
  };
  showSubmitButton?: boolean;
}

const DialogWindow = styled(Dialog)(
  ({
    theme,
  }): {
    "& .MuiDialog-paper": {
      padding: string | number;
    };
    "& .MuiDialogContent-root": {
      padding: string | number;
      paddingTop: string | number;
    };
    "& .MuiDialogActions-root": {
      padding: string | number;
    };
  } => ({
    "& .MuiDialog-paper": {
      padding: theme.spacing(3, 3, 4, 3),
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
      paddingTop: "1em !important",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(3, 0, 0, 0),
    },
  })
);

const StyledDialog = ({
  open,
  onClose,
  title,
  content,
  children,
  SubmitButtonProps,
  showSubmitButton = true,
  maxWidth = "sm",
}: IStyledDialog): JSX.Element => {
  const { loading, icon, title: buttonTitle, onSubmit } = SubmitButtonProps;

  const memoizedContent = useMemo(
    (): React.ReactNode => content ?? children,
    [content, children]
  );

  return (
    <DialogWindow
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      fullWidth
      scroll="body"
    >
      <StyledDialogTitle onClose={onClose}>{title}</StyledDialogTitle>
      <DialogContent>{memoizedContent}</DialogContent>
      {showSubmitButton && (
        <DialogActions>
          <StyledLoadingButton
            fullWidth
            loading={loading}
            loadingPosition="start"
            startIcon={icon ?? <CheckIcon />}
            variant="contained"
            color="primary"
            onClick={onSubmit}
          >
            {buttonTitle ?? "Create"}
          </StyledLoadingButton>
        </DialogActions>
      )}
    </DialogWindow>
  );
};

export default StyledDialog;
