import { useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import TransitionUp from "../../../common/components/TransitionUp";
import Thumbnail from "../../../common/components/media/Thumbnail";
import ValidationForm from "./ValidationForm";

import { S3Helper } from "../../../common/utils/s3helper";
import {
  errorNotification,
  successNotification,
} from "../../../common/variables/notification";
import { useSetPendingState } from "../hooks/useSetPendingState";
import { humanValidatorTableVariable } from "../variables/humanValidatorTable";

const HumanValidatorTableItemDetailsCopy = (): JSX.Element => {
  const { showDetails, tableData } = useReactiveVar(
    humanValidatorTableVariable
  );

  const { setToPending } = useSetPendingState();

  const [objectUrl, setObjectUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect((): void => {
    const getObject = async (): Promise<void> => {
      if (tableData?.mediaOutput) {
        setLoading(true);
        const url = await S3Helper.getObject(tableData?.mediaOutput ?? "");
        setObjectUrl(url);
        setLoading(false);
      }
    };

    getObject();
  }, [tableData?.mediaOutput]);

  const handleClose = (): void => {
    humanValidatorTableVariable({
      showDetails: false,
      tableData: null,
    });
  };

  const handleSetToPending = (): void => {
    setToPending({
      variables: {
        customerId: tableData?.customerId,
        eventId: tableData?.eventId,
      },
      refetchQueries: ["GetEventsPendingHumanValidation"],
    })
      .then((): void => {
        successNotification("The event was returned to the Pending state");
      })
      .catch((error): void => {
        console.error("handleSetToPending error", error);
        errorNotification();
      })
      .finally((): void => {
        humanValidatorTableVariable({
          showDetails: false,
          tableData: null,
        });
      });
  };

  return (
    <Dialog
      fullScreen
      open={showDetails}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
    >
      <DialogTitle>
        <CardHeader
          sx={{ padding: 0 }}
          action={
            <IconButton onClick={handleSetToPending}>
              <CloseRoundedIcon />
            </IconButton>
          }
          title="Details"
        />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9} display={loading ? "flex" : "block"}>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {!loading && objectUrl && <Thumbnail src={objectUrl} controls />}
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ overflowY: "auto" }}>
              <Card
                variant="outlined"
                sx={{
                  wordBreak: "break-all",
                  padding: "0.75em",
                  margin: "1em 0",
                  backgroundColor: "#ececec",
                }}
              >
                <Typography variant="body1">
                  Model: {tableData?.model}
                </Typography>
                <Typography variant="body1">
                  Date: {new Date(tableData?.date ?? "").toLocaleString()}
                </Typography>
                <Typography variant="body1">
                  Camera: {tableData?.cameraName}
                </Typography>
              </Card>

              <ValidationForm />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default HumanValidatorTableItemDetailsCopy;
