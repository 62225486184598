import { makeVar, useReactiveVar } from "@apollo/client";

import { RoleEnum } from "../../../common/models/enums";

export enum SystemSettingsTabsEnum {
  DEVICES = "DEVICES",
  ROLES = "ROLES",
  ADMIN_NAVIGATION = "ADMIN_NAVIGATION",
  ALERT_MANAGEMENT = "ALERT_MANAGEMENT",
}

export const AdminRoles = [
  RoleEnum.ROOT,
  RoleEnum.CC_OPS_ADMIN,
  RoleEnum.CC_OPS,
  RoleEnum.CC_SALES,
  RoleEnum.PARTNER_ADMIN,
  RoleEnum.CUSTOMER_ADMIN,
];

export const NonAdminRoles = [
  RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
  RoleEnum.CUSTOMER_OPS_TEAM,
  RoleEnum.CUSTOMER_HSE,
  RoleEnum.CUSTOMER_SECURITY,
  RoleEnum.CC_PROCTORS,
];

export const SystemSettingsTabsAllowedRoles = {
  [SystemSettingsTabsEnum.DEVICES]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
  ],
  [SystemSettingsTabsEnum.ROLES]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
  ],
  [SystemSettingsTabsEnum.ADMIN_NAVIGATION]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
  ],
  [SystemSettingsTabsEnum.ALERT_MANAGEMENT]: [
    RoleEnum.ROOT,
    RoleEnum.CC_OPS_ADMIN,
    RoleEnum.CC_OPS,
    RoleEnum.CC_SALES,
    RoleEnum.PARTNER_ADMIN,
    RoleEnum.CUSTOMER_ADMIN,
    RoleEnum.CUSTOMER_OPERATOR_FOREMAN,
    RoleEnum.CUSTOMER_OPS_TEAM,
    RoleEnum.CUSTOMER_HSE,
    RoleEnum.CUSTOMER_SECURITY,
  ],
};

export const SystemSettingsTabsList = [
  {
    id: SystemSettingsTabsEnum.DEVICES,
    label: "Devices",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.DEVICES],
  },
  {
    id: SystemSettingsTabsEnum.ROLES,
    label: "Roles",
    allowedRoles: SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.ROLES],
  },
  {
    id: SystemSettingsTabsEnum.ADMIN_NAVIGATION,
    label: "Admin Navigation",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.ADMIN_NAVIGATION],
  },
  {
    id: SystemSettingsTabsEnum.ALERT_MANAGEMENT,
    label: "Alert Management",
    allowedRoles:
      SystemSettingsTabsAllowedRoles[SystemSettingsTabsEnum.ALERT_MANAGEMENT],
  },
];

interface ISystemSettingsTabsState {
  selectedTab: string;
}

export const defaultSystemSettingsTabsState = {
  selectedTab: SystemSettingsTabsEnum.DEVICES,
};

export const systemSettingsTabsVariable = makeVar<ISystemSettingsTabsState>(
  defaultSystemSettingsTabsState
);

export const useSystemSettingsTabsVariable = () =>
  useReactiveVar(systemSettingsTabsVariable);
