import { Typography } from "@mui/material";
import { memo } from "react";

interface IAlertLocationModelProps {
  location: string;
  model: string;
}

const AlertLocationModel = ({
  location,
  model,
}: IAlertLocationModelProps): JSX.Element => {
  if (!location && !model) {
    return <Typography variant="body2">NA</Typography>;
  }

  if (location && model) {
    return (
      <Typography variant="body2">
        {location} / {model}
      </Typography>
    );
  }

  if (location) {
    return <Typography variant="body2">{location} / NA</Typography>;
  }

  return <Typography variant="body2">NA / {model}</Typography>;
};

export default memo(
  AlertLocationModel,
  (prevProps, nextProps): boolean =>
    prevProps.location === nextProps.location &&
    prevProps.model === nextProps.model
);
