import { Button } from "@mui/material";

import { IAlertsTableRow } from "../../../hooks/useAlertsTableRow";
import { AlertType, alertDialogVariables } from "../../../variables/alerts";
import { emailAlertFormVariables } from "../../../variables/emailAlerts";
import UpdateEmailAlertDialog from "./UpdateEmailAlertDialog";

interface IConfigureEmailAlertDialogContainerProps {
  row: IAlertsTableRow;
}

const ConfigureEmailAlertDialogContainer = ({
  row,
}: IConfigureEmailAlertDialogContainerProps): JSX.Element => {
  const handleConfigureButtonClick = (): void => {
    alertDialogVariables({
      isOpen: true,
      rowId: row.rowId,
      alertType: row.alertType as AlertType,
    });
    emailAlertFormVariables({
      ...row,
      location: {
        title: row.location,
        value: row.location,
      },
      additionalEmails: row.additionalEmails as string[],
    });
  };

  return (
    <>
      <Button onClick={handleConfigureButtonClick}>CONFIGURE</Button>
      <UpdateEmailAlertDialog id={row.rowId} status={row.status} />
    </>
  );
};

export default ConfigureEmailAlertDialogContainer;
