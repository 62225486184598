import { CircularProgress } from "@mui/material";
import { Navigate } from "react-router-dom";

import { useAuthenticatedUser } from "../common/hooks/useAuthenticatedUser";
import { ROLES } from "../common/models/enums";

interface IAppRoutesProps {
  allowedRoles: string[];
  children: JSX.Element;
}

const RoleBasedRoute = ({
  allowedRoles,
  children,
}: IAppRoutesProps): JSX.Element => {
  const { user, authStatus, role, route } = useAuthenticatedUser();

  if (route === "setup") {
    return <Navigate to="/login" replace />;
  }

  if (!user || authStatus === "configuring") {
    return <CircularProgress />;
  }

  const userRole = ROLES.find((r): boolean => r === role) ?? "";

  const allowed = allowedRoles?.includes(userRole);

  return allowed ? children : <Navigate to="/unauthorized" replace />;
};

export default RoleBasedRoute;
