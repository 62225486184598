import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiListItemIconDarkStyleOverrides: ComponentsOverrides<Theme>["MuiListItemIcon"] =
  {
    root: ({ theme }) => {
      return {
        color: theme.palette.text.primary,
      };
    },
  };
