import * as THREE from "three";

class SelectObjectOnScene {
  constructor(
    camera,
    intersectObjectsParentArray,
    domElement,
    handleObjectSelection,
    handleObjectHower
  ) {
    this.enabled = true;

    this.camera = camera;
    this.intersectObjectsParentArray = intersectObjectsParentArray;
    this.domElement = domElement;
    this.handleObjectSelection = handleObjectSelection;
    this.handleObjectHower = handleObjectHower;

    this.pointerDown = new THREE.Vector2();
    this.pointerUp = new THREE.Vector2();
    this.pointerMove = new THREE.Vector2();

    this.raycaster = new THREE.Raycaster();

    this.domElement.addEventListener("mousedown", e => this.handleMouseDown(e));
    this.domElement.addEventListener("mouseup", e => this.handleMouseUp(e));
    this.domElement.addEventListener("mousemove", e => this.handleMouseMove(e));
  }

  handleMouseDown(event) {
    this.setPointerPosition(event, this.pointerDown);
  }

  handleMouseUp(event) {
    if (!this.enabled) {
      return;
    }

    this.setPointerPosition(event, this.pointerUp);

    if (this.pointerDown.equals(this.pointerUp)) {
      const intersection = this.getIntersection(this.pointerUp);
      this.handleObjectSelection(intersection);
    }
  }

  handleMouseMove(event) {
    if (!this.enabled) {
      return;
    }
    this.setPointerPosition(event, this.pointerMove);

    const intersection = this.getIntersection(this.pointerMove);
    this.handleObjectHower(intersection);
  }

  getObjectToIntersect() {
    return this.intersectObjectsParentArray.reduce(
      (prev, next) => [...prev, ...next.children],
      []
    );
  }

  setPointerPosition(event, vector) {
    const rect = this.domElement.getBoundingClientRect();

    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    vector.set(x, y);
  }

  getIntersection(pointer) {
    this.raycaster.setFromCamera(pointer, this.camera);

    const intersects = this.raycaster.intersectObjects(
      this.getObjectToIntersect(),
      true
    );

    return intersects.length ? intersects[0] : null;
  }
}

export default SelectObjectOnScene;
