import { Button, Typography, useTheme } from "@mui/material";
import { useConfirm } from "material-ui-confirm";

import { UpdateAlertStatusInput } from "../../../../../../API";
import { useCustomerIdGuard } from "../../../../../../common/hooks/useCustomerIdGuard";
import { successNotification } from "../../../../../../common/variables/notification";
import { IAlertsTableRow } from "../../hooks/useAlertsTableRow";
import { useUpdateAlertStatus } from "../../hooks/useUpdateAlertStatus";

interface ITurnOffButtonProps {
  row: IAlertsTableRow;
}

const TurnOffButton = ({ row }: ITurnOffButtonProps): JSX.Element => {
  const theme = useTheme();
  const confirm = useConfirm();

  const selectedCustomerId = useCustomerIdGuard();

  const { updateAlert, loading } = useUpdateAlertStatus();

  const handleTurnOffClick = (): void => {
    confirm({
      title: null,
      content: (
        <Typography variant="subtitle1">
          Are you sure you want to turn on <strong>{row?.alertName}</strong>?
        </Typography>
      ),
      confirmationText: "Turn Off",
      cancellationText: "Cancel",
      contentProps: {
        sx: {
          color: "text.primary",
        },
      },
      confirmationButtonProps: {
        variant: "text",
        color: "error",
        disabled: loading,
      },
    }).then((): void => {
      const input: UpdateAlertStatusInput = {
        customerId: selectedCustomerId,
        id: row?.rowId,
        status: false,
      };

      updateAlert(input).then((response): void => {
        if (response.data?.updateAlertStatus) {
          successNotification("Alert has been turned off");
        }
      });
    });
  };

  return (
    <Button
      onClick={handleTurnOffClick}
      sx={{
        color: theme.palette.error.dark,
      }}
    >
      TURN OFF
    </Button>
  );
};

export default TurnOffButton;
