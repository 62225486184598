import { ChangeEvent } from "react";

import { Grid, TextField } from "@mui/material";

import CustomServiceSelect from "../../../../../../../common/components/select/CustomService/CustomServiceSelect";
import NodeSelect from "../../../../../../../common/components/select/NodeSelect/NodeSelect";
import {
  systemAlertFormVariables,
  useSystemAlertFormVariables,
} from "../../../variables/systemAlerts";

const SystemAlertDialogForm = (): JSX.Element => {
  const alertForm = useSystemAlertFormVariables();

  const setNode = (value: string): void => {
    systemAlertFormVariables({
      ...alertForm,
      node: value,
    });
  };

  const setCustomService = (value: string): void => {
    systemAlertFormVariables({
      ...alertForm,
      customService: value,
    });
  };

  const setJsonObject = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    systemAlertFormVariables({
      ...alertForm,
      jsonObject: event.target.value,
    });
  };

  return (
    <Grid container spacing={2} paddingBottom="1em">
      <Grid item xs={12} sm={12}>
        <NodeSelect
          disableClearable
          nodeId={alertForm.node}
          onNodeChange={setNode}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <CustomServiceSelect
          service={alertForm.customService}
          onServiceChange={setCustomService}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          fullWidth
          label="JSON Object"
          multiline
          rows={8}
          value={alertForm.jsonObject}
          onChange={setJsonObject}
        />
      </Grid>
    </Grid>
  );
};

export default SystemAlertDialogForm;
