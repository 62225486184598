import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import TableNoDataOverlay from "../../../../common/components/table/TableNoDataOverlay";
import { lightDataGrid } from "../../../../common/providers/theme/design-tokens/DataGrid/light";
import { USERS_TABLE_LIMIT } from "./hooks/useGetLazyPaginatedUsers";
import { useRolesTableColumns } from "./hooks/useRolesTableColumns";
import { useRolesTableRows } from "./hooks/useRolesTableRows";

const RolesTable = (): JSX.Element => {
  const { rows, loading } = useRolesTableRows();
  const { columns } = useRolesTableColumns();

  return (
    <DataGrid
      sx={lightDataGrid.sx}
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: TableNoDataOverlay,
      }}
      autoHeight
      disableSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: string }): string => row.rowId}
      loading={loading}
      pageSize={USERS_TABLE_LIMIT}
      sortingOrder={["desc", "asc"]}
    />
  );
};

export default RolesTable;
