import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import TableNoDataOverlay from "../../../../common/components/table/TableNoDataOverlay";

import { ZONES_TABLE_LIMIT } from "../hooks/useGetZones";
import { useZonesTableColumns } from "../hooks/useZonesTableColumns";
import { useZoneTableRows } from "../hooks/useZoneTableRows";
import { useEffect, useRef } from "react";
import { isEqual } from "lodash";
import { useUpdateShadow } from "../../../../common/hooks/useUpdateShadow";

const ZonesTable = ({
  serviceId,
  handleRowClick,
  thingName,
}: {
  serviceId: string;
  handleRowClick: any;
  thingName?: string;
}): JSX.Element => {
  const { rows, loading } = useZoneTableRows(serviceId);
  const { columns } = useZonesTableColumns();

  const { updateDeviceShadow } = useUpdateShadow();
  const previousRows = useRef(rows);
  useEffect((): void => {
    if (!isEqual(previousRows.current, rows)) {
      updateDeviceShadow(thingName ?? "", {
        zones: rows,
      });
      previousRows.current = rows;
    }
  }, [rows]);

  return (
    <DataGrid
      sx={{
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 600,
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
      }}
      rowsPerPageOptions={[ZONES_TABLE_LIMIT]}
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: TableNoDataOverlay,
      }}
      autoHeight
      disableSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId: any }): string => row.rowId as string}
      loading={loading}
      pageSize={ZONES_TABLE_LIMIT}
      onRowClick={handleRowClick}
    />
  );
};

export default ZonesTable;
