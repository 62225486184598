import { useState } from "react";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";

import { MdContentCopy } from "react-icons/md";
import { AddNodeInput, NodeLevel } from "../../../API";
import { useDialog } from "../../../common/components/dialog/useDialog";
import {
  errorNotification,
  notificationVariable,
  successNotification,
} from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import { useCreateNode } from "../hooks/useCreateNode";
import {
  defaultNodeFormValidationState,
  defaultNodeFormValues,
  nodeFormRules,
  nodeFormValidationStateVariable,
  nodeFormVariables,
  selectedLocationNodesVariable,
  useNodeFormVariables,
} from "../variables/nodes";
import StyledDialog from "./../../../common/components/dialog/StyledDialog";
import CreateNodeForm from "./CreateNodeForm";

const CreateNodeDialog = (): JSX.Element => {
  const nodeForm = useNodeFormVariables();

  const { open, handleDialogOpen, handleDialogClose } = useDialog();
  const {
    open: isScriptDialogOpened,
    handleDialogOpen: openScriptDialog,
    handleDialogClose: closeScriptDialog,
  } = useDialog();

  const [nodeScript, setNodeScript] = useState("");

  const { createNode, loading } = useCreateNode();

  const openDialog = (): void => {
    nodeFormVariables(defaultNodeFormValues);
    nodeFormValidationStateVariable(defaultNodeFormValidationState);
    setNodeScript("");
    handleDialogOpen();
  };

  const closeDialog = (): void => {
    selectedLocationNodesVariable(nodeForm.locationId);
    nodeFormValidationStateVariable(defaultNodeFormValidationState);
    handleDialogClose();
  };

  const saveNode = (): void => {
    if (!nodeForm.nodeName) {
      nodeFormValidationStateVariable({
        ...defaultNodeFormValidationState,
        nodeName: {
          hasError: true,
          errorMessage: "Please type node name",
        },
      });
      return;
    }

    const nodeNameNormalized = nodeForm.nodeName
      .toLowerCase()
      .replace(/\s+/g, "_");

    const nodeIdNormalized = `${nodeForm.locationId?.value}#N#${nodeNameNormalized}`;

    const normalizedId =
      client.cache.identify({
        id: nodeIdNormalized,
        __typename: "Node",
      }) ?? "";

    const extract = client.cache.extract();

    if (normalizedId && extract[normalizedId]?.nodeName === nodeForm.nodeName) {
      nodeFormValidationStateVariable({
        ...defaultNodeFormValidationState,
        nodeName: {
          hasError: true,
          errorMessage: "Node name already exists",
        },
      });
      return;
    }

    if (nodeForm.nodeName.length > nodeFormRules.nodeName.maxLength) {
      nodeFormValidationStateVariable({
        ...defaultNodeFormValidationState,
        nodeName: {
          hasError: true,
          errorMessage: `Node name cannot be longer than ${nodeFormRules.nodeName.maxLength} characters`,
        },
      });
      return;
    }

    if (!nodeForm.locationId?.value) {
      nodeFormValidationStateVariable({
        ...defaultNodeFormValidationState,
        locationId: {
          hasError: true,
          errorMessage: "Please select location",
        },
      });
      return;
    }

    const input: AddNodeInput = {
      locationId: nodeForm.locationId?.value ?? "",
      nodeName: nodeForm.nodeName,
      level: NodeLevel.NODE,
      tags: [], // todo: ask user for tags and add to input
    };

    createNode(input)
      .then((response): void => {
        if (response?.data) {
          setNodeScript(response.data.addNode?.onboardCommand ?? "");
          closeDialog();
          openScriptDialog();
          successNotification();
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((): void => {
        errorNotification();
      });
  };

  const notify = (): void => {
    navigator.clipboard
      .writeText(nodeScript)
      .then((): void => {
        notificationVariable({
          isOpen: true,
          severity: "success",
          message: "Script copied to clipboard!",
        });
      })
      .catch((error): void => {
        console.error(error);
      });
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<AddOutlinedIcon />}
        onClick={openDialog}
      >
        Create
      </Button>
      <StyledDialog
        open={open}
        title="Create a new node"
        onClose={handleDialogClose}
        SubmitButtonProps={{
          loading,
          onSubmit: saveNode,
        }}
      >
        <CreateNodeForm />
      </StyledDialog>

      <StyledDialog
        open={isScriptDialogOpened}
        title="Node onboard script"
        onClose={handleDialogClose}
        SubmitButtonProps={{
          loading,
          onSubmit: saveNode,
        }}
      >
        <CreateNodeForm />
      </StyledDialog>

      <Dialog open={isScriptDialogOpened}>
        <DialogTitle sx={{ textAlign: "center", alignItems: "center" }}>
          Node onboard script
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A Node has been created and is waiting to be provisioned. To begin
            the process of provisioning your node, follow these steps:
          </DialogContentText>
          <ul>
            <li>Click/Copy the code snippet below to your clipboard</li>
            <li>
              Paste the code snippet into a terminal running on the node
              device&apos;s terminal
            </li>
            <li>Run the code</li>
          </ul>
        </DialogContent>

        <DialogActions
          sx={{
            cursor: "pointer",
            backgroundColor: "#95db8a",
            marginBottom: 3,
            marginLeft: 3,
            marginRight: 3,
          }}
          onClick={notify}
        >
          <Typography noWrap>
            <MdContentCopy style={{ marginRight: 10 }} />
            {nodeScript}
          </Typography>
        </DialogActions>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="success"
            onClick={closeScriptDialog}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateNodeDialog;
