const RGBCamera = require("../assets/3dmodels/Camera_Tower_Model/SM_RGBCamera.fbx");
const MinervaCamera = require("../assets/3dmodels/Camera_Tower_Model/SM_Minerva_02.fbx");
const MinervaGasCamera = require("../assets/3dmodels/Camera_Tower_Model/SM_MinervaGas_09.fbx");

export const CamerasType = {
  RGBCamera: "RGBCamera",
  MinervaCamera: "MinervaCamera",
  MinervaGasCamera: "MinervaGasCamera",
};

const CamerasData = [
  {
    name: "RGB Camera",
    path: RGBCamera,
    cameraType: CamerasType.RGBCamera,
  },
  {
    name: "Minerva Camera",
    path: MinervaCamera,
    cameraType: CamerasType.MinervaCamera,
  },
  {
    name: "Minerva Gas Camera",
    path: MinervaGasCamera,
    cameraType: CamerasType.MinervaGasCamera,
  },
];

export default CamerasData;
