import { useMutation } from "@apollo/client";

import type {
  UpdateDeviceMutation,
  UpdateDeviceMutationVariables,
} from "../../../API";
import { UPDATE_DEVICE } from "../../../common/operations/mutations";

export const useUpdateDevice = () => {
  const [putDevice, { data, loading }] = useMutation<
    UpdateDeviceMutation,
    UpdateDeviceMutationVariables
  >(UPDATE_DEVICE);

  const updateDevice = async (input: any) => {
    return await putDevice({
      variables: {
        input,
      },
    });
  };

  return { updateDevice, data, loading };
};
