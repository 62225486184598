import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

// TODO: clean up page UI as needed
const PendingUserPage = (): JSX.Element => {
  const navigate = useNavigate();

  const handleSignOut = async (): Promise<any> => {
    await Auth.signOut();
    navigate("/");
    window.location.reload();
  };

  return (
    <div style={{ flex: 1, display: "flex" }}>
      <div>
        You have created an account, but need to wait to be assigned a role by
        an admin. Please reach out to your organization&apos;s user management
        admin or CleanConnect for support.
      </div>
      <button onClick={handleSignOut}>Sign out</button>
    </div>
  );
};

export default PendingUserPage;
