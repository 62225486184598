import { FetchResult, useMutation } from "@apollo/client";

import type {
  UpdateShadowNodeMutation,
  UpdateShadowNodeMutationVariables,
} from "../../API";
import { UPDATE_SHADOW } from "../../common/operations/mutations";

interface IUpdateShadow {
  updateDeviceShadow: (
    thingName: string,
    payload: Record<string, any>,
    shadowName?: string
  ) => Promise<
    FetchResult<
      UpdateShadowNodeMutation,
      Record<string, any>,
      Record<string, any>
    >
  >;
  data?: UpdateShadowNodeMutation | null;
  loading: boolean;
}

export const useUpdateShadow = (): IUpdateShadow => {
  const [updateShadow, { data, loading }] = useMutation<
    UpdateShadowNodeMutation,
    UpdateShadowNodeMutationVariables
  >(UPDATE_SHADOW);

  function generateShadowPayload(payload: Record<string, any>) {
    const shadowPayload = {
      state: {
        desired: payload,
      },
    };

    return JSON.stringify(shadowPayload);
  }

  const updateDeviceShadow = async (
    thingName: string,
    payload: Record<string, any>,
    shadowName?: string
  ) => {
    return await updateShadow({
      variables: {
        thingName,
        shadowName,
        payload: generateShadowPayload(payload),
      },
    });
  };

  return { updateDeviceShadow, data, loading };
};
