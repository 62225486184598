import { Button } from "@mui/material";

import { IAlertsTableRow } from "../../../hooks/useAlertsTableRow";
import { AlertType, alertDialogVariables } from "../../../variables/alerts";
import { nonAdminAlertFormVariables } from "../../../variables/nonAdminAlerts";
import UpdateUserAlertDialog from "./UpdateUserAlertDialog";

interface IConfigureUserAlertDialogContainerProps {
  row: IAlertsTableRow;
}

const ConfigureUserAlertDialogContainer = ({
  row,
}: IConfigureUserAlertDialogContainerProps): JSX.Element => {
  const handleConfigureButtonClick = (): void => {
    alertDialogVariables({
      isOpen: true,
      rowId: row.rowId,
      alertType: row.alertType as AlertType,
    });
    nonAdminAlertFormVariables({
      ...row,
      location: {
        title: row.location,
        value: row.location,
      },
      model: row.model as string,
    });
  };

  return (
    <>
      <Button onClick={handleConfigureButtonClick}>CONFIGURE</Button>
      <UpdateUserAlertDialog id={row.rowId} status={row.status} />
    </>
  );
};

export default ConfigureUserAlertDialogContainer;
