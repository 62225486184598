import { valid, isMultiLineString } from "geojson-validation";

import { loadGeoJson } from "../threeWebGl/AssetLoaders";
import { getMeshMultilinestring } from "../threeWebGl/MathUtils";

const GEOJSON_HEADERS = [123, 10, 34, 116, 121, 112, 101, 34];

const checkIsGeojsonFileTypeValid = buffers => {
  return GEOJSON_HEADERS.every((header, index) => header === buffers[index]);
};

const checkIsGeojsonDataValid = async data => {
  const topojsonData = await loadGeoJson(data);

  const multilinestringMeshArray = getMeshMultilinestring(topojsonData);

  const isGeojsonDataValid = multilinestringMeshArray.every(mulSrting => {
    return valid(mulSrting) && isMultiLineString(mulSrting);
  });

  return isGeojsonDataValid;
};

export const geojsonValidation = async (fileHeaders, fileData) => {
  // TODO: check file by headers.
  // const isGeojsonFileTypeValid = checkIsGeojsonFileTypeValid(fileHeaders);

  // if (!isGeojsonFileTypeValid) {
  //   return {
  //     error: true,
  //     errorMessage: "Invalid geojson file type",
  //   };
  // }

  const isGeojsonDataValid = await checkIsGeojsonDataValid(fileData);

  if (!isGeojsonDataValid) {
    return {
      error: true,
      errorMessage: "Invalid geojson data",
    };
  }

  return { error: false, errorMessage: "" };
};
