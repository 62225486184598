import { useMemo } from "react";

import { isEmpty } from "lodash";

import { useGetLazyPaginatedUsers } from "./useGetLazyPaginatedUsers";

export const useRolesTableRows = () => {
  const { data, loading } = useGetLazyPaginatedUsers();

  const items = useMemo(() => {
    return (
      data?.getUsersByCompany.items?.map(item => ({
        rowId: item?.id ?? "",
        name: (item?.firstName ?? "") + " " + (item?.lastName ?? ""),
        groupId: item?.user_group,
        roleId: item?.user_role,
        email: item?.email,
        customerId: item?.customer_id,
      })) ?? []
    );
  }, [data?.getUsersByCompany.items]);

  let rows = [...items];

  const pendingArray = items
    .filter((item): boolean => item.roleId === "R#PENDING")
    .sort();

  if (isEmpty(pendingArray)) {
    return { rows, loading };
  }

  const otherArray = items
    .filter((item): boolean => item.roleId !== "R#PENDING")
    .sort();

  rows = [...pendingArray, ...otherArray];

  return { rows, loading };
};
