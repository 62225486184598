import {
  getPointsFromMultilinestring,
  getLatLonFromCoordinates,
  calculateBboxCenterFromPoints,
} from "../../common/threeWebGl/MathUtils";

import {
  REAL_EARTH_RADIUS_IN_KM,
  GROUND_RADIUS_IN_M,
} from "../../common/threeWebGl/Constants";

export const calculateTerainCenter = terrainData => {
  const points = getPointsFromMultilinestring(terrainData[0]);

  const centerIn3dSpace = calculateBboxCenterFromPoints(points);

  const coordinates = getLatLonFromCoordinates(
    REAL_EARTH_RADIUS_IN_KM,
    centerIn3dSpace
  );

  return coordinates;
};

export const calculateZoomLevel = () => {
  const tileSquarSize = 2;
  const tilesInPixel = 512;
  const estimatedZoomLevel = 16;

  const scale = GROUND_RADIUS_IN_M / tilesInPixel;
  const zoomLevel =
    estimatedZoomLevel - Math.log(scale) / Math.log(tileSquarSize);

  return zoomLevel;
};
