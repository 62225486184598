export const detectedSelectOptions = ["Blue Wrong", "Blue Right"];

export const hardHatOptions = [
  {
    title: "Black Hard Hat",
    description: "",
  },
  {
    title: "Object",
    description: "",
  },
  {
    title: "Other",
    description: "",
  },
];

export const gasLeakOptions = [
  {
    title: "Lit Flare/Firetube",
    description: "A flare with whiter in color due to the heat when it is working properly",
  },
  {
    title: "Exhaust/Heat",
    description: "Typically looks white, dissipates quickly, and can be seen of vehicles or some equipment on site",
  },
  {
    title: "Camera Shake",
    description: "The camera is moving up and down and or side to side",
  },
  {
    title: "Shadow/Reflections",
    description: "Sometimes looks like gas but is inconsistent and doesn't move accross the screen",
  },
  {
    title: "Object Movement",
    description: "Vehicle, Person, Animal, Grass, WindSock",
  },
  {
    title: "No Image",
    description: "Out of focus or blurry image/video",
  },
  {
    title: "No Visible Detection",
    description: "Everything seems right but I could not see any gas",
  },
  {
    title: "Other",
    description: "Anything that doesn't fall in any of the other categories",
  },
];

export const liquidLeakOptions = [
  {
    title: "Rain/puddles",
    description: "",
  },
  {
    title: "Other",
    description: "",
  },
];

export const fireSmokeOptions = [
  {
    title: "Light",
    description: "",
  },
  {
    title: "Flare",
    description: "",
  },
  {
    title: "Exhaust",
    description: "",
  },
  {
    title: "Orange Object",
    description: "",
  },
  {
    title: "Other",
    description: "",
  },
];

export const showConfirmButtonList = ["hardhat", "liquidleak", "firesmoke"];

export const models = ["gasleak", "hardhat", "liquidleak", "firesmoke"];

export const causeOptions = new Map();

causeOptions.set("gasleak", gasLeakOptions);
causeOptions.set("hardhat", hardHatOptions);
causeOptions.set("liquidleak", liquidLeakOptions);
causeOptions.set("firesmoke", fireSmokeOptions);
