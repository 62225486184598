import { TextField } from "@mui/material";

interface DeviceDynamicTextInputProps {
  hasError?: boolean;
  name: string;
  deviceConfig: any;
  setDeviceConfig: (name: string, value: string) => void;
}

const DeviceDynamicTextInput = ({
  hasError,
  name,
  deviceConfig,
  setDeviceConfig,
}: DeviceDynamicTextInputProps): JSX.Element => {
  return (
    <TextField
      margin="dense"
      fullWidth
      id={name + "-id"}
      error={hasError && !deviceConfig[name]}
      helperText={hasError && !deviceConfig[name] ? "Please type a value" : ""}
      label={name}
      value={deviceConfig[name]}
      onChange={(event): void => setDeviceConfig(name, event.target.value)}
    />
  );
};

export default DeviceDynamicTextInput;
