import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiToolBarDarkStyleOverrides: ComponentsOverrides<Theme>["MuiToolbar"] =
  {
    root: () => {
      return {
        padding: 0,
        height: "auto",
      };
    },
  };
