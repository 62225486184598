import { LinearProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { isEqual } from "lodash";
import { useEffect, useRef } from "react";

import TableNoDataOverlay from "../../../common/components/table/TableNoDataOverlay";
import { useUpdateShadow } from "../../../common/hooks/useUpdateShadow";
import { useDeviceTableColumns } from "../hooks/useDeviceTableColumns";
import { useDeviceTableRows } from "../hooks/useDeviceTableRows";
import { DEVICES_TABLE_LIMIT } from "../hooks/useGetDevices";
import { lightDataGrid } from "../../../common/providers/theme/design-tokens/DataGrid/light";

const DevicesTable = (): JSX.Element => {
  const { rows, loading } = useDeviceTableRows();
  const { columns } = useDeviceTableColumns();
  const { updateDeviceShadow } = useUpdateShadow();

  // eslint-disable-next-line
  const handleRowClick = (params: any): void => {
    // Open device modal or redirect to device details page
  };

  const getNodeIds = (): (string | undefined)[] => {
    const uniqueNodes: Set<string | undefined> = new Set();
    return rows
      .map((row): any => row.node)
      .filter((node): any => {
        if (uniqueNodes.has(node)) {
          return false; // Discard duplicate nodes
        } else {
          uniqueNodes.add(node);
          return true; // Keep unique nodes
        }
      });
  };

  const nodeIds: (string | undefined)[] = getNodeIds(); // array of unique nodeIds

  function generateThingNamesFromNodeIds(
    nodeIds: (string | undefined)[]
  ): (string | undefined)[] {
    return nodeIds.map((nodeId): any => {
      if (nodeId) {
        return nodeId.replace(/#/g, "_");
      }
      return nodeId;
    });
  }

  const thingNames = generateThingNamesFromNodeIds(nodeIds); // thingNames for shadow update

  const groupRowsByNode = (): { [node: string]: typeof rows } => {
    const groups: { [node: string]: typeof rows } = {};

    rows.forEach((row): any => {
      const node = row.node;
      if (node) {
        if (!groups[node]) {
          groups[node] = [];
        }
        groups[node].push(row);
      }
    });

    return groups;
  };

  const groupedRows = groupRowsByNode(); // devices grouped into arrays by their nodeId's

  const thingNameGroupedRows: { [key: string]: typeof rows } = {};

  Object.keys(groupedRows).forEach((key): any => {
    const modifiedKey = key.replace(/#/g, "_");
    thingNameGroupedRows[modifiedKey] = groupedRows[key];
  });

  const previousRows = useRef(thingNameGroupedRows);

  useEffect((): void => {
    if (!isEqual(previousRows.current, thingNameGroupedRows)) {
      for (const nodeId of thingNames) {
        updateDeviceShadow(nodeId as string, {
          cameras: thingNameGroupedRows[nodeId as string] as any,
        });
        previousRows.current = thingNameGroupedRows;
      }
    }
  }, [rows]);

  return (
    <DataGrid
      sx={lightDataGrid.sx}
      rowsPerPageOptions={[DEVICES_TABLE_LIMIT]}
      rows={rows}
      columns={columns}
      components={{
        LoadingOverlay: LinearProgress,
        NoRowsOverlay: TableNoDataOverlay,
      }}
      onRowClick={handleRowClick}
      autoHeight
      disableSelectionOnClick
      hideFooterSelectedRowCount
      getRowId={(row: { rowId?: string }): string => row?.rowId ?? ""}
      loading={loading}
      pageSize={DEVICES_TABLE_LIMIT}
      sortingOrder={["desc", "asc"]}
    />
  );
};

export default DevicesTable;
