import { ChangeEvent, useMemo } from "react";

import { Grid, TextField } from "@mui/material";

import LocationIdSelect from "../../../../../../../common/components/layout/components/LocationIdSelect";
import { AutocompleteOptionType } from "../../../../../../../common/models/autocomplete";
import {
  nonAdminAlertFormVariables,
  useNonAdminAlertFormVariables,
  useSelectedLocationAlerts,
} from "../../../variables/nonAdminAlerts";
import AlertServiceTypeSelect from "./../../../../../../../common/components/select/ServiceSelect/AlertServiceTypeSelect";

interface ICreateNonAdminAlertFormProps {
  hasError?: boolean;
  alertNameError?: boolean;
}

const CreateNonAdminAlertForm = ({
  hasError,
  alertNameError,
}: ICreateNonAdminAlertFormProps): JSX.Element => {
  const selectedLocation = useSelectedLocationAlerts();
  const alertForm = useNonAdminAlertFormVariables();

  const setAlertName = (event: ChangeEvent<HTMLInputElement>): void => {
    nonAdminAlertFormVariables({
      ...alertForm,
      alertName: event.target.value,
    });
  };

  const setLocation = (value: AutocompleteOptionType): void => {
    nonAdminAlertFormVariables({
      ...alertForm,
      location: value,
    });
  };

  const setService = (value: string): void => {
    nonAdminAlertFormVariables({
      ...alertForm,
      model: value,
    });
  };

  // const setLocationTag = (value: string): void => {
  //   nonAdminAlertFormVariables({
  //     ...alertForm,
  //     locationTag: value,
  //   });
  // };

  const helperText = useMemo((): string => {
    if (hasError && alertForm.alertName === "") {
      return "Please type a Alert name";
    }

    if (alertNameError) {
      return "Name is already taken";
    }

    return "";
  }, [hasError, alertNameError, alertForm.alertName]);

  return (
    <Grid container spacing={2} paddingBottom="1em">
      <Grid item xs={12} sm={12}>
        <TextField
          margin="dense"
          error={(alertNameError || hasError) && !alertForm.alertName}
          helperText={helperText}
          fullWidth
          id="Alert Name"
          label="Alert Name"
          value={alertForm.alertName}
          onChange={setAlertName}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <LocationTagSelect />
      </Grid> */}
      <Grid item xs={12} sm={12}>
        <LocationIdSelect
          disableClearable
          setDefault
          defaultValue={selectedLocation?.value}
          hasError={hasError}
          location={alertForm.location}
          setLocation={setLocation}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <AlertServiceTypeSelect
          onServiceChange={setService}
          locationId={alertForm.location?.value}
          serviceId={alertForm.model}
        />
      </Grid>
    </Grid>
  );
};

export default CreateNonAdminAlertForm;
