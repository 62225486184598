import { useLocation } from "react-router-dom";

import { Paper, Stack } from "@mui/material";

import { useStreamImage } from "../hooks/useStreamImage";
import ZonesSetupPageHeader from "./ZonesSetupPageHeader";
import GasLeakTabContent from "./components/GasLeakTabContent";

const ZonesSetupPage = (): JSX.Element => {
  const location = useLocation();
  const { nodeId, deviceName, rtspSource } = location.state;
  const { streamImage } = useStreamImage(nodeId);

  return (
    <Stack spacing={4} direction="column">
      <Paper sx={{ padding: "1em" }} elevation={3}>
        <ZonesSetupPageHeader />
      </Paper>

      <Paper sx={{ padding: "1em" }} elevation={3}>
        <GasLeakTabContent
          streamImage={streamImage}
          nodeId={nodeId}
          deviceName={deviceName}
          rtspSource={rtspSource}
        />
      </Paper>
    </Stack>
  );
};

export default ZonesSetupPage;
