import { Chip, Typography, useTheme } from "@mui/material";

interface IAlertStatusProps {
  status: boolean;
}

const AlertStatus = ({ status }: IAlertStatusProps): JSX.Element => {
  const theme = useTheme();

  const backgroundColor = status
    ? theme.palette.primary.light
    : theme.palette.error.main;

  return (
    <>
      <Chip
        sx={{
          backgroundColor,
          width: "8px",
          height: "8px",
          marginRight: "0.5em",
        }}
      />
      <Typography variant="body2">
        {status ? "Active" : "Not Active"}
      </Typography>
    </>
  );
};

export default AlertStatus;
