import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";

import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Auth } from "aws-amplify";

import { useNavigate } from "react-router-dom";
import StyledDialog from "../../common/components/dialog/StyledDialog";
import { useLoading } from "../../common/hooks/useLoading";
import { useTargetDomain } from "../../common/hooks/useTargetDomain";
import { RoleEnum } from "../../common/models/enums";
import { IFormValidationState } from "../../common/models/formValidation";
import { accountTypeVariable } from "../../common/variables/accountType";
import { errorNotification } from "../../common/variables/notification";

interface ISignUpFormValidationState {
  firstName: IFormValidationState;
  lastName: IFormValidationState;
  email: IFormValidationState;
}

export const defaultSignUpFormValidationState: ISignUpFormValidationState = {
  firstName: {
    hasError: false,
    errorMessage: "",
  },
  lastName: {
    hasError: false,
    errorMessage: "",
  },
  email: {
    hasError: false,
    errorMessage: "",
  },
};

export const signUpFormRules = {
  firstName: {
    required: true,
  },
  lastName: {
    required: true,
  },
  email: {
    required: true,
    pattern: new RegExp(
      "^([a-z0-9_\\.-]+)@\\S([a-z0-9]+)([.-]?[a-z0-9\\.]){2,30}$"
    ),
  },
};

const LoginPage = (): JSX.Element => {
  const navigate = useNavigate();

  const { isLoading, toggleLoading } = useLoading(false);
  const { redirect } = useTargetDomain();

  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [user, setUser] = useState(null);

  const [isSignUpDialogOpen, setIsSignUpDialogOpen] = useState(false);
  const [signUpValidationState, setSignUpValidationState] = useState(
    defaultSignUpFormValidationState
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [signUpLoading, setSignUpLoading] = useState(false);

  /**
   * Adds correct target domain to the URL.
   */
  useEffect((): void => {
    redirect();
  }, []);

  const handleClose = (
    _event: React.MouseEventHandler<HTMLAnchorElement>,
    reason: "backdropClick" | "escapeKeyDown"
  ): void => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") {
      return;
    }

    resetState();
  };

  const resetState = (): void => {
    toggleLoading();
    setIsDialogOpened(false);
    setIsVerifyLoading(false);
    setCode("");
    setUser(null);
    setIsSignUpDialogOpen(false);
    setSignUpValidationState(defaultSignUpFormValidationState);
    setFirstName("");
    setLastName("");
    setSignUpLoading(false);
  };

  const attemptSignUp = (): void => {
    setSignUpLoading(true);

    Auth.signUp({
      username: email ?? "",
      password: "Uwe27Ft$$90A",
      attributes: {
        given_name: firstName,
        family_name: lastName,
        email,
      },
    })
      .then((): void => {
        resetState();
        setEmail("");
        navigate("/success");
      })
      .catch((error: any): void => {
        console.error(error.message);
        navigate("/products");
        return;
      });
  };

  const signIn = (): void => {
    toggleLoading();

    Auth.signIn(email, "")
      .then((user): void => {
        if (user.challengeName === "CUSTOM_CHALLENGE") {
          setIsDialogOpened(true);
          setUser(user);
        } else {
          console.log(user);
        }
      })
      .catch(async (err): Promise<void> => {
        if (err.toString() === "UserNotFoundException: User does not exist.") {
          // user not found error, so we try to sign up the email
          setIsSignUpDialogOpen(true);
          errorNotification(err.message);
        }
      });
  };

  const signUp = async (): Promise<void> => {
    let validationState = defaultSignUpFormValidationState;

    if (!email) {
      validationState = {
        ...validationState,
        email: {
          hasError: true,
          errorMessage: "Email is required",
        },
      };
    }

    const hasError = !signUpFormRules.email.pattern.test(email);
    const domain = email.split("@")[1];
    const errorMessage =
      domain?.length > 30
        ? "Domain is out of range [2; 30]"
        : "Valid characters: a-z, 0-9, @, ., _, -";

    validationState = {
      ...validationState,
      email: {
        hasError,
        errorMessage: hasError ? errorMessage : "",
      },
    };

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      setSignUpValidationState(validationState);
      return;
    }

    attemptSignUp();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === "Enter") {
      signIn();
    }
  };

  const handleVerifyCodeSubmit = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  const handleSubmit = (): void => {
    setIsVerifyLoading(true);

    Auth.sendCustomChallengeAnswer(user, code)
      .then(async (response): Promise<void> => {
        console.log(response);

        const userRole = response.signInUserSession.idToken.payload.role;

        accountTypeVariable(userRole);
        localStorage.setItem("userRole", JSON.stringify(userRole));

        // NOTE: navigate to the correct page based on role
        if (
          [
            RoleEnum.ROOT,
            RoleEnum.CC_OPS_ADMIN,
            RoleEnum.CC_OPS,
            RoleEnum.CC_SALES,
            RoleEnum.PARTNER_ADMIN,
            RoleEnum.CUSTOMER_ADMIN,
          ].includes(userRole)
        ) {
          window.location.replace("/locations");
        } else if ([RoleEnum.CUSTOMER_OPERATOR_FOREMAN].includes(userRole)) {
          window.location.replace("/model");
        } else if (
          [RoleEnum.CUSTOMER_HSE, RoleEnum.CUSTOMER_OPS_TEAM].includes(userRole)
        ) {
          window.location.replace("/gasLeakTest");
        } else if ([RoleEnum.CUSTOMER_SECURITY].includes(userRole)) {
          window.location.replace("/system-settings");
        } else if ([RoleEnum.CC_PROCTORS].includes(userRole)) {
          window.location.replace("/human-validator");
        } else if ([RoleEnum.PENDING].includes(userRole)) {
          window.location.replace("/pending-user");
        } else {
          window.location.replace("/");
        }
      })
      .catch((error): void => {
        console.error(error);
        errorNotification((error as Error).message);
      })
      .finally((): void => {
        resetState();
      });
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = event?.target?.value ?? "";

    setEmail(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: "400px",
          padding: "1.25em",
          marginBottom: "2em",
        }}
      >
        <Stack spacing={2} direction="column">
          <Box
            component="img"
            sx={{ m: "5px" }}
            alt="logo-image"
            src={"/CleanConnect Dark.png"}
          />
          <Typography variant="h5" textAlign="center">
            Sign In
          </Typography>
          <TextField
            label="What's your email?"
            value={email}
            onChange={(e): void => {
              setEmail(e?.target.value.trim().toLowerCase());
            }}
            onKeyDown={handleKeyDown}
          />
          <LoadingButton
            disabled={isLoading || !email}
            variant="contained"
            color="success"
            onClick={signIn}
          >
            Sign In
          </LoadingButton>

          <Dialog
            disableEscapeKeyDown
            open={isDialogOpened}
            onClose={handleClose}
          >
            <DialogTitle>
              What&apos;s the code you received in the email?
            </DialogTitle>
            <DialogContent sx={{ padding: "2em" }}>
              <br />
              <TextField
                autoFocus
                label="Code"
                fullWidth
                onChange={(e): void => setCode(e?.target.value)}
                onKeyDown={handleVerifyCodeSubmit}
              />
            </DialogContent>
            <DialogActions>
              <Button
                disabled={isVerifyLoading}
                variant="outlined"
                onClick={resetState}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={isVerifyLoading}
                loadingPosition="start"
                startIcon={<VerifiedUserOutlinedIcon />}
                variant="contained"
                color="success"
                onClick={handleSubmit}
              >
                Verify
              </LoadingButton>
            </DialogActions>
          </Dialog>

          <StyledDialog
            open={isSignUpDialogOpen}
            title="Sign Up"
            onClose={(): void => setIsSignUpDialogOpen(false)}
            SubmitButtonProps={{
              loading: signUpLoading,
              onSubmit: signUp,
            }}
          >
            <Grid
              container
              justifyContent="center"
              direction="column"
              spacing={3}
            >
              <Grid item>
                <TextField
                  label="First Name"
                  fullWidth
                  value={firstName}
                  error={
                    signUpValidationState.firstName?.hasError && !firstName
                  }
                  helperText={
                    signUpValidationState.firstName?.hasError && !firstName
                      ? signUpValidationState.firstName?.errorMessage
                      : ""
                  }
                  onChange={(e): void => setFirstName(e?.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Last Name"
                  fullWidth
                  value={lastName}
                  error={signUpValidationState.lastName?.hasError && !lastName}
                  helperText={
                    signUpValidationState.lastName?.hasError && !lastName
                      ? signUpValidationState.lastName?.errorMessage
                      : ""
                  }
                  onChange={(e): void => setLastName(e?.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Email"
                  fullWidth
                  value={email}
                  error={signUpValidationState.email?.hasError}
                  helperText={
                    signUpValidationState.email?.hasError
                      ? signUpValidationState.email?.errorMessage
                      : ""
                  }
                  onChange={handleEmailChange}
                />
              </Grid>
            </Grid>
          </StyledDialog>
        </Stack>
      </Paper>
    </Box>
  );
};

export default LoginPage;
