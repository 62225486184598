import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser";
import { RoleEnum } from "../../models/enums";
import { drawerWidth, type LayoutProps } from "./GeneralLayout";
import CcAdminMenuView from "./NavigationViews/CcAdminMenuView";
import CcOpsSalesPartnerCustomerAdminMenuView from "./NavigationViews/CcOpsSalesPartnerCustomerAdminMenuView";
import CustomerOperatorForemanMenuView from "./NavigationViews/CustomerOperatorForemanMenuView";
import CustomerOpsHseMenuView from "./NavigationViews/CustomerOpsHseMenuView";
import CustomerSecurityMenuView from "./NavigationViews/CustomerSecurityMenuView";

const NavigationSidebar = ({
  mobileOpen,
  toogleDrawer,
}: LayoutProps): JSX.Element => {
  const { role } = useAuthenticatedUser();

  const container =
    window !== undefined ? (): HTMLElement => window.document.body : undefined;

  let view;

  switch (role) {
    case RoleEnum.ROOT:
    case RoleEnum.CC_OPS_ADMIN:
      view = <CcAdminMenuView />;
      break;
    case RoleEnum.CC_OPS:
    case RoleEnum.CC_SALES:
    case RoleEnum.PARTNER_ADMIN:
    case RoleEnum.CUSTOMER_ADMIN:
      view = <CcOpsSalesPartnerCustomerAdminMenuView />;
      break;
    case RoleEnum.CUSTOMER_OPERATOR_FOREMAN:
      view = <CustomerOperatorForemanMenuView />;
      break;
    case RoleEnum.CUSTOMER_OPS_TEAM:
    case RoleEnum.CUSTOMER_HSE:
      view = <CustomerOpsHseMenuView />;
      break;
    case RoleEnum.CUSTOMER_SECURITY:
      view = <CustomerSecurityMenuView />;
      break;
    default:
      // What should defualt case or fallback view be?
      view = null;
      break;
  }

  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
        marginTop: { md: "1.5em" },
        marginLeft: { md: "1.5em" },
      }}
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={toogleDrawer}
        ModalProps={{ keepMounted: true }}
        onClick={(event): void => {
          event.stopPropagation();
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {view}
      </Drawer>

      <Drawer
        variant="permanent"
        onClick={(event): void => {
          event.stopPropagation();
        }}
        PaperProps={{
          elevation: 0,
          style: {
            top: "1.5em",
            left: "1.5em",
            height: "auto",
            border: "none",
            borderRadius: "16px",
            boxSizing: "border-box",
            width: drawerWidth,
            padding: "2em",
            overflowY: "auto",
          },
        }}
        sx={{
          display: { xs: "none", md: "block" },
        }}
        open
      >
        {view}
      </Drawer>
    </Box>
  );
};

export default NavigationSidebar;
