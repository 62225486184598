import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";
import { Auth } from "aws-amplify";
import aws from "./../../../../aws-exports.json";

const ZoneImage = ({ zoneKey }: { zoneKey: string }): JSX.Element => {
  const [imageUrl, setImageUrl] = useState("");
  const [jwt, setJWT] = useState("");

  // get JWT
  useEffect((): void => {
    async function getCurrentSession(): Promise<any> {
      try {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();
        setJWT(jwtToken);
      } catch (error) {
        console.error("Error getting current session:", error);
      }
    }
    getCurrentSession();
  }, []);

  useEffect((): void => {
    // Configure AWS SDK
    AWS.config.region = "us-east-1"; // Set your AWS region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: aws.aws_cognito_identity_pool_id,
      Logins: {
        ["cognito-idp.us-east-1.amazonaws.com/" + aws.aws_user_pools_id]: jwt,
      },
    });

    // Get the S3 client
    const s3 = new AWS.S3();

    console.log(zoneKey);
    // Set the parameters for the S3 getObject method
    const params = {
      Bucket: aws.aws_node_files_s3_bucket,
      Key: zoneKey,
    };

    // Fetch the image from S3
    s3.getObject(params, (err: any, data: any): void => {
      if (err) {
        console.error("Error getting the image from S3");
      } else {
        const imageBlob = new Blob([data.Body], { type: data.ContentType });
        const imageUrl = URL.createObjectURL(imageBlob);
        setImageUrl(imageUrl);
      }
    });
  }, [jwt, zoneKey]);

  // keep for now until we pass real image to UI
  return (
    <Stack spacing={4} direction="column">
      <img src={imageUrl} alt="Zone Image" width={350} />
    </Stack>
  );
};

export default ZoneImage;
