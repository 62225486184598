const NodeIcon = (): JSX.Element => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.47168 0C8.02398 0 8.47168 0.44772 8.47168 1V5C8.47168 5.55228 8.02398 6 7.47168 6H5.47168V8H10.4717V7C10.4717 6.44772 10.9194 6 11.4717 6H17.4717C18.024 6 18.4717 6.44772 18.4717 7V11C18.4717 11.5523 18.024 12 17.4717 12H11.4717C10.9194 12 10.4717 11.5523 10.4717 11V10H5.47168V16H10.4717V15C10.4717 14.4477 10.9194 14 11.4717 14H17.4717C18.024 14 18.4717 14.4477 18.4717 15V19C18.4717 19.5523 18.024 20 17.4717 20H11.4717C10.9194 20 10.4717 19.5523 10.4717 19V18H4.47168C3.9194 18 3.47168 17.5523 3.47168 17V6H1.47168C0.9194 6 0.47168 5.55228 0.47168 5V1C0.47168 0.44772 0.9194 0 1.47168 0H7.47168ZM16.4717 16H12.4717V18H16.4717V16ZM16.4717 8H12.4717V10H16.4717V8ZM6.47168 2H2.47168V4H6.47168V2Z"
        fill="black"
      />
    </svg>
  );
};

export default NodeIcon;
