import * as THREE from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";

import { getRaycastIntersectsPointByFromToVectors } from "../RaycastUtils";

class OrbitControlBuilder {
  constructor(terrain, camera, targetPosition, faceNormal, domElement) {
    this.setCameraUpDirection(camera, faceNormal);

    this.orbitControl = new OrbitControls(camera, domElement);

    this.setCameraPosition(targetPosition, 300, 30, 60);
    this.setOrbitCameraTarget(targetPosition, terrain, camera);

    this.orbitControl.saveState();

    this.setOrbitControlSettings();

    this.addControlTargetPositionLimitation(terrain, camera);
  }

  static createOrbitControlInstance(
    terrain,
    camera,
    targetPosition,
    faceNormal,
    domElement
  ) {
    const orbitControlBuilder = new OrbitControlBuilder(
      terrain,
      camera,
      targetPosition,
      faceNormal,
      domElement
    );

    return orbitControlBuilder.orbitControl;
  }

  setOrbitControlSettings() {
    this.orbitControl.minPolarAngle = THREE.MathUtils.degToRad(0);
    this.orbitControl.maxPolarAngle = THREE.MathUtils.degToRad(75);

    this.orbitControl.minDistance = 10;
    this.orbitControl.maxDistance = 500;

    this.orbitControl.enableDamping = true;
    this.orbitControl.dampingFactor = 0.05;

    this.orbitControl.enablePan = true;
    this.orbitControl.screenSpacePanning = false;
  }

  setOrbitCameraTarget(targetPosition, terrain, camera) {
    this.orbitControl.target.set(
      targetPosition.x,
      targetPosition.y,
      targetPosition.z
    );

    const intersects = getRaycastIntersectsPointByFromToVectors(
      terrain,
      camera.position,
      this.orbitControl.target
    );

    const vector3Temp = new THREE.Vector3();
    vector3Temp.copy(this.orbitControl.target);

    if (intersects.length > 0) {
      this.orbitControl.target.copy(intersects[0].point);
    }

    vector3Temp.sub(this.orbitControl.target);
    camera.position.sub(vector3Temp);
  }

  setCameraPosition(targetPosition, distance, azimuthInDeg, polarInDeg) {
    const azimuth = THREE.MathUtils.degToRad(azimuthInDeg);
    const polar = THREE.MathUtils.degToRad(polarInDeg);

    const x = distance * Math.sin(polar) * Math.cos(azimuth);
    const y = distance * Math.sin(polar) * Math.sin(azimuth);
    const z = distance * Math.cos(polar);

    const position = new THREE.Vector3(x, y, z).add(targetPosition);

    this.orbitControl.object.position.set(position.x, position.y, position.z);
  }

  setCameraUpDirection(camera, faceNormal) {
    camera.up.copy(faceNormal);
    camera.updateProjectionMatrix();
  }

  addControlTargetPositionLimitation(terrain, camera) {
    const vector3Temp = new THREE.Vector3();
    const prevOrbitControlTarget = new THREE.Vector3().copy(
      this.orbitControl.target
    );

    const limitTargetPosition = () => {
      if (prevOrbitControlTarget.equals(this.orbitControl.target)) {
        return;
      }

      const intersects = getRaycastIntersectsPointByFromToVectors(
        terrain,
        camera.position,
        this.orbitControl.target
      );

      if (intersects.length > 0) {
        prevOrbitControlTarget.copy(intersects[0].point);
      } else {
        vector3Temp.copy(this.orbitControl.target);
        this.orbitControl.target.copy(prevOrbitControlTarget);

        vector3Temp.sub(this.orbitControl.target);
        camera.position.sub(vector3Temp);
      }
    };

    this.orbitControl.addEventListener("change", limitTargetPosition);
  }
}

export default OrbitControlBuilder;
