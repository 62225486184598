import { json } from "d3";
import { topology } from "topojson-server";

import { TextureLoader } from "three";
import { FBXLoader } from "three/addons/loaders/FBXLoader.js";
import { KMZLoader } from "three/addons/loaders/KMZLoader.js";
import { RGBELoader } from "three/addons/loaders/RGBELoader.js";

const load = (loader, path, logProgress = false) => {
  return new Promise((resolve, reject) => {
    loader.load(
      path,
      data => {
        resolve(data);
      },
      xhr => {
        logProgress && console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
      },
      error => {
        reject(error);
      }
    );
  });
};

export const loadFBXAsset = path => {
  const loader = new FBXLoader();

  return load(loader, path);
};

export const loadKMZAsset = path => {
  const loader = new KMZLoader();

  return load(loader, path);
};

export const loadTexture = path => {
  const loader = new TextureLoader();

  return load(loader, path);
};

export const loadHdrAsset = path => {
  const loader = new RGBELoader();

  return load(loader, path);
};

export const loadGeoJson = async path => {
  const topologyData = await json(path);
  return topology(topologyData.features);
};
