import { Button } from "@mui/material";

import { IAlertsTableRow } from "../../../hooks/useAlertsTableRow";
import { AlertType, alertDialogVariables } from "../../../variables/alerts";
import { systemAlertFormVariables } from "../../../variables/systemAlerts";
import UpdateSystemAlertDialog from "./UpdateSystemAlertDialog";

interface IConfigureEmailAlertDialogContainerProps {
  row: IAlertsTableRow;
}

const ConfigureSystemAlertDialogContainer = ({
  row,
}: IConfigureEmailAlertDialogContainerProps): JSX.Element => {
  const handleConfigureButtonClick = (): void => {
    alertDialogVariables({
      isOpen: true,
      rowId: row.rowId,
      alertType: row.alertType as AlertType,
    });
    systemAlertFormVariables({
      ...row,
      node: row.node as string,
      customService: row.customService as string,
      jsonObject: JSON.parse(row.jsonObject as string),
    });
  };

  return (
    <>
      <Button onClick={handleConfigureButtonClick}>CONFIGURE</Button>
      <UpdateSystemAlertDialog id={row.rowId} status={row.status} />
    </>
  );
};

export default ConfigureSystemAlertDialogContainer;
