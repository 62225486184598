import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";

import Offset from "./components/Offset";
import ProctorHeaderTobBar from "./ProctorHeaderTobBar";

const HumanValidatorLayout = (): JSX.Element => {
  return (
    <Box
      component="main"
      sx={{
        padding: "2em 0 2em 1.25em",
      }}
    >
      <ProctorHeaderTobBar />
      <Offset />
      <Outlet />
    </Box>
  );
};

export default HumanValidatorLayout;
