import { useState } from "react";

import { readFileAsDataURL, readFileAsArrayBuffer } from "../utils/readFile";

export const useUploadFileState = validation => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    file: null,
    fileData: null,
    error: false,
    errorMessage: "",
    trackError: false,
  });

  const onLoadFile = async file => {
    setLoading(true);

    const fileData = await readFileAsDataURL(file);

    const buffer = await readFileAsArrayBuffer(file.slice(0, 8));
    const fileHeaders = new Uint8Array(buffer);

    const { error, errorMessage } = await validation(fileHeaders, fileData);

    setState({
      ...state,
      file,
      fileData,
      error,
      errorMessage,
      trackError: false,
    });

    setLoading(false);
  };

  const resetState = () => {
    setState({
      file: null,
      fileData: null,
      error: false,
      errorMessage: "",
      trackError: false,
    });
  };

  return { onLoadFile, resetState, state, loading };
};
