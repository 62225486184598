import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Card, Typography, useTheme } from "@mui/material";

import StyledDialog from "../../../../../../common/components/dialog/StyledDialog";
import { useDialog } from "../../../../../../common/components/dialog/useDialog";
import {
  AlertType,
  AlertTypeEnum,
  alertDialogVariables,
  useAlertDialog,
} from "../../variables/alerts";
import EmailAlertIcon from "../icons/EmailAlertIcon";
import SystemAlertIcon from "../icons/SystemAlertIcon";
import CreateEmailAlertDialog from "./email/CreateEmailAlertDialog";
import CreateSystemAlertDialog from "./system/CreateSystemAlertDialog";

const CreateAdminAlertDialogContainer = (): JSX.Element => {
  const theme = useTheme();
  const { open, handleDialogOpen, handleDialogClose } = useDialog();
  const { isOpen, alertType } = useAlertDialog();

  const handleCardClick = (type: AlertType): void => {
    handleDialogClose();
    alertDialogVariables({ alertType: type, rowId: "", isOpen: true });
  };

  return (
    <>
      <Button
        sx={{
          marginLeft: "auto",
        }}
        variant="contained"
        color="primary"
        endIcon={<AddOutlinedIcon />}
        onClick={handleDialogOpen}
      >
        Create
      </Button>
      <StyledDialog
        open={open}
        title="Choose Alert Type"
        maxWidth="sm"
        onClose={handleDialogClose}
        SubmitButtonProps={{}}
        showSubmitButton={false}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1.5em",
          }}
        >
          <Card
            onClick={(): void => handleCardClick(AlertTypeEnum.EMAIL)}
            sx={{
              width: "300px",
              border: `1px solid ${theme.palette.text.disabled}`,
              padding: "1em",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <EmailAlertIcon />
            <Typography variant="h6">Email Notification</Typography>
          </Card>
          <Card
            onClick={(): void => handleCardClick(AlertTypeEnum.SYSTEM)}
            sx={{
              width: "300px",
              border: `1px solid ${theme.palette.text.disabled}`,
              padding: "1em",
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <SystemAlertIcon />
            <Typography variant="h6">System Notification</Typography>
          </Card>
        </Box>
      </StyledDialog>
      {isOpen && alertType === AlertTypeEnum.EMAIL && (
        <CreateEmailAlertDialog />
      )}
      {isOpen && alertType === AlertTypeEnum.SYSTEM && (
        <CreateSystemAlertDialog />
      )}
    </>
  );
};

export default CreateAdminAlertDialogContainer;
