import { Button, Stack, Typography } from "@mui/material";
import type { GridColDef } from "@mui/x-data-grid";

import MenuLink from "../../../common/components/MenuLink";

export const useNodeTableColumns = (): { columns: GridColDef[] } => {
  const columns: GridColDef[] = [
    {
      field: "nodeName",
      headerName: "Name",
      hideable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.nodeName}</Typography>
        ) : null,
    },
    {
      field: "serviceData",
      headerName: "Service Data",
      hideable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.serviceData}</Typography>
        ) : null,
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        return (
          <Stack direction="row" spacing={1}>
            <Button>
              <MenuLink to={`/devices/${params.row?.rowId as string}`}>
                VIEW DEVICES
              </MenuLink>
            </Button>
            <Button>EDIT</Button>
          </Stack>
        );
      },
    },
  ];

  return { columns };
};
