import { useContext } from "react";
import { List } from "@mui/material";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";

import TowerSideListItem from "./TowerSideListItem";

const TowerSideList = ({ towerData }) => {
  const { activeTowerSideIndex, activeObjectId } = useContext(ProjectContext);

  return (
    <List
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        gap: "15px",
      }}
    >
      {towerData.sides.map(sideData => (
        <TowerSideListItem
          key={`${towerData.id}-${sideData.index}`}
          sideData={sideData}
          towerId={towerData.id}
          open={
            activeObjectId === towerData.id &&
            activeTowerSideIndex === sideData.index
          }
        />
      ))}
    </List>
  );
};

export default TowerSideList;
