import { gql, useMutation } from "@apollo/client";

import type {
  addAlertEmailNotificationInput,
  AddAlertEmailNotificationMutation,
  AddAlertEmailNotificationMutationVariables,
} from "../../../../../API";
import { ADD_ALERT_EMAIL_NOTIFICATION } from "../../../../../common/operations/mutations";

export const useCreateAlertEmailNotification = () => {
  const [saveAlertEmailNotification, { data, loading }] = useMutation<
    AddAlertEmailNotificationMutation,
    AddAlertEmailNotificationMutationVariables
  >(ADD_ALERT_EMAIL_NOTIFICATION);

  const createAlertEmailNotification = async (
    input: addAlertEmailNotificationInput
  ) => {
    return await saveAlertEmailNotification({
      variables: {
        input,
      },
      update(cache, response) {
        cache.modify({
          fields: {
            getAllAlerts(existing) {
              const newAlertEmailNotificationRef = cache.writeFragment({
                data: response.data?.addAlertEmailNotification,
                fragment: gql`
                  fragment NewAlertEmailNotification on Alert {
                    additionalEmails
                    alertName
                    alertType
                    customService
                    email
                    id
                    jsonObject
                    location
                    locationTag
                    model
                    node
                    status
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newAlertEmailNotificationRef],
              };
            },
            getUserAlerts(existing) {
              const newAlertEmailNotificationRef = cache.writeFragment({
                data: response.data?.addAlertEmailNotification,
                fragment: gql`
                  fragment NewAlertEmailNotification on Alert {
                    additionalEmails
                    alertName
                    alertType
                    customService
                    email
                    id
                    jsonObject
                    location
                    locationTag
                    model
                    node
                    status
                  }
                `,
              });

              if (!existing) {
                return response?.data;
              }

              return {
                ...existing,
                items: [...existing.items, newAlertEmailNotificationRef],
              };
            },
          },
        });
      },
    });
  };

  return { createAlertEmailNotification, data, loading };
};
