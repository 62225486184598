import { useCallback, useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import { MdContentCopy } from "react-icons/md";

import { NodeLevel, type AddNodeInput } from "../../../API";
import { useToggle } from "../../../common/hooks/useToggle";
import StyledLoadingButton from "../../../common/providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  notificationVariable,
  successNotification,
} from "../../../common/variables/notification";
import client from "../../../configs/apolloClient";
import CreateNodeForm from "../components/CreateNodeForm";
import { useCreateNode } from "../hooks/useCreateNode";
import {
  defaultNodeFormValidationState,
  nodeFormRules,
  nodeFormValidationStateVariable,
  nodeFormVariables,
  useNodeFormValidationState,
  useNodeFormVariables,
} from "../variables/nodes";

const CreateNodePage = (): JSX.Element => {
  const nodeForm = useNodeFormVariables();
  const nodeFormValidationState = useNodeFormValidationState();

  const [nodeScript, setNodeScript] = useState("");

  const { createNode, loading } = useCreateNode();

  const { state: isDialogOpened, setToggleState } = useToggle({
    initialState: false,
  });

  useEffect((): void => {
    resetState();
  }, []);

  const resetState = (): void => {
    setNodeScript("");
    nodeFormVariables({
      ...nodeForm,
      nodeName: "",
    });
    nodeFormValidationStateVariable(defaultNodeFormValidationState);
  };

  const memoizedOpenDialogCallback = useCallback((): void => {
    setToggleState(true);
  }, []);

  const memoizedCloseDialogCallback = useCallback((): void => {
    resetState();
    setToggleState(false);
  }, []);

  const notify = (): void => {
    navigator.clipboard
      .writeText(nodeScript)
      .then((): void => {
        notificationVariable({
          isOpen: true,
          severity: "success",
          message: "Script copied to clipboard!",
        });
      })
      .catch((error): void => console.error(error));
  };

  const saveNode = (): void => {
    let validationState = {
      ...(nodeFormValidationState ?? defaultNodeFormValidationState),
    };

    if (!nodeForm.locationId?.value) {
      validationState = {
        ...validationState,
        locationId: {
          hasError: true,
          errorMessage: "Please select location",
        },
      };
    }

    if (!nodeForm.nodeName) {
      validationState = {
        ...validationState,
        nodeName: {
          hasError: true,
          errorMessage: "Please type node name",
        },
      };
    }

    const nodeNameNormalized = nodeForm.nodeName
      .toLowerCase()
      .replace(/\s+/g, "_");

    const nodeIdNormalized = `${nodeForm.locationId?.value}#N#${nodeNameNormalized}`;

    const normalizedId =
      client.cache.identify({
        id: nodeIdNormalized,
        __typename: "Node",
      }) ?? "";

    const extract = client.cache.extract();

    if (normalizedId && extract[normalizedId]?.nodeName === nodeForm.nodeName) {
      validationState = {
        ...validationState,
        nodeName: {
          hasError: true,
          errorMessage: "Node name already exists",
        },
      };
    }

    if (nodeForm.nodeName.length > nodeFormRules.nodeName.maxLength) {
      validationState = {
        ...validationState,
        nodeName: {
          hasError: true,
          errorMessage: `Node name cannot be longer than ${nodeFormRules.nodeName.maxLength} characters`,
        },
      };
    }

    if (
      Object.values(validationState).some(
        (item): boolean => item?.hasError ?? false
      )
    ) {
      nodeFormValidationStateVariable(validationState);
      return;
    }

    const input: AddNodeInput = {
      locationId: nodeForm.locationId?.value ?? "",
      nodeName: nodeForm.nodeName,
      level: NodeLevel.NODE,
      tags: [], // todo: ask user for tags and add to input
    };

    createNode(input)
      .then((response): void => {
        if (response?.data) {
          successNotification();
          setNodeScript(response.data.addNode?.onboardCommand ?? "");
          memoizedOpenDialogCallback();

          nodeFormVariables({
            ...nodeForm,
            nodeName: "",
          });
          nodeFormValidationStateVariable(defaultNodeFormValidationState);
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      });
  };

  return (
    <Paper sx={{ padding: "1.5em" }}>
      <Dialog open={isDialogOpened}>
        <DialogTitle sx={{ textAlign: "center", alignItems: "center" }}>
          Node onboard script
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A Node has been created and is waiting to be provisioned. To begin
            the process of provisioning your node, follow these steps:
          </DialogContentText>
          <ul>
            <li>Click/Copy the code snippet below to your clipboard</li>
            <li>
              Paste the code snippet into a terminal running on the node
              device&apos;s terminal
            </li>
            <li>Run the code</li>
          </ul>
        </DialogContent>

        <DialogActions
          sx={{
            cursor: "pointer",
            backgroundColor: "#95db8a",
            marginBottom: 3,
            marginLeft: 3,
            marginRight: 3,
          }}
          onClick={notify}
        >
          <Typography noWrap>
            <MdContentCopy style={{ marginRight: 10 }} />
            {nodeScript}
          </Typography>
        </DialogActions>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            color="success"
            onClick={memoizedCloseDialogCallback}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ marginBottom: "1em" }}>
        <CreateNodeForm />
      </Box>
      <StyledLoadingButton
        fullWidth
        loading={loading}
        loadingPosition="start"
        startIcon={<CheckIcon />}
        variant="contained"
        onClick={saveNode}
      >
        Create
      </StyledLoadingButton>
    </Paper>
  );
};

export default CreateNodePage;
