import {
  styled,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
} from "@mui/material";

const HtmlTooltip = styled(
  ({ className, ...props }: TooltipProps): JSX.Element => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }): any => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    padding: "1em",
  },
}));

export default HtmlTooltip;
