import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteZoneMutation,
  DeleteZoneMutationVariables,
} from "../../../../API";
import { DELETE_ZONE } from "../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";

export const useDeleteZone = () => {
  const [deleteZoneMutation, { data, loading }] = useMutation<
    DeleteZoneMutation,
    DeleteZoneMutationVariables
  >(DELETE_ZONE);

  const deleteZone = async (deviceId: string, name: string) => {
    return await deleteZoneMutation({
      variables: {
        input: { deviceId, name },
      },
      onCompleted: response => {
        if (response.deleteZone) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);
        console.error(error.message);
      },
      update(cache) {
        const id = `${deviceId}Z#${name}`;
        const normalizedId = cache.identify({ id, __typename: "Zone" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  return { deleteZone, data, loading };
};
