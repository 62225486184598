import { useCallback, useState } from "react";

export const useLoading = (
  initialState = false
): { isLoading: boolean; toggleLoading: () => void } => {
  const [isLoading, setIsLoading] = useState(initialState);
  const toggleLoading = useCallback(
    () => setIsLoading(!isLoading),
    [isLoading]
  );

  return { isLoading, toggleLoading };
};
