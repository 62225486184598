import { Stack } from "@mui/material";
import { SwiperSlide } from "swiper/react";

import ObjectsData from "../../../data/ObjectsData";

import ObjectCard from "./ObjectCard";
import SwiperSlider from "../../SwiperSlider";

const ObjectsPanel = () => (
  <Stack
    direction="row"
    sx={{
      width: "100%",

      "& .swiper-slide": {
        width: "100px",
      },

      "& .swiper-slider": {
        padding: "0 2px 10px 0",
      },

      "& .swiper-scrollbar": {
        left: "0",
        bottom: "0",
        background: "transparent",
        width: "100%",
        height: "7px",
      },

      "& .swiper-scrollbar-drag": {
        background: "#E0E9FE",
        borderRadius: "30px",
        cursor: "pointer",
      },
    }}
  >
    <SwiperSlider
      initialSlide={0}
      prevButtonPosition={4}
      nextButtonPosition={4}
      direction="horizontal"
      spaceBetween={8}
      slidesPerView={"auto"}
    >
      {ObjectsData.map(obj => (
        <SwiperSlide key={obj.name}>
          <ObjectCard obj={obj} />
        </SwiperSlide>
      ))}
    </SwiperSlider>
  </Stack>
);

export default ObjectsPanel;
