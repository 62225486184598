import { useMemo, useState } from "react";

import { Autocomplete, CircularProgress, TextField } from "@mui/material";

import { useGetZones } from "../../../../pages/devices/zones-setup-page/hooks/useGetZones";

interface IZoneOption {
  title: string | null | undefined;
  value: string | null | undefined;
  deviceId: string | null | undefined;
}

interface IZoneSelectProps {
  serviceId: string;
  zoneId: string;
  onZoneChange: (value: string) => void;
}

const ZoneSelect = ({
  serviceId,
  zoneId,
  onZoneChange,
}: IZoneSelectProps): JSX.Element => {
  const { data, loading } = useGetZones(serviceId);

  const [inputValue, setInputValue] = useState("");

  const memoizedOptions = useMemo((): Array<IZoneOption> => {
    const options =
      data?.getZones?.items.map((zoneDatum): IZoneOption => {
        return {
          title: zoneDatum?.id?.split("#").pop(),
          value: zoneDatum?.id ?? "",
          deviceId: zoneDatum?.deviceId ?? "",
        };
      }) ?? [];

    // legacy implementation for when we queried Zones by deviceId instead of serviceId; may need to be build seperate query to restore that implementation
    // if (deviceId) {
    //   options = options.filter((option): boolean => {
    //     return option.deviceId === deviceId;
    //   });
    // }
    return options;
  }, [data?.getZones?.items]);

  const memoizedValue = useMemo((): IZoneOption | null => {
    if (!zoneId) {
      return null;
    }
    return (
      memoizedOptions.find((option): boolean => option?.value === zoneId) ??
      null
    );
  }, [zoneId, memoizedOptions]);

  return (
    <Autocomplete
      sx={{ height: "20px" }}
      size="small"
      loading={loading}
      value={memoizedValue}
      options={memoizedOptions}
      disabled={loading || !serviceId}
      getOptionLabel={(option): string => option?.title ?? ""}
      onChange={(_event: any, newValue: IZoneOption | null): void => {
        if (newValue) {
          onZoneChange(newValue?.value ?? "");
        }
      }}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue): void => {
        setInputValue(newInputValue);
      }}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          label="Zone"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ZoneSelect;
