import { useEffect, useState } from "react";
import { useListenToNodeFiles } from "../../../common/hooks/useListenToNodeFiles";
import { notificationVariable } from "../../../common/variables/notification";

export const useStreamImage = (nodeId: string) => {
  const [streamImage, setStreamImage] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG8AAABuCAYAAADuYl9EAAAABGdBTUEAALGPC/xhBQAAADlJREFUeNpj/P//PwMIYAFtIYBEGJAExOgMkCZgHpgZKwDZwEmYS+BYYBYgADmF+9tBqufI8AAAAASUVORK5CYII="
  );

  // listener to node uploads for PT image
  const { data } = useListenToNodeFiles(nodeId);

  // upon new data update stream image
  useEffect(() => {
    if (data) {
      // dont need to have notifications
      notificationVariable({
        isOpen: true,
        message: `Event ${data} was taken for validation`,
        severity: "info",
      });
      setStreamImage(data.listenToNodeFiles?.downloadURL ?? "");
    }
  }, [data]);

  return { streamImage };
};
