import { Button, Stack, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import AlertStatus from "../components/AlertStatus";
import TurnOffButton from "../components/actions/TurnOffButton";
import TurnOnButton from "../components/actions/TurnOnButton";
import ConfigureUserAlertDialogContainer from "./../components/dialogs/user/ConfigureUserAlertDialogContainer";
import { useTestSendEmail } from "../../../../../common/hooks/useTestSendEmail";

export const useUserAlertsTableColumns = (): { columns: GridColDef[] } => {
  const { fetchSendEmail } = useTestSendEmail();

  const handleClick = (email: string): void => {
    fetchSendEmail(email);
  };

  const columns: GridColDef[] = [
    {
      field: "alertName",
      headerName: "Name",
      flex: 1,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? (
          <Typography variant="body2">{params?.row.alertName}</Typography>
        ) : null,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params): JSX.Element | null =>
        params ? <AlertStatus status={params?.row.status} /> : null,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      disableColumnMenu: true,
      hideable: false,
      sortable: false,
      renderCell: (params): JSX.Element => {
        const status = params?.row.status;

        return (
          <Stack direction="row" spacing={1}>
            <ConfigureUserAlertDialogContainer row={params?.row} />
            <Button onClick={(): void => handleClick(params?.row.email)}>
              TEST ALERT
            </Button>

            {status && <TurnOffButton row={params.row} />}
            {!status && <TurnOnButton row={params.row} />}
          </Stack>
        );
      },
    },
  ];

  return { columns };
};
