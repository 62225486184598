import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Button, Tooltip, type PaletteMode } from "@mui/material";

import { themeMode } from "../../../providers/theme/MuiThemeProvider";
import {
  themeModeVariable,
  useThemeModeVariable,
} from "../../../variables/themeMode";

const UserSettingsPopover = (): JSX.Element => {
  const { dark, light } = themeMode;

  const mode = useThemeModeVariable();

  const handleThemeChange = (): void => {
    const value = mode === dark ? light : dark;

    localStorage.setItem("themeMode", value);
    themeModeVariable(value as PaletteMode);
  };

  return (
    <Button onClick={handleThemeChange} variant="contained">
      {mode === dark ? (
        <Tooltip title="Light Mode" placement="bottom">
          <LightModeIcon />
        </Tooltip>
      ) : (
        <Tooltip title="Dark Mode" placement="bottom">
          <DarkModeIcon />
        </Tooltip>
      )}
    </Button>
  );
};

export default UserSettingsPopover;
