import { Box } from "@mui/material";

const DragAndDropForm = ({
  handleIsDragActive,
  onFileUpload,
  allowDrag,
  children,
}) => {
  const handleOnDrag = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!allowDrag) return;

    if (e.type === "dragenter" || e.type === "dragover") {
      handleIsDragActive(true);
    } else if (e.type === "dragleave") {
      handleIsDragActive(false);
    }
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!allowDrag) return;

    handleIsDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      onFileUpload(e.dataTransfer.files);
    }
  };

  return (
    <Box
      component={"form"}
      onDragEnter={handleOnDrag}
      onDragLeave={handleOnDrag}
      onDragOver={handleOnDrag}
      onDrop={handleDrop}
    >
      {children}
    </Box>
  );
};

export default DragAndDropForm;
