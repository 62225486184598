const LocationIcon = (): JSX.Element => {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.47168 18.8995L14.4214 13.9497C17.1551 11.2161 17.1551 6.78392 14.4214 4.05025C11.6878 1.31658 7.2556 1.31658 4.52193 4.05025C1.78826 6.78392 1.78826 11.2161 4.52193 13.9497L9.47168 18.8995ZM9.47168 21.7279L3.10772 15.364C-0.407 11.8492 -0.407 6.15076 3.10772 2.63604C6.62244 -0.87868 12.3209 -0.87868 15.8357 2.63604C19.3504 6.15076 19.3504 11.8492 15.8357 15.364L9.47168 21.7279ZM9.47168 11C10.5763 11 11.4717 10.1046 11.4717 9C11.4717 7.89543 10.5763 7 9.47168 7C8.36708 7 7.47168 7.89543 7.47168 9C7.47168 10.1046 8.36708 11 9.47168 11ZM9.47168 13C7.26254 13 5.47168 11.2091 5.47168 9C5.47168 6.79086 7.26254 5 9.47168 5C11.6808 5 13.4717 6.79086 13.4717 9C13.4717 11.2091 11.6808 13 9.47168 13Z"
        fill="black"
      />
    </svg>
  );
};

export default LocationIcon;
