import type { ThemeOptions } from "@mui/material";

import { MuiAppBarDarkStyleOverrides } from "./mui/AppBar/dark";
import { MuiListItemButtonDarkStyleOverrides } from "./mui/ListItemButton/dark";
import { MuiListItemIconDarkStyleOverrides } from "./mui/ListItemIcon/dark";
import { MuiInputLabelDarkStyleOverrides } from "./mui/MuiInputLabel/dark";
import { MuiToolBarDarkStyleOverrides } from "./mui/Toolbar/dark";

/**
 * NOTE: the list of links which can help with customization:
 * 1. https://mui.com/material-ui/customization/how-to-customize/
 * 2. https://mui.com/material-ui/customization/theme-components/
 * 3. https://mui.com/material-ui/customization/dark-mode/
 */
export const darkThemeToken: ThemeOptions = {
  typography: {
    fontFamily: "Roboto, sans-serif",
    th: {
      fontSize: "0.875rem",
      fontWeight: 600,
    },
  },
  palette: {
    mode: "dark",
    text: {
      primary: "#FFFFFF",
      secondary: "rgba(255, 255, 255, 0.7)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    primary: {
      main: "#90CAF9",
      dark: "#42A5F5",
      light: "#E3F2FD",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    secondary: {
      main: "#CE93D8",
      dark: "#AB47BC",
      light: "#F3E5F5",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      main: "#29B6F6",
      dark: "#0288D1",
      light: "#4FC3F7",
      contrastText: "rgba(0, 0, 0, 0.87)",
      shades: {
        "4p": "rgba(2, 136, 209, 0.04)",
        "8p": "rgba(2, 136, 209, 0.08)",
        "12p": "rgba(2, 136, 209, 0.12)",
        "30p": "rgba(2, 136, 209, 0.3)",
        "50p": "rgba(2, 136, 209, 0.5)",
        "160p": "rgba(2, 136, 209, 0.4)",
        "190p": "rgba(2, 136, 209, 0.1)",
      },
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: MuiAppBarDarkStyleOverrides,
    },
    MuiToolbar: {
      styleOverrides: MuiToolBarDarkStyleOverrides,
    },
    MuiListItemButton: {
      styleOverrides: MuiListItemButtonDarkStyleOverrides,
    },
    MuiListItemIcon: {
      styleOverrides: MuiListItemIconDarkStyleOverrides,
    },
    MuiInputLabel: {
      styleOverrides: MuiInputLabelDarkStyleOverrides,
    },
  },
};
