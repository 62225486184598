import { Button, Typography } from "@mui/material";
import { useConfirm } from "material-ui-confirm";
import aws_exports from "../../../../../aws-exports.json";
import { useDeleteUser } from "../hooks/useDeleteUser";

interface IDeleteRoleContainerProps {
  userId: string;
  email: string;
}

const DeleteRoleContainer = ({
  userId,
  email,
}: IDeleteRoleContainerProps): JSX.Element => {
  const { deleteUserById, loading: deleteUserLoading } = useDeleteUser();

  const confirm = useConfirm();

  const handleClick = (): void => {
    confirm({
      title: null,
      content: (
        <Typography variant="subtitle1">
          Are you sure you want to delete <strong>{email}</strong>?
        </Typography>
      ),
      confirmationText: "Delete",
      cancellationText: "Cancel",
      contentProps: {
        sx: {
          color: "text.primary",
        },
      },
      confirmationButtonProps: {
        variant: "text",
        color: "error",
        disabled: deleteUserLoading,
      },
    }).then((): void => {
      deleteUserById(userId, aws_exports.aws_user_pools_id);
    });
  };

  return (
    <Button color="error" onClick={handleClick}>
      DELETE
    </Button>
  );
};

export default DeleteRoleContainer;
