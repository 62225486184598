import { GridColDef } from "@mui/x-data-grid";

export const useAnnotationsTableColumns = (): {
  columns: GridColDef[];
} => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 120,
      hideable: false,
    },
    {
      field: "polygon",
      headerName: "Polygon",
      width: 200,
      hideable: false,
    },
    {
      field: "annotationType",
      headerName: "Annotation Type",
      width: 200,
      hideable: false,
    },
  ];

  return { columns };
};
