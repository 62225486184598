import * as THREE from "three";

import { createGroup } from "./CreateMesh";
import { metersToFeet } from "./MathUtils";

class DistanceMeasurement {
  constructor(
    scene,
    camera,
    terrain,
    objectsGroup,
    domElement,
    handleActionEnd
  ) {
    this.enabled = false;
    this.domElement = domElement;

    this.terrain = terrain;
    this.objectsGroup = objectsGroup;

    this.activeObject = null;
    this.scene = scene;
    this.camera = camera;

    this.lazer = createGroup("lazer", this.scene);
    this.lazerLine = null;
    this.lazerPoint = null;

    this.createLazer();

    this.raycaster = new THREE.Raycaster();
    this.pointer = new THREE.Vector2();

    this.domElement.addEventListener("click", e =>
      this.handleOnClick(e, handleActionEnd)
    );

    this.domElement.addEventListener("mousemove", e =>
      this.handleOnMousemove(e)
    );
  }

  handleOnClick(event, cb) {
    if (!this.enabled) {
      return;
    }

    this.setPointerPosition(event, this.pointer);
    const objectToIntersect = this.getObjectToIntersect(false);
    const intersection = this.getIntersection(objectToIntersect);

    if (intersection) {
      const cameraPosition = this.getObjectWorldPosition(this.camera);
      const objectPosition = this.getObjectWorldPosition(
        intersection.object.parent
      );

      const distance = this.getDistanceToIntersectedObject(
        cameraPosition,
        objectPosition
      );

      const lablePosition = this.getLablePosition(intersection.object.parent);

      cb({ id: intersection.object.parent.ID, distance, lablePosition });
    }
  }

  handleOnMousemove(event) {
    if (!this.enabled) {
      return;
    }

    this.setPointerPosition(event, this.pointer);
    const objectToIntersect = this.getObjectToIntersect(true);
    const intersection = this.getIntersection(objectToIntersect);

    this.setPointerFromBottomCenterViewport(this.pointer);
    const intersectionStartLazer = this.getIntersection(objectToIntersect);

    if (intersection && intersectionStartLazer) {
      this.updateLazer(intersectionStartLazer.point, intersection.point);
    }
  }

  getDistanceToIntersectedObject(cameraPosition, objectPosition) {
    const distance = cameraPosition.distanceTo(objectPosition);
    const distanceInFeet = metersToFeet(distance).toFixed(2);

    return distanceInFeet;
  }

  getLablePosition(object) {
    const bbox = new THREE.Box3().setFromObject(object);

    return new THREE.Vector3().lerpVectors(bbox.max, bbox.min, 0.3);
  }

  createLazer() {
    const color = new THREE.Color(0x3483eb / 255);

    const tubeGeometry = new THREE.TubeGeometry();
    const material = new THREE.MeshBasicMaterial({ color: color });
    this.lazerLine = new THREE.Mesh(tubeGeometry, material);
    this.lazer.add(this.lazerLine);

    const pointGeometry = new THREE.SphereGeometry(0.05, 32, 16);
    const pointMaterial = new THREE.MeshBasicMaterial({ color: color });
    this.lazerPoint = new THREE.Mesh(pointGeometry, pointMaterial);
    this.lazer.add(this.lazerPoint);
  }

  updateLazer(from, to) {
    const curve = new THREE.LineCurve3(from, to);
    const tubeGeometry = new THREE.TubeGeometry(curve, 64, 0.05, 16, false);
    this.lazerLine.geometry.dispose();
    this.lazerLine.geometry = tubeGeometry;

    this.lazerPoint.position.set(to.x, to.y, to.z);
  }

  setLazerVisibility(visible) {
    this.lazer.visible = visible;
  }

  getObjectWorldPosition(object) {
    const positionVector = new THREE.Vector3();
    object.getWorldPosition(positionVector);

    return positionVector;
  }

  getObjectToIntersect(withTerrain) {
    return withTerrain
      ? [...this.objectsGroup.children, this.terrain]
      : this.objectsGroup.children;
  }

  setPointerFromBottomCenterViewport(vector) {
    const rect = this.domElement.getBoundingClientRect();

    const coords = {
      x: rect.width / 2 + rect.left,
      y: rect.bottom,
    };

    const x = ((coords.x - rect.left) / rect.width) * 2 - 1;
    const y = -((coords.y - rect.top) / rect.height) * 2 + 1;

    vector.set(x, y);
  }

  setPointerPosition(event, vector) {
    const rect = this.domElement.getBoundingClientRect();

    const x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    const y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    vector.set(x, y);
  }

  getIntersection(objectsToIntersect) {
    this.raycaster.setFromCamera(this.pointer, this.camera);

    const intersects = this.raycaster.intersectObjects(
      objectsToIntersect,
      true
    );

    return intersects.length ? intersects[0] : null;
  }
}

export default DistanceMeasurement;
