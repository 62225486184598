import { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import ProjectContext from "../../../common/context/ProjectContext/ProjectContext";
import ModalAlertContext from "../../../common/context/ModalAlertContext/ModalAlertContext";

import { useUpdateDTProject } from "../hooks/useUpdateDTProject";
import { useUploadDataToS3 } from "../../../common/hooks/useUploadDataToS3";
import { CUSTOMER_ID } from "../../dashboard/hooks/useGetDTProjects";

import { getDate } from "../../../common/utils/date";

const ProjectPageHeader = ({ dtProject }) => {
  const {
    sceneStarted,
    setIsLoading,
    projectData,
    setProjectData,
    getProjectConfigData,
    exitFromProjectConfigurator,
  } = useContext(ProjectContext);
  const { setIsDisplayDiscardChangesModal, setIsDisplayShareModal, openAlert } =
    useContext(ModalAlertContext);

  const { updateDTProject, loading } = useUpdateDTProject();
  const { upalodDataToS3, loading: upalodDataToS3Loading } =
    useUploadDataToS3();

  const onExitButton = () => {
    const initialData = JSON.stringify(projectData);
    const currentData = JSON.stringify(getProjectConfigData());

    if (initialData !== currentData) {
      setIsDisplayDiscardChangesModal(true);
    } else {
      exitFromProjectConfigurator();
    }
  };

  const saveProject = async () => {
    const projectConfig = {
      ...getProjectConfigData(),
      lastEditDate: getDate(),
    };

    const input = {
      projectId: dtProject.projectId,
      customerId: CUSTOMER_ID,
      displayName: dtProject.displayName,
    };

    const response = await updateDTProject(input);

    if (response.data) {
      await upalodDataToS3(
        response.data.updateDTProject.configURL,
        JSON.stringify(projectConfig),
        "json"
      );
    }

    setProjectData(projectConfig);
  };

  const onSaveButton = async () => {
    setIsLoading(true);

    await saveProject();

    setIsLoading(false);
    openAlert("saved");
  };

  const onShareButton = () => {
    setIsDisplayShareModal(true);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "60px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        padding: "10px 0",
      }}
    >
      <Box>
        <Typography variant="title" component="h1">
          {dtProject.displayName}
        </Typography>
        <Typography
          variant="body16Medium"
          sx={{
            color: "#B3B3B3",
          }}
        >
          {projectData.lastEditDate
            ? `Last edit ${projectData.lastEditDate}`
            : ""}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "12px",
        }}
      >
        <LoadingButton
          loading={loading || upalodDataToS3Loading}
          disabled={!sceneStarted || loading || upalodDataToS3Loading}
          onClick={onSaveButton}
          variant="primary"
          color="blue"
        >
          Save
        </LoadingButton>
        <Button
          disabled={!sceneStarted || loading || upalodDataToS3Loading}
          onClick={onShareButton}
          variant="primary"
          color="blue"
        >
          Share
        </Button>
        <Button
          onClick={onExitButton}
          variant="secondary"
          color="blue"
          disabled={!sceneStarted || loading || upalodDataToS3Loading}
        >
          Exit
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectPageHeader;
