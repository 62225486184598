const SystemSettingsIcon = (): JSX.Element => {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.47168 14H7.47168V16H0.47168V14ZM0.47168 7H9.47168V9H0.47168V7ZM0.47168 0H20.4717V2H0.47168V0ZM19.1457 9.0251L20.3018 8.634L21.3018 10.366L20.3857 11.1711C20.4421 11.4386 20.4717 11.7158 20.4717 12C20.4717 12.2842 20.4421 12.5614 20.3857 12.8289L21.3018 13.634L20.3018 15.366L19.1457 14.9749C18.7352 15.3441 18.248 15.6295 17.7108 15.8044L17.4717 17H15.4717L15.2326 15.8044C14.6954 15.6295 14.2082 15.3441 13.7977 14.9749L12.6416 15.366L11.6416 13.634L12.5577 12.8289C12.5013 12.5614 12.4717 12.2842 12.4717 12C12.4717 11.7158 12.5013 11.4386 12.5577 11.1711L11.6416 10.366L12.6416 8.634L13.7977 9.0251C14.2082 8.6559 14.6954 8.3705 15.2326 8.1956L15.4717 7H17.4717L17.7108 8.1956C18.248 8.3705 18.7352 8.6559 19.1457 9.0251ZM16.4717 14C17.5763 14 18.4717 13.1046 18.4717 12C18.4717 10.8954 17.5763 10 16.4717 10C15.3671 10 14.4717 10.8954 14.4717 12C14.4717 13.1046 15.3671 14 16.4717 14Z"
        fill="black"
      />
    </svg>
  );
};

export default SystemSettingsIcon;
