import { useSubscription } from "@apollo/client";
import { ListenToNodeFilesSubscription } from "../../API";
import { LISTEN_TO_NODE_FILES } from "../operations/subscriptions";

export const useListenToNodeFiles = (nodeId: string) => {
  const { data, loading, error } =
    useSubscription<ListenToNodeFilesSubscription>(LISTEN_TO_NODE_FILES, {
      fetchPolicy: "no-cache",
      variables: {
        nodeId,
      },
    });

  return { data, loading, error } as const;
};
