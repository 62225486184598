import * as THREE from "three";

import {
  CSS2DObject,
  CSS2DRenderer,
} from "three/addons/renderers/CSS2DRenderer.js";

import {
  REAL_EARTH_RADIUS_IN_KM,
  SHAPE_MATERIAL_COLOR,
  GROUND_RADIUS_IN_M,
} from "./Constants";

import { SecondaryViewTypes } from "../data/SecondaryViewData";

import {
  feetToMeters,
  getLatLonFromCoordinates,
  getPointsFromMultilinestring,
  metersToFeet,
} from "./MathUtils";
import {
  calculateObject3dCenter,
  getBufferGeometryFaceNormals,
  getObjectBoundingBox,
} from "./GeometryUtils";
import { getVectorsArrayWithOffset } from "./VectorsUtils";
import { getRaycastIntersectsPointByFromToVectors } from "./RaycastUtils";

import {
  createLineSegments,
  createMeshByPoints,
  createGroup,
} from "./CreateMesh";
import { loadTexture, loadHdrAsset } from "./AssetLoaders";

import ObjectBuilder from "./SceneObjectBuilder/ObjectBuilder";
import TowerBuilder from "./SceneObjectBuilder/TowerBuilder";
import CameraBuilder from "./SceneObjectBuilder/CameraBuilder";
import SelectObjectOnScene from "./SelectObjectOnScene";
import OrbitControlBuilder from "./Controls/OrbitControlBuilder";
import LookCameraControl from "./Controls/LookCameraControl";
import DistanceMeasurement from "./DistanceMeasurement";
import TransformObjectControl from "./Controls/TransformObjectControl";
import ObjectHighLight from "./ObjectHighLight";

import skyboxTexturePath from "../assets/hdr/kloofendal_48d_partly_cloudy_puresky_4k.hdr";
import PostEffects from "./PostEffects";

class ThreeScene {
  constructor(
    mainCanvasElement,
    secondaryCanvasElement,
    mainViewportElement,
    secondaryViewportElement,
    renderer2DElement
  ) {
    this.mainCanvasElement = mainCanvasElement;
    this.secondaryCanvasElement = secondaryCanvasElement;

    this.mainViewportElement = mainViewportElement;
    this.secondaryViewportElement = secondaryViewportElement;
    this.renderer2DElement = renderer2DElement;

    this.mainViewportDimensions = {
      width: this.mainViewportElement.clientWidth,
      height: this.mainViewportElement.clientHeight,
    };

    this.secondaryViewportDimensions = {
      width: this.secondaryViewportElement.clientWidth,
      height: this.secondaryViewportElement.clientHeight,
    };

    this.groundRadius = GROUND_RADIUS_IN_M;

    this.scene = this.initScene();

    this.mainRenderer = this.initRenderer(
      this.mainCanvasElement,
      this.mainViewportDimensions
    );

    this.secondaryRenderer = this.initRenderer(
      this.secondaryCanvasElement,
      this.secondaryViewportDimensions
    );

    this.distanceMeasurementRenderer = this.initCSS2DRenderer(
      this.renderer2DElement,
      this.secondaryViewportDimensions
    );

    this.mainCamera = this.initCamera();
    this.secondaryCamera = null;
    this.secondaryViewType = null;
    this.distanceMeasurementsMode = null;

    this.postEffects = new PostEffects(this.secondaryRenderer, this.scene);
    this.postEffects.initComposer(this.mainCamera);

    this.objectHighLight = new ObjectHighLight();

    this.terrain = null;
    this.terainGroup = createGroup("terainGroup", this.scene);
    this.shapeGroup = createGroup("shapeGroup", this.scene);
    this.objectsGroup = createGroup("objectsGroup", this.scene);
    this.towersGroup = createGroup("towersGroup", this.scene);
    this.distanceMeasurementGroup = createGroup(
      "distanceMeasurementGroup",
      this.scene
    );

    this.addLight(new THREE.Vector3(120, 80, 0));

    this.initCameraHeight = feetToMeters(20);

    this.sceneOffset = new THREE.Vector3();
    this.terrainCenter = new THREE.Vector3();

    this.objectBuilder = new ObjectBuilder();
    this.towerBuilder = new TowerBuilder();
    this.cameraBuilder = new CameraBuilder();

    this.orbitControl = null;
    this.transformControl = null;
  }

  initScene() {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    return scene;
  }

  initRenderer(canvasElement, viewportDimensions) {
    const { width, height } = viewportDimensions;
    const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;

    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: canvasElement,
    });

    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.6;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    renderer.useLegacyLights = false;
    renderer.setPixelRatio(DPR);
    renderer.setSize(width, height);

    return renderer;
  }

  initCSS2DRenderer(element, viewportDimensions) {
    const { width, height } = viewportDimensions;
    const renderer2d = new CSS2DRenderer({ element: element });

    renderer2d.setSize(width, height);

    return renderer2d;
  }

  initCamera() {
    const { width, height } = this.mainViewportDimensions;

    const fieldOfView = 45;
    const aspectRatio = width / height;
    const nearClip = 1;
    const farClip = 10000;

    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearClip,
      farClip
    );

    this.scene.add(camera);

    return camera;
  }

  initObjectSelection(handleObjectSelection, handleObjectHower) {
    this.objectSelection = new SelectObjectOnScene(
      this.mainCamera,
      [this.objectsGroup, this.towersGroup],
      this.mainViewportElement,
      handleObjectSelection,
      handleObjectHower
    );
  }

  addOrbitControl() {
    const targetPosition = this.terrainCenter.clone();
    const faceNormal = getBufferGeometryFaceNormals(this.terrain.geometry)[0];

    this.orbitControl = OrbitControlBuilder.createOrbitControlInstance(
      this.terrain,
      this.mainCamera,
      targetPosition,
      faceNormal,
      this.mainViewportElement
    );

    this.orbitControl.update();
  }

  addTransformControl(onChangeCallback) {
    this.transformControl = new TransformObjectControl(
      this.mainCamera,
      this.mainViewportElement,
      this.terrain,
      onChangeCallback
    );

    this.scene.add(this.transformControl.controls);
  }

  addLookCameraControl(cb) {
    this.lookCameraControl = new LookCameraControl(
      this.secondaryViewportElement,
      cb
    );

    this.lookCameraControl.enabled = false;
  }

  initDistanceMeasurement(cb) {
    const handleActionEnd = data => {
      cb(data);
    };

    this.distanceMeasurement = new DistanceMeasurement(
      this.scene,
      null,
      this.terrain,
      this.objectsGroup,
      this.secondaryViewportElement,
      handleActionEnd
    );

    this.distanceMeasurement.enabled = false;
  }

  async addSkybox() {
    try {
      this.hdrTexture = await loadHdrAsset(skyboxTexturePath);
    } catch (error) {
      console.error("Errorr addSkybox: ", error);
    }

    const skyGeometry = new THREE.SphereGeometry(this.groundRadius, 32, 16);

    const skyMaterial = new THREE.MeshBasicMaterial({
      map: this.hdrTexture,
      side: THREE.BackSide,
    });

    this.skybox = new THREE.Mesh(skyGeometry, skyMaterial);

    const faceNormal = getBufferGeometryFaceNormals(this.terrain.geometry)[0];

    this.skybox.lookAt(faceNormal);
    this.skybox.rotateX(THREE.MathUtils.degToRad(90));

    this.skybox.position.copy(this.terrainCenter);

    this.scene.add(this.skybox);
  }

  getGroundCornerCoords(plane) {
    const planeBbox = getObjectBoundingBox(plane);

    const latLonArray = [planeBbox.min, planeBbox.max].map(vertex => {
      const worldVertice = plane.localToWorld(vertex.clone());

      const [latitude, longitude] = getLatLonFromCoordinates(
        REAL_EARTH_RADIUS_IN_KM,
        new THREE.Vector3().addVectors(worldVertice, this.sceneOffset)
      );

      return { latitude, longitude };
    });

    const allLat = latLonArray.map(c => c.latitude);
    const maxLat = Math.max(...allLat);
    const minLat = Math.min(...allLat);

    const allLon = latLonArray.map(c => c.longitude);
    const maxLon = Math.max(...allLon);
    const minLon = Math.min(...allLon);

    const lonLatObj = {
      maxLat,
      minLat,
      maxLon,
      minLon,
    };

    return lonLatObj;
  }

  async addGround(texturePath) {
    let texture;

    try {
      texture = await loadTexture(texturePath);
    } catch (error) {
      console.error("Errorr: addGround", error);
    }

    const groundGeometry = new THREE.PlaneGeometry(
      this.groundRadius * 2,
      this.groundRadius * 2
    );

    const groundMaterial = new THREE.MeshBasicMaterial({
      map: texture,
    });

    const groundPlane = new THREE.Mesh(groundGeometry, groundMaterial);
    groundPlane.castShadow = true;
    groundPlane.receiveShadow = true;

    const faceNormal = getBufferGeometryFaceNormals(this.terrain.geometry)[0];

    groundPlane.lookAt(faceNormal);

    const pos = this.terrainCenter
      .clone()
      .sub(faceNormal.clone().divideScalar(10));

    groundPlane.position.copy(pos);

    this.scene.add(groundPlane);
  }

  addLight(position) {
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(position.x, position.y, position.z);
    this.scene.add(light);

    const light2 = new THREE.DirectionalLight(0xffffff, 3);
    light2.position.set(20, 50, 0);

    this.scene.add(light2);

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1);
    hemiLight.color.setHSL(0.6, 1, 0.6);
    hemiLight.groundColor.setHSL(0.095, 1, 0.75);
    hemiLight.position.set(0, 50, 0);
    this.scene.add(hemiLight);
  }

  setSceneSettingValues() {
    this.terrainBbox = getObjectBoundingBox(this.terrain);

    const intersects = getRaycastIntersectsPointByFromToVectors(
      this.terrain,
      this.terrainBbox.max,
      this.terrainBbox.min
    );

    if (intersects.length > 0) {
      this.terrainCenter = intersects[0].point;
    } else {
      this.terrainCenter = calculateObject3dCenter(this.terrainBbox);
    }
  }

  setSceneOffsetVector(points) {
    const box = new THREE.Box3();
    box.setFromPoints(points);

    box.getCenter(this.sceneOffset);
  }

  toggleActiveObjectHighlight(
    activeObjectId,
    activeTowerSideIndex,
    activeCameraId,
    hoveredObjectId,
    hoveredTowerSideIndex,
    hoveredCameraId
  ) {
    // Selected objects.
    const objectSel = this.getObjectById(activeObjectId);
    const towerSel = this.getTowerById(activeObjectId);

    const selectedObject = objectSel || towerSel;
    const selectedTowerSide =
      towerSel && this.getSideByIndexInTower(activeTowerSideIndex, towerSel);
    const selectedCamera =
      selectedTowerSide && this.getCameraById(activeCameraId);

    const selected = {
      selectedObject,
      selectedTowerSide,
      selectedCamera,
    };

    // Hovered objects.
    const objectHov = this.getObjectById(hoveredObjectId);
    const towerHov = this.getTowerById(hoveredObjectId);

    const hoveredObject = objectHov || towerHov;
    const hoveredTowerSide =
      towerHov && this.getSideByIndexInTower(hoveredTowerSideIndex, towerHov);
    const hoveredCamera =
      hoveredTowerSide && this.getCameraById(hoveredCameraId);

    const hovered = {
      hoveredObject,
      hoveredTowerSide,
      hoveredCamera,
    };

    this.objectHighLight.toggleHighlight(selected, hovered);
  }

  addTerrainByJson(multilinestringMeshArray) {
    const points = getPointsFromMultilinestring(multilinestringMeshArray[0]);

    this.setSceneOffsetVector(points);

    const pointsWithOffset = getVectorsArrayWithOffset(
      points,
      this.sceneOffset
    );

    const terrain = createMeshByPoints(
      pointsWithOffset,
      new THREE.MeshBasicMaterial({
        transparent: true,
        opacity: 0,
        side: THREE.DoubleSide,
      })
    );

    terrain.name = "terrain";
    this.terrain = terrain;

    this.terainGroup.add(terrain);

    const border = createLineSegments(
      pointsWithOffset,
      new THREE.LineBasicMaterial({
        color: SHAPE_MATERIAL_COLOR,
      })
    );

    this.terainGroup.add(border);
  }

  addShapeByJson(multilinestringMeshArray) {
    for (const mesh of multilinestringMeshArray) {
      const points = getPointsFromMultilinestring(mesh);

      const pointsWithOffset = getVectorsArrayWithOffset(
        points,
        this.sceneOffset
      );

      const shape = createLineSegments(
        pointsWithOffset,
        new THREE.LineBasicMaterial({ color: SHAPE_MATERIAL_COLOR })
      );

      this.shapeGroup.add(shape);
    }
  }

  toggleShape(toggler) {
    this.shapeGroup.children.forEach(line =>
      toggler ? line.layers.enable() : line.layers.disable()
    );
  }

  getObjectAngleState(object) {
    const quaternion1 = object.initialQuaternion.clone();
    const quaternion2 = object.quaternion.clone();

    const angleInRad = quaternion1.angleTo(quaternion2);

    const euler = new THREE.Euler().setFromQuaternion(
      quaternion1.conjugate().multiply(quaternion2)
    );

    const direction = euler.y > 0 ? 1 : -1;

    const angleInDeg = THREE.MathUtils.radToDeg(angleInRad * direction);

    return Math.round(angleInDeg);
  }

  async addObject(objectData) {
    const faceNormal = getBufferGeometryFaceNormals(this.terrain.geometry)[0];
    objectData.envMap = this.hdrTexture;

    const { objectToClone, materialToClone } =
      await this.objectBuilder.getObjectInstance(objectData);

    const object = this.objectBuilder.createObjectInstance(
      objectToClone,
      materialToClone,
      objectData,
      faceNormal,
      this.orbitControl.target
    );

    this.objectsGroup.add(object);

    return object;
  }

  deleteObject(object) {
    this.objectsGroup.remove(object);
  }

  setObjectQuaternionByAngle(id, value = 0) {
    const object = this.getObjectById(id);
    const tower = this.getTowerById(id);

    const activeObject = object || tower;

    if (!activeObject) {
      return;
    }

    const rotationAxis = new THREE.Vector3(0, 1, 0);

    activeObject.quaternion.copy(activeObject.initialQuaternion);

    activeObject.quaternion
      .multiply(
        new THREE.Quaternion().setFromAxisAngle(
          rotationAxis,
          THREE.MathUtils.degToRad(value)
        )
      )
      .normalize();
  }

  getObjectById(id) {
    return this.objectsGroup.children.find(obj => obj.ID === id);
  }

  getTowerById(id) {
    return this.towersGroup.children.find(obj => obj.ID === id);
  }

  getObjectState(object) {
    const [latitude, longitude] = getLatLonFromCoordinates(
      REAL_EARTH_RADIUS_IN_KM,
      new THREE.Vector3().addVectors(object.position, this.sceneOffset)
    );

    const angle = this.getObjectAngleState(object);

    const state = {
      position: {
        x: object.position.x,
        y: object.position.y,
        z: object.position.z,
      },
      rotation: {
        x: object.rotation.x,
        y: object.rotation.y,
        z: object.rotation.z,
      },
      coordinates: {
        latitude,
        longitude,
      },
      initialQuaternion: object.initialQuaternion.toArray(),
      angle,
    };

    return state;
  }

  async addCamera(cameraData, towerSceneObject) {
    const { objectToClone, materialToClone } =
      await this.cameraBuilder.getObjectInstance(cameraData);

    const object = this.cameraBuilder.createCameraInstance(
      objectToClone,
      materialToClone,
      cameraData,
      this.initCameraHeight,
      this.secondaryViewportDimensions
    );

    const sideSceneObject = this.getSideByIndexInTower(
      cameraData.sideIndex,
      towerSceneObject
    );

    sideSceneObject.add(object);

    this.addCameraHelper(object);

    return object;
  }

  deleteCamera(camera) {
    this.removeCameraHelper(camera.ID);
    camera.removeFromParent();
  }

  async addTower(towerData) {
    const faceNormal = getBufferGeometryFaceNormals(this.terrain.geometry)[0];

    const { objectToClone, materialToClone } =
      await this.towerBuilder.getObjectInstance(towerData);

    const object = this.towerBuilder.createObjectInstance(
      objectToClone,
      materialToClone,
      towerData,
      faceNormal,
      this.orbitControl.target
    );

    this.towersGroup.add(object);

    return object;
  }

  deleteTower(tower) {
    this.towersGroup.remove(tower);
  }

  getCameraById(id) {
    return this.towersGroup.getObjectByProperty("ID", id);
  }

  getSideByIndexInTower(index, tower) {
    return tower.getObjectByProperty("SideIndex", index);
  }

  getCameraByIdInTower(id, tower) {
    return tower.getObjectByProperty("ID", id);
  }

  getCameraState(camera) {
    const perspectiveCamera = camera.getObjectByName("SingleLensCamera");

    const cameraHorRotation = camera.getObjectByName("SM_Camera_Hor");
    const polar = cameraHorRotation ? cameraHorRotation.rotation.z : 0;

    const cameraVertRotation = camera.getObjectByName("SM_Camera_Vert");
    const azimuth = cameraVertRotation ? cameraVertRotation.rotation.x : 0;

    if (perspectiveCamera) {
      return {
        height: metersToFeet(camera.position.y),
        horizontalAngle: THREE.MathUtils.radToDeg(polar),
        verticalAngle: THREE.MathUtils.radToDeg(azimuth) * -1,
        fov: perspectiveCamera.fov,
        zoom: perspectiveCamera.zoom,
      };
    }

    return {
      height: this.initCameraHeight,
      horizontalAngle: 0,
      verticalAngle: 0,
      fov: 45,
      zoom: 1,
    };
  }

  updateCameraHeight(id, height) {
    const camera = this.getCameraById(id);
    camera.position.y = feetToMeters(height);
  }

  updateCameraRotation(id, type, value) {
    const camera = this.getCameraById(id);

    if (type === "horizontalAngle") {
      const cameraHorRotation = camera.getObjectByName("SM_Camera_Hor");
      cameraHorRotation.rotation.z = THREE.MathUtils.degToRad(value);
    }

    if (type === "verticalAngle") {
      const cameraVertRotation = camera.getObjectByName("SM_Camera_Vert");
      cameraVertRotation.rotation.x = THREE.MathUtils.degToRad(value) * -1;
    }
  }

  updateCameraFov(id, fov) {
    const camera = this.getCameraById(id);

    const perspectiveCamera = camera.getObjectByName("SingleLensCamera");

    perspectiveCamera.fov = fov;
    perspectiveCamera.updateProjectionMatrix();
  }

  addDistanceMeasurementLable(position, distance) {
    const lable = document.createElement("div");
    lable.style.display = "flex";
    lable.style.alignItems = "center";
    lable.style.justifyContent = "center";
    lable.style.width = "fit-content";
    lable.style.height = "32px";
    lable.style.backgroundColor = "#FFFFFF";
    lable.style.border = "1px solid #98A7BC";
    lable.style.borderRadius = "4px";
    lable.style.padding = "2px 6px 2px 10px";

    const lableText = document.createElement("p");
    lableText.textContent = `${distance} ft`;
    lableText.style.fontFamily = "Inter";
    lableText.style.fontWeight = 500;
    lableText.style.fontSize = "20px";
    lableText.style.lineHeight = "28px";
    lableText.style.color = "#202832";
    lableText.style.margin = "0";

    lable.appendChild(lableText);

    const labelObject = new CSS2DObject(lable);
    labelObject.position.set(position.x, position.y, position.z);
    this.distanceMeasurementGroup.add(labelObject);
  }

  removeDistanceMeasurementLable() {
    while (this.distanceMeasurementGroup.children.length) {
      this.distanceMeasurementGroup.remove(
        this.distanceMeasurementGroup.children[0]
      );
    }
  }

  onWindowResize(main, secondary) {
    const { width: mainWidth, height: mainHeight } = main;
    const { width: secondaryWidth, height: secondaryHeight } = secondary;

    this.mainViewportDimensions.width = mainWidth;
    this.mainViewportDimensions.height = mainHeight;

    this.secondaryViewportDimensions.width = secondaryWidth;
    this.secondaryViewportDimensions.height = secondaryHeight;

    this.mainCamera.aspect = mainWidth / mainHeight;
    this.mainCamera.updateProjectionMatrix();

    this.mainRenderer.setSize(mainWidth, mainHeight);
    this.postEffects.resize(mainWidth, mainHeight);

    if (this.secondaryCamera) {
      const camera = this.secondaryCamera.getObjectByName("SingleLensCamera");

      if (camera) {
        camera.aspect = secondaryWidth / secondaryHeight;
        camera.updateProjectionMatrix();
      }

      this.secondaryRenderer.setSize(secondaryWidth, secondaryHeight);

      this.distanceMeasurementRenderer.setSize(secondaryWidth, secondaryHeight);
    }
  }

  changeCortrolMode() {
    if (this.orbitControl) {
      this.orbitControl.enabled = this.transformControl
        ? !this.transformControl.controls.object && !this.secondaryCamera
        : !this.secondaryCamera;
    }

    if (this.secondaryCamera) {
      const camera = this.secondaryCamera.getObjectByName("SingleLensCamera");

      if (this.lookCameraControl && camera) {
        if (!this.distanceMeasurementsMode) {
          this.lookCameraControl.camera = camera;
          this.lookCameraControl.activeObject = this.secondaryCamera;
          this.lookCameraControl.enabled = true;
        } else {
          this.lookCameraControl.enabled = false;
          this.lookCameraControl.camera = null;
          this.lookCameraControl.activeObject = null;
        }
      }

      if (this.distanceMeasurement && camera) {
        if (this.distanceMeasurementsMode) {
          this.distanceMeasurement.enabled = true;
          this.distanceMeasurement.camera = camera;
          this.distanceMeasurement.activeObject = this.secondaryCamera;
        } else {
          this.distanceMeasurement.enabled = false;
          this.distanceMeasurement.camera = null;
          this.distanceMeasurement.activeObject = null;
        }
      }
    }
  }

  addCameraHelper(camera) {
    const singlePerspectiveCamera = camera.getObjectByName("SingleLensCamera");

    if (singlePerspectiveCamera) {
      const cameraHelper = new THREE.CameraHelper(singlePerspectiveCamera);
      cameraHelper.ID = `camera-helper-${camera.ID}`;

      this.scene.add(cameraHelper);
    }
  }

  removeCameraHelper(id) {
    const cameraHelper = this.scene.getObjectByProperty(
      "ID",
      `camera-helper-${id}`
    );

    if (cameraHelper) {
      this.scene.remove(cameraHelper);
    }
  }

  toggleCameraHelper(toggler) {
    this.scene.getObjectsByProperty("type", "CameraHelper").forEach(object => {
      if (object instanceof THREE.CameraHelper) {
        toggler ? object.layers.enable() : object.layers.disable();
      }
    });
  }

  updateCamerasHelper() {
    this.scene.getObjectsByProperty("type", "CameraHelper").forEach(object => {
      object instanceof THREE.CameraHelper && object.update();
    });
  }

  setCameraHelpersVisibility(visible) {
    this.scene.getObjectsByProperty("type", "CameraHelper").forEach(object => {
      if (object instanceof THREE.CameraHelper) {
        object.visible = visible;
      }
    });
  }

  renderMainCameraViewport() {
    this.setCameraHelpersVisibility(true);

    this.distanceMeasurement.setLazerVisibility(false);

    if (this.transformControl) {
      this.transformControl.toggleVisibility(true);
    }

    this.mainRenderer.render(this.scene, this.mainCamera);
  }

  renderSecondaryCameraViewport() {
    this.setCameraHelpersVisibility(false);

    if (this.distanceMeasurementsMode) {
      this.distanceMeasurement.setLazerVisibility(true);
    }

    if (this.transformControl) {
      this.transformControl.toggleVisibility(false);
    }

    if (this.secondaryViewType === SecondaryViewTypes.viewGas) {
      const camera = this.secondaryCamera.getObjectByName("SingleLensCamera");

      this.secondaryRenderer.setScissorTest(false);

      this.secondaryRenderer.render(this.scene, camera);
      this.postEffects.render(camera);
    } else if (this.secondaryViewType === SecondaryViewTypes.viewRGB) {
      const camera = this.secondaryCamera.getObjectByName("SingleLensCamera");

      this.secondaryRenderer.setScissorTest(false);

      this.secondaryRenderer.render(this.scene, camera);
    } else if (this.secondaryViewType === SecondaryViewTypes.view360) {
      const view360Cameras = this.secondaryCamera.getObjectsByProperty(
        "name",
        "360LensCamera"
      );

      const { width: viewportWidth, height: viewportHeight } =
        this.secondaryViewportDimensions;

      view360Cameras.forEach(camera => {
        const index = camera.parent.name.slice(-1) - 1;

        const left = Math.floor(viewportWidth * 0.25 * index);
        const bottom = 0;
        const width = Math.floor(viewportWidth * 0.25);
        const height = viewportHeight;

        this.secondaryRenderer.setViewport(left, bottom, width, height);
        this.secondaryRenderer.setScissor(left, bottom, width, height);
        this.secondaryRenderer.setScissorTest(true);

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        this.secondaryRenderer.render(this.scene, camera);
      });
    }
  }

  renderDistanceMeasurementViewport() {
    const camera = this.secondaryCamera.getObjectByName("SingleLensCamera");

    this.distanceMeasurementRenderer.render(this.scene, camera);
  }

  update() {
    if (this.orbitControl && this.orbitControl.enabled) {
      this.orbitControl.update();
    }

    this.skybox.rotateY(THREE.MathUtils.degToRad(-0.005));

    this.updateCamerasHelper();

    this.renderMainCameraViewport();

    if (this.secondaryCamera) {
      this.renderSecondaryCameraViewport();
    }

    if (this.secondaryCamera && this.distanceMeasurementsMode) {
      this.renderDistanceMeasurementViewport();
    }
  }
}

export default ThreeScene;
