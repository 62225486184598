import { useContext } from "react";
import { List } from "@mui/material";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";

import TowerListItem from "./TowerListItem";

const TowerList = () => {
  const { towers, activeObjectId } = useContext(ProjectContext);

  return (
    <List
      sx={{
        overflowY: "scroll",
        width: "calc(100% + 11.5px)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "0px",
        gap: "15px",

        paddingRight: "4px",

        "&::-webkit-scrollbar": {
          width: "7px",
          height: "7px",
        },

        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },

        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E0E9FE",
          borderRadius: "30px",
        },
      }}
    >
      {towers.map(data => (
        <TowerListItem
          key={data.id}
          data={data}
          open={activeObjectId === data.id}
        />
      ))}
    </List>
  );
};

export default TowerList;
