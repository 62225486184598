import type { ComponentsOverrides, Theme } from "@mui/material";

export const MuiInputLabelDarkStyleOverrides: ComponentsOverrides<Theme>["MuiInputLabel"] =
  {
    root: ({ theme }) => {
      return {
        color: theme.palette.text.secondary,
      };
    },
  };
