import React from "react";

import { useReactiveVar } from "@apollo/client";
import { CssBaseline } from "@mui/material";
import { ConfirmProvider } from "material-ui-confirm";

import MuiThemeProvider from "./MuiThemeProvider";

import { themeModeVariable } from "../../variables/themeMode";

const withThemeProvider = (
  ComposedComponent: React.ComponentType
): React.FC => {
  return (props): JSX.Element => {
    ComposedComponent.displayName = "withThemeProviderComposedComponent";

    const mode = useReactiveVar(themeModeVariable);

    return (
      <MuiThemeProvider mode={mode}>
        <ConfirmProvider>
          <CssBaseline enableColorScheme />
          <ComposedComponent {...props} />
        </ConfirmProvider>
      </MuiThemeProvider>
    );
  };
};

export default withThemeProvider;
