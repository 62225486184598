import { useContext, useEffect, useState } from "react";
import { Box, ToggleButton, Typography } from "@mui/material";

import CameraParametersListItem from "./CameraParametersListItem";

import ProjectContext from "../../../context/ProjectContext/ProjectContext";
import ConfiguratorPermissionContext from "../../../context/ConfiguratorPermissionContext/ConfiguratorPermissionContext";

import {
  CameraParams,
  Camera360FielOfViewParam,
  CameraHeightParam,
} from "./CameraDefaultParametrs";
import { CamerasType } from "../../../data/CamerasData";

import { SecondaryViewTypes } from "../../../data/SecondaryViewData";

import { DeleteIcon } from "../../../assets/icons/svgAssets";

const CameraControlPanel = () => {
  const {
    isLoading,
    threeScene,
    cameras,
    updateCameraProperties,
    activeCameraId,
    removeCamera,
    setSecondaryCameraId,
    secondaryViewType,
    setSecondaryViewType,
    distanceMeasurementsMode,
  } = useContext(ProjectContext);

  const { isAllowEditing } = useContext(ConfiguratorPermissionContext);

  const [cameraParam, setCameraParam] = useState(
    JSON.parse(JSON.stringify(CameraParams))
  );
  const [cameraHeightParam, setCameraHeightParam] = useState(
    JSON.parse(JSON.stringify(CameraHeightParam))
  );

  const activeCamera = cameras.find(camera => camera.id === activeCameraId);

  const onCameraRGBViewButton = () => {
    setSecondaryCameraId(activeCameraId);

    if (
      activeCamera.cameraType === CamerasType.MinervaGasCamera ||
      activeCamera.cameraType === CamerasType.MinervaCamera
    ) {
      setSecondaryViewType(SecondaryViewTypes.view360);
    }

    if (activeCamera.cameraType === CamerasType.RGBCamera) {
      setSecondaryViewType(SecondaryViewTypes.viewRGB);
    }
  };

  const onCameraGasViewButton = () => {
    setSecondaryCameraId(activeCameraId);
    setSecondaryViewType(SecondaryViewTypes.viewGas);
  };

  const onDeleteCameraButton = () => {
    removeCamera(activeCameraId);
  };

  const handleOnChange = (type, value, isError) => {
    if (isError) {
      return;
    }

    if (type === "height") {
      threeScene.updateCameraHeight(activeCameraId, value);
    }

    if (type === "verticalAngle" || type === "horizontalAngle") {
      threeScene.updateCameraRotation(activeCameraId, type, value);
    }

    if (type === "fieldOfView") {
      threeScene.updateCameraFov(activeCameraId, value);
    }

    updateCameraProperties(activeCameraId);
  };

  const setParamsData = prev => {
    return prev.map(param => {
      if (param.type === "height") {
        const value = parseFloat(activeCamera.height).toFixed(1).toString();

        param.inputData.value = parseFloat(value);
      }

      if (param.type === "verticalAngle") {
        const value = parseFloat(activeCamera.verticalAngle)
          .toFixed(4)
          .toString();

        param.inputData.value = parseFloat(value);
      }

      if (param.type === "horizontalAngle") {
        const value = parseFloat(activeCamera.horizontalAngle)
          .toFixed(4)
          .toString();

        param.inputData.value = parseFloat(value);
      }

      if (param.type === "fieldOfView") {
        param.inputData.value = activeCamera.fov;
      }

      param.inputData.disabled =
        param.inputData.defaultDisabled ||
        distanceMeasurementsMode ||
        isLoading ||
        !isAllowEditing ||
        (param.type !== "height" &&
          secondaryViewType === SecondaryViewTypes.view360);

      return param;
    });
  };

  useEffect(() => {
    if (!activeCamera) {
      return;
    }

    setCameraHeightParam(prev => setParamsData(prev));
    setCameraParam(prev => setParamsData(prev));
  }, [
    activeCamera,
    cameras,
    distanceMeasurementsMode,
    isLoading,
    secondaryViewType,
  ]);

  return (
    <>
      {activeCameraId && activeCamera ? (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            bottom: "72px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            backgroundColor: "#F5F5F5",
            border: "1px solid #999999",
            borderRadius: "4px",
            width: "243px",
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              background: "#326CFB",
              color: "white",
              padding: "2px 10px",
            }}
            variant="body14Medium"
          >
            {activeCamera && activeCamera.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "8px 10px",
              gap: "7px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {activeCamera.cameraType !== CamerasType.RGBCamera &&
                Camera360FielOfViewParam.map(param => {
                  return (
                    <CameraParametersListItem
                      key={`${param.title}-${param.type}`}
                      title={param.title}
                      inputData={param.inputData}
                      inputWidth="75%"
                    />
                  );
                })}

              {cameraHeightParam.map((param, i) => {
                return (
                  <CameraParametersListItem
                    key={`${param.title}-${i}`}
                    title={param.title}
                    inputData={param.inputData}
                    inputWidth="75%"
                    handleOnChange={handleOnChange}
                  />
                );
              })}

              {activeCamera.cameraType === CamerasType.MinervaGasCamera && (
                <Typography
                  sx={{ paddingTop: "6px" }}
                  variant="body14Medium"
                  color="#202832"
                >
                  Gas camera settings
                </Typography>
              )}

              {activeCamera.cameraType === CamerasType.RGBCamera && (
                <Typography
                  sx={{ paddingTop: "6px" }}
                  variant="body14Medium"
                  color="#202832"
                >
                  RGB camera settings
                </Typography>
              )}

              {activeCamera.cameraType !== CamerasType.MinervaCamera &&
                cameraParam.map((param, i) => {
                  return (
                    <CameraParametersListItem
                      key={`${param.title}-${i}`}
                      title={param.title}
                      inputData={param.inputData}
                      inputWidth="75%"
                      handleOnChange={handleOnChange}
                    />
                  );
                })}
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "42px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "stretch",
                padding: "0px",
              }}
            >
              <ToggleButton
                variant="outline-text"
                selected={
                  secondaryViewType === SecondaryViewTypes.view360 ||
                  secondaryViewType === SecondaryViewTypes.viewRGB
                }
                value="cam-view"
                disabled={isLoading}
                onClick={onCameraRGBViewButton}
              >
                Cam view
              </ToggleButton>

              {activeCamera &&
                activeCamera.cameraType === CamerasType.MinervaGasCamera && (
                  <ToggleButton
                    variant="outline-text"
                    selected={secondaryViewType === SecondaryViewTypes.viewGas}
                    value="gas-view"
                    disabled={isLoading}
                    onClick={onCameraGasViewButton}
                  >
                    Gas view
                  </ToggleButton>
                )}

              {isAllowEditing && (
                <ToggleButton
                  variant="icon"
                  value="delete"
                  disabled={isLoading}
                  selected={false}
                  onClick={onDeleteCameraButton}
                >
                  <DeleteIcon />
                </ToggleButton>
              )}
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default CameraControlPanel;
