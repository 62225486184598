import { Button } from "@mui/material";

import StyledDialog from "../../../common/components/dialog/StyledDialog";
import { useDialog } from "../../../common/components/dialog/useDialog";
import DeleteServiceForm from "./DeleteServiceForm";
import { useDeleteService } from "../hooks/useDeleteService";

const DeleteServiceDialogContainer = ({
  serviceId,
  nodeId,
}: {
  serviceId: string;
  nodeId: string;
}): JSX.Element => {
  const { open, handleDialogOpen, handleDialogClose } = useDialog();
  const { deleteService, loading } = useDeleteService();

  const closeDialog = (): void => {
    handleDialogClose();
  };
  const openDialog = (): void => {
    handleDialogOpen();
  };

  const removeServiceFromDDB = (): void => {
    deleteService({ nodeId, serviceId });
  };

  return (
    <>
      <Button color="error" onClick={openDialog}>
        Delete
      </Button>
      <StyledDialog
        open={open}
        title="Delete Model Service Instance"
        maxWidth="md"
        onClose={closeDialog}
        SubmitButtonProps={{}}
        showSubmitButton={false}
      >
        <DeleteServiceForm
          onCancel={closeDialog}
          onConfirm={removeServiceFromDDB}
          loading={loading}
        />
      </StyledDialog>
    </>
  );
};

export default DeleteServiceDialogContainer;
