import * as THREE from "three";

class LookCameraControl {
  constructor(domElement, handleActionEnd) {
    this.enabled = false;

    this.minZoom = 1;
    this.maxZoom = 5;

    this.minPolarAngle = THREE.MathUtils.degToRad(0);
    this.maxPolarAngle = THREE.MathUtils.degToRad(180);
    this.pointerSpeed = 3 * 0.002;

    this.domElement = domElement;

    this.activeObject = null;
    this.camera = null;

    this.domElement.addEventListener("mousedown", e => this.handleMouseDown(e));

    this.domElement.addEventListener("mousemove", e =>
      this.handleMouseMove(e, handleActionEnd)
    );

    this.domElement.addEventListener("wheel", e =>
      this.handOnWheel(e, handleActionEnd)
    );

    document.body.addEventListener("mouseup", () =>
      this.handleMouseUp(handleActionEnd)
    );
  }

  handleMouseDown(event) {
    if (!this.enabled || !this.camera) {
      return;
    }

    if (event.button === 0) {
      this.leftDown = true;
    }
  }

  handleMouseMove(event, cb) {
    if (!this.enabled || !this.camera) {
      return;
    }

    event.preventDefault();

    if (this.leftDown) {
      const dx =
        event.movementX || event.mozMovementX || event.webkitMovementX || 0;
      const dy =
        event.movementY || event.mozMovementY || event.webkitMovementY || 0;

      this.rotateCamera(dx, dy);

      cb && cb(this.activeObject.ID);
    }
  }

  handleMouseUp(cb) {
    if (this.leftDown) {
      cb && cb(this.activeObject.ID);
    }

    this.leftDown = false;
  }

  handOnWheel(event, cb) {
    if (!this.enabled || !this.camera) {
      return;
    }

    event.preventDefault();

    this.zoomCamera(event.deltaY);

    cb && cb(this.activeObject.ID);
  }

  rotateCamera(dx, dy) {
    const cameraHorRotation =
      this.activeObject.getObjectByName("SM_Camera_Hor");
    cameraHorRotation.rotation.z += dx * this.pointerSpeed;

    const cameraVertRotation =
      this.activeObject.getObjectByName("SM_Camera_Vert");
    cameraVertRotation.rotation.x -= dy * this.pointerSpeed;

    cameraVertRotation.rotation.x = THREE.MathUtils.clamp(
      cameraVertRotation.rotation.x,
      Math.PI / 2 - this.maxPolarAngle,
      Math.PI / 2 - this.minPolarAngle
    );
  }

  zoomCamera(delta) {
    this.camera.zoom -= delta * 0.002;
    this.camera.zoom = THREE.MathUtils.clamp(
      this.camera.zoom,
      this.minZoom,
      this.maxZoom
    );

    this.camera.updateProjectionMatrix();
  }
}

export default LookCameraControl;
