import { Box } from "@mui/material";
import inputLoadingGif from "../../assets/gif/input-loading.gif";

const LoadFileIcon = () => (
  <Box
    sx={{
      width: "14px",
      height: "14px",
      marginRight: "8px",
      "& img": { width: "100%", height: "100%" },
    }}
  >
    <img src={inputLoadingGif} alt="loading..." />
  </Box>
);

export default LoadFileIcon;
