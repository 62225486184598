import { useState, useCallback, useEffect, useLayoutEffect } from "react";
import { Swiper } from "swiper/react";
import { FreeMode, Scrollbar, Mousewheel } from "swiper";
import { Stack, Fab } from "@mui/material";

import { ArrowIcon } from "../assets/icons/svgAssets";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

const SwiperSlider = ({
  initialSlide,
  prevButtonPosition = 0,
  nextButtonPosition = 0,
  spaceBetween = 16,
  direction = "horizontal",
  slidesPerView = "auto",
  children,
}) => {
  const [prevButtonDisabled, setPrevButtonDisabled] = useState();
  const [nextButtonDisabled, setNextButtonDisabled] = useState();
  const [swiperRef, setSwiperRef] = useState();

  const handleLeftClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slidePrev();
  }, [swiperRef]);

  const handleRightClick = useCallback(() => {
    if (!swiperRef) return;
    swiperRef.slideNext();
  }, [swiperRef]);

  const onSwiper = swiper => {
    setSwiperRef(swiper);
  };

  const changeButtonVisibility = () => {
    if (swiperRef) {
      setPrevButtonDisabled(swiperRef.isBeginning);
      setNextButtonDisabled(swiperRef.isEnd);
    }
  };

  useEffect(() => {
    changeButtonVisibility();
  }, [swiperRef]);

  useLayoutEffect(() => {
    const cb = () => {
      swiperRef.update();
      changeButtonVisibility();
    };

    window.addEventListener("resize", cb);
    return () => window.removeEventListener("resize", cb);
  });

  useEffect(() => {
    swiperRef && swiperRef.slideTo(initialSlide, 0);
  }, [initialSlide]);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        "& .swiper-slider": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      <Fab
        disabled={prevButtonDisabled}
        onClick={handleLeftClick}
        sx={{
          position: "absolute",
          left: prevButtonPosition,
          width: "24px",
          height: "24px",
          minHeight: "24px",
          backgroundColor: "white",
          p: 1,
          "&.Mui-disabled": {
            display: "none",
          },
        }}
      >
        <ArrowIcon />
      </Fab>
      <Swiper
        className="swiper-slider"
        direction={direction}
        initialSlide={initialSlide}
        slidesPerView={slidesPerView}
        spaceBetween={spaceBetween}
        freeMode={true}
        scrollbar={{ draggable: true }}
        mousewheel={true}
        onSwiper={onSwiper}
        onSlideChange={changeButtonVisibility}
        onAny={changeButtonVisibility}
        modules={[FreeMode, Scrollbar, Mousewheel]}
      >
        {children}
      </Swiper>
      <Fab
        disabled={nextButtonDisabled}
        onClick={handleRightClick}
        sx={{
          position: "absolute",
          right: nextButtonPosition,
          width: "24px",
          height: "24px",
          minHeight: "24px",
          backgroundColor: "white",
          p: 1,
          transform: "rotateY(180deg)",
          "&.Mui-disabled": {
            display: "none",
          },
          ["@container main (max-width: 600px)"]: {
            right: "14px",
          },
        }}
      >
        <ArrowIcon />
      </Fab>
    </Stack>
  );
};

export default SwiperSlider;
