import * as THREE from "three";

import SceneObjectBuilder from "./SceneObjectBuilder";

import { setMaterial } from "../MaterilsUtils";
import { feetToMeters } from "../MathUtils";

import { CamerasType } from "../../data/CamerasData";

class CameraBuilder extends SceneObjectBuilder {
  createCameraInstance(
    objectClone,
    materialClone,
    cameraData,
    defaultCameraHeight,
    viewportDimensions
  ) {
    const object = objectClone.clone(true);
    setMaterial(object, materialClone.clone());

    object.ID = cameraData.id;
    object.EntityType = cameraData.EntityType;
    object.CameraType = cameraData.cameraType;

    if (
      object.CameraType === CamerasType.RGBCamera ||
      object.CameraType === CamerasType.MinervaGasCamera
    ) {
      const lensParent = object.getObjectByName("SM_CameraLens");

      const cameraName = "SingleLensCamera";

      this.createCamera(
        lensParent,
        viewportDimensions,
        cameraData.fov,
        cameraData.zoom,
        cameraName
      );

      this.setPerspectiveCameraRotation(
        object,
        cameraData.horizontalAngle,
        cameraData.verticalAngle
      );
    }

    if (
      object.CameraType === CamerasType.MinervaCamera ||
      object.CameraType === CamerasType.MinervaGasCamera
    ) {
      const minervaLensParent = object.getObjectByName("SM_Minerva");
      const cameraName = "360LensCamera";

      minervaLensParent.children.forEach(lensParent => {
        this.createCamera(lensParent, viewportDimensions, 90, 1, cameraName);
      });
    }

    this.setCameraHeight(object, cameraData.height, defaultCameraHeight);

    return object;
  }

  createCamera(object, viewportDimensions, fov, zoom, name) {
    const { width, height } = viewportDimensions;

    const fieldOfView = fov || 45;
    const aspectRatio = width / height;
    const nearClip = 0.1;
    const farClip = 10000;

    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      aspectRatio,
      nearClip,
      farClip
    );

    camera.zoom = zoom || 1;

    camera.name = name;

    object.add(camera);

    return camera;
  }

  setPerspectiveCameraRotation(object, horizontalAngle, verticalAngle) {
    const cameraHorRotation = object.getObjectByName("SM_Camera_Hor");
    cameraHorRotation.rotation.z = THREE.MathUtils.degToRad(
      horizontalAngle || 0
    );

    const cameraVertRotation = object.getObjectByName("SM_Camera_Vert");
    cameraVertRotation.rotation.x =
      THREE.MathUtils.degToRad(verticalAngle) * -1;
  }

  setCameraHeight(object, height, defaultCameraHeight) {
    object.position.y =
      height === undefined ? defaultCameraHeight : feetToMeters(height);
  }
}

export default CameraBuilder;
