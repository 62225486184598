import CamerasData from "../../../data/CamerasData";

export const getParamDataByInputType = (state, type) => {
  return state.find(p => p.type === type);
};

export const getCameraModelDataByType = type => {
  return CamerasData.find(data => data.name === type);
};

export const CameraTypeParam = [
  {
    type: "cameraType",
    title: "Type of camera:",
    inputData: {
      defaultDisabled: false,
      placeholder: "Select...",
      type: "cameraType",
      inputType: "select",
      value: "Select...",
      options: CamerasData.map(d => d.name),
    },
  },
];

export const CameraHeightParam = [
  {
    type: "height",
    title: "Height (ft):",
    inputData: {
      defaultDisabled: false,
      type: "height",
      inputType: "number",
      value: 10,
      max: 44,
      min: 5,
      step: 0.1,
    },
  },
];

export const Camera360FielOfViewParam = [
  {
    type: "360-fov",
    title: "Field of view:",
    inputData: {
      defaultDisabled: false,
      type: "360-fov",
      inputType: "readonly",
      value: "360°",
    },
  },
];

export const CameraParams = [
  {
    type: "fieldOfView",
    title: "Field of view:",
    inputData: {
      defaultDisabled: false,
      type: "fieldOfView",
      inputType: "select",
      value: 45,
      options: [9, 20, 45, 75, 90],
    },
  },
  {
    type: "verticalAngle",
    title: "Angle vert. (deg):",
    inputData: {
      defaultDisabled: false,
      type: "verticalAngle",
      inputType: "number",
      value: 0,
      max: 180,
      min: -180,
      step: 0.5,
    },
  },
  {
    type: "horizontalAngle",
    title: "Angle hor. (deg):",
    inputData: {
      defaultDisabled: false,
      type: "horizontalAngle",
      inputType: "number",
      value: 0,
      max: 180,
      min: -180,
      step: 0.5,
    },
  },
];
