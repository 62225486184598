import { ApolloError, useMutation } from "@apollo/client";

import {
  DeleteAllAnnotationsMutation,
  DeleteAllAnnotationsMutationVariables,
} from "../../../../API";
import { DELETE_ALL_ANNOTATIONS } from "../../../../common/operations/mutations";
import {
  errorNotification,
  successNotification,
} from "../../../../common/variables/notification";

export const useDeleteAllAnnotations = () => {
  const [deleteAllAnnotationsMutation, { data, loading }] = useMutation<
    DeleteAllAnnotationsMutation,
    DeleteAllAnnotationsMutationVariables
  >(DELETE_ALL_ANNOTATIONS);

  const deleteAllAnnotations = async (items: any) => {
    return await deleteAllAnnotationsMutation({
      variables: {
        annotationsJSON: JSON.stringify({ annotations: items }),
      },
      onCompleted: response => {
        if (response.deleteAllAnnotations) {
          successNotification();
        }
      },
      onError: (error: ApolloError): void => {
        errorNotification(error.message);
      },
    });
  };

  return { deleteAllAnnotations, data, loading };
};
