import { UpdateAdminSystemAlertInput } from "../../../../../../../API";
import StyledDialog from "../../../../../../../common/components/dialog/StyledDialog";
import { useCustomerIdGuard } from "../../../../../../../common/hooks/useCustomerIdGuard";
import {
  errorNotification,
  successNotification,
} from "../../../../../../../common/variables/notification";
import { useUpdateAdminSystemAlert } from "../../../hooks/useUpdateAdminSystemAlert";
import {
  alertDialogVariables,
  defaultAlertDialogVariables,
  useAlertDialog,
} from "../../../variables/alerts";
import {
  defaultSystemAlertFormVariables,
  systemAlertFormVariables,
  useSystemAlertFormVariables,
} from "../../../variables/systemAlerts";
import EmailAlertDialogForm from "./SystemAlertDialogForm";

interface IUpdateSystemAlertDialogProps {
  id: string;
  status: boolean;
}

const UpdateSystemAlertDialog = ({
  id,
  status,
}: IUpdateSystemAlertDialogProps): JSX.Element => {
  const selectedCustomerId = useCustomerIdGuard();

  const { isOpen, rowId } = useAlertDialog();
  const alertForm = useSystemAlertFormVariables();

  const { updateAlert, loading } = useUpdateAdminSystemAlert();

  const handleDialogClose = (): void => {
    alertDialogVariables(defaultAlertDialogVariables);
    systemAlertFormVariables({
      ...defaultSystemAlertFormVariables,
    });
  };

  const handleSubmit = (): void => {
    const input: UpdateAdminSystemAlertInput = {
      id,
      customerId: selectedCustomerId,
      status,
      customService: alertForm.customService,
      jsonObject: JSON.stringify(
        alertForm.jsonObject === "" ? {} : alertForm.jsonObject
      ),
      node: alertForm.node,
    };

    updateAlert(input)
      .then((response): void => {
        if (response?.data) {
          successNotification("Alert was successfully updated");
          handleDialogClose();
        }

        if (response.errors) {
          errorNotification();
        }
      })
      .catch((error): void => {
        errorNotification();
        console.error(error);
      });
  };

  return (
    <StyledDialog
      open={isOpen && rowId === id}
      title="Update System Alert"
      maxWidth="sm"
      onClose={handleDialogClose}
      SubmitButtonProps={{
        onSubmit: handleSubmit,
        loading,
        title: "Update",
      }}
    >
      <EmailAlertDialogForm />
    </StyledDialog>
  );
};

export default UpdateSystemAlertDialog;
