import { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";

import ModalAlertContext from "../../../common/context/ModalAlertContext/ModalAlertContext";

import { IconPlus } from "../../../common/assets/icons/svgAssets";

const DashboardPageHeader = () => {
  const { openCreateNewProjectModal } = useContext(ModalAlertContext);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "44px 0 56px",
        borderBottom: "1px solid #98A7BC",
        "@media (max-width:1500px)": {
          padding: "35px 0 48px",
        },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          color: "#202832",
        }}
      >
        Projects
      </Typography>
      <Button
        onClick={openCreateNewProjectModal}
        borders="rectangle"
        variant="primary"
        color="green"
      >
        <IconPlus width="20px" />
        <Typography variant="body16Medium">New project</Typography>
      </Button>
    </Box>
  );
};

export default DashboardPageHeader;
