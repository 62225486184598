import { useQuery } from "@apollo/client";

import { GetRolesQuery, GetRolesQueryVariables } from "../../../../API";
import { GET_ROLES } from "../../../operations/queries";

export const USERS_TABLE_LIMIT = 25;

export const useGetRoles = () => {
  const { data, loading } = useQuery<GetRolesQuery, GetRolesQueryVariables>(
    GET_ROLES,
    {
      variables: {},
    }
  );

  return { data, loading };
};
