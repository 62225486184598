import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useCurrentUserSession } from "../../common/hooks/useCurrentUserSession";

// TODO: clean up page UI as needed
const NotAuthorizedPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { userGroups } = useCurrentUserSession();

  const handleSignOut = async (): Promise<any> => {
    await Auth.signOut();
    window.location.replace("/login");
  };

  // if there is a human validator role then show button to navigate there
  const isHumanValidatorRole = (): boolean => {
    return (userGroups && userGroups[1] === "1670918123") || false;
  };

  return (
    <div style={{ flex: 1, display: "flex" }}>
      <div>
        You were not authorized to view that page, please navigate to a page you
        have access to.
      </div>
      <button onClick={handleSignOut}>Sign out</button>
      <button onClick={(): void => history.back()}>Go Back</button>
      {isHumanValidatorRole() && (
        <button onClick={(): void => navigate("/human-validator")}>
          Go to Human Validator
        </button>
      )}
    </div>
  );
};

export default NotAuthorizedPage;
