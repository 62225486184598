import { useContext } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { WarningIcon } from "../../../common/assets/icons/svgAssets";
import ModalWindow from "../../../common/components/ModalWindow";

import ModalAlertContext from "../../../common/context/ModalAlertContext/ModalAlertContext";

import { useDeleteDTProject } from "../hooks/useDeleteDTProject";
import { CUSTOMER_ID } from "../hooks/useGetDTProjects";

const DeleteProjectModal = () => {
  const {
    isDisplayDeleteProjectModal,
    closeDeleteProjectModal,
    openAlert,
    selectedProjectData,
  } = useContext(ModalAlertContext);

  const { deleteDTProject, loading } = useDeleteDTProject();

  const onCloseModal = () => {
    if (loading) return;

    closeDeleteProjectModal();
  };

  const onDeleteButton = async () => {
    await deleteDTProject(
      selectedProjectData.projectId,
      CUSTOMER_ID,
      selectedProjectData.displayName
    );

    openAlert("deleted");
    onCloseModal();
  };

  return (
    <ModalWindow isOpen={isDisplayDeleteProjectModal} onClose={onCloseModal}>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "40px",
        }}
      >
        <Box
          sx={{
            width: "24px",
            height: "100%",
            position: "absolute",
            left: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WarningIcon fillColor="#E17575" />
        </Box>

        <Typography id="modal-modal-title" variant="h3" component="h2">
          Delete the project
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "40px",
          margin: "16px 0 24px",
        }}
      >
        <Typography id="modal-modal-description" variant="body14Regular">
          {`Are you sure you want to delete the ${
            selectedProjectData && selectedProjectData.displayName
          } ?`}
        </Typography>
      </Box>

      <Stack direction="row" spacing={1} justifyContent="flex-end">
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={onDeleteButton}
          borders="rectangle"
          variant="primary"
          color="red"
        >
          Delete
        </LoadingButton>
        <Button
          disabled={loading}
          onClick={onCloseModal}
          borders="rectangle"
          variant="secondary"
          color="blue"
        >
          Cancel
        </Button>
      </Stack>
    </ModalWindow>
  );
};

export default DeleteProjectModal;
