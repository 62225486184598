import { Paper } from "@mui/material";

import NodesPageHeader from "./components/NodesPageHeader";
import NodesTable from "./components/NodesTable";

const NodesPage = (): JSX.Element => {
  return (
    <Paper>
      <NodesPageHeader />
      <NodesTable />
    </Paper>
  );
};

export default NodesPage;
